import React, { Component } from 'react';
import { Modal, Input, Select } from 'antd';
import { connect } from 'react-redux';
import Mixpanel from 'mixpanel-browser';

import { isEmpty } from '../../../utilities/util';
import { adminEffects } from '../../../@betterhalf-private/redux/src';

const { TextArea } = Input;
const { Option } = Select;

class FeedbackModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      optionValue: props.isHumanMatchmaker ? 'HUMAN_MATCHMAKER' : 'POSITIVE',
    };
  }

  componentDidMount() {
    const { getAdminProfileData } = this.props;
    getAdminProfileData();
  }

  handleOkPress = () => {
    const { onModalClose, sendUserFeedback, userData, isHumanMatchmaker } =
      this.props;
    const { optionValue, text } = this.state;

    const data = {
      type: isHumanMatchmaker ? 'human_matchmaker' : optionValue.toLowerCase(),
      feedback: 'Admin Feedback',
      description: text,
    };

    Mixpanel.track('FEEDBACK_SUBMITTED', { id: userData.id, data });
    sendUserFeedback({ id: userData.id, data });
    onModalClose();
    return null;
  };

  onTextChange = ({ target: { value } }) => {
    this.setState({
      text: value,
    });
  };

  onChange = (value) => {
    this.setState({
      optionValue: value,
    });
  };

  render() {
    const { title, visible, onModalClose, isHumanMatchmaker } = this.props;
    const { text } = this.state;
    return (
      <Modal
        destroyOnClose
        onCancel={() => onModalClose()}
        visible={visible}
        title={title}
        onOk={this.handleOkPress}
        okButtonProps={
          !isEmpty(text) ? { disabled: false } : { disabled: true }
        }
      >
        {!isHumanMatchmaker && (
          <Select
            showSearch
            defaultValue={isHumanMatchmaker ? 'HUMAN_MATCHMAKER' : 'POSITIVE'}
            onChange={this.onChange}
            style={{ width: '30%' }}
          >
            <Option key="POSITIVE" value="HUMAN_MATCHMAKER">
              Human Matchmaker
            </Option>

            <Option key="POSITIVE" value="POSITIVE">
              Positive
            </Option>
            <Option key="NEGATIVE" value="NEGATIVE">
              Negative
            </Option>
          </Select>
        )}

        <TextArea
          placeholder="Please add your comments..."
          value={text}
          onChange={this.onTextChange}
          style={{ marginTop: 20 }}
          autoSize={{ minRows: 4 }}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  adminData: state.adminData.data,
  userFeedbackStatus: state.sendUserFeedback.isLoading,
  userFeedbackSuccess: state.sendUserFeedback.success,
  userFeedbackError: state.sendUserFeedback.error,
  userFeedbackData: state.sendUserFeedback.data,
});

export default connect(mapStateToProps, {
  sendUserFeedback: adminEffects.sendUserFeedback,
  getAdminProfileData: adminEffects.getAdminProfileData,
})(FeedbackModal);
