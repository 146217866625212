import React from 'react';
import { Collapse } from 'antd';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import './styles.scss';

export const { Panel } = Collapse;

const ExpandIcon = ({ isActive }) =>
  isActive ? (
    <UpCircleOutlined className="expandIcon-inner-panel" />
  ) : (
    <DownCircleOutlined className="expandIcon-inner-panel" />
  );

const DetailsCollapse = ({ children, ...restProps }) => (
  <Collapse
    accordion
    bordered={false}
    className="details-collapsible-container"
    expandIcon={ExpandIcon}
    expandIconPosition="right"
    {...restProps}
  >
    {children}
  </Collapse>
);

export default DetailsCollapse;
