import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Modal, Checkbox, Input } from 'antd';
import Mixpanel from 'mixpanel-browser';
import { isEmpty } from '../../../utilities/util';
import { constructReasons } from '../../../utilities/helper';
import { userProfileEffects } from '../../../@betterhalf-private/redux/src';
import { reasons } from './reasons';

const { TextArea } = Input;

class UnverifyModal extends PureComponent {
  constructor(props) {
    super(props);
    const reasonsCheck = [];
    reasons.map(() => {
      reasonsCheck.push(false);
      return null;
    });
    this.state = {
      text: '',
      showOk: false,
      reasonsCheck,
    };
  }

  onCheckBoxChange = (index) => {
    const { reasonsCheck, text } = this.state;
    const newReasonsCheck = [...reasonsCheck];
    newReasonsCheck[index] = !newReasonsCheck[index];
    this.setState({
      reasonsCheck: [...newReasonsCheck],
      showOk: newReasonsCheck.includes(true) || text.length > 0,
    });
  };

  onTextChange = ({ target: { value } }) => {
    const { text, reasonsCheck } = this.state;
    this.setState({
      text: value,
      showOk: text.length > 0 || reasonsCheck.includes(true),
    });
  };

  handleModalClear = () => {
    this.setState({
      text: '',
      showOk: false,
      reasonsCheck: [],
    });
  };

  handelOkPress = () => {
    const { text, reasonsCheck, showOk } = this.state;
    const {
      updateUserDetails,
      userData: { id },
    } = this.props;
    if (showOk ) {
      let data = {};
      if (reasonsCheck.includes(true)) {
        data = {
          verification_status: 'unverified_by_admin',
          reason: constructReasons({
            predefindReasons: reasons,
            reasonsCheck,
            extraText: text,
          }),
        };
        this.handleModalClear()
      } else {
        data = {
          verification_status: 'unverified_by_admin',
          reason: constructReasons({ extraText: text }),
        };
        this.handleModalClear()
      }
      Mixpanel.track('CHANGE_VERIFICATION_STATUS', {
        id,
        data,
      });
      updateUserDetails({ id, data });
    }
  };

  render() {
    const { title, visible, onModalClose } = this.props;
    const { text, reasonsCheck } = this.state;
    const Reasons = reasons.map((item) => (
      <div key={`unverify_reason_${item.index}`}>
        <Checkbox
          checked={reasonsCheck[item.index]}
          defaultChecked={false}
          onChange={() => this.onCheckBoxChange(item.index)}
        >
          {item.reason}
        </Checkbox>
      </div>
    ));

    return (
      <Modal
        destroyOnClose
        onCancel={() => onModalClose()}
        visible={visible}
        title={title}
        onOk={this.handelOkPress}
        okButtonProps={
          reasonsCheck.includes(true) || !isEmpty(text)
            ? { disabled: false }
            : { disabled: true }
        }
      >
        {Reasons}
        <div>
          <TextArea
            placeholder="Please Specify the reason"
            value={text}
            onChange={this.onTextChange}
            style={{ marginTop: 20 }}
            autoSize={{ minRows: 4 }}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.data,
  loadingStatus: state.userData.isLoading,
  updateUserLoadingStatus: state.updateUserData.isLoading,
  updateUserState: state.updateUserData.success,
});

export default connect(mapStateToProps, {
  updateUserDetails: userProfileEffects.updateUserDetails,
})(UnverifyModal);