import React, { PureComponent } from 'react';
import { Modal, Upload } from 'antd';
import { connect } from 'react-redux';
import Compress from 'compress.js';
import { InboxOutlined } from '@ant-design/icons';

import { userProfileEffects } from '../../../@betterhalf-private/redux/src';

const { Dragger } = Upload;
const compress = new Compress();

class ImageUploadModal extends PureComponent {
  constructor() {
    super();
    this.state = {
      file: null,
    };
  }

  handleOkPress = async () => {
    const {
      userData: { id },
      addUserImage,
    } = this.props;
    const { file } = this.state;
    const payload = new FormData();
    payload.append('image', file, file.name);
    await addUserImage({ id, payload });
    this.onClose();
    return null;
  };

  onClose = () => {
    const { onModalClose } = this.props;
    this.setState({ file: null });
    onModalClose();
  };

  saveResizedFile = (file) => {
    const context = this;
    const u = URL.createObjectURL(file);
    const img = new Image();
    img.onload = () => {
      compress
        .compress([file], {
          quality: 0.9, // the quality of the image, max is 1,
          maxWidth: 960,
          maxHeight: (img.height / img.width) * 960,
          resize: true,
        })
        .then((data) => {
          const img1 = data[0];
          const base64str = img1.data;
          const imgExt = img1.ext;
          const newBlob = Compress.convertBase64ToFile(base64str, imgExt);
          const newFile = new File([newBlob], 'fileName.jpeg', {
            lastModified: new Date().getTime(),
            type: newBlob.type,
          });
          context.setState({ file: newFile });
        });
    };
    img.src = u;
    return false;
  };

  render() {
    const { title, visible } = this.props;
    const { file } = this.state;
    const uploadProps = {
      name: 'file',
      multiple: false,
      beforeUpload: this.saveResizedFile,
    };

    return (
      <Modal
        destroyOnClose
        onCancel={this.onClose}
        visible={visible}
        title={title}
        onOk={this.handleOkPress}
        okButtonProps={{ disabled: !file }}
      >
        <Dragger {...uploadProps} disabled={file}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger>
      </Modal>
    );
  }
}

export default connect(null, {
  addUserImage: userProfileEffects.addUserImage,
})(ImageUploadModal);
