export const filtersAndSorterActionTypes = {
  filtersDataSuccess: 'FILTERSDATA_SUCCESS',
  SortDataSuccess: 'SORTDATA_SUCCESS',
  paginationDataSuccess: 'PAGINATIONDATA_SUCCESS',
};

const initialState = {
  filters: {},
  sorter: {},
  pageDetails: 1,
};

export function filtersAndSortReducer(state = initialState, action) {
  switch (action.type) {
    case filtersAndSorterActionTypes.filtersDataSuccess:
      return { ...state, filters: action.payload };

    case filtersAndSorterActionTypes.SortDataSuccess:
      return { ...state, sorter: action.payload };

    case filtersAndSorterActionTypes.paginationDataSuccess:
      return { ...state, pageDetails: action.payload };

    default:
      return state;
  }
}

export const filtersAndSorterActions = {
  setFiltersDataSuccess(filters) {
    return {
      type: filtersAndSorterActionTypes.filtersDataSuccess,
      payload: filters,
    };
  },
  setSortDataSuccess(sorter) {
    return {
      type: filtersAndSorterActionTypes.SortDataSuccess,
      payload: sorter,
    };
  },
  setPaginationSuccess(pageNo) {
    return {
      type: filtersAndSorterActionTypes.paginationDataSuccess,
      payload: pageNo,
    };
  },
};

export const filtersAndSorterEffects = {
  setFiltersData({ filters }) {
    return (dispatch) => {
      dispatch(filtersAndSorterActions.setFiltersDataSuccess(filters));
    };
  },
  setSorterData({ sorter }) {
    return (dispatch) => {
      dispatch(filtersAndSorterActions.setSortDataSuccess(sorter));
    };
  },
  setPaginationData({ pageNo }) {
    return (dispatch) => {
      dispatch(filtersAndSorterActions.setPaginationSuccess(pageNo));
    };
  },
};
