import React, { Component } from 'react';
import { Input, Button, Table, DatePicker, Pagination, Select } from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { connect } from 'react-redux';
import Mixpanel from 'mixpanel-browser';
import { withRouter } from 'react-router-dom';
import { fetchUserProfiles } from '../../utilities/apiUrls';
import { isEmpty } from '../../utilities/util';
import { columns } from './columnData';

import {
  filtersAndSorterEffects,
  listOfUsersinDashboardEffects,
} from '../../@betterhalf-private/redux/src';
import { gender } from '../../components/DetailsCard/data.enum';
import '../SearchUser/styles.less';

const { RangePicker } = DatePicker;
const { Option } = Select;

class HMSearchUser extends Component {
  constructor(props) {
    super(props);

    const {
      location: { search },
    } = props;

    const name = search.split('=');
    const key = name[0].substring(1);

    this.state = {
      dropDownClicked: false,
      searchEmail: key === 'email' ? name[1] : '',
      searchIdText: key === 'id' ? name[1] : '',
      searchFnameText: '',
      searchLnameText: '',
      searchMbNo: key === 'mobile' ? name[1] : '',
      dateStartDateStrings: [],
      dateEndDateStrings: [],
      gender: key === 'gender' ? name[1] : '',
    };
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
  }

  componentDidMount() {
    const {
      location: { search },
    } = this.props;

    if (!isEmpty(search)) {
      this.getUserProfiles();
    }
  }

  onChangeStartDate(dates, dateStrings) {
    this.setState({
      dateStartDateStrings: dateStrings,
    });
  }

  onChangeEndDate(dates, dateStrings) {
    this.setState({
      dateEndDateStrings: dateStrings,
    });
  }

  handleEmailChange = (e) => {
    this.setState({ searchEmail: e.target.value });
  };

  handleIdChange = (e) => {
    this.setState({ searchIdText: e.target.value });
  };

  handleFnameChange = (e) => {
    this.setState({ searchFnameText: e.target.value });
  };

  handleLnameChange = (e) => {
    this.setState({ searchLnameText: e.target.value });
  };

  handleMbNoChange = (e) => {
    this.setState({ searchMbNo: e.target.value });
  };

  getUserProfiles = () => {
    const {
      searchEmail,
      searchIdText,
      searchFnameText,
      searchLnameText,
      searchMbNo,
      dateStartDateStrings,
      dateEndDateStrings,
      // eslint-disable-next-line no-shadow
      gender,
    } = this.state;

    const { setPaginationData, getListOfUsersData } = this.props;

    let searchInput = '&plan_type=human_matchmaker';
    if (searchEmail) {
      searchInput += `&email=${searchEmail}`;
    }

    if (searchIdText) {
      searchInput += `&id=${searchIdText}`;
    }

    if (searchFnameText) {
      searchInput += `&first_name=${searchFnameText}`;
    }

    if (searchLnameText) {
      searchInput += `&last_name=${searchLnameText}`;
    }

    if (searchMbNo) {
      searchInput += `&mobile=${searchMbNo}&`;
    }

    if (gender) {
      searchInput += `&gender=${gender.toLowerCase()}`;
    }

    if (!isEmpty(searchInput)) {
      setPaginationData({ pageNo: 1 });
    }

    if (!isEmpty(dateStartDateStrings)) {
      searchInput += `&plan_start__gte=${dateStartDateStrings[0]}&plan_start__lte=${dateStartDateStrings[1]}`;
    }

    if (!isEmpty(dateEndDateStrings)) {
      searchInput += `&plan_end__gte=${dateEndDateStrings[0]}&plan_end__lte=${dateEndDateStrings[1]}`;
    }

    const UserProfilesUrl = `${fetchUserProfiles.url}?limit=10${searchInput}`;
    Mixpanel.track('SEARCH_USER', { passedParams: searchInput });
    getListOfUsersData(UserProfilesUrl);
  };

  onChangePage = (page) => {
    const { setPaginationData } = this.props;
    setPaginationData({ pageNo: page });
  };

  itemRender = (current, type, originalElement) => {
    const { previousUrl, loadingStatus, nextUrl, getListOfUsersData } =
      this.props;
    if (type === 'prev') {
      return (
        <Button
          disabled={isEmpty(previousUrl) || loadingStatus}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 10,
          }}
          onClick={
            isEmpty(previousUrl)
              ? () => {}
              : () => {
                  Mixpanel.track('LIST_OF_USER_PAGINATION_PREVIOUS');
                  getListOfUsersData(previousUrl);
                }
          }
        >
          <LeftOutlined />
        </Button>
      );
    }

    if (type === 'next') {
      return (
        <Button
          disabled={isEmpty(nextUrl) || loadingStatus}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 10,
          }}
          onClick={
            isEmpty(nextUrl)
              ? () => {}
              : () => {
                  Mixpanel.track('LIST_OF_USER_PAGINATION_NEXT');
                  getListOfUsersData(nextUrl);
                }
          }
        >
          <RightOutlined />
        </Button>
      );
    }

    return originalElement;
  };

  getDropDownChildren = ({ dropDownKey }) => {
    const children = [];
    if (dropDownKey === 'gender') {
      gender.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    }
    return children;
  };

  handleDropDownChange = (value, { dropDownKey }) => {
    if (dropDownKey === 'gender') {
      this.setState({
        gender: value,
      });
    }
  };

  render() {
    const {
      dropDownClicked,
      searchEmail,
      searchIdText,
      searchFnameText,
      searchLnameText,
      searchMbNo,
    } = this.state;
    const {
      listOfUsers,
      loadingStatus,
      totalNoOfUsers,
      filters,
      sorter,
      pageNo,
    } = this.props;
    return (
      <div className="SearchUser">
        <div style={{ width: 500, paddingBottom: 16 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: 10,
              paddingTop: 25,
            }}
          >
            {dropDownClicked ? (
              <CaretUpOutlined
                style={{ marginRight: 20 }}
                onClick={() => {
                  this.setState({ dropDownClicked: !dropDownClicked });
                }}
              />
            ) : (
              <CaretDownOutlined
                style={{ marginRight: 20 }}
                onClick={() => {
                  this.setState({ dropDownClicked: !dropDownClicked });
                }}
              />
            )}
            <Input
              allowClear
              size="large"
              value={searchEmail}
              placeholder="Email"
              onChange={this.handleEmailChange}
              disabled={loadingStatus}
              onPressEnter={this.getUserProfiles}
            />
            &nbsp;&nbsp;
            <Button
              shape="round"
              size="large"
              type="primary"
              onClick={this.getUserProfiles}
              loading={loadingStatus}
              disabled={loadingStatus}
            >
              Search
            </Button>
          </div>

          {dropDownClicked && (
            <div style={{ width: '73%', marginLeft: 34 }}>
              <div style={{ paddingBottom: 10 }}>
                <Input
                  size="large"
                  allowClear
                  value={searchIdText}
                  placeholder="id"
                  onChange={this.handleIdChange}
                  disabled={loadingStatus}
                  onPressEnter={this.getUserProfiles}
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Input
                  size="large"
                  allowClear
                  value={searchFnameText}
                  placeholder="First Name"
                  onChange={this.handleFnameChange}
                  disabled={loadingStatus}
                  onPressEnter={this.getUserProfiles}
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Input
                  size="large"
                  allowClear
                  value={searchLnameText}
                  placeholder="Last Name"
                  onChange={this.handleLnameChange}
                  disabled={loadingStatus}
                  onPressEnter={this.getUserProfiles}
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Input
                  size="large"
                  allowClear
                  value={searchMbNo}
                  placeholder="Mobile No."
                  onChange={this.handleMbNoChange}
                  disabled={loadingStatus}
                  onPressEnter={this.getUserProfiles}
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Select
                  showSearch
                  mode="default"
                  placeholder="Gender"
                  onChange={(value) =>
                    this.handleDropDownChange(value, {
                      dropDownKey: 'gender',
                    })
                  }
                  style={{ width: '100%' }}
                  allowClear
                  size="large"
                  disabled={loadingStatus}
                >
                  {this.getDropDownChildren({ dropDownKey: 'gender' })}
                </Select>
              </div>
              <div style={{ paddingBottom: 5 }}>Plan start date within</div>
              <div style={{ paddingBottom: 10 }}>
                <RangePicker
                  size="large"
                  allowClear
                  disabled={loadingStatus}
                  placeholder={['From Date', 'To Date']}
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                    'This Year': [
                      moment().startOf('year'),
                      moment().endOf('year'),
                    ],
                  }}
                  onChange={this.onChangeStartDate}
                />
              </div>

              <div style={{ paddingBottom: 5 }}>Plan end date within</div>
              <div style={{ paddingBottom: 10 }}>
                <RangePicker
                  size="large"
                  allowClear
                  placeholder={['From Date', 'To Date']}
                  disabled={loadingStatus}
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                    'This Year': [
                      moment().startOf('year'),
                      moment().endOf('year'),
                    ],
                  }}
                  onChange={this.onChangeEndDate}
                />
              </div>
            </div>
          )}
        </div>

        <Table
          loading={loadingStatus}
          columns={columns({ filters, sorter })}
          dataSource={!isEmpty(listOfUsers) ? listOfUsers : []}
          rowKey={(row) => row.id}
          scroll={{ x: true }}
          pagination={false}
          // eslint-disable-next-line no-shadow
          onChange={(pagination, filter, sorter) => {
            const { setSorterData, setFiltersData } = this.props;
            if (filter) {
              setFiltersData({ filters: filter });
            }

            if (sorter) {
              setSorterData({ sorter });
            }
          }}
        />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <Pagination
            hideOnSinglePage
            simple
            current={pageNo}
            total={totalNoOfUsers}
            onChange={this.onChangePage}
            itemRender={this.itemRender}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loadingStatus: state.listOfUsersData.isLoading,
  previousUrl: state.listOfUsersData.data.previous,
  totalNoOfUsers: state.listOfUsersData.data.count,
  nextUrl: state.listOfUsersData.data.next,
  filters: state.filtersAndSortData.filters,
  sorter: state.filtersAndSortData.sorter,
  pageNo: state.filtersAndSortData.pageDetails,
  listOfUsers: state.listOfUsersData.data.users,
});

export default connect(mapStateToProps, {
  getListOfUsersData: listOfUsersinDashboardEffects.getListOfUsersData,
  setFiltersData: filtersAndSorterEffects.setFiltersData,
  setSorterData: filtersAndSorterEffects.setSorterData,
  setPaginationData: filtersAndSorterEffects.setPaginationData,
})(withRouter(HMSearchUser));
