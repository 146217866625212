import axios from 'axios';
import Cookies from 'js-cookie';

export function setAuthHeaders() {
  const accessToken = Cookies.get('accessToken') || '';
  const tokenType = Cookies.get('tokenType') || '';
  axios.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
}

export function removeAuthHeaders() {
  axios.defaults.headers.common.Authorization = '';
}
