import React from 'react';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const ProfilePanel = ({ key, children, header, ...antdProps }) => (
  <Panel {...antdProps} header={header} key={key}>
    <div className="panelBody">{children}</div>
  </Panel>
);

export default ProfilePanel;
