export const MARITAL_STATUSES = [
  {
    id: 0,
    displayText: 'Never married',
    value: 'never_married',
  },
  {
    id: 1,
    displayText: 'Divorced',
    value: 'divorced',
  },
  {
    id: 2,
    displayText: 'Widowed',
    value: 'widowed',
  },
  {
    id: 3,
    displayText: 'Awaiting divorce',
    value: 'awaiting_divorce',
  },
];

export const RELIGIONS = [
  {
    id: 0,
    displayText: 'Hindu',
    value: 'hindu',
    caste: [
      {
        id: 0,
        displayText: 'Agarwal',
        value: 'agarwal',
      },
      {
        id: 1,
        displayText: 'Arora',
        value: 'arora',
      },
      {
        id: 2,
        displayText: 'Baniya',
        value: 'baniya',
      },
      {
        id: 3,
        displayText: 'Brahmin',
        value: 'brahmin',
      },
      {
        id: 4,
        displayText: 'Gujarati',
        value: 'gujarati',
      },
      {
        id: 5,
        displayText: 'Gupta',
        value: 'gupta',
      },
      {
        id: 6,
        displayText: 'Jat',
        value: 'jat',
      },
      {
        id: 7,
        displayText: 'Kayastha',
        value: 'kayastha',
      },
      {
        id: 8,
        displayText: 'Khatri',
        value: 'khatri',
      },
      {
        id: 9,
        displayText: 'Kshatriya',
        value: 'kshatriya',
      },
      {
        id: 10,
        displayText: 'Patel',
        value: 'patel',
      },
    ],
  },
  {
    id: 1,
    displayText: 'Muslim',
    value: 'muslim',
    caste: [
      {
        id: 0,
        displayText: 'Bangali',
        value: 'bangali',
      },
      {
        id: 1,
        displayText: 'Bohra',
        value: 'bohra',
      },
      {
        id: 2,
        displayText: 'Khoja',
        value: 'khoja',
      },
      {
        id: 3,
        displayText: 'Mapila',
        value: 'mapila',
      },
      {
        id: 4,
        displayText: 'Memon',
        value: 'memon',
      },
      {
        id: 5,
        displayText: 'Rajput',
        value: 'rajput',
      },
      {
        id: 6,
        displayText: 'Shafi',
        value: 'shafi',
      },
      {
        id: 7,
        displayText: 'Shia',
        value: 'shia',
      },
      {
        id: 8,
        displayText: 'Sunni',
        value: 'sunni',
      },
      {
        id: 9,
        displayText: 'Other',
        value: 'other',
      },
    ],
  },
  {
    id: 2,
    displayText: 'Christian',
    value: 'christian',
    caste: [
      {
        id: 0,
        displayText: 'Anglo',
        value: 'anglo',
      },
      {
        id: 1,
        displayText: 'Born again',
        value: 'born again',
      },
      {
        id: 2,
        displayText: 'Catholic',
        value: 'catholic',
      },
      {
        id: 3,
        displayText: 'CNI',
        value: 'cni',
      },
      {
        id: 4,
        displayText: 'CSI',
        value: 'csi',
      },
      {
        id: 5,
        displayText: 'Jacobite',
        value: 'jacobite',
      },
      {
        id: 6,
        displayText: 'Pentecost',
        value: 'pentecost',
      },
      {
        id: 7,
        displayText: 'Marthoma',
        value: 'marthoma',
      },
      {
        id: 8,
        displayText: 'Methodist',
        value: 'methodist',
      },
      {
        id: 9,
        displayText: 'Orthodox',
        value: 'orthodox',
      },
      {
        id: 10,
        displayText: 'Presbyterian',
        value: 'presbyterian',
      },
      {
        id: 11,
        displayText: 'Protestant',
        value: 'protestant',
      },
      {
        id: 12,
        displayText: 'Adventist',
        value: 'adventist',
      },
      {
        id: 13,
        displayText: 'Baptist',
        value: 'baptist',
      },
      {
        id: 14,
        displayText: 'Brethren',
        value: 'brethren',
      },
      {
        id: 15,
        displayText: 'Luthren',
        value: 'luthren',
      },
      {
        id: 16,
        displayText: 'Anglican',
        value: 'anglican',
      },
      {
        id: 17,
        displayText: 'Other',
        value: 'other',
      },
    ],
  },
  {
    id: 3,
    displayText: 'Sikh',
    value: 'sikh',
    caste: [
      {
        id: 0,
        displayText: 'Arora',
        value: 'arora',
      },
      {
        id: 1,
        displayText: 'Shaven',
        value: 'shaven',
      },
      {
        id: 2,
        displayText: 'Gursikh',
        value: 'gursikh',
      },
      {
        id: 3,
        displayText: 'Jat',
        value: 'jat',
      },
      {
        id: 4,
        displayText: 'Kamboj',
        value: 'kamboj',
      },
      {
        id: 5,
        displayText: 'Ramgharia',
        value: 'ramgharia',
      },
      {
        id: 6,
        displayText: 'Ramdasia',
        value: 'ramdasia',
      },
      {
        id: 7,
        displayText: 'Other',
        value: 'other',
      },
    ],
  },
  {
    id: 4,
    displayText: 'Buddhist',
    value: 'buddhist',
    caste: [
      {
        id: 0,
        displayText: 'other',
        value: 'other',
      },
    ],
  },
  {
    id: 5,
    displayText: 'Jain',
    value: 'jain',
    caste: [
      {
        id: 0,
        displayText: 'Digambar',
        value: 'digambar',
      },
      {
        id: 1,
        displayText: 'Shwetamber',
        value: 'shwetamber',
      },
      {
        id: 2,
        displayText: 'Vania',
        value: 'vania',
      },
      {
        id: 3,
        displayText: 'Other',
        value: 'other',
      },
    ],
  },
  {
    id: 6,
    displayText: 'Parsis',
    value: 'parsis',

    caste: [
      {
        id: 0,
        displayText: 'other',
        value: 'other',
      },
    ],
  },
  {
    id: 7,
    displayText: 'Others',
    value: 'others',
    caste: [
      {
        id: 0,
        displayText: 'other',
        value: 'other',
      },
    ],
  },
  { id: 8, displayText: 'Any', value: 'any' },
];

export const HINDU_COMMUNITIES = [
  {
    id: 0,
    displayText: 'Agarwal',
  },
  {
    id: 1,
    displayText: 'Arora',
  },
  {
    id: 2,
    displayText: 'Baniya',
  },
  {
    id: 3,
    displayText: 'Brahmin',
  },
  {
    id: 4,
    displayText: 'Gujarati',
  },
  {
    id: 5,
    displayText: 'Gupta',
  },
  {
    id: 6,
    displayText: 'Jat',
  },
  {
    id: 7,
    displayText: 'Kayastha',
  },
  {
    id: 8,
    displayText: 'Khatri',
  },
  {
    id: 9,
    displayText: 'Kshatriya',
  },
  {
    id: 10,
    displayText: 'Patel',
  },
  {
    id: 11,
    displayText: 'Rajput',
  },
  {
    id: 12,
    displayText: 'Sindhi',
  },
  {
    id: 13,
    displayText: 'Vaish',
  },
  {
    id: 14,
    displayText: 'Agri',
  },
  {
    id: 15,
    displayText: 'Ahom',
  },
  {
    id: 16,
    displayText: 'Arekatica',
  },
  {
    id: 17,
    displayText: 'Arunthathiyar',
  },
  {
    id: 18,
    displayText: 'Arya Vysya',
  },
  {
    id: 19,
    displayText: 'Arya Samaj',
  },
  {
    id: 20,
    displayText: 'Baidya',
  },
  {
    id: 21,
    displayText: 'Baishya',
  },
  {
    id: 22,
    displayText: 'Bilava',
  },
  {
    id: 23,
    displayText: 'Brahmbatt',
  },
  {
    id: 24,
    displayText: 'Brahmo',
  },
  {
    id: 25,
    displayText: 'Bunt',
  },
  {
    id: 26,
    displayText: 'CKP',
  },
  {
    id: 27,
    displayText: 'Chambar',
  },
  {
    id: 28,
    displayText: 'Chandravanshi Kahar',
  },
  {
    id: 29,
    displayText: 'Chaudary',
  },
  {
    id: 30,
    displayText: 'Chaurasia',
  },
  {
    id: 31,
    displayText: 'Cheramar',
  },
  {
    id: 32,
    displayText: 'Chettiar',
  },
  {
    id: 33,
    displayText: 'Chhetri',
  },
  {
    id: 34,
    displayText: 'Devanga',
  },
  {
    id: 35,
    displayText: 'Devendra Kula Vellar',
  },
  {
    id: 36,
    displayText: 'Dhoba',
  },
  {
    id: 37,
    displayText: 'Dhobi',
  },
  {
    id: 38,
    displayText: 'Ediga',
  },
  {
    id: 39,
    displayText: 'Ezhavathi',
  },
  {
    id: 40,
    displayText: 'Garhwali',
  },
  {
    id: 41,
    displayText: 'Ghumar',
  },
  {
    id: 42,
    displayText: 'Goan',
  },
  {
    id: 43,
    displayText: 'Goud',
  },
  {
    id: 44,
    displayText: 'Gounder',
  },
  {
    id: 45,
    displayText: 'Gowda',
  },
  {
    id: 46,
    displayText: 'Jaiswal',
  },
  {
    id: 47,
    displayText: 'Jatav',
  },
  {
    id: 48,
    displayText: 'Kalar',
  },
  {
    id: 49,
    displayText: 'Kalita',
  },
  {
    id: 50,
    displayText: 'Kalwar',
  },
  {
    id: 51,
    displayText: 'Kamma',
  },
  {
    id: 52,
    displayText: 'Kammala',
  },
  {
    id: 53,
    displayText: 'Kannada Mogaveera',
  },
  {
    id: 54,
    displayText: 'Karana',
  },
  {
    id: 55,
    displayText: 'Kapu Naidu',
  },
  {
    id: 56,
    displayText: 'Karmakar',
  },
  {
    id: 57,
    displayText: 'Kasar',
  },
  {
    id: 58,
    displayText: 'Kashyap',
  },
  {
    id: 59,
    displayText: 'Khandelwal',
  },
  {
    id: 60,
    displayText: 'Khatik',
  },
  {
    id: 61,
    displayText: 'Koli',
  },
  {
    id: 62,
    displayText: 'Konkani',
  },
  {
    id: 63,
    displayText: 'Kori',
  },
  {
    id: 64,
    displayText: 'Koshti',
  },
  {
    id: 65,
    displayText: 'Kumaoni',
  },
  {
    id: 66,
    displayText: 'Kumhar',
  },
  {
    id: 67,
    displayText: 'Kummari',
  },
  {
    id: 68,
    displayText: 'Kunbi',
  },
  {
    id: 69,
    displayText: 'Kurava',
  },
  {
    id: 70,
    displayText: 'Kurmi',
  },
  {
    id: 71,
    displayText: 'Kuruba',
  },
  {
    id: 72,
    displayText: 'Kushwaha',
  },
  {
    id: 73,
    displayText: 'Kutchi',
  },
  {
    id: 74,
    displayText: 'Lanbani',
  },
  {
    id: 75,
    displayText: 'Leva Patil',
  },
  {
    id: 76,
    displayText: 'Lingayat',
  },
  {
    id: 77,
    displayText: 'Lohana',
  },
  {
    id: 78,
    displayText: 'Mahajan',
  },
  {
    id: 79,
    displayText: 'Mahar',
  },
  {
    id: 80,
    displayText: 'Maharashtrian',
  },
  {
    id: 81,
    displayText: 'Mahishya',
  },
  {
    id: 82,
    displayText: 'Malayalee Variar',
  },
  {
    id: 83,
    displayText: 'Mali',
  },
  {
    id: 84,
    displayText: 'Mangalorean',
  },
  {
    id: 85,
    displayText: 'Maratha',
  },
  {
    id: 86,
    displayText: 'Marvar',
  },
  {
    id: 87,
    displayText: 'Marwari',
  },
  {
    id: 88,
    displayText: 'Maurya',
  },
  {
    id: 89,
    displayText: 'Meena',
  },
  {
    id: 90,
    displayText: 'Mehra',
  },
  {
    id: 91,
    displayText: 'Menon',
  },
  {
    id: 92,
    displayText: 'Mudaliar',
  },
  {
    id: 93,
    displayText: 'Munnuru Kapu',
  },
  {
    id: 94,
    displayText: 'Nadar',
  },
  {
    id: 95,
    displayText: 'Naidu',
  },
  {
    id: 96,
    displayText: 'Naicker',
  },
  {
    id: 97,
    displayText: 'Nai',
  },
  {
    id: 98,
    displayText: 'Namasudra',
  },
  {
    id: 99,
    displayText: 'Nayak',
  },
  {
    id: 100,
    displayText: 'Nepali',
  },
  {
    id: 101,
    displayText: 'Oswal',
  },
  {
    id: 102,
    displayText: 'Padmashali',
  },
  {
    id: 103,
    displayText: 'Perika',
  },
  {
    id: 104,
    displayText: 'Pillai',
  },
  {
    id: 105,
    displayText: 'Prajapati',
  },
  {
    id: 106,
    displayText: 'Ravidasia',
  },
  {
    id: 107,
    displayText: 'Reddy',
  },
  {
    id: 108,
    displayText: 'Saini',
  },
  {
    id: 109,
    displayText: 'Shah',
  },
  {
    id: 110,
    displayText: 'Sonar',
  },
  {
    id: 111,
    displayText: 'Soni',
  },
  {
    id: 112,
    displayText: 'Sowrashtra',
  },
  {
    id: 113,
    displayText: 'Sri Vaishana',
  },
  {
    id: 114,
    displayText: 'Sundhi',
  },
  {
    id: 115,
    displayText: 'Sutar',
  },
  {
    id: 116,
    displayText: 'Swarnakar',
  },
  {
    id: 117,
    displayText: 'Tamil Yadava',
  },
  {
    id: 118,
    displayText: 'Tanti',
  },
  {
    id: 119,
    displayText: 'Teli',
  },
  {
    id: 120,
    displayText: 'Telugu',
  },
  {
    id: 121,
    displayText: 'Thakkar',
  },
  {
    id: 122,
    displayText: 'Thakur',
  },
  {
    id: 123,
    displayText: 'Thevar',
  },
  {
    id: 124,
    displayText: 'Thiyya',
  },
  {
    id: 125,
    displayText: 'Togata',
  },
  {
    id: 126,
    displayText: 'Udayar',
  },
  {
    id: 127,
    displayText: 'Vadagalai',
  },
  {
    id: 128,
    displayText: 'Vaishav',
  },
  {
    id: 129,
    displayText: 'Vaishya',
  },
  {
    id: 130,
    displayText: 'Vallala',
  },
  {
    id: 131,
    displayText: 'Valmiki',
  },
  {
    id: 132,
    displayText: 'Vanniyar',
  },
  {
    id: 133,
    displayText: 'Variat',
  },
  {
    id: 134,
    displayText: 'Varshney',
  },
  {
    id: 135,
    displayText: 'Velar',
  },
  {
    id: 136,
    displayText: 'Vellalar',
  },
  {
    id: 137,
    displayText: 'Vishwakarma',
  },
  {
    id: 138,
    displayText: 'Vokaliga',
  },
  {
    id: 139,
    displayText: 'Vysya',
  },
  {
    id: 140,
    displayText: 'Yadav',
  },
  {
    id: 141,
    displayText: 'Scheduled Caste',
  },
  {
    id: 142,
    displayText: 'Other',
  },
];

export const MOTHER_TONGUE_LIST = [
  {
    id: 1,
    displayText: 'Hindi',
    value: 'hindi',
  },
  {
    id: 2,
    displayText: 'Bengali',
    value: 'bengali',
  },
  {
    id: 3,
    displayText: 'Telugu',
    value: 'telugu',
  },
  {
    id: 4,
    displayText: 'Marathi',
    value: 'marathi',
  },
  {
    id: 5,
    displayText: 'Tamil',
    value: 'tamil',
  },
  {
    id: 6,
    displayText: 'Malayalam',
    value: 'malayalam',
  },
  {
    id: 7,
    displayText: 'Gujarati',
    value: 'gujarati',
  },
  {
    id: 8,
    displayText: 'Kannada',
    value: 'kannada',
  },
  {
    id: 9,
    displayText: 'Punjabi',
    value: 'punjabi',
  },
  {
    id: 10,
    displayText: 'Odia',
    value: 'odia',
  },
  {
    id: 11,
    displayText: 'Urdu',
    value: 'urdu',
  },
  {
    id: 12,
    displayText: 'Assamese',
    value: 'assamese',
  },
  {
    id: 13,
    displayText: 'Maithili',
    value: 'maithili',
  },
  {
    id: 14,
    displayText: 'Bhilli',
    value: 'bhilli',
  },
  {
    id: 15,
    displayText: 'Santali',
    value: 'santali',
  },
  {
    id: 16,
    displayText: 'Kashmiri',
    value: 'kashmiri',
  },
  {
    id: 17,
    displayText: 'Nepali',
    value: 'nepali',
  },
  {
    id: 18,
    displayText: 'Gondi',
    value: 'gondi',
  },
  {
    id: 19,
    displayText: 'Sindhi',
    value: 'sindhi',
  },
  {
    id: 20,
    displayText: 'Konkani',
    value: 'konkani',
  },
  {
    id: 21,
    displayText: 'English',
    value: 'english',
  },
  {
    id: 22,
    displayText: 'Marwari',
    value: 'marwari',
  },
  {
    id: 23,
    displayText: 'Other',
    value: 'other',
  },
  {
    id: 24,
    displayText: 'Any',
    value: 'any',
  },
];

// VEGETARIAN = 'vegetarian'
// NON_VEGETARIAN = 'non_vegetarian'
// VEGAN = 'vegan'
// EGGETARIAN = 'eggetarian'

export const FOOD_PREFERENCE_LIST = [
  {
    id: 0,
    displayText: 'Vegetarian',
    value: 'vegetarian',
  },
  {
    id: 1,
    displayText: 'Non-vegetarian',
    value: 'non_vegetarian',
  },
  {
    id: 2,
    displayText: 'Vegan',
    value: 'vegan',
  },
  {
    id: 3,
    displayText: 'Eggetarian',
    value: 'eggetarian',
  },
  {
    id: 4,
    displayText: 'Any',
    value: 'any',
  },
];

export const SETTLE_DOWN = [
  {
    id: 0,
    displayText: 'Within 6 months',
    dataToBeSent: '6 months',
  },
  {
    id: 1,
    displayText: 'In a year',
    dataToBeSent: 'in a year',
  },
  {
    id: 2,
    displayText: '1-2 years',
    dataToBeSent: '1-2 years',
  },
  {
    id: 3,
    displayText: '2+ years',
    dataToBeSent: '2+ years',
  },
];

export const LOCATION_LIST = [
  {
    type: 'Feature',
    geometry: { type: 'Point', coordinates: [77.1025, 28.7041] },
    properties: {
      city: 'Delhi',
      state: 'Delhi',
      country: 'India',
      tag: null,
    },
    displayText: 'Delhi',
    value: 'Delhi',
    id: 1,
  },
  {
    type: 'Feature',
    geometry: { type: 'Point', coordinates: [72.8777, 19.076] },
    properties: {
      city: 'Mumbai',
      state: 'Maharashtra',
      country: 'India',
      tag: null,
    },
    displayText: 'Mumbai',
    value: 'Mumbai',
    id: 2,
  },
  {
    type: 'Feature',
    geometry: { type: 'Point', coordinates: [77.5946, 12.9716] },
    properties: {
      city: 'Bangalore',
      state: 'Karnataka',
      country: 'India',
      tag: null,
    },
    displayText: 'Bangalore',
    value: 'Bangalore',
    id: 3,
  },
  {
    type: 'Feature',
    geometry: { type: 'Point', coordinates: [80.2707, 13.0827] },
    properties: {
      city: 'Chennai',
      state: 'Tamil Nadu',
      country: 'India',
      tag: null,
    },
    displayText: 'Chennai',
    value: 'Chennai',
    id: 4,
  },
  {
    type: 'Feature',
    geometry: { type: 'Point', coordinates: [88.3639, 22.5726] },
    properties: {
      city: 'Kolkata',
      state: 'West Bengal',
      country: 'India',
      tag: null,
    },
    displayText: 'Kolkata',
    value: 'Kolkata',
    id: 5,
  },
  {
    type: 'Feature',
    geometry: { type: 'Point', coordinates: [78.4867, 17.385] },
    properties: {
      city: 'Hyderabad',
      state: 'Tamil Nadu',
      country: 'India',
      tag: null,
    },
    displayText: 'Hyderabad',
    value: 'Hyderabad',
    id: 6,
  },
];

export const SMOKE_PREFERENCE_LIST = [
  {
    id: 0,
    displayText: 'Never',
    value: 'never',
  },
  {
    id: 1,
    displayText: 'Socially',
    value: 'socially',
  },
  {
    id: 2,
    displayText: 'Regularly',
    value: 'regularly',
  },
  {
    id: 3,
    displayText: 'Planning to quit',
    value: 'planning_to_quit',
  },
];

export const DRINK_PREFERENCE_LIST = [
  {
    id: 0,
    displayText: 'Never',
    value: 'never',
  },
  {
    id: 1,
    displayText: 'Socially',
    value: 'socially',
  },
  {
    id: 2,
    displayText: 'Regularly',
    value: 'regularly',
  },
  {
    id: 3,
    displayText: 'Planning to quit',
    value: 'planning_to_quit',
  },
];

export const HIGHEST_QUALIFICATION_LIST = [
  {
    id: 0,
    displayText: 'Masters of Business Administration',
  },
  {
    id: 1,
    displayText: 'Bachelor of Commerce',
  },
  {
    id: 2,
    displayText: 'Bachelor of Technology',
  },
  {
    id: 3,
    displayText: 'Bachelor of Arts',
  },
  {
    id: 4,
    displayText: 'Bachelor of Engineering',
  },
  {
    id: 5,
    displayText: 'Master of Arts',
  },
];

export const JOB_TITLE_LIST = [
  {
    id: 0,
    displayText: 'Software Engineer',
  },
  {
    id: 1,
    displayText: 'Business Analyst',
  },
  {
    id: 2,
    displayText: 'Team Lead',
  },
  {
    id: 3,
    displayText: 'Assistant Manager',
  },
  {
    id: 4,
    displayText: 'Consultant',
  },
  {
    id: 5,
    displayText: 'Entrepreneur',
  },
];

export const COLLEGE_NAME_LIST = [
  {
    id: 0,
    displayText: 'University Of Delhi',
  },
  {
    id: 1,
    displayText: 'University Of Mumbai',
  },
  {
    id: 2,
    displayText: 'University Of Pune',
  },
  {
    id: 3,
    displayText: 'Gujarat University',
  },
  {
    id: 4,
    displayText: 'Anna University Chennai',
  },
  {
    id: 5,
    displayText: 'Jntu College Of Engineering',
  },
];

// export const HEIGHT_DATA = [
//   {
//     id: 0,
//     displayText: "5'9(176cm)",
//     value: 71,
//   },
//   {
//     id: 1,
//     displayText: "5'10(177cm)",
//     value: 72,
//   },
//   {
//     id: 2,
//     displayText: "5'11(179cm)",
//     value: 73,
//   },
//   {
//     id: 3,
//     displayText: "6'0(180cm)",
//     value: 74,
//   },
//   {
//     id: 4,
//     displayText: "6'1(182cm)",
//     value: 75,
//   },
// ];

export const HEIGHT_DATA = [
  {
    value: 53,
  },
  {
    value: 54,
  },
  {
    value: 55,
  },
  {
    value: 56,
  },
  {
    value: 57,
  },
  {
    value: 58,
  },
  {
    value: 59,
  },
  {
    value: 60,
  },
  {
    value: 61,
  },
  {
    value: 62,
  },
  {
    value: 63,
  },
  {
    value: 64,
  },
  {
    value: 65,
  },

  {
    value: 66,
  },
  {
    value: 67,
  },

  {
    value: 68,
  },
  {
    value: 69,
  },
  {
    value: 70,
  },
  {
    value: 71,
  },
  {
    value: 72,
  },
  {
    value: 73,
  },
  {
    value: 74,
  },
  {
    value: 75,
  },
  {
    value: 76,
  },
  {
    value: 77,
  },
];

export const COMPANY_LIST = [
  {
    id: 0,
    displayText: 'Accenture',
  },
  {
    id: 1,
    displayText: 'Adobe',
  },
  {
    id: 2,
    displayText: 'TCS',
  },
  {
    id: 3,

    displayText: 'Amazon',
  },
  {
    id: 4,

    displayText: 'Infosys Ltd',
  },
  {
    id: 5,
    displayText: 'IBM',
  },
];

export const SALARY_DATA = [
  {
    id: 0,
    value: '0 - 2 Lpa',
    displayText: '0 - 2 Lpa',
  },
  {
    id: 1,
    value: '2 - 4 Lpa',
    displayText: '2 - 4 Lpa',
  },
  {
    id: 2,
    value: '4 - 7 Lpa',
    displayText: '4 - 7 Lpa',
  },
  {
    id: 3,
    value: '7 - 10 Lpa',
    displayText: '7 - 10 Lpa',
  },
  {
    id: 4,
    value: '10 - 15 Lpa',
    displayText: '10 - 15 Lpa',
  },
  {
    id: 5,
    value: '15 - 20 Lpa',
    displayText: '15 - 20 Lpa',
  },
  {
    id: 6,
    value: '20 - 30 Lpa',
    displayText: '20 - 30 Lpa',
  },
  {
    id: 7,
    value: '30 - 50 Lpa',
    displayText: '30 - 50 Lpa',
  },
  {
    id: 8,
    value: '50 - 100 Lpa',
    displayText: '50+ Lpa',
  },
];
export const COUNTRY_ISD_CODES = [
  {
    country: 'India',
    calling_code: 91,
  },
  {
    country: 'United States',
    calling_code: 1,
  },
  {
    country: 'Afghanistan',
    calling_code: 93,
  },
  {
    country: 'Albania',
    calling_code: 355,
  },
  {
    country: 'Algeria',
    calling_code: 213,
  },
  {
    country: 'American Samoa',
    calling_code: 1684,
  },
  {
    country: 'Andorra',
    calling_code: 376,
  },
  {
    country: 'Angola',
    calling_code: 244,
  },
  {
    country: 'Anguilla',
    calling_code: 1264,
  },
  {
    country: 'Antarctica',
    calling_code: 672,
  },
  {
    country: 'Antigua and Barbuda',
    calling_code: 1268,
  },
  {
    country: 'Argentina',
    calling_code: 54,
  },
  {
    country: 'Armenia',
    calling_code: 374,
  },
  {
    country: 'Aruba',
    calling_code: 297,
  },
  {
    country: 'Australia',
    calling_code: 61,
  },
  {
    country: 'Austria',
    calling_code: 43,
  },
  {
    country: 'Azerbaijan',
    calling_code: 994,
  },
  {
    country: 'Bahamas',
    calling_code: 1242,
  },
  {
    country: 'Bahrain',
    calling_code: 973,
  },
  {
    country: 'Bangladesh',
    calling_code: 880,
  },
  {
    country: 'Barbados',
    calling_code: 1246,
  },
  {
    country: 'Belarus',
    calling_code: 375,
  },
  {
    country: 'Belgium',
    calling_code: 32,
  },
  {
    country: 'Belize',
    calling_code: 501,
  },
  {
    country: 'Benin',
    calling_code: 229,
  },
  {
    country: 'Bermuda',
    calling_code: 1441,
  },
  {
    country: 'Bhutan',
    calling_code: 975,
  },
  {
    country: 'Bolivia',
    calling_code: 591,
  },
  {
    country: 'Bosnia and Herzegovina',
    calling_code: 387,
  },
  {
    country: 'Botswana',
    calling_code: 267,
  },
  {
    country: 'Bouvet Island',
    calling_code: 55,
  },
  {
    country: 'Brazil',
    calling_code: 55,
  },
  {
    country: 'British Indian Ocean Territory',
    calling_code: 246,
  },
  {
    country: 'Brunei',
    calling_code: 673,
  },
  {
    country: 'Bulgaria',
    calling_code: 359,
  },
  {
    country: 'Burkina Faso',
    calling_code: 226,
  },
  {
    country: 'Burundi',
    calling_code: 257,
  },
  {
    country: 'Cambodia',
    calling_code: 855,
  },
  {
    country: 'Cameroon',
    calling_code: 237,
  },
  {
    country: 'Canada',
    calling_code: 1,
  },
  {
    country: 'Cape Verde',
    calling_code: 238,
  },
  {
    country: 'Cayman Islands',
    calling_code: 1345,
  },
  {
    country: 'Central African Republic',
    calling_code: 236,
  },
  {
    country: 'Chad',
    calling_code: 235,
  },
  {
    country: 'Chile',
    calling_code: 56,
  },
  {
    country: 'China',
    calling_code: 86,
  },
  {
    country: 'Christmas Island',
    calling_code: 61,
  },
  {
    country: 'Cocos (Keeling) Islands',
    calling_code: 61,
  },
  {
    country: 'Colombia',
    calling_code: 57,
  },
  {
    country: 'Comoros',
    calling_code: 269,
  },
  {
    country: 'Congo',
    calling_code: 242,
  },
  {
    country: 'Cook Islands',
    calling_code: 682,
  },
  {
    country: 'Costa Rica',
    calling_code: 506,
  },
  {
    country: 'Croatia',
    calling_code: 385,
  },
  {
    country: 'Cuba',
    calling_code: 53,
  },
  {
    country: 'Cyprus',
    calling_code: 357,
  },
  {
    country: 'Czech Republic',
    calling_code: 420,
  },
  {
    country: 'Denmark',
    calling_code: 45,
  },
  {
    country: 'Djibouti',
    calling_code: 253,
  },
  {
    country: 'Dominica',
    calling_code: 1767,
  },
  {
    country: 'Dominican Republic',
    calling_code: 1849,
  },
  {
    country: 'East Timor',
    calling_code: 670,
  },
  {
    country: 'Ecuador',
    calling_code: 593,
  },
  {
    country: 'Egypt',
    calling_code: 20,
  },
  {
    country: 'El Salvador',
    calling_code: 503,
  },
  {
    country: 'England',
    calling_code: 44,
  },
  {
    country: 'Equatorial Guinea',
    calling_code: 240,
  },
  {
    country: 'Eritrea',
    calling_code: 291,
  },
  {
    country: 'Estonia',
    calling_code: 372,
  },
  {
    country: 'Ethiopia',
    calling_code: 251,
  },
  {
    country: 'Falkland Islands',
    calling_code: 500,
  },
  {
    country: 'Faroe Islands',
    calling_code: 298,
  },
  {
    country: 'Fiji Islands',
    calling_code: 679,
  },
  {
    country: 'Finland',
    calling_code: 358,
  },
  {
    country: 'France',
    calling_code: 33,
  },
  {
    country: 'French Guiana',
    calling_code: 594,
  },
  {
    country: 'French Polynesia',
    calling_code: 689,
  },
  {
    country: 'French Southern territories',
    calling_code: 262,
  },
  {
    country: 'Gabon',
    calling_code: 241,
  },
  {
    country: 'Gambia',
    calling_code: 220,
  },
  {
    country: 'Georgia',
    calling_code: 995,
  },
  {
    country: 'Germany',
    calling_code: 49,
  },
  {
    country: 'Ghana',
    calling_code: 233,
  },
  {
    country: 'Gibraltar',
    calling_code: 350,
  },
  {
    country: 'Greece',
    calling_code: 30,
  },
  {
    country: 'Greenland',
    calling_code: 299,
  },
  {
    country: 'Grenada',
    calling_code: 1473,
  },
  {
    country: 'Guadeloupe',
    calling_code: 590,
  },
  {
    country: 'Guam',
    calling_code: 1671,
  },
  {
    country: 'Guatemala',
    calling_code: 502,
  },
  {
    country: 'Guinea',
    calling_code: 224,
  },
  {
    country: 'Guinea-Bissau',
    calling_code: 245,
  },
  {
    country: 'Guyana',
    calling_code: 592,
  },
  {
    country: 'Haiti',
    calling_code: 509,
  },
  {
    country: 'Heard Island and McDonald Islands',
    calling_code: 672,
  },
  {
    country: 'Holy See (Vatican City State)',
    calling_code: 379,
  },
  {
    country: 'Honduras',
    calling_code: 504,
  },
  {
    country: 'Hong Kong',
    calling_code: 852,
  },
  {
    country: 'Hungary',
    calling_code: 36,
  },
  {
    country: 'Iceland',
    calling_code: 354,
  },
  {
    country: 'Indonesia',
    calling_code: 62,
  },
  {
    country: 'Iran',
    calling_code: 98,
  },
  {
    country: 'Iraq',
    calling_code: 964,
  },
  {
    country: 'Ireland',
    calling_code: 353,
  },
  {
    country: 'Israel',
    calling_code: 972,
  },
  {
    country: 'Italy',
    calling_code: 39,
  },
  {
    country: 'Ivory Coast',
    calling_code: 225,
  },
  {
    country: 'Jamaica',
    calling_code: 1876,
  },
  {
    country: 'Japan',
    calling_code: 81,
  },
  {
    country: 'Jordan',
    calling_code: 962,
  },
  {
    country: 'Kazakhstan',
    calling_code: 7,
  },
  {
    country: 'Kenya',
    calling_code: 254,
  },
  {
    country: 'Kiribati',
    calling_code: 686,
  },
  {
    country: 'Kuwait',
    calling_code: 965,
  },
  {
    country: 'Kyrgyzstan',
    calling_code: 996,
  },
  {
    country: 'Laos',
    calling_code: 856,
  },
  {
    country: 'Latvia',
    calling_code: 371,
  },
  {
    country: 'Lebanon',
    calling_code: 961,
  },
  {
    country: 'Lesotho',
    calling_code: 266,
  },
  {
    country: 'Liberia',
    calling_code: 231,
  },
  {
    country: 'Libyan Arab Jamahiriya',
    calling_code: 218,
  },
  {
    country: 'Liechtenstein',
    calling_code: 423,
  },
  {
    country: 'Lithuania',
    calling_code: 370,
  },
  {
    country: 'Luxembourg',
    calling_code: 352,
  },
  {
    country: 'Macao',
    calling_code: 853,
  },
  {
    country: 'North Macedonia',
    calling_code: 389,
  },
  {
    country: 'Madagascar',
    calling_code: 261,
  },
  {
    country: 'Malawi',
    calling_code: 265,
  },
  {
    country: 'Malaysia',
    calling_code: 60,
  },
  {
    country: 'Maldives',
    calling_code: 960,
  },
  {
    country: 'Mali',
    calling_code: 223,
  },
  {
    country: 'Malta',
    calling_code: 356,
  },
  {
    country: 'Marshall Islands',
    calling_code: 692,
  },
  {
    country: 'Martinique',
    calling_code: 596,
  },
  {
    country: 'Mauritania',
    calling_code: 222,
  },
  {
    country: 'Mauritius',
    calling_code: 230,
  },
  {
    country: 'Mayotte',
    calling_code: 262,
  },
  {
    country: 'Mexico',
    calling_code: 52,
  },
  {
    country: 'Micronesia, Federated States of',
    calling_code: 691,
  },
  {
    country: 'Moldova',
    calling_code: 373,
  },
  {
    country: 'Monaco',
    calling_code: 377,
  },
  {
    country: 'Mongolia',
    calling_code: 976,
  },
  {
    country: 'Montserrat',
    calling_code: 1664,
  },
  {
    country: 'Morocco',
    calling_code: 212,
  },
  {
    country: 'Mozambique',
    calling_code: 258,
  },
  {
    country: 'Myanmar',
    calling_code: 95,
  },
  {
    country: 'Namibia',
    calling_code: 264,
  },
  {
    country: 'Nauru',
    calling_code: 674,
  },
  {
    country: 'Nepal',
    calling_code: 977,
  },
  {
    country: 'Netherlands',
    calling_code: 31,
  },
  {
    country: 'Netherlands Antilles',
    calling_code: 599,
  },
  {
    country: 'New Caledonia',
    calling_code: 687,
  },
  {
    country: 'New Zealand',
    calling_code: 64,
  },
  {
    country: 'Nicaragua',
    calling_code: 505,
  },
  {
    country: 'Niger',
    calling_code: 227,
  },
  {
    country: 'Nigeria',
    calling_code: 234,
  },
  {
    country: 'Niue',
    calling_code: 683,
  },
  {
    country: 'Norfolk Island',
    calling_code: 672,
  },
  {
    country: 'North Korea',
    calling_code: 850,
  },
  {
    country: 'Northern Ireland',
    calling_code: 44,
  },
  {
    country: 'Northern Mariana Islands',
    calling_code: 1670,
  },
  {
    country: 'Norway',
    calling_code: 47,
  },
  {
    country: 'Oman',
    calling_code: 968,
  },
  {
    country: 'Pakistan',
    calling_code: 92,
  },
  {
    country: 'Palau',
    calling_code: 680,
  },
  {
    country: 'Palestine',
    calling_code: 970,
  },
  {
    country: 'Panama',
    calling_code: 507,
  },
  {
    country: 'Papua New Guinea',
    calling_code: 675,
  },
  {
    country: 'Paraguay',
    calling_code: 595,
  },
  {
    country: 'Peru',
    calling_code: 51,
  },
  {
    country: 'Philippines',
    calling_code: 63,
  },
  {
    country: 'Pitcairn',
    calling_code: 64,
  },
  {
    country: 'Poland',
    calling_code: 48,
  },
  {
    country: 'Portugal',
    calling_code: 351,
  },
  {
    country: 'Puerto Rico',
    calling_code: 1939,
  },
  {
    country: 'Qatar',
    calling_code: 974,
  },
  {
    country: 'Reunion',
    calling_code: 262,
  },
  {
    country: 'Romania',
    calling_code: 40,
  },
  {
    country: 'Russian Federation',
    calling_code: 7,
  },
  {
    country: 'Rwanda',
    calling_code: 250,
  },
  {
    country: 'Saint Helena',
    calling_code: 290,
  },
  {
    country: 'Saint Kitts and Nevis',
    calling_code: 1869,
  },
  {
    country: 'Saint Lucia',
    calling_code: 1758,
  },
  {
    country: 'Saint Pierre and Miquelon',
    calling_code: 508,
  },
  {
    country: 'Saint Vincent and the Grenadines',
    calling_code: 1784,
  },
  {
    country: 'Samoa',
    calling_code: 685,
  },
  {
    country: 'San Marino',
    calling_code: 378,
  },
  {
    country: 'Sao Tome and Principe',
    calling_code: 239,
  },
  {
    country: 'Saudi Arabia',
    calling_code: 966,
  },
  {
    country: 'Scotland',
    calling_code: 44,
  },
  {
    country: 'Senegal',
    calling_code: 221,
  },
  {
    country: 'Serbia',
    calling_code: 381,
  },
  {
    country: 'Seychelles',
    calling_code: 248,
  },
  {
    country: 'Sierra Leone',
    calling_code: 232,
  },
  {
    country: 'Singapore',
    calling_code: 65,
  },
  {
    country: 'Slovakia',
    calling_code: 421,
  },
  {
    country: 'Slovenia',
    calling_code: 386,
  },
  {
    country: 'Solomon Islands',
    calling_code: 677,
  },
  {
    country: 'Somalia',
    calling_code: 252,
  },
  {
    country: 'South Africa',
    calling_code: 27,
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    calling_code: 500,
  },
  {
    country: 'South Korea',
    calling_code: 82,
  },
  {
    country: 'South Sudan',
    calling_code: 211,
  },
  {
    country: 'Spain',
    calling_code: 34,
  },
  {
    country: 'Sri Lanka',
    calling_code: 'homeTown',
  },
  {
    country: 'Sudan',
    calling_code: 249,
  },
  {
    country: 'Suriname',
    calling_code: 597,
  },
  {
    country: 'Svalbard and Jan Mayen',
    calling_code: 47,
  },
  {
    country: 'Swaziland',
    calling_code: 268,
  },
  {
    country: 'Sweden',
    calling_code: 46,
  },
  {
    country: 'Switzerland',
    calling_code: 41,
  },
  {
    country: 'Syria',
    calling_code: 963,
  },
  {
    country: 'Tajikistan',
    calling_code: 992,
  },
  {
    country: 'Tanzania',
    calling_code: 255,
  },
  {
    country: 'Thailand',
    calling_code: 66,
  },
  {
    country: 'The Democratic Republic of Congo',
    calling_code: 243,
  },
  {
    country: 'Togo',
    calling_code: 228,
  },
  {
    country: 'Tokelau',
    calling_code: 690,
  },
  {
    country: 'Tonga',
    calling_code: 676,
  },
  {
    country: 'Trinidad and Tobago',
    calling_code: 1868,
  },
  {
    country: 'Tunisia',
    calling_code: 216,
  },
  {
    country: 'Turkey',
    calling_code: 90,
  },
  {
    country: 'Turkmenistan',
    calling_code: 993,
  },
  {
    country: 'Turks and Caicos Islands',
    calling_code: 1649,
  },
  {
    country: 'Tuvalu',
    calling_code: 688,
  },
  {
    country: 'Uganda',
    calling_code: 256,
  },
  {
    country: 'Ukraine',
    calling_code: 380,
  },
  {
    country: 'United Arab Emirates',
    calling_code: 971,
  },
  {
    country: 'United Kingdom',
    calling_code: 44,
  },
  {
    country: 'United States Minor Outlying Islands',
    calling_code: 1,
  },
  {
    country: 'Uruguay',
    calling_code: 598,
  },
  {
    country: 'Uzbekistan',
    calling_code: 998,
  },
  {
    country: 'Vanuatu',
    calling_code: 678,
  },
  {
    country: 'Venezuela',
    calling_code: 58,
  },
  {
    country: 'Vietnam',
    calling_code: 84,
  },
  {
    country: 'Virgin Islands, British',
    calling_code: 1,
  },
  {
    country: 'Virgin Islands, U.S.',
    calling_code: 1,
  },
  {
    country: 'Wales',
    calling_code: 44,
  },
  {
    country: 'Wallis and Futuna',
    calling_code: 681,
  },
  {
    country: 'Western Sahara',
    calling_code: 212,
  },
  {
    country: 'Yemen',
    calling_code: 967,
  },
  {
    country: 'Zambia',
    calling_code: 260,
  },
  {
    country: 'Zimbabwe',
    calling_code: 263,
  },
];

// create a feet-ich to cm converter
