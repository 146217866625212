/* eslint-disable indent */
import { snakeToCamel } from './utils';

export const OTPActions = {
  Loading: 'OTP_LOADING',
  Success: 'OTP_SUCCESS',
  Failure: 'OTP_Failure',
  verify: 'OTP_VERIFY',
  sendOTPLoading: 'SENDOTP_LOADING',
  sendOTPSuccess: 'SENDOTP_SUCCESS',
  sendOTPFailure: 'SENDOTP_FAILURE',
};

const initialState = {
  isLoading: false,
  data: {},
  error: {},
  success: false,
  accessToken: null,
};

export function sendOtpReducer(state = initialState, action) {
  switch (action.type) {
    case OTPActions.sendOTPLoading:
      return {
        ...state,
        isLoading: true,
        success: false,
      };

    case OTPActions.sendOTPSuccess:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };

    case OTPActions.sendOTPFailure:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false,
      };

    default:
      return state;
  }
}

export function otpReducer(state = initialState, action) {
  switch (action.type) {
    case OTPActions.Loading:
      return { ...state, isLoading: true, success: false };

    case OTPActions.Success:
      return {
        ...state,
        isLoading: false,
        accessToken: action.accessToken,
        success: true,
      };

    case OTPActions.Failure:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false,
        accessToken : null
      };

    default:
      return state;
  }
}

export const otpActions = {
  sendOTPLoading(bool) {
    return {
      type: OTPActions.sendOTPLoading,
      bool,
    };
  },
  sendOTPSuccess(data) {
    return {
      type: OTPActions.sendOTPSuccess,
      payload: data,
    };
  },
  sendOTPFailure(error) {
    return {
      type: OTPActions.sendOTPFailure,
      error,
    };
  },
  verificationPending(bool) {
    return {
      type: OTPActions.Loading,
      bool,
    };
  },
  verifyOTP(otp) {
    return {
      type: OTPActions.verify,
      otp,
    };
  },
  verificationSuccessful(accessToken) {
    return {
      type: OTPActions.Success,
      accessToken,
    };
  },
  verificationFailure() {
    return {
      type: OTPActions.Failure,
    };
  },
};

export const otpEffects = {
  sendOtp({ data }) {
    return async (dispatch) => {
      dispatch(otpActions.sendOTPLoading(true));
      try {
        const resp = await window.client.sendOTP({ ...data });
        if (resp.status >= 300 || resp.status < 200) throw resp;
        dispatch(otpActions.sendOTPSuccess(resp.body));
      } catch (e) {
        dispatch(otpActions.sendOTPFailure(e));
      }
    };
  },

  verifyOTP(otp) {
    return async (dispatch) => {
      dispatch(otpActions.verificationPending(true));
      try {
        const resp = await window.client.verifyOTP(otp);
        if (resp.status >= 300 || resp.status < 200) throw resp;
        const { body } = resp;
        const result = {};
        Object.keys(body).map((item) => {
          result[snakeToCamel(item)] = body[item];
          return null;
        });
        dispatch(otpActions.verificationSuccessful(result));
      } catch (e) {
        dispatch(otpActions.verificationFailure());
      }
    };
  },
};
