import React from 'react';
import { Input as AntdInput } from 'antd';
import { SearchOutlined, CloseCircleFilled } from '@ant-design/icons';
import './styles.scss';

const Searchbar = ({ placeholder, className, onChange, value, onClear }) => (
  <AntdInput
    className={`searchbar ${className || ''}`}
    prefix={<SearchOutlined />}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    suffix={<CloseCircleFilled onClick={onClear} />}
  />
);

export default Searchbar;
