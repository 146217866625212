import { Alert } from 'antd';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { ReactComponent as Female } from '../../../../assets/icons/female.svg';
import { ReactComponent as FemaleActive } from '../../../../assets/icons/femaleActive.svg';
import { ReactComponent as Male } from '../../../../assets/icons/male.svg';
import { ReactComponent as MaleActive } from '../../../../assets/icons/maleActive.svg';
import { useUserData } from '../../../UserProfile/CompleteProfile/UserDataContext';
import { editUserDetails } from '../../api';
import './styles.scss';

const GenderSection = () => {
  const {
    gender: userGender,
    id: userId,
    verificationStatus: userVerificationStatus,
  } = useUserData();

  const [gender, setGender] = useState(userGender);

  useEffect(() => {
    setGender(userGender);
  }, [userGender]);

  const handleSelection = async (genderType) => {
    setGender(genderType);
    const payload = {
      gender: genderType,
    };
    await editUserDetails(userId, payload);
  };
  // auto_unverified
  return (
    <div className="genderContainer">
      <h2>Gender</h2>
      <div className="genderContainer__inner">
        {/* //! Not editable */}
        {/* // Gender is editable only if the verification status is auto_unverified */}
        {userVerificationStatus !== 'auto_unverified' ? (
          <div>
            <Alert message="This field is not editable." type="info" showIcon />
            <Gender type={gender} selectedType={gender} />
          </div>
        ) : (
          <>
            <Gender
              type="male"
              selectedType={gender}
              onSelect={handleSelection}
            />
            <Gender
              type="female"
              selectedType={gender}
              onSelect={handleSelection}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default GenderSection;

const Gender = ({ type, selectedType, onSelect }) => (
  <button
    type="submit"
    onClick={() => onSelect(type)}
    className={onSelect ? 'editable' : ''}
  >
    <span className="button__icon">
      {type === 'male' ? (
        type === selectedType ? (
          <MaleActive />
        ) : (
          <Male />
        )
      ) : type === selectedType ? (
        <FemaleActive />
      ) : (
        <Female />
      )}
    </span>
    {/* // TODO : text color */}
    <span className={classNames({ 'text-pink': type === selectedType })}>
      {type === 'male' ? 'Male' : 'Female'}
    </span>
  </button>
);
