export default [
  {
    title: '',
    data: [
      {
        id: 'india_+91',
        name: 'India',
        code: '+91',
        numberOfDigits: '10',
      },
      {
        id: 'united_states_+1',
        name: 'United States',
        code: '+1',
        numberOfDigits: '10',
      },
      {
        id: 'united_kingdom_+44',
        name: 'United Kingdom',
        code: '+44',
        numberOfDigits: '10',
      },
      {
        id: 'united_arab_emirates_+971',
        name: 'United Arab Emirates',
        code: '+971',
        numberOfDigits: '9',
      },
    ],
  },
  {
    title: 'A',
    data: [
      {
        id: 'afghanistan_+93',
        name: 'Afghanistan',
        code: '+93',
        numberOfDigits: '9',
      },
      {
        id: 'aland_islands_+358',
        name: 'Aland Islands',
        code: '+358',
        numberOfDigits: '10',
      },
      {
        id: 'albania_+355',
        name: 'Albania',
        code: '+355',
        numberOfDigits: '9',
      },
      {
        id: 'algeria_+213',
        name: 'Algeria',
        code: '+213',
        numberOfDigits: '9',
      },
      {
        id: 'americanSamoa_+1684',
        name: 'AmericanSamoa',
        code: '+1684',
        numberOfDigits: null,
      },
      {
        id: 'andorra_+376',
        name: 'Andorra',
        code: '+376',
        numberOfDigits: null,
      },
      {
        id: 'angola_+244',
        name: 'Angola',
        code: '+244',
        numberOfDigits: '9',
      },
      {
        id: 'anguilla_+1264',
        name: 'Anguilla',
        code: '+1264',
        numberOfDigits: null,
      },
      {
        id: 'antarctica_+672',
        name: 'Antarctica',
        code: '+672',
        numberOfDigits: '9',
      },
      {
        id: 'antigua_and_barbuda_+1268',
        name: 'Antigua and Barbuda',
        code: '+1268',
        numberOfDigits: null,
      },
      {
        id: 'argentina_+54',
        name: 'Argentina',
        code: '+54',
        numberOfDigits: null,
      },
      {
        id: 'armenia_+374',
        name: 'Armenia',
        code: '+374',
        numberOfDigits: '6',
      },
      {
        id: 'Aruba_+297',
        name: 'Aruba',
        code: '+297',
        numberOfDigits: '7',
      },
      {
        id: 'australia_+61',
        name: 'Australia',
        code: '+61',
        numberOfDigits: '9',
      },
      {
        id: 'austria_+43',
        name: 'Austria',
        code: '+43',
        numberOfDigits: null,
      },
      {
        id: 'azerbaijan_+994',
        name: 'Azerbaijan',
        code: '+994',
        numberOfDigits: '9',
      },
    ],
  },
  {
    title: 'B',
    data: [
      {
        id: 'bahamas_+1242',
        name: 'Bahamas',
        code: '+1242',
        numberOfDigits: '10',
      },
      {
        id: 'bahrain_+973',
        name: 'Bahrain',
        code: '+973',
        numberOfDigits: '8',
      },
      {
        id: 'bangladesh_+880',
        name: 'Bangladesh',
        code: '+880',
        numberOfDigits: '10',
      },
      {
        id: 'barbados_+1246',
        name: 'Barbados',
        code: '+1246',
        numberOfDigits: '10',
      },
      {
        id: 'belarus_+375',
        name: 'Belarus',
        code: '+375',
        numberOfDigits: '9',
      },
      {
        id: 'belgium_+32',
        name: 'Belgium',
        code: '+32',
        numberOfDigits: '9',
      },
      {
        id: 'belize_+501',
        name: 'Belize',
        code: '+501',
        numberOfDigits: null,
      },
      {
        id: 'benin_+229',
        name: 'Benin',
        code: '+229',
        numberOfDigits: null,
      },
      {
        id: 'bermuda_+1441',
        name: 'Bermuda',
        code: '+1441',
        numberOfDigits: '10',
      },
      {
        id: 'bhutan_+975',
        name: 'Bhutan',
        code: '+975',
        numberOfDigits: null,
      },
      {
        id: 'bolivia,_plurinational_state of_+591',
        name: 'Bolivia, Plurinational State of',
        code: '+591',
        numberOfDigits: null,
      },
      {
        id: 'bosnia_and_herzegovina_+387',
        name: 'Bosnia and Herzegovina',
        code: '+387',
        numberOfDigits: '8',
      },
      {
        id: 'botswana_+267',
        name: 'Botswana',
        code: '+267',
        numberOfDigits: null,
      },
      {
        id: 'brazil_+55',
        name: 'Brazil',
        code: '+55',
        numberOfDigits: '11',
      },
      {
        id: 'british_indian_ocean_territory_+246',
        name: 'British Indian Ocean Territory',
        code: '+246',
        numberOfDigits: '7',
      },
      {
        id: 'brunei_darussalam_+673',
        name: 'Brunei Darussalam',
        code: '+673',
        numberOfDigits: null,
      },
      {
        id: 'bulgaria_+359',
        name: 'Bulgaria',
        code: '+359',
        numberOfDigits: '9',
      },
      {
        id: 'burkina_faso_+226',
        name: 'Burkina Faso',
        code: '+226',
        numberOfDigits: '8',
      },
      {
        id: 'burundi_+257',
        name: 'Burundi',
        code: '+257',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'C',
    data: [
      {
        id: 'cambodia_+855',
        name: 'Cambodia',
        code: '+855',
        numberOfDigits: '9',
      },
      {
        id: 'cameroon_+237',
        name: 'Cameroon',
        code: '+237',
        numberOfDigits: null,
      },
      {
        id: 'canada_+1',
        name: 'Canada',
        code: '+1',
        numberOfDigits: '10',
      },
      {
        id: 'cape_verde_+238',
        name: 'Cape Verde',
        code: '+238',
        numberOfDigits: null,
      },
      {
        id: 'cayman_islands_+345',
        name: 'Cayman Islands',
        code: '+345',
        numberOfDigits: '10',
      },
      {
        id: 'central_african_republic_+236',
        name: 'Central African Republic',
        code: '+236',
        numberOfDigits: null,
      },
      {
        id: 'chad_+235',
        name: 'Chad',
        code: '+235',
        numberOfDigits: '8',
      },
      {
        id: 'chile_+56',
        name: 'Chile',
        code: '+56',
        numberOfDigits: '9',
      },
      {
        id: 'china_+86',
        name: 'China',
        code: '+86',
        numberOfDigits: '11',
      },
      {
        id: 'christmas_island_+61',
        name: 'Christmas Island',
        code: '+61',
        numberOfDigits: null,
      },
      {
        id: 'cocos_(keeling)_islands_+61',
        name: 'Cocos (Keeling) Islands',
        code: '+61',
        numberOfDigits: null,
      },
      {
        id: 'colombia_+57',
        name: 'Colombia',
        code: '+57',
        numberOfDigits: '10',
      },
      {
        id: 'comoros_+269',
        name: 'Comoros',
        code: '+269',
        numberOfDigits: null,
      },
      {
        id: 'congo_+242',
        name: 'Congo',
        code: '+242',
        numberOfDigits: null,
      },
      {
        id: 'congo,_the_democratic_republic_of_the_congo_+243',
        name: 'Congo, The Democratic Republic of the Congo',
        code: '+243',
        numberOfDigits: null,
      },
      {
        id: 'cook_islands_+682',
        name: 'Cook Islands',
        code: '+682',
        numberOfDigits: '5',
      },
      {
        id: 'costa_rica_+506',
        name: 'Costa Rica',
        code: '+506',
        numberOfDigits: '8',
      },
      {
        id: 'cote_divoire_+225',
        name: "Cote d'Ivoire",
        code: '+225',
        numberOfDigits: null,
      },
      {
        id: 'croatia_+385',
        name: 'Croatia',
        code: '+385',
        numberOfDigits: '9',
      },
      {
        id: 'cuba_+53',
        name: 'Cuba',
        code: '+53',
        numberOfDigits: null,
      },
      {
        id: 'cyprus_+357',
        name: 'Cyprus',
        code: '+357',
        numberOfDigits: '8',
      },
      {
        id: 'czech_republic_+420',
        name: 'Czech Republic',
        code: '+420',
        numberOfDigits: '9',
      },
    ],
  },
  {
    title: 'D',
    data: [
      {
        id: 'denmark_+45',
        name: 'Denmark',
        code: '+45',
        numberOfDigits: '8',
      },
      {
        id: 'djibouti_+253',
        name: 'Djibouti',
        code: '+253',
        numberOfDigits: null,
      },
      {
        id: 'dominica_+1767',
        name: 'Dominica',
        code: '+1767',
        numberOfDigits: '10',
      },
      {
        id: 'dominican_republic_+1849',
        name: 'Dominican Republic',
        code: '+1849',
        numberOfDigits: '10',
      },
    ],
  },
  {
    title: 'E',
    data: [
      {
        id: 'ecuador_+593',
        name: 'Ecuador',
        code: '+593',
        numberOfDigits: null,
      },
      {
        id: 'egypt_+20',
        name: 'Egypt',
        code: '+20',
        numberOfDigits: '10',
      },
      {
        id: 'el_salvador_+503',
        name: 'El Salvador',
        code: '+503',
        numberOfDigits: '7',
      },
      {
        id: 'equatorial_guinea_+240',
        name: 'Equatorial Guinea',
        code: '+240',
        numberOfDigits: null,
      },
      {
        id: 'eritrea_+291',
        name: 'Eritrea',
        code: '+291',
        numberOfDigits: null,
      },
      {
        id: 'estonia_+372',
        name: 'Estonia',
        code: '+372',
        numberOfDigits: null,
      },
      {
        id: 'ethiopia_+251',
        name: 'Ethiopia',
        code: '+251',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'F',
    data: [
      {
        id: 'falkland_islands_(malvinas)_+500',
        name: 'Falkland Islands (Malvinas)',
        code: '+500',
        numberOfDigits: '5',
      },
      {
        id: 'faroe_islands_+298',
        name: 'Faroe Islands',
        code: '+298',
        numberOfDigits: null,
      },
      {
        id: 'fiji_+679',
        name: 'Fiji',
        code: '+679',
        numberOfDigits: null,
      },
      {
        id: 'finland_+358',
        name: 'Finland',
        code: '+358',
        numberOfDigits: null,
      },
      {
        id: 'france_+33',
        name: 'France',
        code: '+33',
        numberOfDigits: '9',
      },
      {
        id: 'french_guiana_+594',
        name: 'French Guiana',
        code: '+594',
        numberOfDigits: null,
      },
      {
        id: 'french_polynesia_+689',
        name: 'French Polynesia',
        code: '+689',
        numberOfDigits: '6',
      },
    ],
  },
  {
    title: 'G',
    data: [
      {
        id: 'gabon_+241',
        name: 'Gabon',
        code: '+241',
        numberOfDigits: '7',
      },
      {
        id: 'gambia_+220',
        name: 'Gambia',
        code: '+220',
        numberOfDigits: null,
      },
      {
        id: 'georgia_+995',
        name: 'Georgia',
        code: '+995',
        numberOfDigits: '9',
      },
      {
        id: 'germany_+49',
        name: 'Germany',
        code: '+49',
        numberOfDigits: null,
      },
      {
        id: 'ghana_+233',
        name: 'Ghana',
        code: '+233',
        numberOfDigits: '9',
      },
      {
        id: 'gibraltar_+350',
        name: 'Gibraltar',
        code: '+350',
        numberOfDigits: null,
      },
      {
        id: 'greece_+30',
        name: 'Greece',
        code: '+30',
        numberOfDigits: '10',
      },
      {
        id: 'greenland_+299',
        name: 'Greenland',
        code: '+299',
        numberOfDigits: '6',
      },
      {
        id: 'grenada_+1473',
        name: 'Grenada',
        code: '+1473',
        numberOfDigits: '10',
      },
      {
        id: 'guadeloupe_+590',
        name: 'Guadeloupe',
        code: '+590',
        numberOfDigits: null,
      },
      {
        id: 'guam_+1671',
        name: 'Guam',
        code: '+1671',
        numberOfDigits: '10',
      },
      {
        id: 'guatemala_+502',
        name: 'Guatemala',
        code: '+502',
        numberOfDigits: null,
      },
      {
        id: 'guernsey_+44',
        name: 'Guernsey',
        code: '+44',
        numberOfDigits: '10',
      },
      {
        id: 'guinea_+224',
        name: 'Guinea',
        code: '+224',
        numberOfDigits: null,
      },
      {
        id: 'guinea-Bissau_+245',
        name: 'Guinea-Bissau',
        code: '+245',
        numberOfDigits: null,
      },
      {
        id: 'guyana_+595',
        name: 'Guyana',
        code: '+595',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'H',
    data: [
      {
        id: 'haiti_+509',
        name: 'Haiti',
        code: '+509',
        numberOfDigits: null,
      },
      {
        id: 'holy_see_(vatican_city_state)_+379',
        name: 'Holy See (Vatican City State)',
        code: '+379',
        numberOfDigits: null,
      },
      {
        id: 'honduras_+504',
        name: 'Honduras',
        code: '+504',
        numberOfDigits: null,
      },
      {
        id: 'hong_kong_+852',
        name: 'Hong Kong',
        code: '+852',
        numberOfDigits: '8',
      },
      {
        id: 'hungary_+36',
        name: 'Hungary',
        code: '+36',
        numberOfDigits: '9',
      },
    ],
  },
  {
    title: 'I',
    data: [
      {
        id: 'iceland_+354',
        name: 'Iceland',
        code: '+354',
        numberOfDigits: null,
      },
      {
        id: 'indonesia_+62',
        name: 'Indonesia',
        code: '+62',
        numberOfDigits: null,
      },
      {
        id: 'iran,_islamic_republic of_persian_gulf_+98',
        name: 'Iran, Islamic Republic of Persian Gulf',
        code: '+98',
        numberOfDigits: null,
      },
      {
        id: 'iraq_+964',
        name: 'Iraq',
        code: '+964',
        numberOfDigits: null,
      },
      {
        id: 'ireland_+353',
        name: 'Ireland',
        code: '+353',
        numberOfDigits: '9',
      },
      {
        id: 'isle_of_man_+44',
        name: 'Isle of Man',
        code: '+44',
        numberOfDigits: '10',
      },
      {
        id: 'israel_+972',
        name: 'Israel',
        code: '+972',
        numberOfDigits: '9',
      },
      {
        id: 'italy_+39',
        name: 'Italy',
        code: '+39',
        numberOfDigits: '10',
      },
    ],
  },
  {
    title: 'J',
    data: [
      {
        id: 'jamaica_+1876',
        name: 'Jamaica',
        code: '+1876',
        numberOfDigits: null,
      },
      {
        id: 'japan_+81',
        name: 'Japan',
        code: '+81',
        numberOfDigits: '11',
      },
      {
        id: 'jersey_+44',
        name: 'Jersey',
        code: '+44',
        numberOfDigits: null,
      },
      {
        id: 'jordan_+962',
        name: 'Jordan',
        code: '+962',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'K',
    data: [
      {
        id: 'kazakhstan_+77',
        name: 'Kazakhstan',
        code: '+77',
        numberOfDigits: null,
      },
      {
        id: 'kenya_+254',
        name: 'Kenya',
        code: '+254',
        numberOfDigits: null,
      },
      {
        id: 'kiribati_+686',
        name: 'Kiribati',
        code: '+686',
        numberOfDigits: null,
      },
      {
        id: 'korea,_democratic_peoples_republic_of_korea_+850',
        name: "Korea, Democratic People's Republic of Korea",
        code: '+850',
        numberOfDigits: null,
      },
      {
        id: 'korea,_republic_of_south_korea_+82',
        name: 'Korea, Republic of South Korea',
        code: '+82',
        numberOfDigits: null,
      },
      {
        id: 'kuwait_+965',
        name: 'Kuwait',
        code: '+965',
        numberOfDigits: null,
      },
      {
        id: 'kyrgyzstan_+996',
        name: 'Kyrgyzstan',
        code: '+996',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'L',
    data: [
      {
        id: 'laos_+856',
        name: 'Laos',
        code: '+856',
        numberOfDigits: null,
      },
      {
        id: 'latvia_+371',
        name: 'Latvia',
        code: '+371',
        numberOfDigits: null,
      },
      {
        id: 'lebanon_+961',
        name: 'Lebanon',
        code: '+961',
        numberOfDigits: null,
      },
      {
        id: 'lesotho_+266',
        name: 'Lesotho',
        code: '+266',
        numberOfDigits: null,
      },
      {
        id: 'liberia_+231',
        name: 'Liberia',
        code: '+231',
        numberOfDigits: null,
      },
      {
        id: 'libyan_arab_jamahiriya_+218',
        name: 'Libyan Arab Jamahiriya',
        code: '+218',
        numberOfDigits: null,
      },
      {
        id: 'liechtenstein_+423',
        name: 'Liechtenstein',
        code: '+423',
        numberOfDigits: null,
      },
      {
        id: 'lithuania_+370',
        name: 'Lithuania',
        code: '+370',
        numberOfDigits: null,
      },
      {
        id: 'luxembourg_+352',
        name: 'Luxembourg',
        code: '+352',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'M',
    data: [
      {
        id: 'macao_+853',
        name: 'Macao',
        code: '+853',
        numberOfDigits: null,
      },
      {
        id: 'macedonia_+389',
        name: 'Macedonia',
        code: '+389',
        numberOfDigits: null,
      },
      {
        id: 'madagascar_+261',
        name: 'Madagascar',
        code: '+261',
        numberOfDigits: null,
      },
      {
        id: 'malawi_+265',
        name: 'Malawi',
        code: '+265',
        numberOfDigits: null,
      },
      {
        id: 'malaysia_+60',
        name: 'Malaysia',
        code: '+60',
        numberOfDigits: null,
      },
      {
        id: 'maldives_+960',
        name: 'Maldives',
        code: '+960',
        numberOfDigits: '7',
      },
      {
        id: 'mali_+223',
        name: 'Mali',
        code: '+223',
        numberOfDigits: null,
      },
      {
        id: 'malta_+356',
        name: 'Malta',
        code: '+356',
        numberOfDigits: null,
      },
      {
        id: 'marshall_islands_+692',
        name: 'Marshall Islands',
        code: '+692',
        numberOfDigits: null,
      },
      {
        id: 'martinique_+596',
        name: 'Martinique',
        code: '+596',
        numberOfDigits: null,
      },
      {
        id: 'mauritania_+222',
        name: 'Mauritania',
        code: '+222',
        numberOfDigits: null,
      },
      {
        id: 'mauritius_+230',
        name: 'Mauritius',
        code: '+230',
        numberOfDigits: null,
      },
      {
        id: 'mayotte_+262',
        name: 'Mayotte',
        code: '+262',
        numberOfDigits: null,
      },
      {
        id: 'mexico_+52',
        name: 'Mexico',
        code: '+52',
        numberOfDigits: null,
      },
      {
        id: 'micronesia,_federated_states_of_micronesia_+691',
        name: 'Micronesia, Federated States of Micronesia',
        code: '+691',
        numberOfDigits: null,
      },
      {
        id: 'moldova_+373',
        name: 'Moldova',
        code: '+373',
        numberOfDigits: null,
      },
      {
        id: 'monaco_+377',
        name: 'Monaco',
        code: '+377',
        numberOfDigits: null,
      },
      {
        id: 'mongolia_+976',
        name: 'Mongolia',
        code: '+976',
        numberOfDigits: null,
      },
      {
        id: 'montenegro_+382',
        name: 'Montenegro',
        code: '+382',
        numberOfDigits: null,
      },
      {
        id: 'montserrat_+1664',
        name: 'Montserrat',
        code: '+1664',
        numberOfDigits: null,
      },
      {
        id: 'morocco_+212',
        name: 'Morocco',
        code: '+212',
        numberOfDigits: null,
      },
      {
        id: 'mozambique_+258',
        name: 'Mozambique',
        code: '+258',
        numberOfDigits: null,
      },
      {
        id: 'myanmar_+95',
        name: 'Myanmar',
        code: '+95',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'N',
    data: [
      {
        id: 'namibia_+264',
        name: 'Namibia',
        code: '+264',
        numberOfDigits: null,
      },
      {
        id: 'nauru_+674',
        name: 'Nauru',
        code: '+674',
        numberOfDigits: null,
      },
      {
        id: 'nepal_+977',
        name: 'Nepal',
        code: '+977',
        numberOfDigits: null,
      },
      {
        id: 'netherlands_+31',
        name: 'Netherlands',
        code: '+31',
        numberOfDigits: null,
      },
      {
        id: 'netherlands_antilles_+599',
        name: 'Netherlands Antilles',
        code: '+599',
        numberOfDigits: null,
      },
      {
        id: 'new_caledonia_+687',
        name: 'New Caledonia',
        code: '+687',
        numberOfDigits: null,
      },
      {
        id: 'new_zealand_+64',
        name: 'New Zealand',
        code: '+64',
        numberOfDigits: null,
      },
      {
        id: 'nicaragua_+505',
        name: 'Nicaragua',
        code: '+505',
        numberOfDigits: null,
      },
      {
        id: 'niger_+227',
        name: 'Niger',
        code: '+227',
        numberOfDigits: null,
      },
      {
        id: 'nigeria_+234',
        name: 'Nigeria',
        code: '+234',
        numberOfDigits: null,
      },
      {
        id: 'niue_+683',
        name: 'Niue',
        code: '+683',
        numberOfDigits: null,
      },
      {
        id: 'norfolk_island_+672',
        name: 'Norfolk Island',
        code: '+672',
        numberOfDigits: null,
      },
      {
        id: 'northern_mariana_islands_+1670',
        name: 'Northern Mariana Islands',
        code: '+1670',
        numberOfDigits: null,
      },
      {
        id: 'norway_+47',
        name: 'Norway',
        code: '+47',
        numberOfDigits: null,
      },
    ],
  },
  { title: 'O', data: [{ name: 'Oman', code: '+968', numberOfDigits: null }] },
  {
    title: 'P',
    data: [
      {
        id: 'pakistan_+92',
        name: 'Pakistan',
        code: '+92',
        numberOfDigits: '10',
      },
      {
        id: 'palau_+680',
        name: 'Palau',
        code: '+680',
        numberOfDigits: null,
      },
      {
        id: 'palestinian_territory,_occupied_+970',
        name: 'Palestinian Territory, Occupied',
        code: '+970',
        numberOfDigits: null,
      },
      {
        id: 'panama_+507',
        name: 'Panama',
        code: '+507',
        numberOfDigits: null,
      },
      {
        id: 'papua_new_guinea_+675',
        name: 'Papua New Guinea',
        code: '+675',
        numberOfDigits: null,
      },
      {
        id: 'paraguay_+595',
        name: 'Paraguay',
        code: '+595',
        numberOfDigits: null,
      },
      {
        id: 'peru_+51',
        name: 'Peru',
        code: '+51',
        numberOfDigits: null,
      },
      {
        id: 'philippines_+63',
        name: 'Philippines',
        code: '+63',
        numberOfDigits: null,
      },
      {
        id: 'pitcairn_+872',
        name: 'Pitcairn',
        code: '+872',
        numberOfDigits: null,
      },
      {
        id: 'poland_+48',
        name: 'Poland',
        code: '+48',
        numberOfDigits: '9',
      },
      {
        id: 'portugal_+351',
        name: 'Portugal',
        code: '+351',
        numberOfDigits: '9',
      },
      {
        id: 'puerto_rico_+1939',
        name: 'Puerto Rico',
        code: '+1939',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'Q',
    data: [
      {
        id: 'qatar_+974',
        name: 'Qatar',
        code: '+974',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'R',
    data: [
      {
        id: 'romania_+40',
        name: 'Romania',
        code: '+40',
        numberOfDigits: null,
      },
      {
        id: 'russia_+7',
        name: 'Russia',
        code: '+7',
        numberOfDigits: '10',
      },
      {
        id: 'rwanda_+250',
        name: 'Rwanda',
        code: '+250',
        numberOfDigits: null,
      },
      {
        id: 'reunion_+262',
        name: 'Reunion',
        code: '+262',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'S',
    data: [
      {
        id: 'saint_barthelemy_+590',
        name: 'Saint Barthelemy',
        code: '+590',
        numberOfDigits: null,
      },
      {
        id: 'saint_helena,_ascension_and_tristan_da_cunha_+290',
        name: 'Saint Helena, Ascension and Tristan Da Cunha',
        code: '+290',
        numberOfDigits: null,
      },
      {
        id: 'saint_kitts_and_nevis_+1869',
        name: 'Saint Kitts and Nevis',
        code: '+1869',
        numberOfDigits: null,
      },
      {
        id: 'saint_lucia_+1758',
        name: 'Saint Lucia',
        code: '+1758',
        numberOfDigits: null,
      },
      {
        id: 'saint_martin_+590',
        name: 'Saint Martin',
        code: '+590',
        numberOfDigits: null,
      },
      {
        id: 'saint_pierre_and_miquelon_+508',
        name: 'Saint Pierre and Miquelon',
        code: '+508',
        numberOfDigits: null,
      },
      {
        id: 'saint_vincent_and_the_grenadines_+1784',
        name: 'Saint Vincent and the Grenadines',
        code: '+1784',
        numberOfDigits: null,
      },
      {
        id: 'samoa_+685',
        name: 'Samoa',
        code: '+685',
        numberOfDigits: null,
      },
      {
        id: 'san_marino_+378',
        name: 'San Marino',
        code: '+378',
        numberOfDigits: null,
      },
      {
        id: 'sao_tome_and_principe_+239',
        name: 'Sao Tome and Principe',
        code: '+239',
        numberOfDigits: null,
      },
      {
        id: 'saudi_arabia_+966',
        name: 'Saudi Arabia',
        code: '+966',
        numberOfDigits: null,
      },
      {
        id: 'senegal_+221',
        name: 'Senegal',
        code: '+221',
        numberOfDigits: null,
      },
      {
        id: 'serbia_+381',
        name: 'Serbia',
        code: '+381',
        numberOfDigits: null,
      },
      {
        id: 'seychelles_+248',
        name: 'Seychelles',
        code: '+248',
        numberOfDigits: null,
      },
      {
        id: 'sierra_leone_+232',
        name: 'Sierra Leone',
        code: '+232',
        numberOfDigits: null,
      },
      {
        id: 'singapore_+65',
        name: 'Singapore',
        code: '+65',
        numberOfDigits: '8',
      },
      {
        id: 'slovakia_+421',
        name: 'Slovakia',
        code: '+421',
        numberOfDigits: null,
      },
      {
        id: 'slovenia_+386',
        name: 'Slovenia',
        code: '+386',
        numberOfDigits: null,
      },
      {
        id: 'solomon_islands_+677',
        name: 'Solomon Islands',
        code: '+677',
        numberOfDigits: null,
      },
      {
        id: 'somalia_+252',
        name: 'Somalia',
        code: '+252',
        numberOfDigits: null,
      },
      {
        id: 'south_africa_+27',
        name: 'South Africa',
        code: '+27',
        numberOfDigits: '9',
      },
      {
        id: 'south_sudan_+211',
        name: 'South Sudan',
        code: '+211',
        numberOfDigits: null,
      },
      {
        id: 'south_georgia_and_the_south_sandwich_islands_+500',
        name: 'South Georgia and the South Sandwich Islands',
        code: '+500',
        numberOfDigits: null,
      },
      {
        id: 'spain_+34',
        name: 'Spain',
        code: '+34',
        numberOfDigits: '9',
      },
      {
        id: 'sri_lanka_+94',
        name: 'Sri Lanka',
        code: '+94',
        numberOfDigits: null,
      },
      {
        id: 'sudan_+249',
        name: 'Sudan',
        code: '+249',
        numberOfDigits: null,
      },
      {
        id: 'suriname_+597',
        name: 'Suriname',
        code: '+597',
        numberOfDigits: null,
      },
      {
        id: 'svalbard_and_jan_mayen_+47',
        name: 'Svalbard and Jan Mayen',
        code: '+47',
        numberOfDigits: null,
      },
      {
        id: 'swaziland_+268',
        name: 'Swaziland',
        code: '+268',
        numberOfDigits: null,
      },
      {
        id: 'sweden_+46',
        name: 'Sweden',
        code: '+46',
        numberOfDigits: '9',
      },
      {
        id: 'switzerland_+41',
        name: 'Switzerland',
        code: '+41',
        numberOfDigits: '10',
      },
      {
        id: 'syrian_arab_republic_+963',
        name: 'Syrian Arab Republic',
        code: '+963',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'T',
    data: [
      {
        id: 'taiwan_+886',
        name: 'Taiwan',
        code: '+886',
        numberOfDigits: null,
      },
      {
        id: 'tajikistan_+992',
        name: 'Tajikistan',
        code: '+992',
        numberOfDigits: null,
      },
      {
        id: 'tanzania,_united_republic_of_tanzania_+255',
        name: 'Tanzania, United Republic of Tanzania',
        code: '+255',
      },
      {
        id: 'thailand_+66',
        name: 'Thailand',
        code: '+66',
        numberOfDigits: '9',
      },
      {
        id: 'timor-leste_+670',
        name: 'Timor-Leste',
        code: '+670',
        numberOfDigits: null,
      },
      {
        id: 'togo_+228',
        name: 'Togo',
        code: '+228',
        numberOfDigits: null,
      },
      {
        id: 'tokelau_+690',
        name: 'Tokelau',
        code: '+690',
        numberOfDigits: null,
      },
      {
        id: 'tonga_+676',
        name: 'Tonga',
        code: '+676',
        numberOfDigits: null,
      },
      {
        id: 'trinidad_and_tobago_+1868',
        name: 'Trinidad and Tobago',
        code: '+1868',
        numberOfDigits: null,
      },
      {
        id: 'tunisia_+216',
        name: 'Tunisia',
        code: '+216',
        numberOfDigits: null,
      },
      {
        id: 'turkey_+90',
        name: 'Turkey',
        code: '+90',
        numberOfDigits: '10',
      },
      {
        id: 'turkmenistan_+993',
        name: 'Turkmenistan',
        code: '+993',
        numberOfDigits: null,
      },
      {
        id: 'turks_and_caicos_islands_+1649',
        name: 'Turks and Caicos Islands',
        code: '+1649',
        numberOfDigits: null,
      },
      {
        id: 'tuvalu_+688',
        name: 'Tuvalu',
        code: '+688',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'U',
    data: [
      {
        id: 'uganda_+256',
        name: 'Uganda',
        code: '+256',
        numberOfDigits: null,
      },
      {
        id: 'ukraine_+380',
        name: 'Ukraine',
        code: '+380',
        numberOfDigits: null,
      },
      {
        id: 'uruguay_+598',
        name: 'Uruguay',
        code: '+598',
        numberOfDigits: null,
      },
      {
        id: 'uzbekistan_+998',
        name: 'Uzbekistan',
        code: '+998',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'V',
    data: [
      {
        id: 'vanuatu_+678',
        name: 'Vanuatu',
        code: '+678',
        numberOfDigits: null,
      },
      {
        id: 'venezuela,_bolivarian_republic_of_venezuela_+58',
        name: 'Venezuela, Bolivarian Republic of Venezuela',
        code: '+58',
        numberOfDigits: null,
      },
      {
        id: 'vietnam_+84',
        name: 'Vietnam',
        code: '+84',
        numberOfDigits: '9',
      },
      {
        id: 'virgin_islands,_british_+1284',
        name: 'Virgin Islands, British',
        code: '+1284',
        numberOfDigits: null,
      },
      {
        id: 'virgin_islands,_U.S_+1340',
        name: 'Virgin Islands, U.S.',
        code: '+1340',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'W',
    data: [
      {
        id: 'wallis_and_futuna_+681',
        name: 'Wallis and Futuna',
        code: '+681',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'Y',
    data: [
      {
        id: 'yemen_+967',
        name: 'Yemen',
        code: '+967',
        numberOfDigits: null,
      },
    ],
  },
  {
    title: 'Z',
    data: [
      {
        id: 'zambia_+260',
        name: 'Zambia',
        code: '+260',
        numberOfDigits: null,
      },
      {
        id: 'zimbabwe_+263',
        name: 'Zimbabwe',
        code: '+263',
        numberOfDigits: null,
      },
    ],
  },
];
