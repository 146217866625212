import './rating.scss';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import StarSvg from '../../assets/Staricon';

function range(size, startAt = 0) {
  return [...Array(size).keys()].map((i) => i + startAt);
}
const Rating = ({ userRating = null, onUserRating, userRatedBy }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rating, setRating] = useState(userRating);

  useEffect(() => {
    setRating(userRating);
  }, [userRating]);

  const handleRating = (newRating) => {
    setRating(newRating);
    setIsOpen(false);
    onUserRating({ rating:newRating });
  };

  return (
    <div className="ratingWrapper">
      <button
        className="ratingWrapper__toggleButton"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        {rating || <StarSvg />}
      </button>
      {rating > 0 && (
        <Link
          to={{
            pathname: `/user-profile/${userRatedBy}/profile`,
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="ratingWrapper__star">
            <StarSvg />
          </div>
        </Link>
      )}

      <div>
        <SlideDown className="pure-menu pure-menu-scrollable " closed={!isOpen}>
          <div className="ratingWrapper__elements-wrapper">
            {range(10, 1).map((i) => (
              <button
                className="ratingWrapper__element"
                key={i}
                onClick={() => handleRating(i)}
                type="button"
              >
                {i}
              </button>
            ))}
          </div>
        </SlideDown>
      </div>
    </div>
  );
};

export default React.memo(Rating);
