import React from 'react';

const StarSvg = () => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24">
    <linearGradient
      id="a"
      gradientUnits="userSpaceOnUse"
      x1="12"
      x2="12"
      y1="0"
      y2="24"
    >
      <stop offset="0" stopColor="#f6aa00" />
      <stop offset="1" stopColor="#f6cf00" />
    </linearGradient>
    <path
      d="m23.2243 8.45108-7.1947-1.09496-3.2163-6.827917c-.0878-.186944-.2323-.33828-.4109-.4302688-.4477-.2314552-.9918-.0385758-1.2156.4302688l-3.21625 6.827917-7.19473 1.09496c-.198359.02968-.379715.1276-.518565.27597-.1678632.18067-.2603633.42374-.25717524.67579.00318805.25206.10180324.49247.27417724.66846l5.205483 5.3145-1.22982 7.5045c-.02884.1746-.01039.3541.05325.5183.06364.1641.16994.3063.30682.4104.13689.1041.2989.166.46765.1786s.3375-.0246.48711-.1073l6.43535-3.543 6.4353 3.543c.1757.0979.3797.1306.5752.095.4931-.0891.8246-.5787.7396-1.095l-1.2298-7.5045 5.2055-5.3145c.1416-.14543.2352-.33535.2635-.54306.0765-.51929-.2692-1.00001-.7651-1.07716z"
      fill="url(#a)"
    />
  </svg>
);

export default StarSvg;
