import React, { PureComponent } from 'react';
import { Modal, Input, Select } from 'antd';
import { connect } from 'react-redux';

import { isEmpty } from '../../../utilities/util';
import { paymentPlansEffects } from '../../../@betterhalf-private/redux/src';
import { isEmailValid, isPhoneNumberValid } from '../../../utilities/helper';

const { Option } = Select;

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  input: {
    width: '60%',
  },
  strikeThrough: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
};

class BuyBvModal extends PureComponent {
  constructor() {
    super();
    this.state = {
      planId: '',
      email: '',
      phoneNumber: '',
      amount: '',
    };
  }

  componentDidMount() {
    const {
      userData: { id, email, mobile },
      fetchPlans,
    } = this.props;
    this.setState({ email, phoneNumber: mobile });
    fetchPlans({ id });
  }

  changeAmount = (value) => {
    if (Object.is(NaN, Number(value))) return;
    this.setState({ amount: value });
  };

  modalClose = () => {
    const { onModalClose } = this.props;
    this.setState({ planId: '', email: '', phoneNumber: '', amount: '' });
    onModalClose();
  };

  handleOkPress = async () => {
    const { planId, amount, email, phoneNumber } = this.state;
    const { userData, onOkayPress, paymentLinks } = this.props;

    const dataToSend = { id: userData.id, plan: planId, amount };

    if (email.length && email.trim() !== userData.email)
      dataToSend.email = email;
    if (phoneNumber.length && phoneNumber.trim() !== userData.mobile)
      dataToSend.mobile = phoneNumber;

    paymentLinks(dataToSend);

    this.setState({ planId: '', email: '', phoneNumber: '', amount: '' });
    onOkayPress();
  };

  onChange = (value = '') => {
    const { plans } = this.props;
    const plan = plans[value];
    this.setState({ planId: plan.plan_id, amount: plan.discounted_amount });
  };

  isValidData = () => {
    const { planId, amount, phoneNumber, email } = this.state;
    return (
      !isEmpty(planId) &&
      !isEmpty(amount) &&
      isPhoneNumberValid(phoneNumber) &&
      isEmailValid(email)
    );
  };

  render() {
    const { title, visible, plans } = this.props;
    const { planId, email, phoneNumber, amount } = this.state;

    let planOptions = null;

    if (Object.keys(plans).length > 0) {
      planOptions = Object.keys(plans).map((currentPlanId) => {
        const plan = plans[currentPlanId];
        return (
          <Option key={plan.plan_id} value={plan.plan_id}>
            {`${plan.consumable_quantities} - ${plan.name} ( `}
            <span style={styles.strikeThrough}>{`₹${plan.amount}`}</span>
            {`  ₹${plan.discounted_amount} )`}
          </Option>
        );
      });
    }

    return (
      <Modal
        destroyOnClose
        onCancel={this.modalClose}
        visible={visible}
        title={title}
        onOk={this.handleOkPress}
        okButtonProps={
          this.isValidData() ? { disabled: false } : { disabled: true }
        }
      >
        <div style={styles.card}>
          <h4>Select Plan</h4>
          <Select onChange={this.onChange} style={styles.input}>
            {planOptions}
          </Select>
        </div>
        <div style={styles.card}>
          <h4>Email</h4>
          <Input
            value={email}
            onChange={(e) => this.setState({ email: e.target.value })}
            style={styles.input}
            placeholder="Email"
          />
        </div>
        <div style={styles.card}>
          <h4>Phone Number</h4>
          <Input
            value={phoneNumber}
            onChange={(e) => this.setState({ phoneNumber: e.target.value })}
            style={styles.input}
            placeholder="Phone Number"
          />
        </div>
        <div style={styles.card}>
          <h4>Amount</h4>
          <Input
            disabled={!planId}
            value={amount}
            onChange={(e) => this.changeAmount(e.target.value)}
            style={styles.input}
            placeholder="Enter plan amount"
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  plans: state.bvPlans.data,
});

export default connect(mapStateToProps, {
  fetchPlans: paymentPlansEffects.fetchBvPlans,
  paymentLinks: paymentPlansEffects.paymentLinks,
})(BuyBvModal);
