import React, { useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import OptionPill from '../../../../components/OptionPill';
import Button from '../../../../components/Button';
import SearchList from '../../SearchList';
import { RELIGIONS } from '../../config';
import { searchHinduCommunities } from '../../api';
import './styles.scss';

const Community = ({
  selectedReligion,
  selectedCommunity,
  setSelectedCommunity,
}) => {
  const [filteredCommunities, setFilteredCommunities] = useState(
    () =>
      RELIGIONS.find((religion) => religion.value === selectedReligion.value)
        .caste
  );
  const initialFilteredCommunities = useRef(filteredCommunities);
  const [showSearchList, setShowSearchList] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  /* This is the to store the most current searchInput which will be used inside useMemo */
  const searchInputRef = useRef('');

  /* -------------------------------------------------------------------------- */
  /*                               SEARCH LIST HANDLERS                         */
  /* -------------------------------------------------------------------------- */

  const debouncedSearch = useMemo(
    () =>
      debounce(async () => {
        if (searchInputRef.current !== '') {
          const searchResults = await searchHinduCommunities(
            searchInputRef.current
          );

          const transformedSearchResults = searchResults.community_list.map(
            (searchResult) => ({
              id: searchResult.id,
              value: searchResult.community.toLowerCase(),
              displayText: searchResult.community,
            })
          );

          setFilteredCommunities(transformedSearchResults);
        }
      }, 250),
    []
  );

  const handleSearch = (searchTerm) => {
    const trimmedSearchTerm = searchTerm.trim();
    setSearchInput(searchTerm);
    searchInputRef.current = trimmedSearchTerm;

    if (trimmedSearchTerm === '') {
      setFilteredCommunities(initialFilteredCommunities.current);
    } else {
      debouncedSearch();
    }
  };

  const handleSelectLanguageFromSearchList = (community) => {
    setSelectedCommunity(community);
  };

  const handleOnBack = () => {
    setShowSearchList(false);
  };

  return showSearchList ? (
    <SearchList
      inputPlaceholder="Search your language"
      searchInput={searchInput}
      onChange={handleSearch}
      data={filteredCommunities}
      onSelect={handleSelectLanguageFromSearchList}
      selectedItems={selectedCommunity}
      onBack={handleOnBack}
    />
  ) : (
    <section className="community-container">
      {filteredCommunities.map((community) => (
        <OptionPill
          key={community.id}
          onClick={() => setSelectedCommunity(community)}
          className="community-pill"
          selected={selectedCommunity?.value === community.value}
        >
          {community.displayText}
        </OptionPill>
      ))}

      {selectedReligion.value === 'hindu' && (
        <Button
          isLink
          className="community-pill"
          onClick={() => setShowSearchList(true)}
        >
          See more
        </Button>
      )}
    </section>
  );
};

export default Community;
