/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import { Comment, Avatar, Tooltip, Tag, Button, Empty, Rate } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import {
  isEmpty,
  capitalize,
  dateFormatter,
  stringFormatter,
} from '../../../utilities/util';
import { userProfileEffects } from '../../../@betterhalf-private/redux/src';
import admin from './assets/admin.png';

const Author = ({ taken_by, given_by, firstName, lastName, id }) => {
  let firstNameToBeShown;
  let lastNameToBeShown;
  let userId;
  if (taken_by && taken_by.first_name) {
    firstNameToBeShown = taken_by.first_name;
    lastNameToBeShown = taken_by.last_name || '';
    userId = taken_by.id;
  }
  if (!taken_by && !isEmpty(given_by) && !isEmpty(id)) {
    if (given_by.toLowerCase() === id.toLowerCase()) {
      firstNameToBeShown = firstName;
      lastNameToBeShown = lastName;
      userId = id;
    }
  }

  return (
    <Tooltip title={userId}>
      <a>{`${firstNameToBeShown} ${lastNameToBeShown}`}</a>
    </Tooltip>
  );
};

const CommentAvatar = ({
  taken_by,
  given_by,
  id,
  profileImage,
  adminId,
  images,
}) => {
  let commentUserId;
  if (taken_by && taken_by.id) {
    commentUserId = taken_by.id;
  }

  if (!taken_by) {
    if (given_by.toLowerCase() === id.toLowerCase()) {
      commentUserId = id;
    }
  }

  return (
    <Avatar
      src={
        commentUserId === id
          ? profileImage
          : commentUserId === adminId
          ? images[0]
          : admin
      }
    />
  );
};

class Comments extends PureComponent {
  render() {
    const { userData, adminData, getMoreComments } = this.props;
    if (isEmpty(userData.results)) {
      return <Empty />;
    }
    const { results, next, id, profileImage, firstName, lastName } = userData;
    const { id: adminId, images } = adminData;
    let Data = null;

    if (!isEmpty(results)) {
      Data = results.map(
        ({
          id: commentId,
          feedback,
          description,
          additional_feedback,
          taken_by,
          given_by,
          created_at,
          type,
          os,
          phone_model,
          telecom_network,
          signal_strength,
          network_type,
          app_version,
        }) => (
          <div
            style={{
              marginBottom: 16,
            }}
            key={`comments_${commentId}`}
          >
            <Comment
              author={
                <Author
                  taken_by={taken_by}
                  given_by={given_by}
                  firstName={firstName}
                  lastName={lastName}
                  id={id}
                />
              }
              avatar={
                <CommentAvatar
                  taken_by={taken_by}
                  given_by={given_by}
                  id={id}
                  profileImage={profileImage}
                  adminId={adminId}
                  images={images}
                />
              }
              content={
                <>
                  <span style={{ fontWeight: 'bold' }}>
                    {capitalize(feedback)}
                  </span>
                  <br />
                  {feedback !== description && (
                    <span>{capitalize(description)}</span>
                  )}
                </>
              }
              datetime={
                <Tooltip title={dateFormatter(created_at)}>
                  <span>{moment(created_at).fromNow()}</span>
                </Tooltip>
              }
            />

            {additional_feedback && additional_feedback.in_app_review && (
              <div style={{ marginBottom: 20 }}>
                <Rate
                  disabled
                  style={{ color: '#ffcf50' }}
                  defaultValue={Number(
                    additional_feedback.in_app_review.rating
                  )}
                />
              </div>
            )}

            {additional_feedback && additional_feedback.in_app_review && (
              <div style={{ marginBottom: 20 }}>
                <span>{additional_feedback.in_app_review.description}</span>
              </div>
            )}

            <div>
              {type && (
                <Tag
                  color={
                    type === 'negative'
                      ? 'red'
                      : type === 'in_app_review'
                      ? '#d2b7ff'
                      : 'green'
                  }
                >
                  {stringFormatter({ text: type, type: 'snakeCase' })}
                </Tag>
              )}
              {os && <Tag color="orange">{os}</Tag>}
              {phone_model && (
                <Tag color="geekblue">{capitalize(phone_model)}</Tag>
              )}
              {telecom_network && (
                <Tag color="volcano">{capitalize(telecom_network)}</Tag>
              )}
              {signal_strength && (
                <Tag color="lime">{capitalize(signal_strength)}</Tag>
              )}
              {network_type && (
                <Tag color="cyan">{capitalize(network_type)}</Tag>
              )}
              {app_version && (
                <Tag color="purple">{capitalize(app_version)}</Tag>
              )}
            </div>
          </div>
        )
      );
    }

    return (
      <div>
        {Data}
        {!isEmpty(next) && (
          <Button
            type="link"
            block
            onClick={() => {
              getMoreComments({ url: next });
            }}
          >
            Load More Comments
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  comments: state.userFeedbackData,
});

export default connect(mapStateToProps, {
  getMoreComments: userProfileEffects.getMoreComments,
})(Comments);
