import React, { useEffect, useState } from 'react';
import { Empty, Button } from 'antd';
import { connect } from 'react-redux';

import { isEmpty } from '../../../utilities/util';
import {
  adminEffects,
  userProfileEffects,
} from '../../../@betterhalf-private/redux/src';
import './styles.scss';
import ShortlistedItem from './ShortlistedItem';

const Shortlisted = ({
  shortlistedUsers,
  userData,
  getShortlistedUsers,
  updateUserHmStatus,
  isLoading,
}) => {
  const [offSet, setOffSet] = useState(null);
  const [list, setList] = useState([]);
  useEffect(() => {
    const { id } = userData;
    if (!isEmpty(id)) {
      getShortlistedUsers({ id });
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (shortlistedUsers.data) {
        if (!isEmpty(shortlistedUsers.data.next)) {
          const obj = Object.fromEntries(
            new URLSearchParams(shortlistedUsers.data.next)
          );

          const newOffset = obj.offset;

          if (offSet !== newOffset) {
            setOffSet(newOffset);
          }
        }

        if (isEmpty(shortlistedUsers.data.next)) {
          setOffSet(null);
        }
      }

      if (shortlistedUsers.data && shortlistedUsers.data.results) {
        setList(shortlistedUsers.data.results);
      }
    }
  }, [isLoading, shortlistedUsers, offSet]);

  if (isEmpty(shortlistedUsers.data)) {
    return <Empty />;
  }

  const Data = list.map((item) => (
    <ShortlistedItem
      key={item.id}
      updateUserHmStatus={updateUserHmStatus}
      id={userData.id}
      item={item}
    />
  ));

  return (
    <>
      {Data}
      {!isEmpty(offSet) && (
        <Button
          type="link"
          block
          onClick={() => {
            getShortlistedUsers({ id: userData.id, qs: `offset=${offSet}` });
          }}
        >
          Load More Users
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  shortlistedUsers: state.shortlistedUsers,
  isLoading: state.shortlistedUsers.isLoading,
});

export default connect(mapStateToProps, {
  getShortlistedUsers: userProfileEffects.getShortlistedUsers,
  updateUserHmStatus: adminEffects.updateUserHmStatus,
})(Shortlisted);
