/* eslint-disable */
import { userProfileEffects } from './userProfile';
import { isEmpty, snakeToCamel } from './utils';

export const adminActionTypes = {
  adminProfileDataSuccess: 'ADMINPROFILEDATA_SUCCESS',
  adminProfileDataFailure: 'ADMINPROFILEDATA_FAILURE',
  adminProfileDataLoading: 'ADMINPROFILEDATA_LOADING',
  userFeedbackLoading: 'USERFEEDBACK_FETCHING',
  userFeedbackSuccess: 'USERFEEDBACK_SUCCESS',
  userFeedbackFailure: 'USERFEEDBACK_FAILURE',
};

const initialState = {
  adminData: {
    isLoading: false,
    data: {},
    error: {},
  },
  sendUserFeedBackState: {
    isLoading: false,
    data: {},
    error: {},
    success: false,
  },
};

export function adminDataReducer(state = initialState.adminData, action) {
  switch (action.type) {
    case adminActionTypes.adminProfileDataLoading:
      return { ...state, isLoading: true };

    case adminActionTypes.adminProfileDataFailure:
      return { ...state, error: action.error, isLoading: false };

    case adminActionTypes.adminProfileDataSuccess:
      return { ...state, data: action.payload, isLoading: false };

    default:
      return state;
  }
}

export function sendUserFeedbackReducer(
  state = initialState.sendUserFeedBackState,
  action
) {
  switch (action.type) {
    case adminActionTypes.userFeedbackLoading:
      return { ...state, isLoading: true, success: false };
    case adminActionTypes.userFeedbackSuccess:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };
    case adminActionTypes.userFeedbackFailure:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false,
      };
    default:
      return state;
  }
}

export const adminActions = {
  adminProfileDataLoading(bool) {
    return {
      type: adminActionTypes.adminProfileDataLoading,
      isLoading: bool,
    };
  },
  adminProfileDataSuccess(data) {
    return {
      type: adminActionTypes.adminProfileDataSuccess,
      payload: data,
    };
  },
  adminProfileDataFailure(error) {
    return {
      type: adminActionTypes.adminProfileDataFailure,
      error,
    };
  },
  sendUserFeedbackLoading(bool) {
    return {
      type: adminActionTypes.userFeedbackLoading,
      isLoading: bool,
    };
  },
  sendUserFeedbackSuccess(data) {
    return {
      type: adminActionTypes.userFeedbackSuccess,
      payload: data,
    };
  },
  sendUserFeedbackFailure(error) {
    return {
      type: adminActionTypes.userFeedbackFailure,
      error,
    };
  },
};

export const adminEffects = {
  getAdminProfileData() {
    return async (dispatch) => {
      dispatch(adminActions.adminProfileDataLoading(true));
      try {
        let resp = await window.client.getAdminDetails();
        const { body } = resp;
        const result = {};
        Object.keys(body).map((item) => {
          result[snakeToCamel(item)] = body[item];
          return null;
        });

        if (!isEmpty(body.images) && body.images.length > 0)
          result['profileImage'] = body.images[0];
        dispatch(adminActions.adminProfileDataSuccess(body));
        return body;
      } catch (e) {
        dispatch(adminActions.adminProfileDataFailure(e));
      }
    };
  },
  sendUserFeedback({ id, data }) {
    return async (dispatch) => {
      dispatch(adminActions.sendUserFeedbackLoading(true));
      try {
        let resp = await window.client.sendUserFeedback({ id, ...data });
        dispatch(adminActions.sendUserFeedbackSuccess(resp.body));
        const qs =
          data.type === 'human_matchmaker'
            ? `type=human_matchmaker`
            : `type=positive,negative,in_app_review`;

        dispatch(userProfileEffects.getUserProfileFeedback({ id, qs }));
      } catch (e) {
        dispatch(adminActions.sendUserFeedbackFailure(e));
      }
    };
  },
  updateUserHmStatus({ id, connectionId, payload }) {
    return async () => {
      try {
        const resp = await window.client.updateUserHmStatus({
          id,
          connectionId,
          payload,
        });

        return resp;
      } catch (e) {
        console.log(e);
      }
    };
  },
  revokePlan({ id, payload }) {
    return async () => {
      try {
        const resp = await window.client.revokePlan({
          id,
          payload,
        });

        return resp;
      } catch (e) {
        console.log(e);
      }
    };
  },
};
