import React, { useEffect, useReducer } from 'react';
import classNames from 'classnames';
import {
  formatNumber,
  getMonthIndex,
  getMonthString,
  isValidDate,
  months,
  range,
} from '../../utilities/helper';
import Clear from '../Clear';
import './styles.scss';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_STATE':
      return { ...state, currentState: action.payload };
    case 'SET_DAY':
      return { ...state, day: action.payload, currentState: 'month' };
    case 'SET_MONTH':
      return { ...state, month: action.payload, currentState: 'year' };
    case 'SET_YEAR':
      return { ...state, year: action.payload };
    case 'RESET':
      return {
        currentState: 'day',
        day: null,
        month: null,
        year: null,
      };
    default:
      return {
        currentState: 'day',
        day: null,
        month: null,
        year: null,
      };
  }
};

const CustomCalendar = ({
  setIsButtonEnabled,
  formatDob,
  setError,
  date_of_birth,
}) => {
  // const [savedYear, savedMonth, savedDay] = user.date_of_birth?.split("-"); // This will not work if the date_of_birth is null

  const initialState = {
    currentState: 'day',
    day: parseInt(date_of_birth?.split('-')[2], 10),
    month: getMonthString(parseInt(date_of_birth?.split('-')[1], 10)),
    year: parseInt(date_of_birth?.split('-')[0], 10),
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // check if all the fields are field then only make the button active
  useEffect(() => {
    setIsButtonEnabled(
      !!state.day &&
        !!state.month &&
        !!state.year &&
        isValidDate(`${state.year}-${getMonthIndex(state.month)}-${state.day}`)
    );
    formatDob(state);
  }, [
    state.day,
    state.month,
    state.year,
    state,
    setIsButtonEnabled,
    formatDob,
  ]);

  const handleSelectDay = (value) =>
    dispatch({ type: 'SET_DAY', payload: value });

  const handleSelectMonth = (value) =>
    dispatch({ type: 'SET_MONTH', payload: value });

  const handleSelectYear = (value) =>
    dispatch({ type: 'SET_YEAR', payload: value });

  const resetCalender = () => dispatch({ type: 'RESET' });

  const CALENDAR_BUTTONS = [
    { id: 1, type: 'day', value: state.day, short: 'dd' },
    { id: 2, type: 'month', value: state.month, short: 'mm' },
    { id: 3, type: 'year', value: state.year, short: 'yyyy' },
  ];

  const CALENDAR_BODY_MAPPING = {
    day: <SelectDay onChange={handleSelectDay} value={state.day} />,
    month: <SelectMonth onChange={handleSelectMonth} value={state.month} />,
    year: <SelectYear onChange={handleSelectYear} value={state.year} />,
  };

  return (
    // eslint-disable-next-line
    <div className=" calendar" onClick={() => setError(false)}>
      <div className=" calendar__header">
        <div className="">
          {CALENDAR_BUTTONS.map(({ type, short, id, value }, index) => (
            <button
              type="button"
              className={classNames({
                'text-primaryPink': state.currentState === type,
                'text-black': value,
                'text-silver': !value && state.currentState !== type,
              })}
              onClick={() =>
                dispatch({ type: 'SET_CURRENT_STATE', payload: type })
              }
              key={id}
            >
              {formatNumber(value) || short}
              {index !== 2 && <span className="text-silver"> -</span>}
            </button>
          ))}
        </div>
        <Clear onClickHandler={resetCalender} visible />
      </div>

      <div className="calendar__body">
        <h2>Select a {state.currentState}</h2>
        {CALENDAR_BODY_MAPPING[state.currentState]}
      </div>
    </div>
  );
};

export default CustomCalendar;

// nested button inside calender body

const SelectDateButton = ({ onClickHandler, selectedValue, currentValue }) => (
  <button
    type="button"
    className={classNames('button button__select-date', {
      // eslint-disable-next-line eqeqeq
      'selected ': selectedValue?.toString() === currentValue?.toString(),
    })}
    onClick={() => {
      onClickHandler(currentValue);
    }}
  >
    {currentValue}
  </button>
);

const SelectYear = ({ onChange, value }) => (
  <div className=" calendar__year">
    {range(1971, 2005).map((_year) => (
      <SelectDateButton
        key={_year}
        onClickHandler={onChange}
        selectedValue={value}
        currentValue={_year}
      />
    ))}
  </div>
);

const SelectMonth = ({ onChange, value }) => (
  <div className="calendar__month">
    {months.map((_month) => (
      <SelectDateButton
        key={_month}
        onClickHandler={onChange}
        selectedValue={value}
        currentValue={_month}
      />
    ))}
  </div>
);

const SelectDay = ({ onChange, value }) => (
  <div className=" calendar__day">
    {range(1, 31).map((_day) => (
      <SelectDateButton
        key={_day}
        onClickHandler={onChange}
        selectedValue={value}
        currentValue={`0${_day}`.slice(-2)} //! refactor this
      />
    ))}
  </div>
);
