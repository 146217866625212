import React from 'react';
import OptionPill from '../../../../components/OptionPill';
import { RELIGIONS } from '../../config';
import './styles.scss';

const Religion = ({ religions, setReligions, section }) => (
  <section className="religion-container">
    {RELIGIONS.map((religion) => {
      if (
        /* If this is rendered inside Partner Preference, we need to show the 'Any'
        opiont as well. Else, skip it. */
        (religion.value === 'any' && section === 'partner-preference') ||
        religion.value !== 'any'
      ) {
        return (
          <OptionPill
            key={religion.id}
            onClick={() => setReligions(religion)}
            className="religion-pill"
            selected={religions.some(
              (currentReligion) => currentReligion.value === religion.value
            )}
          >
            {religion.displayText}
          </OptionPill>
        );
      }
      return null;
    })}
  </section>
);

export default Religion;
