import React from 'react';
import { useHistory } from 'react-router-dom';
import MobileNumber from '../UserOnboarding/MobileNumber';
import './styles.scss';

const CreateProfile = () => {
  const history = useHistory();

  const handleRedirect = (id) => {
    history.push({
      pathname: `/user-profile/${id}/profile`,
      state: {
        redirectedFromCreateProfile: true,
      },
    });
  };

  return (
    <div className="createProfile__wrapper">
      <div className="createProfile">
        <MobileNumber handleRedirect={handleRedirect} />
      </div>
    </div>
  );
};

export default CreateProfile;
