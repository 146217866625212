import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { userProfileEffects } from '../../../@betterhalf-private/redux/src';

const styles = {
  modal: {},
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  input: {
    width: '60%',
  },
  radioGroup: {
    marginBottom: 20,
  },
  radioStyle: {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  },
};

const RestoreExpireModal = ({ visible, title, onModalClose, message }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.data);

  const handleOkPress = () => {
    // api call
    dispatch(userProfileEffects.retrieveExpiredCount({ id: userData.id }));
    onModalClose();
  };
  return (
    <Modal
      visible={visible}
      destroyOnClose
      title={title}
      onCancel={() => {
        onModalClose();
      }}
      onOk={() => handleOkPress()}
      style={styles.modal}
      okText="OK"
    >
      {message}
    </Modal>
  );
};

export default RestoreExpireModal;
