import React, { useState } from 'react';
import { Tabs } from 'antd';
import './styles.scss';
import TabBar from '../../../components/TabBar';
import MustHave from './MustHave';
import NiceToHave from './NiceToHave';

const { TabPane } = Tabs;

const TABS_TO_RENDER = [
  {
    key: '1',
    label: 'Must have',
    value: 'must_have',
  },
  {
    key: '2',
    label: 'Nice to have',
    value: 'nice_to_have',
  },
];

const PartnerPreference = ({
  closePanel,
  navigateToAiFromPreferences,
  closeCompleteProfile,
}) => {
  const [activeTab, setActiveTab] = useState('1');

  const goToNiceToHave = () => setActiveTab('2');

  return (
    <section className="partner-preference-container">
      <Tabs
        renderTabBar={(props) => <TabBar {...props} tabs={TABS_TO_RENDER} />}
        defaultActiveKey="1"
        onChange={setActiveTab}
        activeKey={activeTab}
      >
        <TabPane tab="must_have" key="1" className="tab-pane-container">
          <MustHave goToNiceToHave={goToNiceToHave} />
        </TabPane>
        <TabPane tab="nice_to_have" key="2" className="tab-pane-container">
          <NiceToHave
            closeMainPanel={closePanel}
            navigateToAiFromPreferences={navigateToAiFromPreferences}
            closeCompleteProfile={closeCompleteProfile}
          />
        </TabPane>
      </Tabs>
    </section>
  );
};

export default PartnerPreference;
