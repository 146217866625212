import React, { PureComponent } from 'react';
import { Modal, Input, Select } from 'antd';
import { connect } from 'react-redux';

import { isEmpty } from '../../../utilities/util';
import { paymentPlansEffects } from '../../../@betterhalf-private/redux/src';
import { isEmailValid, isPhoneNumberValid } from '../../../utilities/helper';

const { Option } = Select;

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  input: {
    width: '60%',
  },
  inputDisable: {
    width: '60%',
    color: '#000',
  },
  strikeThrough: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
};
class BuyKundaliModal extends PureComponent {
  constructor() {
    super();
    this.state = {
      planId: '',
      email: '',
      phoneNumber: '',
      amount: '',
      gst_amount: 0, // computed value
      amount_payable: 0, // computed value
    };
  }

  componentDidMount() {
    const { userData, fetchPlans } = this.props;
    const { id, email, mobile } = userData;
    this.setState({ email, phoneNumber: mobile });
    fetchPlans({ id });
  }

  changeAmount = (value) => {
    if (Object.is(NaN, Number(value))) return;
    const { plans } = this.props;
    const gst_amount = Object.values(plans)[0].is_gst_supported
      ? Number(value) * (Object.values(plans)[0].gst_percentage / 100)
      : 0;
    const amount_payable = gst_amount + Number(value);
    this.setState({ amount: value, gst_amount, amount_payable });
  };

  modalClose = () => {
    const { onModalClose } = this.props;
    this.setState({ planId: '', email: '', phoneNumber: '', amount: '' });
    onModalClose();
  };

  handleOkPress = async () => {
    const { planId, email, phoneNumber, amount_payable, gst_amount } =
      this.state;
    const { userData, onOkayPress, paymentLinks } = this.props;

    const dataToSend = {
      id: userData.id,
      plan: planId,
      amount: amount_payable.toFixed(2),
      gst_amount: gst_amount.toFixed(2),
    };

    if (email.length && email.trim() !== userData.email)
      dataToSend.email = email;
    if (phoneNumber.length && phoneNumber.trim() !== userData.mobile)
      dataToSend.mobile = phoneNumber;

    paymentLinks(dataToSend);

    this.setState({
      planId: '',
      email: '',
      phoneNumber: '',
      amount: '',
      amount_payable: 0,
      gst_amount: 0,
    });
    onOkayPress();
  };

  onChange = (value = '') => {
    const { plans } = this.props;
    const plan = plans[value];
    const gst_amount = plan.is_gst_supported
      ? plan.discounted_amount * (plan.gst_percentage / 100)
      : 0;
    const amount_payable = gst_amount + plan.discounted_amount;
    this.setState({
      planId: plan.plan_id,
      amount: plan.discounted_amount,
      gst_amount,
      amount_payable,
    });
  };

  isValidData = () => {
    const { planId, amount, phoneNumber, email } = this.state;
    return (
      !isEmpty(planId) &&
      !isEmpty(amount) &&
      isPhoneNumberValid(phoneNumber) &&
      isEmailValid(email)
    );
  };

  render() {
    const { title, visible, plans } = this.props;
    const { planId, email, phoneNumber, amount, gst_amount, amount_payable } =
      this.state;

    let planOptions = null;
    if (Object.keys(plans).length > 0) {
      planOptions = Object.keys(plans).map((currentPlanId) => {
        const plan = plans[currentPlanId];
        return (
          <Option key={plan.plan_id} value={plan.plan_id}>
            {`${plan.consumable_quantities} - ${plan.name} ( `}
            <span style={styles.strikeThrough}>{`₹${plan.amount}`}</span>
            {`  ₹${plan.discounted_amount} )`}
          </Option>
        );
      });
    }

    return (
      <Modal
        destroyOnClose
        onCancel={this.modalClose}
        visible={visible}
        title={title}
        onOk={this.handleOkPress}
        okButtonProps={
          this.isValidData() ? { disabled: false } : { disabled: true }
        }
      >
        <div style={styles.card}>
          <h4>Select Plan</h4>
          <Select onChange={this.onChange} style={styles.input}>
            {planOptions}
          </Select>
        </div>
        <div style={styles.card}>
          <h4>Email</h4>
          <Input
            value={email}
            onChange={(e) => this.setState({ email: e.target.value })}
            style={styles.input}
            placeholder="Email"
          />
        </div>
        <div style={styles.card}>
          <h4>Phone Number</h4>
          <Input
            value={phoneNumber}
            onChange={(e) => this.setState({ phoneNumber: e.target.value })}
            style={styles.input}
            placeholder="Phone Number"
          />
        </div>
        <div style={styles.card}>
          <h4>Amount</h4>
          <Input
            disabled={!planId}
            value={amount}
            onChange={(e) => this.changeAmount(e.target.value)}
            style={styles.input}
            placeholder="Enter plan amount"
          />
        </div>
        <div style={styles.card}>
          <h4>GST amount</h4>
          <Input
            disabled
            value={gst_amount.toFixed(2)}
            style={styles.inputDisable}
          />
        </div>
        <div style={styles.card}>
          <h4>Payable amount</h4>
          <Input
            disabled
            value={amount_payable.toFixed(2)}
            style={styles.inputDisable}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  plans: state.kundaliPlans.data,
});

export default connect(mapStateToProps, {
  fetchPlans: paymentPlansEffects.fetchKundaliPlans,
  paymentLinks: paymentPlansEffects.paymentLinks,
})(BuyKundaliModal);
