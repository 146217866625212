import React, { useState, useEffect } from 'react';
import Collapse from '../Details/DetailCollapse';
import DetailPanel from '../Details/DetailPanel';
import DetailHeader from '../Details/DetailHeader';
import {
  updateLocation,
  editUserDetails,
  searchCity,
  searchCollege,
  searchCompany,
  searchDesignation,
  searchHighestQualification,
} from '../api';
import './styles.scss';

import SettleDown from './SettleDown';

import {
  COLLEGE_NAME_LIST,
  COMPANY_LIST,
  DRINK_PREFERENCE_LIST,
  FOOD_PREFERENCE_LIST,
  HEIGHT_DATA,
  HIGHEST_QUALIFICATION_LIST,
  JOB_TITLE_LIST,
  LOCATION_LIST,
  SALARY_DATA,
  SMOKE_PREFERENCE_LIST,
} from '../config';
import { SelectWithSearch, SelectPreference, SelectCustom } from '../Commons';
import Button from '../../../components/Button';
import { useCollapse } from '../hooks';
import {
  getFormattedLocationPayloadFromInternalAPI,
  extractCity,
  convertIncomeNumberToString,
} from '../helpers';
import { useUserData } from '../../UserProfile/CompleteProfile/UserDataContext';
import {
  convertToStartcase,
  formatIncome,
  heightConverterIntoFtAndInches,
  heightFormatter,
  InchesIntoCms,
} from '../../../utilities/helper';

const ACCORDION_KEYS = [
  'settleDown',
  'homeTown',
  'height',
  'highestDegree',
  'collegeName',
  'designation',
  'companyName',
  'incomeFrom',
  'foodPreference',
  'drinkPreference',
  'smokePreference',
];

const formatDisplayHeight = (value) =>
  `${heightConverterIntoFtAndInches({
    inches: value,
    format: 'withoutFtAndIn',
  })} (${InchesIntoCms(value)})`;

const getDisplaySmokePreference = (value) =>
  SMOKE_PREFERENCE_LIST.find((item) => item.value === value).displayText;

const getDisplayDrinkPreference = (value) =>
  DRINK_PREFERENCE_LIST.find((item) => item.value === value).displayText;

const PersonalDetails = ({ moveToNext: mainCollapse_moveToNext }) => {
  const {
    id: userId,
    settleDown: userSavedSettleDown,
    height: userSavedHeight,
    locations: userSavedLocations,

    highestDegree: userSavedDegree,
    collegeName: userSavedCollegeName,
    designation: userSavedDesignation,
    companyName: userSavedCompanyName,
    annualIncome: userSavedAnnualIncome,
    foodPreference: userSavedFoodPreference,
    drinkPreference: userSavedDrinkPreference,
    smokePreference: userSavedSmokePreference,
    incomeFrom: userSavedIncomeFrom,
    incomeTo: userSavedIncomeTo,
    status: userStatus,
  } = useUserData();

  const { activeKey, moveToNext, jumpToStep, closePanel } = useCollapse(
    ACCORDION_KEYS,
    ACCORDION_KEYS[0],
    !!userSavedSettleDown
  );

  const [settleDown, setSettleDown] = useState(userSavedSettleDown);
  const [homeTown, setHomeTown] = useState(() =>
    extractCity(userSavedLocations, 'hometown')
  );

  const [height, setHeight] = useState(userSavedHeight);
  const [highestDegree, setHighestDegree] = useState(userSavedDegree);
  const [collegeName, setCollegeName] = useState(userSavedCollegeName);
  const [jobTitle, setJobTitle] = useState(userSavedDesignation);
  const [companyName, setCompanyName] = useState(userSavedCompanyName);
  const [incomeFrom, setIncomeFrom] = useState(
    userSavedIncomeFrom && Number(userSavedIncomeFrom).toLocaleString('en-IN')
  );
  const [incomeTo, setIncomeTo] = useState(
    userSavedIncomeFrom && Number(userSavedIncomeTo).toLocaleString('en-IN')
  );

  const [foodPreference, setFoodPreference] = useState(userSavedFoodPreference);
  const [drinkPreference, setDrinkPreference] = useState(
    userSavedDrinkPreference
  );
  const [smokePreference, setSmokePreference] = useState(
    userSavedSmokePreference
  );

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const STEP_TO_KEYS = {
    0: settleDown,
    1: homeTown,
    2: height,
    3: highestDegree,
    4: collegeName,
    5: jobTitle,
    6: companyName,
    7: incomeFrom,
    8: foodPreference,
    9: drinkPreference,
    10: smokePreference,
  };

  // pre-fill the data
  useEffect(() => {
    if (
      userSavedCompanyName &&
      userSavedSettleDown &&
      userSavedHeight &&
      userSavedLocations &&
      userSavedDegree &&
      userSavedCollegeName &&
      userSavedDesignation &&
      userSavedAnnualIncome &&
      userSavedFoodPreference &&
      userSavedDrinkPreference &&
      userSavedSmokePreference
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [
    userSavedCompanyName,
    userSavedSettleDown,
    userSavedHeight,
    userSavedLocations,
    userSavedDegree,
    userSavedCollegeName,
    userSavedDesignation,
    userSavedAnnualIncome,
    userSavedFoodPreference,
    userSavedDrinkPreference,
    userSavedSmokePreference,
    userSavedIncomeFrom,
    userSavedIncomeTo,
  ]);

  const openNextCollapse = () => {
    const activeIndex = ACCORDION_KEYS.indexOf(activeKey);
    const nextKey = STEP_TO_KEYS[activeIndex + 1];

    if (!nextKey) moveToNext();
    else closePanel();
  };

  const onClickHandler = async () => {
    if (userStatus === 'ai_details') {
      const payload = {
        status: 'personal_details',
      };
      await editUserDetails(userId, payload);
    }

    mainCollapse_moveToNext();
  };

  const handleOnChange = (key) => {
    const indexToJump = ACCORDION_KEYS.indexOf(key);
    const prevKey = STEP_TO_KEYS[indexToJump - 1];
    if (prevKey || prevKey === -1 || indexToJump === 0 || key === undefined)
      jumpToStep(key);
  };

  const handleSelectHighestQualification = async (qualification) => {
    setHighestDegree(
      typeof qualification === 'string'
        ? qualification
        : qualification.displayText
    );

    const payload = {
      highest_degree: qualification.displayText || qualification,
    };
    await editUserDetails(userId, payload);
    openNextCollapse();
  };

  const handleSelectCompanyName = async (company) => {
    setCompanyName(typeof company === 'string' ? company : company.displayText);

    const payload = {
      company_name: company.displayText || company,
    };
    await editUserDetails(userId, payload);
    openNextCollapse();
  };

  const handleSelectCollegeName = async (college) => {
    setCollegeName(typeof college === 'string' ? college : college.displayText);
    const payload = {
      college_name: college.displayText || college,
    };
    await editUserDetails(userId, payload);
    openNextCollapse();
  };

  const handleDesignation = async (designation) => {
    setJobTitle(
      typeof designation === 'string' ? designation : designation.displayText
    );
    const payload = {
      designation: designation.displayText || designation,
    };
    await editUserDetails(userId, payload);
    openNextCollapse();
  };

  const handleSettleDown = async (value) => {
    setSettleDown(value.displayText);

    const payload = {
      settle_down: value.dataToBeSent,
    };
    await editUserDetails(userId, payload);
    openNextCollapse();
  };

  const handleHomeTown = async (city) => {
    let payload;
    setHomeTown(typeof city === 'string' ? city : city.displayText);

    // check if the selected city is from the list of static cities
    const filteredLocationList = LOCATION_LIST.filter(
      (_homeTown) => _homeTown.displayText === city.displayText
    );

    if (filteredLocationList.length === 0) {
      payload = getFormattedLocationPayloadFromInternalAPI(city);
    } else {
      // eslint-disable-next-line prefer-destructuring
      const temp_payload = filteredLocationList[0];
      payload = { ...temp_payload };
      delete payload.displayText;
      delete payload.id;
    }
    payload.properties.tag = 'hometown';

    await updateLocation(userId, payload, 'hometown');

    openNextCollapse();
  };

  const handleDrinkPreference = async (preference) => {
    setDrinkPreference(
      typeof preference === 'string' ? preference : preference.value
    );

    const payload = {
      drink_preference: preference.value,
    };
    await editUserDetails(userId, payload);
    openNextCollapse();
  };

  const handleSmokePreference = async (preference) => {
    setSmokePreference(
      typeof preference === 'string' ? preference : preference.value
    );
    const payload = {
      smoke_preference: preference.value,
    };
    await editUserDetails(userId, payload);
    openNextCollapse();
  };

  const handleSelectFoodPreference = async (preference) => {
    setFoodPreference(
      typeof preference === 'string' ? preference : preference.value
    );
    const payload = {
      food_preference: preference.value,
    };
    await editUserDetails(userId, payload);
    openNextCollapse();
  };

  const handleSelectHeight = async (value) => {
    setHeight(value);

    const payload = {
      height: value,
    };
    await editUserDetails(userId, payload);
    openNextCollapse();
  };

  const handleSelectAnnualIncome = async (income) => {
    const formattedIncome = formatIncome(income);

    setIncomeFrom(Number(formattedIncome.incomeFrom)?.toLocaleString('en-IN'));
    setIncomeTo(Number(formattedIncome.incomeTo)?.toLocaleString('en-IN'));
    const payload = {
      income_from: formattedIncome.incomeFrom,
      income_to: formattedIncome.incomeTo,
      currency: 'INR',
    };

    await editUserDetails(userId, payload);
    openNextCollapse();
  };

  return (
    <section className="personal-details-container">
      <Collapse
        destroyInactivePanel
        activeKey={activeKey}
        onChange={handleOnChange}
      >
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Settle-down"
              value={settleDown}
              placeholder="Eg: Within 6 months"
              isActive={activeKey === ACCORDION_KEYS[0]}
            />
          }
          key={ACCORDION_KEYS[0]}
        >
          <SettleDown
            handleSettleDown={handleSettleDown}
            settleDown={settleDown}
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Hometown"
              value={homeTown}
              placeholder="Eg: Kolkata"
              isActive={activeKey === ACCORDION_KEYS[1]}
            />
          }
          key={ACCORDION_KEYS[1]}
        >
          <SelectWithSearch
            selectedData={homeTown}
            onSelect={handleHomeTown}
            list={LOCATION_LIST}
            isTypeVertical={false}
            searchCallback={searchCity}
            getTransformedSearchResults={(searchResults) =>
              searchResults?.result?.map((searchResult) => ({
                ...searchResult,
                value: searchResult.city,
                displayText: searchResult.city,
              }))
            }
            showBtns={{ add: false, ctas: false }}
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Height"
              value={height && `${heightFormatter(userSavedHeight)}`}
              placeholder="Eg: 4ft 11 in"
              isActive={activeKey === ACCORDION_KEYS[2]}
            />
          }
          key={ACCORDION_KEYS[2]}
        >
          <SelectCustom
            handleChange={handleSelectHeight}
            data={HEIGHT_DATA}
            defaultValue={userSavedHeight || HEIGHT_DATA[16].value}
            formatDisplayText={formatDisplayHeight}
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Highest qualification"
              value={highestDegree}
              placeholder="Eg: MBA"
              isActive={activeKey === ACCORDION_KEYS[3]}
            />
          }
          key={ACCORDION_KEYS[3]}
        >
          <SelectWithSearch
            selectedDataList={highestDegree}
            onSelect={handleSelectHighestQualification}
            list={HIGHEST_QUALIFICATION_LIST}
            searchCallback={searchHighestQualification}
            getTransformedSearchResults={(searchResults) =>
              searchResults.degree_list.map((searchResult) => ({
                id: searchResult.id,
                value: searchResult.Name.toLowerCase(),
                displayText: searchResult.Name,
              }))
            }
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="College"
              value={collegeName}
              placeholder="Eg: Indian Institute of Management, Bangalore"
              isActive={activeKey === ACCORDION_KEYS[4]}
            />
          }
          key={ACCORDION_KEYS[4]}
        >
          <SelectWithSearch
            onSelect={handleSelectCollegeName}
            list={COLLEGE_NAME_LIST}
            searchCallback={searchCollege}
            getTransformedSearchResults={(searchResults) =>
              searchResults.college_list.map((searchResult) => ({
                id: searchResult.id,
                value: searchResult.College.toLowerCase(),
                displayText: searchResult.College.toLowerCase(),
              }))
            }
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Job title"
              value={jobTitle}
              placeholder="Eg: Product Manager"
              isActive={activeKey === ACCORDION_KEYS[5]}
            />
          }
          key={ACCORDION_KEYS[5]}
        >
          <SelectWithSearch
            onSelect={handleDesignation}
            list={JOB_TITLE_LIST}
            searchCallback={searchDesignation}
            getTransformedSearchResults={(searchResults) =>
              searchResults.designation_list.map((searchResult) => ({
                id: searchResult.id,
                value: searchResult.designation.toLowerCase(),
                displayText: searchResult.designation,
              }))
            }
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Company name"
              value={companyName}
              placeholder="Eg: Google"
              isActive={activeKey === ACCORDION_KEYS[6]}
            />
          }
          key={ACCORDION_KEYS[6]}
        >
          <SelectWithSearch
            onSelect={handleSelectCompanyName}
            list={COMPANY_LIST}
            searchCallback={searchCompany}
            getTransformedSearchResults={(searchResults) =>
              searchResults.company_list.map((searchResult) => ({
                id: searchResult.id,
                value: searchResult.Name.toLowerCase(),
                displayText: searchResult.Name,
              }))
            }
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Annual Income"
              value={incomeFrom && `${incomeFrom} - ${incomeTo}`}
              placeholder="Eg: 4-5 LPA"
              isActive={activeKey === ACCORDION_KEYS[7]}
            />
          }
          key={ACCORDION_KEYS[7]}
        >
          <SelectCustom
            handleChange={handleSelectAnnualIncome}
            data={SALARY_DATA}
            defaultValue={
              incomeFrom
                ? convertIncomeNumberToString(`${incomeFrom} - ${incomeTo}`)
                : SALARY_DATA[3]
            }
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Food preference"
              value={foodPreference && convertToStartcase(foodPreference)}
              placeholder="Eg: Vegetarian"
              isActive={activeKey === ACCORDION_KEYS[8]}
            />
          }
          key={ACCORDION_KEYS[8]}
        >
          <SelectPreference
            list={FOOD_PREFERENCE_LIST.slice(0, 4)}
            selectedPreference={foodPreference}
            handlePreference={handleSelectFoodPreference}
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Drink"
              value={
                drinkPreference && getDisplayDrinkPreference(drinkPreference)
              }
              placeholder="Eg: Drinks socially"
              isActive={activeKey === ACCORDION_KEYS[9]}
            />
          }
          key={ACCORDION_KEYS[9]}
        >
          <SelectPreference
            list={DRINK_PREFERENCE_LIST}
            selectedPreference={drinkPreference}
            handlePreference={handleDrinkPreference}
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Smoke"
              value={
                smokePreference && getDisplaySmokePreference(smokePreference)
              }
              placeholder="Eg: Smokes socially"
              isActive={activeKey === ACCORDION_KEYS[10]}
            />
          }
          key={ACCORDION_KEYS[10]}
        >
          <SelectPreference
            list={SMOKE_PREFERENCE_LIST}
            selectedPreference={smokePreference}
            handlePreference={handleSmokePreference}
          />
        </DetailPanel>
      </Collapse>
      {userStatus === 'ai_details' && (
        <div className="personal-next-button">
          <Button onClick={onClickHandler} disabled={isButtonDisabled}>
            {userStatus === 'ai_details' ? 'Next' : 'Save'}
          </Button>
        </div>
      )}
    </section>
  );
};

export default PersonalDetails;
