/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Input,
  DatePicker,
  Slider,
  Select,
  Button,
  Row,
  Layout,
  Radio,
  Table,
  Pagination,
  message,
} from 'antd';
import moment from 'moment';
import { LeftOutlined, RightOutlined, SyncOutlined } from '@ant-design/icons';
import {
  userProfileEffects,
  salesEffects,
} from '../../@betterhalf-private/redux/src';

import DebounceSelect from '../../components/DebounceSelect';
import {
  fetchCaste,
  fetchCityList,
  fetchDegreeList,
  fetchCollegeName,
  fetchJobTitle,
} from '../../services/searchAPI';
import { heightConverter } from '../../utilities/helper';
import {
  isEmpty,
  removeUnderscore,
  addUnderscore,
  getLakhsFromCurrencyValue,
  capitalize,
  stringFormatter,
  letterToSnake,
} from '../../utilities/util';
import {
  partnerPreferenceDetails,
  foodPartnerPreference,
  maritalStatusPreference,
  languagesPreference,
  religionsPreference,
  annualIncome,
  highestDegree,
  smokeRecommendationPreference,
  foodPreference,
  planName,
  sparklePlanName,
  religion,
  motherTongue,
  settleDown,
  drinkRecommendationPreference,
} from '../../components/DetailsCard/data.enum';
import { columns } from './columns';

const dateFormat = 'YYYY-MM-DD';

const { Option } = Select;

const { TextArea } = Input;

const { Content } = Layout;

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  input: {
    width: '60%',
  },
  recalBtn: {
    backgroundColor: 'white',
    borderColor: '#7C83FD',
    color: '#7C83FD',
    fontWeight: 'bold',
    height: 36,
    width: '42%',
  },
  totalCount: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'white',
    borderColor: '#7C83FD',
    color: '#7C83FD',
    fontWeight: 'bold',
    height: 36,
    width: '42%',
  },
  ppBtn: {
    backgroundColor: '#7C83FD',
    fontWeight: 'bold',
    color: '#fff',
    height: 36,
    width: '50%',
  },
};

const SalesSection = ({
  userData,
  additionalInfo,
  isLoadingMatchCount,
  getTwowayLeftMatchesCount,
  getOnewayLeftMatchesCount,
  isLoadingMatches,
  matches,
  getMatches,
  updatePartnerPreferences,
  addMatches,
}) => {
  const { partnerPreference } = userData;

  const [cityValue, setCityValue] = useState([]);
  const [degreeValue, setDegreeValue] = useState([]);
  const [casteValue, setCasteValue] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [collegeName, setCollegeName] = useState([]);
  const [slider, setSlider] = useState({});
  const [dropdownList, setDropdownList] = useState({});
  const [editedDetails, setEditedDetails] = useState([]); // TODO : FIX THIS
  const [numberRange, setNumberRange] = useState({});
  const [apiCall, setApiCall] = useState(1);
  const [matchesToBeAdded, setMatchesToBeAdded] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (partnerPreference) {
      setCityValue(partnerPreference.cities);
    }
  }, [partnerPreference]);

  const onSliderChange = (value, item) => {
    setSlider({ ...slider, [item.key]: value });
  };

  const onChangeInputText = (e, key) => {
    setEditedDetails({ ...editedDetails, [key]: e.target.value });
  };

  const onDateChange = (dates, dateStrings) => {
    setEditedDetails({ ...editedDetails, dateOfBirth: dateStrings });
  };

  const getDefaultValues = (list) => list?.map((i) => removeUnderscore(i));

  const tipFormatter = (value, { key }) => {
    if (key === 'heightPreference' || key === 'height') {
      return heightConverter({ inches: value });
    }
    if (key === 'annualIncomePreference') {
      return `${new Intl.NumberFormat('en-IN').format(value)} LPA`;
    }
    return value;
  };

  const onAfterSliderChange = ({ value, fieldsToBeSubmitted }) => {
    if (Array.isArray(fieldsToBeSubmitted)) {
      setEditedDetails({
        ...editedDetails,
        partner_preference: {
          ...editedDetails.partner_preference,
          [fieldsToBeSubmitted[0]]: value[0],
          [fieldsToBeSubmitted[1]]: value[1],
        },
      });
    }
  };

  const getDropDownList = (item) => {
    if (item.type === 'list' || item.type === 'debouncedList') {
      if (!isEmpty(dropdownList[item.fieldsToBeSubmitted])) {
        return [...dropdownList[item.fieldsToBeSubmitted]];
      }
      if (!isEmpty(editedDetails.partner_preference)) {
        if (item.fieldsToBeSubmitted in editedDetails.partner_preference) {
          return editedDetails.partner_preference[item.fieldsToBeSubmitted];
        }
        return getDefaultValues(userData[item.value]);
      }
      return getDefaultValues(userData[item.value]);
    }
    if (item.type === 'numberRange') {
      if (!isEmpty(numberRange)) {
        if (
          numberRange.income_from === '50,00,000' &&
          numberRange.income_to === '1,00,00,000'
        ) {
          return `50+ LPA`;
        }
        return `${getLakhsFromCurrencyValue(
          numberRange.income_from
        )} - ${getLakhsFromCurrencyValue(numberRange.income_to)} LPA`;
      }
      const valCheck1 = userData[item.key].substring(
        0,
        userData[item.key].indexOf('-') - 1
      );
      const valCheck2 = userData[item.key].substring(
        userData[item.key].indexOf('-') + 2,
        userData[item.key].indexOf('L') - 1
      );
      if (valCheck1 === '50,00,000' && valCheck2 === '1,00,00,000') {
        return `50+ LPA`;
      }
      return `${getLakhsFromCurrencyValue(
        valCheck1
      )} - ${getLakhsFromCurrencyValue(valCheck2)} LPA`;
    }
    if (item.type === 'selector') {
      if (!isEmpty(dropdownList[item.fieldsToBeSubmitted])) {
        return capitalize(dropdownList[item.fieldsToBeSubmitted]);
      }
      return capitalize(userData[item.key]);
    }

    return null;
  };

  const getDropDownMode = ({ key }) => {
    if (
      [
        'annualIncome',
        'highestDegree',
        'settleDown',
        'planName',
        'religion',
        'motherTongue',
        'sparklePlanName',
      ].includes(key)
    )
      return 'default';
    return 'multiple';
  };

  const handleDropDownChange = (
    value,
    { fieldsToBeSubmitted, type, submissionFormat = 'LowerCase' }
  ) => {
    if (type === 'list' || type === 'debouncedList') {
      if (value.includes(`Doesn't matter`)) {
        setDropdownList({ ...dropdownList, [fieldsToBeSubmitted]: [] });
        setEditedDetails({
          ...editedDetails,
          partner_preference: {
            ...editedDetails.partner_preference,
            [fieldsToBeSubmitted]: [],
          },
        });
        return null;
      }
      setDropdownList({
        ...dropdownList,
        [fieldsToBeSubmitted]: Array.isArray(value) ? value : [value],
      });
      setEditedDetails({
        ...editedDetails,
        partner_preference: {
          ...editedDetails.partner_preference,
          [fieldsToBeSubmitted]: Array.isArray(value)
            ? value.map((item) => {
                if (
                  [
                    'smoke_preference',
                    'drink_preference',
                    'food_preference',
                  ].includes(fieldsToBeSubmitted)
                ) {
                  return addUnderscore(item.toLowerCase());
                }

                if (
                  ['jobTitle', 'collegeName', 'cities'].includes(
                    fieldsToBeSubmitted
                  )
                ) {
                  return item;
                }

                return addUnderscore(item.toLowerCase());
              })
            : [letterToSnake(value.toLowerCase())],
        },
      });
      return null;
    }
    if (type === 'selector') {
      setDropdownList({
        ...dropdownList,
        [fieldsToBeSubmitted]:
          submissionFormat === 'LowerCase' ? value.toLowerCase() : value,
      });
      setEditedDetails({
        ...editedDetails,
        [fieldsToBeSubmitted]:
          submissionFormat === 'LowerCase' ? value.toLowerCase() : value,
      });
      return null;
    }
    if (value === '50+ LPA') {
      setEditedDetails({
        [fieldsToBeSubmitted[0]]: 5000000,
        [fieldsToBeSubmitted[1]]: 10000000,
      });
      setNumberRange({
        [fieldsToBeSubmitted[0]]: new Intl.NumberFormat('en-IN').format(
          5000000
        ),
        [fieldsToBeSubmitted[1]]: new Intl.NumberFormat('en-IN').format(
          10000000
        ),
      });
    } else {
      const val1 = Number(`${value.substring(0, value.indexOf('-') - 1)}00000`);
      const val2 = Number(
        `${value.substring(
          value.indexOf('-') + 2,
          value.indexOf('L') - 1
        )}00000`
      );

      setEditedDetails({
        [fieldsToBeSubmitted[0]]: val1,
        [fieldsToBeSubmitted[1]]: val2,
      });

      setNumberRange({
        [fieldsToBeSubmitted[0]]: new Intl.NumberFormat('en-IN').format(val1),
        [fieldsToBeSubmitted[1]]: new Intl.NumberFormat('en-IN').format(val2),
      });
    }

    return null;
  };

  const getDropDownChildren = ({ key }) => {
    const children = [];
    if (key === 'foodPartnerPreference') {
      foodPartnerPreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'maritalStatusPreference') {
      maritalStatusPreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'languagesPreference') {
      languagesPreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'religionsPreference') {
      religionsPreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'annualIncome') {
      annualIncome.map((i) => {
        if (i === '50 - 100 LPA') {
          children.push(<Option key={i}>50+ LPA</Option>);
        } else {
          children.push(<Option key={i}>{i}</Option>);
        }
        return null;
      });
    } else if (key === 'degreePreference') {
      highestDegree.map((i) => {
        children.push(
          <Option key={i}>
            {stringFormatter({ text: i, type: 'string' })}
          </Option>
        );
        return null;
      });
    } else if (key === 'smokeRecommendationPreference') {
      smokeRecommendationPreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'drinkRecommendationPreference') {
      drinkRecommendationPreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'foodPreference') {
      foodPreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'settleDown') {
      settleDown.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'planName') {
      planName.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'sparklePlanName') {
      sparklePlanName.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'religion') {
      religion.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'motherTongue') {
      motherTongue.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    }
    return children;
  };

  const Fields = partnerPreferenceDetails.map((item) => {
    if (item.editable && userData && partnerPreference) {
      return (
        <>
          {item.editComponent === 'searchDegree' && (
            <p
              style={{
                display: 'block',
                textAlign: 'center',
                fontSize: '0.9rem',
                lineHeight: '0.1em',
                borderBottom: '1px solid #000',
                margin: '20px 0 20px',
              }}
            >
              <span
                style={{
                  backgroundColor: '#fff',
                  padding: '0 10px',
                }}
              >
                Additional Fields (not part of partner-preference){' '}
              </span>
            </p>
          )}
          <div key={`${item.key}_${item.text}`} style={styles.card}>
            <h4>{item.text}</h4>
            {item.editComponent === 'input' && (
              <Input
                allowClear
                style={styles.input}
                disabled={!item.editable}
                onChange={(e) => onChangeInputText(e, item.key)}
                placeholder={userData[item.key]}
              />
            )}
            {item.editComponent === 'calender' && (
              <DatePicker
                style={{ width: '60%' }}
                defaultValue={moment(userData[item.key], dateFormat)}
                format={dateFormat}
                onChange={onDateChange}
              />
            )}
            {item.editComponent.includes('slider') && (
              <div style={{ width: '60%' }}>
                <Slider
                  range={item.editComponent === 'dualslider'}
                  step={item.steps}
                  min={item.min}
                  max={item.max}
                  tipFormatter={(value) => tipFormatter(value, item)}
                  value={
                    isEmpty(slider[item.key]) && item.type === 'numberRange'
                      ? [
                          partnerPreference[item.fieldsToBeSubmitted[0]],
                          partnerPreference[item.fieldsToBeSubmitted[1]],
                        ]
                      : isEmpty(slider[item.key]) && item.type === 'slider'
                      ? userData[item.key]
                      : slider[item.key]
                  }
                  onChange={(value) => onSliderChange(value, item)}
                  onAfterChange={(value) =>
                    onAfterSliderChange({
                      value,
                      fieldsToBeSubmitted: item.fieldsToBeSubmitted,
                    })
                  }
                />
              </div>
            )}
            {item.editComponent === 'dropdown' && (
              <Select
                mode={getDropDownMode({ key: item.key })}
                placeholder="Any"
                style={{ width: '60%' }}
                value={getDropDownList(item)}
                onChange={(value) => handleDropDownChange(value, item)}
              >
                {getDropDownChildren(item)}
              </Select>
            )}
            {item.editComponent === 'search' && (
              <DebounceSelect
                mode="multiple"
                value={cityValue}
                allowClear
                size="large"
                style={{ width: '60%' }}
                placeholder="Any"
                fetchOptions={fetchCityList}
                onChange={(newValue) => {
                  setCityValue(newValue);
                  handleDropDownChange(newValue, item);
                }}
              />
            )}
            {item.editComponent === 'searchDegree' && (
              <DebounceSelect
                mode="multiple"
                value={degreeValue}
                allowClear
                size="large"
                style={{ width: '60%' }}
                placeholder="Any"
                fetchOptions={fetchDegreeList}
                onChange={(newValue) => {
                  setDegreeValue(newValue);
                  handleDropDownChange(newValue, item);
                }}
              />
            )}
            {item.editComponent === 'searchCaste' && (
              <DebounceSelect
                mode="multiple"
                value={casteValue}
                allowClear
                size="large"
                style={{ width: '60%' }}
                placeholder="Any"
                fetchOptions={fetchCaste}
                onChange={(newValue) => {
                  setCasteValue(newValue);
                  handleDropDownChange(newValue, item);
                }}
              />
            )}

            {item.editComponent === 'searchJobTitle' && (
              <DebounceSelect
                mode="multiple"
                value={jobTitle}
                allowClear
                size="large"
                style={{ width: '60%' }}
                placeholder="Any"
                fetchOptions={fetchJobTitle}
                onChange={(newValue) => {
                  setJobTitle(newValue);
                  handleDropDownChange(newValue, item);
                }}
              />
            )}

            {item.editComponent === 'searchCollegeName' && (
              <DebounceSelect
                mode="multiple"
                val={collegeName}
                allowClear
                size="large"
                style={{ width: '60%' }}
                placeholder="Any"
                fetchOptions={fetchCollegeName}
                onChange={(newValue) => {
                  setCollegeName(newValue);
                  handleDropDownChange(newValue, item);
                }}
              />
            )}

            {item.editComponent === 'LongInput' && (
              <TextArea
                defaultValue={userData[item.key]}
                disabled={!item.editable}
                onChange={(e) => onChangeInputText(e, item.key)}
                style={{ marginTop: 20, marginLeft: 20 }}
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            )}
          </div>
        </>
      );
    }
    return null;
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const matchesToAdd = [];
      selectedRows.map((i) => {
        matchesToAdd.push(i.id);
        return null;
      });
      setMatchesToBeAdded(matchesToAdd);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const itemRender = (current, type, originalElement) => {
    const { id } = userData;
    const { next, previous } = matches;
    if (type === 'prev') {
      return (
        <Button
          disabled={isEmpty(previous) || isLoadingMatches}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 10,
          }}
          onClick={
            isEmpty(previous)
              ? () => {}
              : () => {
                  // Mixpanel.track('SEND_RECOMMENDATIONS_PAGINATION_PREVIOUS')
                  getMatches({ id, url: previous });
                }
          }
        >
          <LeftOutlined />
        </Button>
      );
    }

    if (type === 'next') {
      return (
        <Button
          disabled={isEmpty(next) || isLoadingMatches}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 10,
          }}
          onClick={
            isEmpty(next)
              ? () => {}
              : () => {
                  // Mixpanel.track('SEND_RECOMMENDATIONS_PAGINATION_NEXT')
                  getMatches({ id, url: next });
                }
          }
        >
          <RightOutlined />
        </Button>
      );
    }

    return originalElement;
  };

  const onGetRecommendation = () => {
    let qs = apiCall === 1 ? `type=two_way` : `type=one_way`;
    const { partner_preference } = editedDetails;
    if (!isEmpty(partner_preference)) {
      const {
        age_from,
        age_to,
        cities,
        college_name,
        food_preference,
        smoke_preference,
        drink_preference,
        designation,
        height_from,
        height_to,
        income_from,
        income_to,
        languages,
        marital_status,
        religions,
        castes,
        degrees,
      } = partner_preference;
      if (!isEmpty(age_from)) {
        qs += `&age_from=${age_from}`;
      }

      if (!isEmpty(age_to)) {
        qs += `&age_to=${age_to}`;
      }

      if (!isEmpty(height_from)) {
        qs += `&height_from=${height_from}`;
      }

      if (!isEmpty(height_to)) {
        qs += `&height_to=${height_to}`;
      }

      if (!isEmpty(income_from)) {
        qs += `&income_from=${income_from}`;
      }

      if (!isEmpty(income_to)) {
        qs += `&income_to=${income_to}`;
      }

      if (!isEmpty(cities)) {
        let city = '';
        cities.map((c, index) => {
          const text = index === 0 ? `${c}` : `,${c}`;
          city += text;
          return null;
        });
        qs += `&cities=${city}`;
      }
      if (cities && cities.length === 0) {
        qs += `&cities=any`;
      }
      // if castes is not empty the join each element with comma
      if (!isEmpty(castes)) {
        const formattedCast = castes.join(',');
        qs += `&caste=${formattedCast}`;
      }

      if (!isEmpty(degrees)) {
        let formattedDegrees = degrees.join(',');
        formattedDegrees = formattedDegrees.replaceAll('_', ' ');
        qs += `&highest_degree=${formattedDegrees}`;
      }

      if (!isEmpty(jobTitle)) {
        qs += `&designation=${jobTitle}`;
      }

      if (!isEmpty(collegeName)) {
        qs += `&college_name=${collegeName}`;
      }

      if (!isEmpty(food_preference)) {
        let currentFoodPreference = '';
        food_preference.map((foodP, index) => {
          const text = index === 0 ? `${foodP}` : `,${foodP}`;
          currentFoodPreference += text;
          return null;
        });
        qs += `&food_preference=${currentFoodPreference}`;
      }

      
      if (!isEmpty(smoke_preference)) {
        let currentSmokePreference = '';
        smoke_preference.map((smokeP, index) => {
          const text = index === 0 ? `${smokeP}` : `,${smokeP}`;
          currentSmokePreference += text;
          return null;
        });
        
        qs += `&smoke_preference=${currentSmokePreference}`;
      }

      

      if (!isEmpty(drink_preference)) {
        let currentDrinkPreference = '';
        drink_preference.map((drinkP, index) => {
          const text = index === 0 ? `${drinkP}` : `,${drinkP}`;
          currentDrinkPreference += text;
          return null;
        });
        qs += `&drink_preference=${currentDrinkPreference}`;
      }     

      if (!isEmpty(languages)) {
        let language = '';
        languages.map((l, index) => {
          const text =
            index === 0 ? `${l.toLowerCase()}` : `,${l.toLowerCase()}`;
          language += text;
          return null;
        });
        qs += `&languages=${language}`;
      }

      if (languages && languages.length === 0) {
        qs += `&languages=any`;
      }

      if (!isEmpty(marital_status)) {
        let maritalStatus = '';
        marital_status.map((ms, index) => {
          const text = index === 0 ? `${ms}` : `,${ms}`;
          maritalStatus += text;
          return null;
        });
        qs += `&marital_status=${maritalStatus}`;
      }

      if (marital_status && marital_status.length === 0) {
        qs += `&marital_status=any`;
      }

      if (!isEmpty(religions)) {
        let currentReligion = '';
        religions.map((r, index) => {
          const text =
            index === 0 ? `${r.toLowerCase()}` : `,${r.toLowerCase()}`;
          currentReligion += text;
          return null;
        });
        qs += `&religions=${currentReligion}`;
      }

      if (religions && religions.length === 0) {
        qs += `&religions=any`;
      }
    }

    getMatches({ id: userData.id, qs });
  };

  const { count } = matches;

  if (!userData) return null;

  return (
    <Content className="Content-DataView" style={{ marginTop: '65px' }}>
      <Row gutter={20}>
        <Col span={8}>
          <div className="DataCards">
            <div style={{ fontWeight: 'bold' }}>Two Way Matches Left</div>
            <div className="Datas">
              <div style={{ fontWeight: 'bold' }}>
                {additionalInfo.twoWayMatchCount}
              </div>
              {userData.status === 'on_boarding_completed' && (
                <Button
                  size="small"
                  onClick={() => {
                    getTwowayLeftMatchesCount({ id: userData.id });
                  }}
                  shape="circle"
                  style={{
                    marginLeft: 20,
                  }}
                >
                  <SyncOutlined
                    spin={isLoadingMatchCount}
                    style={{ fontSize: 12 }}
                  />
                </Button>
              )}
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="DataCards">
            <div style={{ fontWeight: 'bold' }}>One Way Matches Left</div>
            <div className="Datas">
              <div style={{ fontWeight: 'bold' }}>
                {additionalInfo.oneWayMatchCount}
              </div>
              {userData.status === 'on_boarding_completed' && (
                <Button
                  size="small"
                  onClick={() => {
                    getOnewayLeftMatchesCount({ id: userData.id });
                  }}
                  shape="circle"
                  style={{
                    marginLeft: 20,
                  }}
                >
                  <SyncOutlined
                    spin={isLoadingMatchCount}
                    style={{ fontSize: 12 }}
                  />
                </Button>
              )}
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="DataCards">
            <div style={{ fontWeight: 'bold' }}>Recommendation Sent</div>
            <div style={{ fontWeight: 'bold' }}>
              {additionalInfo.recommendationCount}
            </div>
          </div>
        </Col>
      </Row>
      <Content
        style={{
          width: '100%',
          display: 'flex',
          flexGrow: 1,
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Col
          xs={{ span: 24 }}
          xl={{ span: 8 }}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <Button
              onClick={onGetRecommendation}
              style={styles.recalBtn}
              type="default"
              shape="round"
            >
              Get Recommendation
            </Button>
            <Button
              onClick={() => {
                if (
                  !isEmpty(editedDetails) &&
                  !isEmpty(editedDetails.partner_preference)
                ) {
                  updatePartnerPreferences({
                    user_id: userData.id,
                    data: editedDetails.partner_preference,
                    id: userData.partnerPreference.id,
                  });
                  setEditedDetails({
                    ...editedDetails,
                    partner_preference: {},
                  });
                } else {
                  message.warn('Already updated', 4);
                }
              }}
              style={styles.ppBtn}
              type="default"
              shape="round"
            >
              Update Partner Preference
            </Button>
          </div>

          {/* <h2>Partner Preferences</h2> */}

          <div className="BorderDataPP">
            <div className="RadioSales">
              <Radio.Group
                onChange={(e) => setApiCall(e.target.value)}
                value={apiCall}
              >
                <Radio value={1}>Two-way Recommendation</Radio>
                <Radio value={2}>One-way Recommendation</Radio>
              </Radio.Group>
            </div>
            <div style={{ width: '100%' }}>{Fields}</div>
          </div>
        </Col>

        <Col xs={{ span: 24 }} xl={{ span: 16 }}>
          <>
            <div
              className="TableTitle"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              {/* {count && ( */}
              <div style={styles.totalCount}>
                {`Total recommendation to send - ${count || '*'}`}
              </div>
              {/* )} */}

              <Button
                disabled={isEmpty(matchesToBeAdded)}
                onClick={() => {
                  addMatches({ userId: userData.id, ids: matchesToBeAdded });
                  setTimeout(() => {
                    onGetRecommendation();
                  }, 2000);
                }}
                style={styles.ppBtn}
                type="default"
                shape="round"
              >
                Send Matches
              </Button>
            </div>
            <Table
              className="BorderData"
              loading={isLoadingMatches}
              columns={columns}
              dataSource={isEmpty(matches) ? [] : matches.results}
              rowKey={(row) => row.id}
              scroll={{ x: true }}
              pagination={false}
              rowSelection={rowSelection}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 10,
                marginLeft: 20,
              }}
            >
              <Pagination
                hideOnSinglePage
                defaultPageSize={25}
                simple
                current={currentPage}
                total={Number(count) || 0}
                onChange={onChangePage}
                itemRender={itemRender}
              />
            </div>
          </>
        </Col>
      </Content>
    </Content>
  );
};

const mapStateToProps = (state) => ({
  isLoadingMatchCount: state.additionalInfo.isLoading,
  isLoadingMatches: state.matches.isLoading,
  matches: state.matches.data,
});

export default connect(mapStateToProps, {
  getOnewayLeftMatchesCount: userProfileEffects.getOnewayLeftMatches,
  getTwowayLeftMatchesCount: userProfileEffects.getTwowayLeftMatches,
  getMatches: salesEffects.getMatches,
  addMatches: salesEffects.addMatches,
  updatePartnerPreferences: userProfileEffects.updatePartnerPreferences,
})(SalesSection);
