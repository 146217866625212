import moment from 'moment';

export function initalHeader(input) {
  return input.length > 0 ? '&' : '?';
}

export function isEmpty(obj) {
  if (obj !== null && obj !== undefined) {
    // for general objects
    if (typeof obj === 'string') {
      if (obj.trim() === '' || obj === 'null' || obj === null) {
        // for string
        return true;
      }

      return false;
    }
    if (obj.length <= 0) {
      // for array
      return true;
    }
    if (typeof obj === 'object') {
      const keys = Object.keys(obj);
      const len = keys.length;
      if (len <= 0) {
        return true;
      }
      return false;
    }
    return false;
  }

  return true;
}

export const snakeToCamel = (str) =>
  str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  );

export const camelToSnake = (str) =>
  str.replace(/\.?([A-Z])/g, (x, y) => `_${y.toLowerCase()}`).replace(/^_/, '');

export const letterToSnake = (str) => camelToSnake(str.replace(/-/g, ''));

export function _calculateAge(birthday) {
  // birthday is a date
  if (isEmpty(birthday)) {
    return '';
  }
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return s;
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const removeCommas = (str) => str.replace(/,/g, '');

export const removeUnderscore = (str) => capitalize(str.replace(/_/g, ' '));

export const addUnderscore = (str) => str.replace(/ /g, '_').toLowerCase();

export const convertCurrencyToIndianFormat = (no) =>
  no.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');

export const getLakhsFromCurrencyValue = (no) => {
  if (Number(removeCommas(no)) < 1000000) {
    return no.substring(0, 1);
  }
  return no.substring(0, 2);
};

export const removeSpaces = (str) => str.replace(/ /g, '');

export const dateFormatter = ({ date }) => {
  let dateStr = date;
  if (!dateStr) return null;
  if (dateStr.includes('Z')) {
    return `${moment(dateStr)
      .utcOffset('+05:30')
      .format('ddd, MMM DD YYYY, h:mm:ss A')} (India Standard Time)`;
  }
  dateStr = date.substring(0, date.length - 7);
  dateStr = `${dateStr}Z`;
  return `${moment(dateStr)
    .utcOffset('+05:30')
    .format('ddd, MMM DD YYYY, h:mm:ss A')} (India Standard Time)`;
};

export const stringFormatter = ({ text, type = 'string' }) => {
  if (text) {
    if (type === 'snakeCase' && typeof text === 'string') {
      let newText = text.replace('_', ' ');
      newText = newText.replace('_', ' ');
      return newText.charAt(0).toUpperCase() + newText.slice(1);
    }
    if (type === 'date' && typeof text === 'string') {
      return dateFormatter({ date: text });
    }
    if ((type === 'string' || type === 'selector') && text) {
      const textStr = String(text);
      return removeUnderscore(
        textStr.charAt(0).toUpperCase() + textStr.slice(1)
      );
    }
    if (type === 'list' || type === 'debouncedList') {
      if (text.length > 0) {
        let str = ``;
        text.map((item) => {
          if (str.length) {
            const formattedItem = item.replace('_', ' ');
            str = `${str}, ${
              formattedItem.charAt(0).toUpperCase() + formattedItem.slice(1)
            }`;
          } else {
            const formattedItem = item.replace('_', ' ');
            str = `${
              formattedItem.charAt(0).toUpperCase() + formattedItem.slice(1)
            }`;
          }
          return null;
        });
        return str;
      }
      return `Any`;
    }
    return text;
  }
  return '';
};

export const getHMStatus = (type) => {
  switch (type) {
    case 'pending':
      return {
        title: 'Pending',
        color: 'rgba(253, 216, 73, 100)',
      };
    case 'interested':
      return {
        title: 'Interested',
        color: 'rgba(253, 216, 73, 100)',
      };
    case 'not_interested':
      return {
        title: 'Not interested',
        color: 'rgba(128, 128, 128, 1)',
      };
    case 'vc_done':
      return {
        title: 'Call done',
        color: 'rgba(255, 91, 145, 1)',
      };
    case 'vc_scheduled':
      return {
        title: 'Call scheduled',
        color: 'rgba(255, 91, 145, 1)',
      };
    case 'accepted':
      return {
        title: 'Accepted',
        color: 'rgba(17, 174, 135, 1)',
      };
    case 'declined':
      return {
        title: 'Declined',
        color: 'rgba(128, 128, 128, 1)',
      };
    default:
      return {
        title: '',
        color: '',
      };
  }
};

export const uniqueIdGenerator = () => {
  const guid = () =>
    // eslint-disable-next-line no-bitwise
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return `${
    guid() + guid()
  }-${guid()}-${guid()}-${guid()}-${guid()}${guid()}${guid()}`;
};

export const isStagingEnv = () => {
  const url = window.location.href;
  // return url.includes('staging') || url.includes('localhost');
  const STAGING_WORDS = ['staging', 'localhost'];
  return STAGING_WORDS.some((word) => url.includes(word));
};
