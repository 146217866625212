import React from 'react';
import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { capitalize } from 'lodash';

export const dataColumns = [
  {
    title: 'OS',
    dataIndex: 'os',
    key: 'os',
    render: (os) => {
      if (os === 'android') {
        return <AndroidFilled style={{ fontSize: '20px', color: '#3DDC84' }} />;
      }
      return <AppleFilled style={{ fontSize: '20px', color: '#000' }} />;
    },
  },
  {
    title: 'OS Version',
    dataIndex: 'os_version',
    key: 'os_version',
    render: (os_version) => capitalize(os_version),
  },
  {
    title: 'Phone model',
    dataIndex: 'phone_model',
    key: 'phone_model',
    render: (phone_model) => capitalize(phone_model),
  },
  {
    title: 'Network type',
    dataIndex: 'network_type',
    key: 'network_type',
    render: (network_type) => capitalize(network_type),
  },
  {
    title: 'App version',
    dataIndex: 'app_version',
    key: 'app_version',
    render: (app_version) => capitalize(app_version),
  },
  {
    title: 'Build number',
    dataIndex: 'build_number',
    key: 'build_number',
    render: (build_number) => capitalize(build_number),
  },
  {
    title: 'Telecom network',
    dataIndex: 'telecom_operator',
    key: 'telecom_operator',
    render: (telecom_operator) => capitalize(telecom_operator),
  },
];
