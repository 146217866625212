export function govtIdVerification({ get, patch }) {
  return {
    listGovtIdVerification({ limit, qs }) {
      if (qs) {
        return get(`/govt-verification/?${qs}`);
      }
      return get(`/govt-verification/?limit=${limit}`);
    },
    updateUserGovtIdVerificationStatus({ id, ...body }) {
      return patch(`/govt-verification/${id}/`, body);
    },
  };
}
