import React, { useState } from 'react';
import { Layout, Col, Row } from 'antd';
import DetailsCard from '../../components/DetailsCard';
import FeedbackModal from '../UserProfile/FeedbackModal';

const { Content } = Layout;

const HmSection = ({
  userData,
  userFeedback,
  loadingStatus,
  adminData,
  additionalInfo,
}) => {
  const [feedbackModalStatus, setFeedbackModalStatus] = useState(false);
  const onEditDetails = () => {
    setFeedbackModalStatus(true);
  };

  return (
    <>
      <Content
        className="Content-DataView"
        style={{
          marginTop: '65px',
        }}
      >
        <Row gutter={20}>
          <Col span={8}>
            <div className="DataCards">
              <div style={{ fontWeight: 'bold' }}>HM Recommendation Sent</div>
              <div style={{ fontWeight: 'bold' }}>
                {additionalInfo.hmRecommendationSent}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="DataCards">
              <div style={{ fontWeight: 'bold' }}>Shortlisted Count Left</div>
              <div style={{ fontWeight: 'bold' }}>{userData.hmQuantities}</div>
            </div>
          </Col>
          <Col span={8}>
            <div className="DataCards">
              <div style={{ fontWeight: 'bold' }}>HM Connection Made</div>
              <div style={{ fontWeight: 'bold' }}>
                {additionalInfo.hmAcceptedCount}
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={{ span: 24 }} xl={{ span: 16 }}>
            <DetailsCard
              key="sections_hm_users"
              data={{
                ...userData,
                ...userFeedback,
              }}
              adminData={adminData}
              editable
              loading={loadingStatus}
              index="human_matchmaker"
              title="Shortlisted Users"
              onEditPress={onEditDetails}
            />
          </Col>
          <Col xs={{ span: 24 }} xl={{ span: 8 }}>
            <DetailsCard
              key="sections_hm_feedback"
              data={{
                ...userData,
                ...userFeedback,
              }}
              adminData={adminData}
              userData={userData}
              editable
              loading={loadingStatus}
              index={10}
              title="Feedback"
              onEditPress={onEditDetails}
            />
          </Col>
        </Row>
      </Content>
      <FeedbackModal
        isHumanMatchmaker
        visible={feedbackModalStatus}
        title="Add Feedback about the User..."
        onModalClose={() => {
          setFeedbackModalStatus(false);
        }}
        userData={userData}
      />
    </>
  );
};

export default HmSection;
