import React from 'react';
import OptionPill from '../../../../components/OptionPill';
import { MOTHER_TONGUE_LIST } from '../../config';
import './styles.scss';

const MotherTongue = ({
  selectedMotherTongueList,
  setSelectedMotherTongueList,
  section,
}) => (
  <section className="mother-tongue-container">
    {MOTHER_TONGUE_LIST.map((motherTongue) => {
      if (
        /* If this is rendered inside Partner Preference, we need to show the 'Any'
        opiont as well. Else, skip it. */
        (motherTongue.value === 'any' && section === 'partner-preference') ||
        motherTongue.value !== 'any'
      ) {
        return (
          <OptionPill
            key={motherTongue.id}
            onClick={() => setSelectedMotherTongueList(motherTongue)}
            className="mother-tongue-pill"
            selected={selectedMotherTongueList.some(
              (motherTongueItem) =>
                motherTongueItem.value === motherTongue.value
            )}
          >
            {motherTongue.displayText}
          </OptionPill>
        );
      }
      return null;
    })}
  </section>
);

export default MotherTongue;
