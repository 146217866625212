import React, { Component } from 'react';
import { Input, Button, message } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import Mixpanel from 'mixpanel-browser';

import { listOfSelfieVerificationEffects } from '../../@betterhalf-private/redux/src';

import UserCards from './UserCards';

class SelfieVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownClicked: false,
      searchEmail: '',
      searchIdText: '',
      searchMobileText: '',
    };
  }

  handleEmailChange = (e) => {
    this.setState({ searchEmail: e.target.value });
  };

  handleIdChange = (e) => {
    this.setState({ searchIdText: e.target.value });
  };

  handleMobileChange = (e) => {
    this.setState({ searchMobileText: e.target.value });
  };

  getSelfieVerificationProfiles = () => {
    const { searchEmail, searchIdText, searchMobileText } = this.state;

    const { getListOfSelfieVerification } = this.props;

    let searchInput = '';
    if (searchEmail) {
      searchInput += `email=${searchEmail}`;
    }

    if (searchIdText) {
      if (searchInput.length) {
        searchInput += `&id=${searchIdText}`;
      } else {
        searchInput += `id=${searchIdText}`;
      }
    }

    if (searchMobileText) {
      if (searchInput.length) {
        searchInput += `&mobile=${searchMobileText}`;
      } else {
        searchInput += `mobile=${searchMobileText}`;
      }
    }

    // if (!isEmpty(searchInput)) {
    //   setPaginationData({ pageNo: 1 })
    // }

    getListOfSelfieVerification({ url: searchInput });
  };

  // onChangePage = (page) => {
  //   const { setPaginationData } = this.props
  //   setPaginationData({ pageNo: page })
  // }

  // itemRender = (current, type, originalElement) => {
  //   const {
  //     previousUrl,
  //     loadingStatus,
  //     nextUrl,
  //     getListOfSelfieVerification,
  //   } = this.props
  //   if (type === 'prev') {
  //     return (
  //       <Button
  //         disabled={isEmpty(previousUrl) || loadingStatus}
  //         style={{
  //           alignItems: 'center',
  //           justifyContent: 'center',
  //           marginRight: 10,
  //         }}
  //         onClick={
  //           isEmpty(previousUrl)
  //             ? () => {}
  //             : () => {
  //                 const url = previousUrl && previousUrl.split('?')
  //                 Mixpanel.track(
  //                   'LIST_OF_SELFIE_VERIFIED_USER_PAGINATION_PREVIOUS'
  //                 )
  //                 getListOfSelfieVerification({ url: `${url[1]}` })
  //               }
  //         }
  //       >
  //         <Icon type="left" />
  //       </Button>
  //     )
  //   }

  //   if (type === 'next') {
  //     return (
  //       <Button
  //         disabled={isEmpty(nextUrl) || loadingStatus}
  //         style={{
  //           alignItems: 'center',
  //           justifyContent: 'center',
  //           marginLeft: 10,
  //         }}
  //         onClick={
  //           isEmpty(nextUrl)
  //             ? () => {}
  //             : () => {
  //                 const url = nextUrl && nextUrl.split('?')
  //                 console.log('url[1]', url[1])
  //                 Mixpanel.track('LIST_OF_SELFIE_VERIFIED_USER_PAGINATION_NEXT')
  //                 getListOfSelfieVerification({ url: `${url[1]}` })
  //               }
  //         }
  //       >
  //         <Icon type="right" />
  //       </Button>
  //     )
  //   }

  //   return originalElement
  // }

  onVerifyPress = async ({ item }) => {
    const {
      removeUserFromList,
      updateSelfieVerificationStatus,
      getListOfSelfieVerification,
      // nextUrl,
    } = this.props;
    const { id } = item;
    const body = {
      status: 'verified',
    };

    const { resp } = await updateSelfieVerificationStatus({ id, ...body });

    if (resp && resp.status === 200) {
      Mixpanel.track('VERIFY_USER_SELFIE_REQUEST');
      removeUserFromList({ userData: item });
      // const offset = nextUrl.charAt(nextUrl.length - 1)
      getListOfSelfieVerification({ limit: 1 });
      return;
    }

    message.error('Something went wrong. Please try again', 3);
  };

  onRejectPress = async ({ item, isPreviouslyFroced }) => {
    const {
      removeUserFromList,
      updateSelfieVerificationStatus,
      getListOfSelfieVerification,
      // nextUrl,
    } = this.props;
    const { id } = item;
    const body = {};
    body.status = 'pending';

    if (isPreviouslyFroced) {
      body.status = 'force_verification';
    }
    const { resp } = await updateSelfieVerificationStatus({ id, ...body });
    if (resp && resp.status === 200) {
      Mixpanel.track('REJECT_USER_SELFIE_REQUEST');
      removeUserFromList({ userData: item });
      // const offset = nextUrl.charAt(nextUrl.length - 1)
      getListOfSelfieVerification({ limit: 1 });
      return;
    }

    message.error('Something went wrong. Please try again', 3);
  };

  render() {
    const { dropDownClicked, searchEmail, searchIdText, searchMobileText } =
      this.state;
    const {
      loadingStatus,
      // totalNoOfUsers,
      // pageNo,
      listOfSelfieVerificationData,
      adminData,
    } = this.props;

    return (
      <div className="SearchUser">
        <div style={{ width: 500, paddingBottom: 16 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: 10,
              paddingTop: 25,
            }}
          >
            {dropDownClicked ? (
              <CaretUpOutlined
                style={{ marginRight: 20 }}
                onClick={() => {
                  this.setState({ dropDownClicked: !dropDownClicked });
                }}
              />
            ) : (
              <CaretDownOutlined
                style={{ marginRight: 20 }}
                onClick={() => {
                  this.setState({ dropDownClicked: !dropDownClicked });
                }}
              />
            )}
            <Input
              allowClear
              size="large"
              value={searchEmail}
              placeholder="Email"
              onChange={this.handleEmailChange}
              disabled={loadingStatus}
              onPressEnter={this.getSelfieVerificationProfiles}
            />
            &nbsp;&nbsp;
            <Button
              shape="round"
              size="large"
              type="primary"
              onClick={this.getSelfieVerificationProfiles}
              loading={loadingStatus}
              disabled={loadingStatus}
            >
              Search
            </Button>
          </div>

          {dropDownClicked && (
            <div style={{ width: '73%', marginLeft: 34 }}>
              <div style={{ paddingBottom: 10 }}>
                <Input
                  size="large"
                  allowClear
                  value={searchIdText}
                  placeholder="Id"
                  onChange={this.handleIdChange}
                  disabled={loadingStatus}
                  onPressEnter={this.getSelfieVerificationProfiles}
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Input
                  size="large"
                  allowClear
                  value={searchMobileText}
                  placeholder="Mobile"
                  onChange={this.handleMobileChange}
                  disabled={loadingStatus}
                  onPressEnter={this.getSelfieVerificationProfiles}
                />
              </div>
            </div>
          )}
        </div>

        <UserCards
          listOfSelfieVerificationData={listOfSelfieVerificationData}
          adminData={adminData}
          onVerifyPress={this.onVerifyPress}
          onRejectPress={this.onRejectPress}
        />

        {/* <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <Pagination
            hideOnSinglePage
            simple
            defaultCurrent={1}
            defaultPageSize={4}
            current={pageNo}
            total={totalNoOfUsers}
            onChange={this.onChangePage}
            itemRender={this.itemRender}
          />
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminData: state.adminData.data,
  loadingStatus: state.listOfSelfieVerification.isLoading,
  listOfSelfieVerificationData: state.listOfSelfieVerification.data.users,
  // previousUrl: state.listOfSelfieVerification.data.previous,
  // totalNoOfUsers: state.listOfSelfieVerification.data.count,
  // nextUrl: state.listOfSelfieVerification.data.next,
  // pageNo: state.filtersAndSortData.pageDetails,
});

export default connect(mapStateToProps, {
  getListOfSelfieVerification:
    listOfSelfieVerificationEffects.getListOfSelfieVerification,
  removeUserFromList: listOfSelfieVerificationEffects.removeUserFromList,
  updateSelfieVerificationStatus:
    listOfSelfieVerificationEffects.updateUserSelfieVerificationStatus,
  // setPaginationData: filtersAndSorterEffects.setPaginationData,
})(SelfieVerification);
