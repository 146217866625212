import React from 'react';
import { Link } from 'react-router-dom';
import { sorterFunc } from '../SearchUser/columnData';
import { getCurrentCity } from '../../utilities/namingConvension';

export const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: (a, b) => sorterFunc(a, b, 'firstName'),
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: (a, b) => sorterFunc(a, b, 'lastName'),
  },
  {
    title: 'Mother Tongue',
    dataIndex: 'motherTongue',
    key: 'motherTongue',
    sorter: (a, b) => sorterFunc(a, b, 'motherTongue'),
  },
  {
    title: 'Religion',
    dataIndex: 'religion',
    key: 'religion',
    sorter: (a, b) => sorterFunc(a, b, 'religion'),
  },
  {
    title: 'Caste',
    dataIndex: 'caste',
    key: 'caste',
  },
  {
    title: 'City',
    dataIndex: 'locations',
    key: 'locations',
    render: (locations) => getCurrentCity({ locations }),
  },
  {
    title: 'Income',
    dataIndex: 'income',
    key: 'income',
  },
  {
    title: 'Rating',
    dataIndex: 'ratingCount',
    key: 'ratingCount',
  },
  {
    title: 'Action',
    key: 'action',
    fixed: 'right',
    dataIndex: 'id',
    render: (id) => (
      <div>
        <Link
          to={{
            pathname: `/user-profile/${id}/profile`,
          }}
          target="_blank"
        >
          View
        </Link>
      </div>
    ),
  },
];
