import React from 'react';
import OptionPill from '../../../../components/OptionPill';
import { MARITAL_STATUSES } from '../../config';
import './styles.scss';

const MaritalStatus = ({ maritalStatuses, setMaritalStatuses }) => (
  <section className="marital-status-container">
    {MARITAL_STATUSES.map((status) => (
      <OptionPill
        key={status.id}
        onClick={() => setMaritalStatuses(status)}
        className="marital-status-pill"
        selected={maritalStatuses.some(
          (currentStatus) => currentStatus.value === status.value
        )}
      >
        {status.displayText}
      </OptionPill>
    ))}
  </section>
);

export default MaritalStatus;
