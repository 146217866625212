import { ReloadOutlined } from '@ant-design/icons';

import React from 'react';
import classNames from 'classnames';

const Clear = ({ visible, onClickHandler }) => (
  <button
    type="button"
    className={classNames(
      'p-1 rounded-full cursor-pointer bg-grayShadow transition-opacity duration-500 ',
      {
        '!opacity-100': visible,
        'opacity-0': !visible,
      }
    )}
    onClick={onClickHandler}
  >
    <ReloadOutlined className=" text-h6 text-grayIcon" />
  </button>
);

export default Clear;
