import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Gallery from 'react-grid-gallery';
import Mixpanel from 'mixpanel-browser';
import { Popconfirm, message } from 'antd';
import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';

import { userProfileEffects } from '../../../@betterhalf-private/redux/src';
import './styles.scss';

class GalleryComponent extends PureComponent {
  onConfirmDeleteImage = () => {
    const { currentImage } = this.gallery.state;
    const {
      deleteUserImage,
      userData: { id },
    } = this.props;

    if (currentImage === 0) {
      this.gallery.closeLightbox();
      message.error('Profile Picture cannot be deleted', 2);
    } else {
      Mixpanel.track('DELETE_IMAGE', {
        id,
        image_id: currentImage,
      });
      deleteUserImage({ id, image_id: currentImage });
      this.gallery.closeLightbox();
    }
  };

  onMakeProfilePicture = () => {
    const {
      images,
      updateUserProfileImage,
      userData: { id },
    } = this.props;
    const { currentImage } = this.gallery.state;
    if (currentImage === 0) {
      message.warning('Already a profile picture', 2);
    } else {
      // To-DO : Make modification with Crop
      const imageToUpdate = images[currentImage].src;
      let profileImg = images[0].src.substring(
        imageToUpdate.lastIndexOf('/') + 1
      );
      let imageId = imageToUpdate.substring(imageToUpdate.lastIndexOf('/') + 1);
      const newImages = images.map((image) =>
        image.src.substring(image.src.lastIndexOf('/') + 1)
      );

      const tmpImg = profileImg;
      profileImg = imageId;
      imageId = tmpImg;
      newImages[0] = profileImg;
      newImages[currentImage] = imageId;

      Mixpanel.track('UPDATE_PROFILE_IMAGE', {
        id,
        images: newImages,
      });
      updateUserProfileImage({ id, images: newImages });
    }

    this.gallery.closeLightbox();
  };

  renderControls = () => {
    const controls = [
      <Popconfirm
        placement="leftTop"
        title="Delete Picture?"
        onConfirm={() => this.onConfirmDeleteImage()}
        okText="Yes"
        cancelText="No"
        zIndex={100000}
      >
        <button type="button" className="close_1x3s325">
          <DeleteOutlined style={{ fontSize: 20, color: 'white' }} />
        </button>
      </Popconfirm>,
      <Popconfirm
        placement="top"
        title="Update Profile Picture?"
        onConfirm={() => this.onMakeProfilePicture()}
        okText="Yes"
        cancelText="No"
        zIndex={100000}
      >
        <button type="button" className="close_1x3s325">
          <UserAddOutlined style={{ fontSize: 20, color: 'white' }} />
        </button>
      </Popconfirm>,
    ];
    return controls;
  };

  render() {
    const { images } = this.props;
    return (
      <Gallery
        showLightboxThumbnails
        ref={(gallery) => {
          this.gallery = gallery;
        }}
        images={images}
        enableImageSelection={false}
        margin={10}
        onClickImage={() => {}}
        rowHeight={200}
        customControls={this.renderControls()}
      />
    );
  }
}

export default connect(() => ({}), {
  deleteUserImage: userProfileEffects.deleteUserImage,
  updateUserProfileImage: userProfileEffects.updateUserProfileImage,
})(GalleryComponent);
