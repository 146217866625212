import { applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers/index';

const config = {
  key: 'root', // key is required
  storage, // storage is now required
  blacklist: [''],
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(config, rootReducer);

const store = composeEnhancers(applyMiddleware(ReduxThunk))(createStore)(
  persistedReducer
);

export default store;
