import React from 'react';

const DetailHeader = ({ headerTitle, isActive, placeholder, value }) => (
  <div>
    <p className="detail-panel-header-title">{headerTitle}</p>
    {!isActive && (
      <p className={`detail-panel-header-subtitle ${!value && 'placeholder'}`}>
        {value || placeholder}
      </p>
    )}
  </div>
);

export default DetailHeader;
