import React, { useEffect, useState } from 'react';
import { Select, Input } from 'antd';
import { AlertOutlined } from '@ant-design/icons';
import Button from '../../../components/Button';
import styles from './MobileNumber.module.scss';
// import { COUNTRY_ISD_CODES } from '../config';
import { useUserData } from '../../UserProfile/CompleteProfile/UserDataContext';
import { createUser, editUserDetails } from '../api';
import phoneMockData from '../../../utilities/consts/phoneMockData';

const { Option } = Select;

// convert the data to a flat 1d array
let FORMATTED_COUNTRY_CODE_DATA = [];
phoneMockData.forEach((arr) => {
  FORMATTED_COUNTRY_CODE_DATA = [...FORMATTED_COUNTRY_CODE_DATA, ...arr.data];
});

// if handleRedirect is passed, then it means it'a part of CreateProfile

const MobileNumber = ({ handleRedirect, moveToNext }) => {
  const { id: userId = '', isdCode = '+91', mobile = '' } = useUserData() || {};
  const [mobileNumber, setMobileNumber] = useState(mobile || '');
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedCountryConfig, setSelectedCountryConfig] = useState(
    userId && (mobile || isdCode)
      ? FORMATTED_COUNTRY_CODE_DATA.find(({ code }) => code.includes(isdCode))
      : FORMATTED_COUNTRY_CODE_DATA[0]
  );

  const [countryCode, setCountryCode] = useState(
    () => `${selectedCountryConfig.code}-${selectedCountryConfig.name}`
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleCreateUser = async () => {
    const payload = {
      mobile: mobileNumber,
      isd_code: countryCode.split('-')[0].slice(1),
    };
    try {
      const response = await createUser(payload);
      handleRedirect(response.id);
    } catch (error) {
      if (error.response.status === 400) {
        setErrorMessage('Oops! Looks like the number already exists!');
      } else setErrorMessage('Server Error! Something went wrong');
    }
  };

  const onClickHandler = async () => {
    if (handleRedirect) {
      await handleCreateUser();
      return;
    }

    if (mobileNumber === mobile) {
      /* If the mobile number in state is equal to mobile number that's already in userData,
       avoid making the API call and move to the next section */
      moveToNext();
      return;
    }

    const payload = {
      mobile: mobileNumber,
      isd_code: countryCode.split('-')[0].slice(1),
    };
    await editUserDetails(userId, payload);
    moveToNext();
  };

  const handleMobileChange = (event) => {
    const inputValue = event.target.value;

    setErrorMessage(null);

    // if the input is empty, disable the button
    if (inputValue.length === 0) {
      setIsButtonDisabled(true);
      setMobileNumber(inputValue);
      return;
    }

    // restrict the user to enter only the maximum length of the mobile number according to the selected country
    if (
      selectedCountryConfig.numberOfDigits &&
      inputValue.length > Number(selectedCountryConfig.numberOfDigits)
    )
      return;

    // incase the maximum length of the mobile number is not available,
    if (!selectedCountryConfig.numberOfDigits && inputValue.length >= 1) {
      setIsButtonDisabled(false);
      setMobileNumber(inputValue);
      return;
    }

    // check if the input is a valid length number according to the selected country
    if (inputValue.length === Number(selectedCountryConfig.numberOfDigits)) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }

    // atlast set the value
    setMobileNumber(inputValue);
  };

  const handleCountryCodeChange = (value) => {
    setCountryCode(value);
    setSelectedCountryConfig(
      FORMATTED_COUNTRY_CODE_DATA.find(
        ({ code, name }) => `${code}-${name}` === value
      )
    );
  };

  useEffect(() => {
    if (!selectedCountryConfig.numberOfDigits && mobileNumber.length >= 1) {
      setIsButtonDisabled(false);
      return;
    }
    if (mobileNumber.length !== Number(selectedCountryConfig.numberOfDigits))
      setIsButtonDisabled(true);
    else setIsButtonDisabled(false);
  }, [selectedCountryConfig]);

  return (
    <section className={styles.mainContainer}>
      <p>Account login number</p>
      <Input.Group compact>
        <Select
          onChange={handleCountryCodeChange}
          dropdownClassName={styles.dropdown}
          dropdownMatchSelectWidth={false}
          value={countryCode}
        >
          {FORMATTED_COUNTRY_CODE_DATA.map((countryItem) => (
            <Option
              key={countryItem.id}
              value={`${countryItem.code}-${countryItem.name}`}
            >
              {countryItem.code} ({countryItem.name})
            </Option>
          ))}
        </Select>
        <Input
          value={mobileNumber}
          className={styles.input}
          type="number"
          onChange={handleMobileChange}
          maxLength={selectedCountryConfig.numberOfDigits}
        />
      </Input.Group>

      <div className={styles.buttonContainer}>
        <Button
          disabled={isButtonDisabled}
          onClick={onClickHandler}
          htmlType="submit"
        >
          {mobile ? 'Save' : 'Next'}
        </Button>
      </div>

      {errorMessage && (
        <div className={styles.errorMessage}>
          <span>
            <AlertOutlined />
          </span>
          <p>{errorMessage}</p>
        </div>
      )}
    </section>
  );
};
export default MobileNumber;
