import React from 'react';
import { Button as AntDButton } from 'antd';
import Searchbar from '../../../components/Searchbar';
import Button from '../../../components/Button';
import SelectedItems from '../PartnerPreference/SelectedItems';
import { uniqueIdGenerator, capitalize } from '../../../utilities/util';
import './styles.scss';

const SearchList = ({
  inputPlaceholder,
  searchInput,
  onChange,
  data,
  onSelect,
  selectedItems,
  onBack,
  showSelectedItems = true,
  onDelete,
  showBtns = { add: true, ctas: false },
}) => (
  <div>
    <Searchbar
      className="search-input"
      placeholder={inputPlaceholder}
      onChange={(event) => onChange(event.target.value)}
      value={searchInput}
      onClear={() => onChange('')}
    />
    <section className="search-list">
      {data.map((item) => (
        <AntDButton
          type="link"
          className="search-list-item"
          onClick={() => onSelect(item)}
          key={item.id}
        >
          {item.displayText}
        </AntDButton>
      ))}

      {showBtns.add && searchInput.length > 0 && (
        <AntDButton
          type="link"
          className="search-list-add-new"
          onClick={() => {
            const newItem = {
              id: uniqueIdGenerator(),
              value: searchInput,
              displayText: capitalize(searchInput),
            };
            onSelect(newItem);
          }}
        >
          Add {searchInput}
        </AntDButton>
      )}
      {showSelectedItems && selectedItems?.length > 0 && (
        <SelectedItems selectedItems={selectedItems} onDelete={onDelete} />
      )}
      {showBtns.ctas && (
        <div className="search-list-cta">
          <Button isLink isSave>
            Save
          </Button>
          <Button isLink isSave onClick={onBack}>
            Back
          </Button>
        </div>
      )}
    </section>
  </div>
);

export default SearchList;
