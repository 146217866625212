import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie';
import Mixpanel from 'mixpanel-browser';

import store from './store';
import history from './services/history';
import Home from './pages/Home';
import UserProfile from './pages/UserProfile';
import Login from './pages/Login';
import { setAuthHeaders } from './utilities/api';

const isLoggedIn = () => {
  const accessToken = Cookies.get('accessToken');
  setAuthHeaders();
  if (accessToken) {
    return true;
  }
  return false;
};

export default class Routes extends Component {
  componentDidMount() {
    this.unlisten = history.listen((location, action) => {
      Mixpanel.track('ROUTE_CHANGE', {
        action,
        path: location.pathname,
      });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return (
      <Provider store={store}>
        <Switch>
          {/* NOTE: PUBLIC ROUTES ARE NOT AVAILABLE AFTER LOGIN */}
          <PublicRoute exact path="/">
            <Login />
          </PublicRoute>

          <PrivateRoute exact path="/home/:page">
            <Home />
          </PrivateRoute>

          <PrivateRoute exact path="/user-profile/:userId/:page/">
            <UserProfile />
          </PrivateRoute>
        </Switch>
      </Provider>
    );
  }
}

// export default function Routes() {
//   return (
//     <Provider store={store}>
//       <Switch>
//         {/* NOTE: PUBLIC ROUTES ARE NOT AVAILABLE AFTER LOGIN */}
//         <PublicRoute exact path="/">
//           <Login />
//         </PublicRoute>

//         <PrivateRoute exact path="/home">
//           <Main />
//         </PrivateRoute>

//         <PrivateRoute exact path="/user-profile/:userId">
//           <UserProfile />
//         </PrivateRoute>
//       </Switch>
//     </Provider>
//   )
// }

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isLoggedIn() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/home/search',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
