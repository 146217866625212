import React from 'react';
import OptionPill from '../../../../components/OptionPill';
import { SETTLE_DOWN } from '../../config';
import './styles.scss';

const SettleDown = ({ handleSettleDown, settleDown }) => (
  <section className="settleDown__container">
    {SETTLE_DOWN.map((value) => (
      <OptionPill
        key={value.id}
        onClick={() => handleSettleDown(value)}
        className="settleDown__pill"
        selected={settleDown === value.dataToBeSent}
      >
        {value.displayText}
      </OptionPill>
    ))}
  </section>
);

export default SettleDown;
