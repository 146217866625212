import React from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import './styles.scss';
import UserOnboarding from '../../UserOnboarding';
import UserDataProvider from './UserDataContext';

const CompleteProfile = (props) => {
  const { onClose, userData } = props;

  return (
    <main className="container">
      <CloseCircleFilled className="closeIcon" onClick={onClose} />
      <section className="accordion-container">
        {/* UserOnboarding will take up as much width as the section with the className "accordion-container" provides */}
        <UserDataProvider userData={userData}>
          <UserOnboarding closeCompleteProfile={onClose} />
        </UserDataProvider>
      </section>
    </main>
  );
};

export default CompleteProfile;
