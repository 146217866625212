import React from 'react';
import './styles.scss';

const TabBar = ({ onTabClick, tabs, activeKey }) => (
  <div className="tabbar-container">
    {tabs.map((tab) => (
      <button
        key={tab.key}
        type="button"
        className={`tabbar-item ${activeKey === tab.key ? 'active' : ''}`}
        onClick={() => onTabClick(tab.key)}
      >
        {tab.label}
      </button>
    ))}
  </div>
);

export default TabBar;
