/* eslint-disable */
import { snakeToCamel, incomeConverter } from './utils';

export const listOfUsersInDashboardActionTypes = {
  listOfUsersDataLoading: 'LIST_OF_USERS_DATA_LOADING',
  listOfUsersDataSuccess: 'LIST_OF_USERS_DATA_SUCCESS',
  listOfUsersDataFailure: 'LIST_OF_USERS_DATA_FAILURE',
  removeUserFromList: 'REMOVE_USER_FROM_LIST',
};

const initialState = {
  isLoading: false,
  data: [],
  error: {},
};

export function listOfUsersReducer(state = initialState, action) {
  switch (action.type) {
    case listOfUsersInDashboardActionTypes.listOfUsersDataSuccess: {
      return { ...state, data: action.payload, isLoading: false };
    }
    case listOfUsersInDashboardActionTypes.listOfUsersDataFailure: {
      return { ...state, error: action.error, isLoading: false };
    }
    case listOfUsersInDashboardActionTypes.listOfUsersDataLoading: {
      return { ...state, isLoading: true };
    }
    case listOfUsersInDashboardActionTypes.removeUserFromList: {
      const users = state.data?.users || [];
      const newUsers = users.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        data: {
          ...state.data,
          users: newUsers,
        },
      };
    }
    default:
      return state;
  }
}

export const listOfUsersInDashboardActions = {
  listOfUsersDataSuccess(usersData) {
    return {
      type: listOfUsersInDashboardActionTypes.listOfUsersDataSuccess,
      payload: {
        users: usersData.results,
        count: usersData.count,
        next: usersData.next,
        previous: usersData.previous,
      },
    };
  },
  listOfUsersDataFailure(error) {
    return {
      type: listOfUsersInDashboardActionTypes.listOfUsersDataFailure,
      error,
    };
  },
  listOfUsersLoading(bool) {
    return {
      type: listOfUsersInDashboardActionTypes.listOfUsersDataLoading,
      isLoading: bool,
    };
  },
  removeUserFromList(userData) {
    return {
      type: listOfUsersInDashboardActionTypes.removeUserFromList,
      payload: userData,
    };
  },
};

export const listOfUsersinDashboardEffects = {
  getListOfUsersData(url) {
    return async (dispatch) => {
      dispatch(listOfUsersInDashboardActions.listOfUsersLoading(true));

      let qs = '?' + url.split('?')[1];

      try {
        let resp = await window.client.listUserProfiles({ qs });
        const transformData = resp.body.results.map((row) => {
          const result = {};
          Object.keys(row).map((item) => {
            result[snakeToCamel(item)] = row[item];
            return null;
          });

          result['income'] = incomeConverter({
            incomeFrom: result.incomeFrom,
            incomeTo: result.incomeTo,
          });
          if (row.images && row.images.length > 0)
            result['profileImage'] = row.images[0];
          return result;
        });
        const dataToBeSent = { ...resp.body, ...{ results: transformData } };
        dispatch(
          listOfUsersInDashboardActions.listOfUsersDataSuccess(dataToBeSent)
        );
      } catch (e) {
        dispatch(listOfUsersInDashboardActions.listOfUsersDataFailure(e));
      }
    };
  },
  removeUserFromList(userData) {
    return async (dispatch) => {
      dispatch(listOfUsersInDashboardActions.removeUserFromList(userData));
    };
  },
};
