import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Checkbox, Input } from 'antd';
import Mixpanel from 'mixpanel-browser';
import { isEmpty } from '../../../utilities/util';
import { constructReasons } from '../../../utilities/helper';
import { userProfileEffects } from '../../../@betterhalf-private/redux/src';
import { reasons } from './reasons';

const { TextArea } = Input;

const VerificationOnHoldModal = ({
  onModalClose,
  title,
  visible,
  userData: { id },
  updateUserDetails,
}) => {
  const [text, setText] = useState('');
  const [showHoldButton, setShowHoldButton] = useState(false);
  const [reasonsCheck, setReasonsCheck] = useState([]);

  const onCheckBoxChange = (index) => {
    const newReasonsCheck = [...reasonsCheck];
    newReasonsCheck[index] = !newReasonsCheck[index];
    setReasonsCheck([...newReasonsCheck]);
    setShowHoldButton(newReasonsCheck.includes(true) || text.length > 0);
  };

  const onTextChange = ({ target: { value } }) => {
    setText(value);
    setShowHoldButton(text.length > 0 || reasonsCheck.includes(true));
  };

  const handleModalClear = () => {
    setText('');
    setShowHoldButton(false);
    setReasonsCheck([]);
  };

  const handleOkPress = () => {
    if (showHoldButton) {
      let data = {};
      if (reasonsCheck.includes(true)) {
        data = {
          verification_status: 'verification_on_hold',
          reason: constructReasons({
            extraText: text,
            reasonsCheck,
            predefindReasons: reasons,
          }),
        };
        handleModalClear();
      } else {
        data = {
          verification_status: 'verification_on_hold',
          reason: constructReasons({
            extraText: text,
          }),
        };
        handleModalClear();
      }

      updateUserDetails({
        id,
        data,
      });
      Mixpanel.track('CHANGE_VERIFICATION_STATUS', {
        id,
        data,
      });
    }
  };

  const defaultReasons = reasons?.map(({ index, reason }) => (
    <div key={`unverify_reason_${index}`}>
      <Checkbox
        checked={reasonsCheck[index]}
        defaultChecked={false}
        onChange={() => onCheckBoxChange(index)}
      >
        {reason}
      </Checkbox>
    </div>
  ));

  return (
    <Modal
      destroyOnClose
      onCancel={() => onModalClose()}
      visible={visible}
      title={title}
      onOk={handleOkPress}
      okButtonProps={
        reasonsCheck.includes(true) || !isEmpty(text)
          ? { disabled: false }
          : { disabled: true }
      }
    >
      {defaultReasons}
      <div>
        <TextArea
          placeholder="Please Specify the reason"
          value={text}
          onChange={onTextChange}
          style={{ marginTop: 20 }}
          autoSize={{ minRows: 4 }}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  userData: state.userData.data,
  loadingStatus: state.userData.isLoading,
  updateUserLoadingStatus: state.updateUserData.isLoading,
  updateUserState: state.updateUserData.success,
});

export default connect(mapStateToProps, {
  updateUserDetails: userProfileEffects.updateUserDetails,
})(VerificationOnHoldModal);
