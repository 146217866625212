import React from 'react';
import OptionPill from '../../../../components/OptionPill';
import { FOOD_PREFERENCE_LIST } from '../../config';
import './styles.scss';

const FoodPreference = ({ foodPreferences, setFoodPreferences }) => (
  <section className="food-preference-container">
    {FOOD_PREFERENCE_LIST.map((preference) => (
      <OptionPill
        key={preference.id}
        onClick={() => setFoodPreferences(preference)}
        className="food-preference-pill"
        selected={foodPreferences.some(
          (foodPreferenceItem) => foodPreferenceItem.value === preference.value
        )}
      >
        {preference.displayText}
      </OptionPill>
    ))}
  </section>
);

export default FoodPreference;
