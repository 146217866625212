import React from 'react';
import { Tag } from 'antd';
import { ManOutlined, WomanOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { stringFormatter, isEmpty } from '../../utilities/util';
import {
  getUserStatus,
  getVerificationStatus,
  getCurrentCity,
} from '../../utilities/namingConvension';

export const sorterFunc = (a, b, value) => {
  let aValue = a[`${value}`];
  let bValue = b[`${value}`];
  if (isEmpty(aValue) || isEmpty(bValue)) {
    if (aValue) {
      bValue = '1';
    } else {
      aValue = '1';
    }
  }
  return aValue.localeCompare(bValue);
};

export const filterFunc = (val, record, value) =>
  isEmpty(record[`${value}`]) ? null : record[`${value}`].indexOf(val) === 0;

const sortFinder = ({ key, sorter }) => {
  if (sorter.columnKey === key) {
    return sorter.order;
  }
  return false;
};

export const columns = ({ filters, sorter }) => {
  const genderFilter = !isEmpty(filters.gender) ? filters.gender : [];
  const statusFilter = !isEmpty(filters.status) ? filters.status : [];
  const verificationStatusFilter = !isEmpty(filters.verificationStatus)
    ? filters.verificationStatus
    : [];

  return [
    {
      title: 'Profile Image',
      dataIndex: 'profileImage',
      key: 'profileImage',
      render: (image) => {
        if (!image) {
          return null;
        }
        return (
          <img
            alt=""
            src={image}
            style={{
              width: '80px',
              height: '80px',
              objectFit: 'contain',
            }}
          />
        );
      },
    },
    {
      title: 'Other Images',
      dataIndex: 'images',
      key: 'images',
      render: (text) => (
        <div style={{ display: 'flex' }}>
          {text.map((t, index) => {
            if (index === 0) {
              return null;
            }
            return (
              <img
                alt=""
                key={index.toString()}
                src={t}
                style={{
                  width: '80px',
                  height: '80px',
                  marginRight: 8,
                  objectFit: 'contain',
                }}
              />
            );
          })}
        </div>
      ),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => sorterFunc(a, b, 'firstName'),
      sortOrder: sortFinder({ key: 'firstName', sorter }),
      render: (text) => <b>{text}</b>,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a, b) => sorterFunc(a, b, 'lastName'),
      sortOrder: sortFinder({ key: 'lastName', sorter }),
      render: (text) => <b>{text}</b>,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      sorter: (a, b) => sorterFunc(a, b, 'gender'),
      sortOrder: sortFinder({ key: 'gender', sorter }),
      filters: [
        {
          text: (
            <span style={{ color: '#0277bd' }}>
              <ManOutlined /> Male
            </span>
          ),
          value: 'male',
        },
        {
          text: (
            <span style={{ color: '#ec0d7c' }}>
              <WomanOutlined /> Female
            </span>
          ),
          value: 'female',
        },
      ],
      filteredValue: genderFilter,
      onFilter: (value, record) => filterFunc(value, record, 'gender'),
      render: (text) => {
        let color = '#000';
        if (text === 'male') color = '#0277bd';
        else if (text === 'female') color = '#ec0d7c';
        else color = '#000';
        return <b style={{ color }}>{stringFormatter({ text })}</b>;
      },
    },
    {
      title: 'City',
      dataIndex: 'locations',
      key: 'locations',
      render: (locations) => {
        const city = getCurrentCity({ locations });
        return <b>{city}</b>;
      },
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      sorter: (a, b) => sorterFunc(a, b, 'dateOfBirth'),
      sortOrder: sortFinder({ key: 'dateOfBirth', sorter }),
      onCell: () => ({
        style: {
          whiteSpace: 'nowrap',
        },
      }),
      render: (text) => <b>{text}</b>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => sorterFunc(a, b, 'status'),
      sortOrder: sortFinder({ key: 'status', sorter }),
      filters: [
        {
          text: 'Onboarding Started',
          value: 'on_boarding_started',
        },
        {
          text: 'Onboarding Completed',
          value: 'on_boarding_completed',
        },
      ],
      filteredValue: statusFilter,
      onFilter: (value, record) => filterFunc(value, record, 'status'),
      render: (text) => {
        let color = '#000';
        if (text === 'on_boarding_completed') color = 'green';
        return <b style={{ color }}>{getUserStatus(text)}</b>;
      },
    },
    {
      title: 'Verification',
      dataIndex: 'verificationStatus',
      key: 'verificationStatus',
      sorter: (a, b) => sorterFunc(a, b, 'verificationStatus'),
      sortOrder: sortFinder({ key: 'verificationStatus', sorter }),
      filters: [
        {
          text: 'Auto Verified',
          value: 'auto_verified',
        },
        {
          text: 'Verified by Admin',
          value: 'verified_by_admin',
        },
        {
          text: 'Auto Unverified',
          value: 'auto_unverified',
        },
        {
          text: 'Unverified by Admin',
          value: 'unverified_by_admin',
        },
        {
          text: 'Verification On Hold',
          value: 'verification_on_hold',
        },
      ],
      onFilter: (value, record) =>
        filterFunc(value, record, 'verificationStatus'),
      filteredValue: verificationStatusFilter,
      render: (verificationStatus) => {
        let color = '#000';
        if (
          verificationStatus === 'auto_verified' ||
          verificationStatus === 'verified_by_admin'
        ) {
          color = 'green';
        } else {
          color = 'volcano';
        }
        return (
          <Tag color={color}>{getVerificationStatus(verificationStatus)}</Tag>
        );
      },
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text) => <b>{text}</b>,
    },
    {
      title: 'Profile Id',
      dataIndex: 'id',
      key: 'id',
      onCell: () => ({
        style: {
          whiteSpace: 'nowrap',
        },
      }),
      render: (text) => <b>{text}</b>,
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <b>{text}</b>,
    },
    // {
    //   title: "Medium",
    //   dataIndex: "medium",
    //   key: "medium",
    //   sorter: (a, b) => a.medium.localeCompare(b.medium),
    //   render: text => <b>{text}</b>
    // },
    // {
    //   title: "Source",
    //   dataIndex: "source",
    //   key: "source",
    //   sorter: (a, b) => a.source.localeCompare(b.source),
    //   render: text => <b>{text}</b>
    // },
    // {
    //   title: "Created At",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    //   sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    //   onCell: () => {
    //     return {
    //       style: {
    //         whiteSpace: "nowrap"
    //       }
    //     };
    //   },
    //   render: text => <b>{text}</b>
    // },
    // {
    //   title: "Religion",
    //   dataIndex: "religion",
    //   key: "religion",
    //   render: text => <b>{text}</b>
    // },
    {
      title: 'Income',
      dataIndex: 'income',
      key: 'income',
      onCell: () => ({
        style: {
          whiteSpace: 'nowrap',
        },
      }),
      render: (text) => <b>{text}</b>,
    },
    // {
    //   title: "Currency",
    //   dataIndex: "currency",
    //   key: "currency",
    //   render: text => <b>{text}</b>
    // },

    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      dataIndex: 'id',
      render: (id) => (
        <div>
          <Link
            to={{
              pathname: `/user-profile/${id}/hm`,
            }}
          >
            View profile
          </Link>
        </div>
      ),
    },
  ];
};
