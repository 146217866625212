export const handleSelectWithAny = ({
  selectedPreference,
  preferenceState,
  ANY_ITEM,
}) => {
  let updatedPreferenceState = [];
  /* Check if the selected preference doesn't already exist in the state */
  if (
    !preferenceState.some(
      (preferenceItem) => preferenceItem.value === selectedPreference.value
    )
  ) {
    if (selectedPreference.value === 'any') {
      /* If the currently selected preference is "Any", remove all other preferences from state */
      updatedPreferenceState = [selectedPreference];
    } else {
      /* Else, remove "Any" if it exists in the state and then add the currently selected preference */
      updatedPreferenceState = [
        ...preferenceState.filter(
          (preferenceItem) => preferenceItem.value !== 'any'
        ),
        selectedPreference,
      ];
    }
  } else {
    /* If the currently selected preference already exists in state, remove it */
    updatedPreferenceState = preferenceState.filter(
      (preferenceItem) => preferenceItem.value !== selectedPreference.value
    );
    /* After removing, if the state is empty, add "Any" as the currently selected preference */
    if (updatedPreferenceState.length === 0) {
      updatedPreferenceState = [ANY_ITEM];
    }
  }

  return updatedPreferenceState;
};

/** Convert inches to feet and inches
 * @param  {number} value
 */
export const heightFormatter = (value) => {
  const feet = Math.floor(value / 12);
  const inches = value % 12;
  return `${feet} ft ${inches > 0 ? `${inches} inches` : ''}`;
};
