import Cookies from 'js-cookie';
import { getApiPrefix } from '../../utilities/apiUrls';
import { createInstance } from './src';

const accessToken = Cookies.get('accessToken');
// const API_PREFIX = process.env.REACT_APP_BASE_URL;
const API_PREFIX = getApiPrefix();

const httpClient = createInstance(API_PREFIX);
// console.log('accessToken', accessToken);
if (accessToken) {
  const tokenType = Cookies.get('tokenType') || '';
  httpClient.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
}

export default httpClient;
