import { message } from 'antd';
import { isEmpty, snakeToCamel } from './utils';

export const salesActionTypes = {
  matchesLoading: 'MATCHES_LOADING',
  matchesSuccess: 'MATCHES_SUCCESS',
  matchesFailure: 'MATCHES_FAILURE',
  addMatchesLoading: 'ADDMATCHES_LOADING',
  addMatchesSuccess: 'ADDMATCHES_SUCCESS',
  addMatchesFailure: 'ADDMATCHES_FAILURE',
};

const initialState = {
  addMatches: {
    isLoading: false,
    data: {},
    error: {},
    success: false,
  },
  matches: {
    isLoading: false,
    data: {},
    error: {},
  },
};

export function matchesReducer(state = initialState.matches, action) {
  switch (action.type) {
    case salesActionTypes.matchesLoading:
      return { ...state, isLoading: true };

    case salesActionTypes.matchesSuccess: {
      const { payload } = action;
      const result = [];
      payload.results.map((i) => {
        const temp = {};
        Object.keys(i).map((item) => {
          temp[snakeToCamel(item)] = i[item];
          return null;
        });
        result.push(temp);
        return null;
      });
      result.map((i) => {
        // eslint-disable-next-line no-param-reassign
        i.income = `${i.incomeFrom} - ${i.incomeTo} LPA`;
        return null;
      });
      const dataToBeSent = { ...action.payload, ...{ results: result } };
      return { ...state, data: dataToBeSent, isLoading: false };
    }
    case salesActionTypes.matchesFailure:
      return { ...state, error: action.error, isLoading: false };

    default:
      return state;
  }
}

export function addMatchesReducer(state = initialState.addMatches, action) {
  switch (action.type) {
    case salesActionTypes.addMatchesLoading:
      return { ...state, isLoading: true, data: { ...state.data, count: 0 } };

    case salesActionTypes.addMatchesSuccess:
      return { ...state, data: action.payload, isLoading: false };

    case salesActionTypes.addMatchesFailure:
      return { ...state, error: action.error, isLoading: false };

    default:
      return state;
  }
}

export const salesActions = {
  matchesLoading(bool) {
    return {
      type: salesActionTypes.matchesLoading,
      isLoading: bool,
    };
  },
  matchesSuccess(data) {
    return {
      type: salesActionTypes.matchesSuccess,
      payload: data,
    };
  },
  matchesFailure(err) {
    return {
      type: salesActionTypes.matchesFailure,
      error: err,
    };
  },
  addMatchesLoading(bool) {
    return {
      type: salesActionTypes.addMatchesLoading,
      isLoading: bool,
    };
  },
  addMatchesSuccess(data) {
    return {
      type: salesActionTypes.addMatchesSuccess,
      payload: data,
    };
  },
  addMatchesFailure(err) {
    return {
      type: salesActionTypes.addMatchesFailure,
      error: err,
    };
  },
};

export const salesEffects = {
  getMatches({ id, url, qs }) {
    return async (dispatch) => {
      const urlToBeSent = !isEmpty(url)
        ? url.substring(url.indexOf('v2') + 2)
        : '';
      dispatch(salesActions.matchesLoading(true));
      try {
        const newUrl = isEmpty(urlToBeSent)
          ? `/users/${id}/remaining-recommendations/?${qs}`
          : urlToBeSent;
        const resp = await window.client.getMatches(newUrl);
        dispatch(salesActions.matchesSuccess(resp.body));
      } catch (e) {
        dispatch(salesActions.matchesFailure(e));
      }
    };
  },
  addMatches({ userId, ids }) {
    return async (dispatch) => {
      dispatch(salesActions.addMatchesLoading(true));
      try {
        const resp = await window.client.addMatches({
          id: userId,
          ids,
        });
        dispatch(salesActions.addMatchesSuccess(resp.body));
        message.success('Matches added successfully', 3);
      } catch (e) {
        message.error(
          `Something went wrong - Matches are not sent ***${e}***`,
          5
        );
        dispatch(salesActions.addMatchesFailure(e));
      }
    };
  },
};
