export const preDefinedType = [
  {
    key: 'delete_type_1',
    text: 'User Request',
    value: 1,
  },
  {
    key: 'delete_type_2',
    text: 'Other',
    value: 2,
  },
];

export const preDefinedReason = [
  {
    key: 'delete_reason_1',
    text: 'Duplicate account',
    value: 1,
  },
  {
    key: 'delete_reason_2',
    text: 'Fake account/Suspicious account',
    value: 2,
  },
  {
    key: 'delete_reason_7',
    text: 'Other',
    value: 3,
  },
];
