import React from 'react';
import ReactDOM from 'react-dom';
import Mixpanel from 'mixpanel-browser';
import { MixpanelProvider } from 'react-mixpanel';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import './index.css';
import App from './App';

import 'react-image-lightbox/style.css';

const bugsnagClient = bugsnag(process.env.REACT_APP_BUGSNAG_KEY);
bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin('react');

Mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);

ReactDOM.render(
  <ErrorBoundary>
    <MixpanelProvider mixpanel={Mixpanel}>
      <App />
    </MixpanelProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
