import React, { createContext, useContext } from 'react';

const UserDataContext = createContext(null);

const UserDataProvider = (props) => {
  const { userData, children } = props;

  return (
    <UserDataContext.Provider value={userData}>
      {children}
    </UserDataContext.Provider>
  );
};

export default UserDataProvider;

export const useUserData = () => useContext(UserDataContext);
