export function payments({ get, post }) {
  return {
    getAllPremiumPlans({ id }) {
      return get(
        // update url as per app
        // `users/${id}/user-plans/?type=regular&payment_gateway=razorpay`
        `users/${id}/payments/one-time/plans/?payment_gateway=razorpay&type=regular`
      );
    },
    paymentLinks({ id, ...body }) {
      return post(`users/${id}/payment-links/`, body);
    },
    getSparklePlans({ id }) {
      return get(
        // update url as per app
        // `users/${id}/user-plans/?type=value_added_service&payment_gateway=razorpay`
        `users/${id}/payments/one-time/plans/?payment_gateway=razorpay&type=value_added_service`
      );
    },
    getHmPlans({ id }) {
      return get(
        `users/${id}/user-plans/?type=human_matchmaker&payment_gateway=razorpay`
      );
    },
    getBvPlans({ id }) {
      return get(
        `users/${id}/user-plans/?type=background_verification&payment_gateway=razorpay`
      );
    },
    getKundaliPlans({ id }) {
      return get(
        `users/${id}/user-plans/?type=kundali&payment_gateway=razorpay`
      );
    },
    getPaymentsHistory(req) {
      return get(req);
    },
  };
}
