import axios from 'axios';
import Cookies from 'js-cookie';
import { admin } from './admin';
import { users } from './users';
import { payments } from './payments';
import { selfieVerification } from './selfieVerification';
import { govtIdVerification } from './govtIdVerification';
import { sales } from './sales';
import { removeAuthHeaders, } from '../../../utilities/api';
import { getApiPrefix } from '../../../utilities/apiUrls';


let isRefreshing = false;
let failedQueue = [];

const transformResponses = (instance) => {
  const shouldIntercept = (error) => {
    try {
      // console.log({ errorStatus: error.response.status });
      return error.response.status === 401;
    } catch (e) {
      return false;
    }
  };

  const getNewToken = () => {
    const refreshToken = Cookies.get('refreshToken');
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const payload = {
            refresh_token: refreshToken,
          }
          const response = await axios.post(`${getApiPrefix()}/auth/token/refresh/`, payload, {headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }})
          const { access_token: accessToken, token_type: tokenType } =
            response.data;

          Cookies.set('accessToken', accessToken);
          Cookies.set('tokenType', tokenType);

          // eslint-disable-next-line no-param-reassign
          instance.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
          isRefreshing = false;
          resolve({
            accessToken,
            tokenType,
          });
        } catch (e) {

          if (shouldIntercept(e)) {
            Cookies.remove('tokenType');
            Cookies.remove('accessToken');
            Cookies.remove('refreshToken');
            Cookies.remove('adminImage');
            Cookies.remove('userData');
            reject(e);
          }
          removeAuthHeaders();
          window.location.href = '/';
          window.location.reload()
        }
      })();
    });
  };
  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  instance.interceptors.response.use(
    async (data) => {
      const status = data.status ? data.status : '';
      const header = data.headers['content-type']
        ? data.headers['content-type']
        : '';
      return {
        status,
        body: data.data,
        headers: header,
      };
    },
    async (error) => {
      if (!shouldIntercept(error)) {
        return Promise.reject(error);
      }

      if (error.config._retry || error.config._queued) {
        return Promise.reject(error);
      }

      const originalRequest = error.config;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(async (access_token) => {
            const tokenType = Cookies.get('tokenType');

            originalRequest._queued = true;

            // eslint-disable-next-line no-param-reassign
            instance.defaults.headers.common.Authorization = `${tokenType} ${access_token}`;
            originalRequest.headers.Authorization = `${tokenType} ${access_token}`;

            return instance.request(originalRequest);
          })
          .catch(() => Promise.reject(error));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        getNewToken(originalRequest)
          .then((tokenData) => {
            const { accessToken, tokenType } = tokenData;
            if (accessToken && tokenType) {
              // eslint-disable-next-line no-param-reassign
              instance.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
              originalRequest.headers.Authorization = `${tokenType} ${accessToken}`;

              processQueue(null, accessToken);
              resolve(instance.request(originalRequest));
            }
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }
  );
};

const required = (msg) => {
  throw new Error(msg);
};

export const createInstance = (baseURL, timeout) => {
  // console.log('create-instance called');
  const instance = axios.create({
    baseURL,
    timeout,
    withCredentials: true,
  });
  // transformRequest(instance)
  transformResponses(instance);
  return instance;
};

export const createClient = ({
  baseURL = required('baseURL is a required parameter to create a client'),
  timeout = 0,
} = {}) => {
  const instance = createInstance(baseURL, timeout);

  const factories = [
    admin,
    users,
    payments,
    selfieVerification,
    sales,
    govtIdVerification,
  ];

  const client = factories.reduce((c, fac) => {
    const funcs = fac(instance);
    Object.keys(funcs).forEach((name) => {
      if (
        process.env.NODE_ENV !== 'production' &&
        typeof c[name] !== 'undefined'
      ) {
        throw new Error(
          `Factory ${fac.name} defines duplicate method '${name}'`
        );
      } else {
        // eslint-disable-next-line no-param-reassign
        c[name] = funcs[name];
      }
    });
    return c;
  }, {});

  client.setAuthToken = (accessToken) => {
    const tokenType = Cookies.get('tokenType') || '';
    instance.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
  };

  client.removeAuthToken = () => {
    instance.defaults.headers.common.Authorization = '';
  };

  return client;
};

// const transformRequest = (instance) => {
//   instance.interceptors.request.use(async (config) => {
//     console.log('transformRequest', config)
//     return config
//   })
// }
