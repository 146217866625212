// export const getFormattedLocationPayload = (data) => {
//   const { formatted_address } = data[0];
//   const { lat, lng } = data[0].geometry.location;
//   const addresses = formatted_address.split(',');

//   const city = addresses[0].trim();
//   const state =
//     addresses.length === 2 ? addresses[0].trim() : addresses[1].trim();
//   const country =
//     addresses.length === 2 ? addresses[1].trim() : addresses[2].trim();

//   return {
//     type: 'Feature',
//     geometry: {
//       type: 'Point',
//       coordinates: [lat, lng],
//     },
//     properties: {
//       city,
//       state,
//       country,
//       tag: 'homeTown',
//     },
//   };
// };

export const getFormattedLocationPayloadFromInternalAPI = (data) => {
  const { latitude, longitude, city, state, country } = data;
  const lat = Number(latitude);
  const lng = Number(longitude);
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [lat, lng],
    },
    properties: {
      city,
      state,
      country,
      tag: 'homeTown',
    },
  };
};

/**
 *
 * @param {*} userSavedLocations
 * @param {*} type : "residential" |"hometown"
 * @returns city | undefined
 */

export const extractCity = (userSavedLocations, type) => {
  const location = userSavedLocations.features.filter(
    (item) => item.properties.tag === type
  );
  return location[0]?.properties.city;
};

/**
 * Email validation based on the same logic used in the backend (Django Email Validator
 */
export function isValidEmail(email) {
  const regexValid =
    /^[\w.]+@((?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\.)+)[A-Za-z0-9-]{1,63}[A-Za-z0-9]$/;
  return regexValid.test(email);
}

// function convert income number to strings =>
export const convertIncomeNumberToString = (income) => {
  const [incomeFrom, incomeTo] = income.split(' - ');
  const formattedIncomeFrom = incomeFrom.substring(0, incomeFrom.length - 7);
  const formattedIncomeTo = incomeTo.substring(0, incomeTo.length - 7);
  return `${formattedIncomeFrom} - ${formattedIncomeTo} LPA`;
};
