/* eslint-disable */
import {
  isEmpty,
  snakeToCamel,
  heightConverter,
  incomeConverter,
} from './utils';
import { message } from 'antd';
export const userProfileActionTypes = {
  showMatchesLoading: 'SHOWMATCHES_LOADING',
  showMatchesSuccess: 'SHOWMATCHES_SUCCESS',
  showMatchesFailure: 'SHOWMATCHES_FAILURE',
  userProfileDataLoading: 'USERDATA_LOADING',
  userProfileDataSuccess: 'USERDATA_SUCCESS',
  userProfileDataFailure: 'USERDATA_FAILURE',
  updateUserDataLoading: 'UPDATEUSERDATA_LOADING',
  updateUserDataSuccess: 'UPDATEUSERDATA_SUCCESS',
  updateUserDataFailure: 'UPDATEUSERDATA_FAILURE',
  userProfileFeedbackLoading: 'USERPROFILEFEEDBACK_LOADING',
  userProfileFeedbackSuccess: 'USERPROFILEFEEDBACK_SUCCESS',
  userProfileFeedbackFailure: 'USERPROFILEFEEDBACK_FAILURE',
  commentsLoading: 'COMMENTS_LOADING',
  commentsSuccess: 'COMMENTS_SUCCESS',
  commentsFailure: 'COMMENTS_FAILURE',
  oneWayLeftMatchesLoading: 'ONEWAY_LEFTMATCHES_LOADING',
  oneWayLeftMatchesSuccess: 'ONEWAY_LEFTMATCHES_SUCCESS',
  oneWayLeftMatchesFailure: 'ONEWAY_LEFTMATCHES_FAILURE',
  twoWayLeftMatchesLoading: 'TWOWAY_LEFTMATCHES_LOADING',
  twoWayLeftMatchesSuccess: 'TWOWAY_LEFTMATCHES_SUCCESS',
  twoWayLeftMatchesFailure: 'TWOWAY_LEFTMATCHES_FAILURE',
  additionalInfoLoading: 'ADDITIONAL_INFO_LOADING',
  additionalInfoSuccess: 'ADDITIONAL_INFO_SUCCESS',
  additionalInfoFailure: 'ADDITIONAL_INFO_FAILURE',
  updatePartnerPreferencesLoading: 'UPDATEPARTNERPREFERENCES_LOADING',
  updatePartnerPreferencesSuccess: 'UPDATEPARTNERPREFERENCES_SUCCESS',
  updatePartnerPreferencesFailure: 'UPDATEPARTNERPREFERENCES_FAILURE',
  addImageLoading: 'ADD_IMAGE_LOADING',
  addImageSuccess: 'ADD_IMAGE_SUCCESS',
  addImageFailure: 'ADD_IMAGE_FAILURE',
  deleteImageLoading: 'DELETE_IMAGE_LOADING',
  deleteImageSuccess: 'DELETE_IMAGE_SUCCESS',
  deleteImageFailure: 'DELETE_IMAGE_FAILURE',
  clearUserData: 'CLEAR_USER_DATA',
  updateProfileImageLoading: 'UPDATE_PROFILE_IMAGE_LOADING',
  updateProfileImageSuccess: 'UPDATE_PROFILE_IMAGE_SUCCESS',
  updateProfileImageFailure: 'UPDATE_PROFILE_IMAGE_FAILURE',
  updatePaidUserDetailsLoading: 'UPDATEPAIDUSERDETAILS_LOADING',
  updatePaidUserDetailsSuccess: 'UPDATEPAIDUSERDETAILS_SUCCESS',
  updatePaidUserDetailsFailure: 'UPDATEPAIDUSERDETAILS_FAILURE',
  requestReceivedCountLoading: 'RR_COUNT_LOADING',
  requestReceivedCountSuccess: 'RR_COUNT_SUCCESS',
  requestReceivedCountFailure: 'RR_COUNT_FAILURE',
  deleteUserAccountLoading: 'DELETE_USER_ACCOUNT_LOADING',
  deleteUserAccountSuccess: 'DELETE_USER_ACCOUNT_SUCCESS',
  deleteUserAccountFailure: 'DELETE_USER_ACCOUNT_FAILURE',
  forceSelfieVerificationLoading: 'FORCE_SELFIE_VERIFICARION_LOADING',
  forceSelfieVerificationSuccess: 'FORCE_SELFIE_VERIFICARION_SUCCESS',
  forceSelfieVerificationFailure: 'FORCE_SELFIE_VERIFICARION_FAILURE',
  shortListedUsersLoading: 'SHORTLISTED_USERS_LOADING',
  shortListedUsersSuccess: 'SHORTLISTED_USERS_SUCCESS',
  shortListedUsersFailure: 'SHORTLISTED_USERS_FAILURE',
};

const initialState = {
  showMatches: {
    isLoading: false,
    data: {},
    error: {},
  },
  userData: {
    isLoading: false,
    data: {},
    error: {},
  },
  updateUserStatus: {
    isLoading: false,
    data: {},
    error: {},
    success: false,
    paidUserDetailsUpdateSuccess: false,
  },
  userFeedback: {
    isLoading: false,
    data: {},
    error: {},
  },
  additonalInfoState: {
    isLoading: false,
    data: {},
    error: {},
  },
  partnerPreferenceUpdateStatus: {
    isLoading: false,
    data: {},
    error: {},
    success: false,
  },
  addImage: {
    isLoading: false,
    data: {},
    error: false,
    uploadingImageCount: 0,
  },
  userProfileDelete: {
    isLoading: false,
    success: false,
    error: {},
  },
  forceSelfieVerification: {
    isLoading: false,
    success: false,
    error: {},
  },
  shortlistedUser: {
    isLoading: false,
    data: {},
    error: {},
  },
};

export function showMatchesReducer(state = initialState.showMatches, action) {
  switch (action.type) {
    case userProfileActionTypes.showMatchesLoading:
      return { ...state, isLoading: true };
    case userProfileActionTypes.showMatchesSuccess:
      return { ...state, data: action.payload, isLoading: false };
    case userProfileActionTypes.showMatchesFailure:
      return { ...state, error: action.error, isLoading: false };
    default:
      return state;
  }
}

export function userDataReducer(state = initialState.userData, action) {
  switch (action.type) {
    case userProfileActionTypes.clearUserData:
      return initialState.userData;
    case userProfileActionTypes.userProfileDataLoading:
      return { ...state, isLoading: true };
    case userProfileActionTypes.userProfileDataFailure:
      return { ...state, error: action.error, isLoading: false };
    case userProfileActionTypes.userProfileDataSuccess:
      return { ...state, data: action.payload, isLoading: false };
    default:
      return state;
  }
}

export function deleteImageReducer(state = initialState.deleteImage, action) {
  switch (action.type) {
    case userProfileActionTypes.deleteImageLoading:
      return { ...state, isLoading: true };
    case userProfileActionTypes.deleteImageFailure:
      return { ...state, error: action.error, isLoading: false };
    case userProfileActionTypes.deleteImageSuccess:
      return { ...state, data: action.payload, isLoading: false };
    default:
      return state;
  }
}

export function addImageReducer(state = initialState.addImage, action) {
  switch (action.type) {
    case userProfileActionTypes.addImageLoading:
      return { ...state, isLoading: true };
    case userProfileActionTypes.addImageFailure:
      return { ...state, error: action.error, isLoading: false };
    case userProfileActionTypes.addImageSuccess:
      return { ...state, data: action.payload, isLoading: false };
    default:
      return state;
  }
}

export function updateUserReducer(
  state = initialState.updateUserStatus,
  action
) {
  switch (action.type) {
    case userProfileActionTypes.updateUserDataLoading:
      return {
        ...state,
        isLoading: true,
        success: false,
        paidUserDetailsUpdateSuccess: false,
        error: {},
      };
    case userProfileActionTypes.updateUserDataSuccess:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        success: true,
        paidUserDetailsUpdateSuccess: false,
        error: {},
      };
    case userProfileActionTypes.updateUserDataFailure:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        success: false,
        paidUserDetailsUpdateSuccess: false,
      };
    case userProfileActionTypes.updatePaidUserDetailsSuccess:
      return {
        ...state,
        paidUserDetailsUpdateSuccess: true,
        isLoading: false,
        data: action.payload,
        success: false,
        error: {},
      };
    default:
      return state;
  }
}

export function userFeedbackReducer(state = initialState.userFeedback, action) {
  switch (action.type) {
    case userProfileActionTypes.clearUserData:
      return initialState.userFeedback;
    case userProfileActionTypes.userProfileFeedbackLoading:
      return { ...state, isLoading: true };
    case userProfileActionTypes.userProfileDataFailure:
      return { ...state, error: action.error, isLoading: false };
    case userProfileActionTypes.userProfileFeedbackSuccess:
      return { ...state, data: action.payload, isLoading: false };
    case userProfileActionTypes.commentsLoading:
      return { ...state, isLoading: true };
    case userProfileActionTypes.commentsSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          next: action.payload.next,
          results: [...state.data.results, ...action.payload.results],
        },
        isLoading: false,
      };
    case userProfileActionTypes.commentsFailure:
      return { ...state, error: action.error, isLoading: false };
    default:
      return state;
  }
}

export function additionalInfoReducer(
  state = initialState.additonalInfoState,
  action
) {
  switch (action.type) {
    case userProfileActionTypes.clearUserData:
      return initialState.additonalInfoState;
    case userProfileActionTypes.oneWayLeftMatchesLoading:
      return { ...state, isLoading: true };
    case userProfileActionTypes.oneWayLeftMatchesSuccess:
      return {
        ...state,
        data: { ...state.data, oneWayMatchCount: action.payload.count },
        isLoading: false,
      };
    case userProfileActionTypes.oneWayLeftMatchesFailure:
      return { ...state, error: action.error, isLoading: false };
    case userProfileActionTypes.twoWayLeftMatchesLoading:
      return { ...state, isLoading: true };
    case userProfileActionTypes.twoWayLeftMatchesSuccess:
      return {
        ...state,
        data: { ...state.data, twoWayMatchCount: action.payload.count },
        isLoading: false,
      };
    case userProfileActionTypes.twoWayLeftMatchesFailure:
      return { ...state, error: action.error, isLoading: false };
    case userProfileActionTypes.additionalInfoLoading:
      return { ...state, isAddionalDetialsLoading: true };
    case userProfileActionTypes.additionalInfoSuccess:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isAddionalDetialsLoading: false,
      };
    case userProfileActionTypes.additonalInfoFailure:
      return { ...state, isAddionalDetialsLoading: false, error: action.error };
    case userProfileActionTypes.requestReceivedCountLoading:
      return { ...state, isLoading: true };
    case userProfileActionTypes.requestReceivedCountSuccess: {
      const { count } = action.payload;
      const {
        seen_count: seenCount,
        unseen_count: unSeenCount,
        expired_count: expiredCount,
        inactive_or_unverified_count: inactiveOrUnverifiedCount,
      } = count;
      return {
        ...state,
        data: {
          ...state.data,
          unSeenCount,
          seenCount,
          expiredCount,
          inactiveOrUnverifiedCount,
        },
        isLoading: false,
      };
    }
    case userProfileActionTypes.requestReceivedCountFailure:
      return { ...state, error: action.error, isLoading: false };
    default:
      return state;
  }
}

export function partnerPreferencesReducer(
  state = initialState.partnerPreferenceUpdateStatus,
  action
) {
  switch (action.type) {
    case userProfileActionTypes.updatePartnerPreferencesLoading:
      return { ...state, isLoading: true };
    case userProfileActionTypes.updatePartnerPreferencesSuccess:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };
    case userProfileActionTypes.updatePartnerPreferencesFailure:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false,
      };
    default:
      return state;
  }
}

export function userProfileDeleteReducer(
  state = initialState.userProfileDelete,
  action
) {
  switch (action.type) {
    case userProfileActionTypes.deleteUserAccountLoading:
      return { ...state, isLoading: true, error: {} };
    case userProfileActionTypes.deleteUserAccountSuccess:
      return { ...state, isLoading: false, success: true };
    case userProfileActionTypes.deleteUserAccountFailure:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false,
      };
    default:
      return state;
  }
}

export function forceSelfieVerificationReducer(
  state = initialState.forceSelfieVerification,
  action
) {
  switch (action.type) {
    case userProfileActionTypes.forceSelfieVerificationLoading:
      return { ...state, isLoading: true };
    case userProfileActionTypes.forceSelfieVerificationSuccess:
      return { ...state, data: action.payload, isLoading: false };
    case userProfileActionTypes.forceSelfieVerificationFailure:
      return { ...state, error: action.error, isLoading: false };
    default:
      return state;
  }
}

export function shortlistedUsersReducer(
  state = initialState.shortlistedUser,
  action
) {
  switch (action.type) {
    case userProfileActionTypes.clearUserData:
      return initialState.shortlistedUser;
    case userProfileActionTypes.shortListedUsersLoading:
      return { ...state, isLoading: true };
    case userProfileActionTypes.shortListedUsersFailure:
      return { ...state, error: action.error, isLoading: false };
    case userProfileActionTypes.shortListedUsersSuccess: {
      if (state.data && state.data.results) {
        return {
          ...state,
          data: {
            ...action.payload,
            results: [...state.data.results, ...action.payload.results],
          },
          isLoading: false,
        };
      }
      return {
        ...state,
        data: {
          ...action.payload,
          results: action.payload.results,
        },
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

export const userProfileActions = {
  showMatchesLoading(bool) {
    return {
      type: userProfileActionTypes.showMatchesLoading,
      bool,
    };
  },
  showMatchesSuccess({ data }) {
    return {
      type: userProfileActionTypes.showMatchesSuccess,
      payload: data,
    };
  },
  showMatchesFailure(error) {
    return {
      type: userProfileActionTypes.showMatchesFailure,
      error,
    };
  },
  userProfileDataLoading(bool) {
    return {
      type: userProfileActionTypes.userProfileDataLoading,
      isLoading: bool,
    };
  },
  userProfileDataSuccess(data) {
    return {
      type: userProfileActionTypes.userProfileDataSuccess,
      payload: data,
    };
  },
  userProfileDataFailure(error) {
    return {
      type: userProfileActionTypes.userProfileDataFailure,
      error,
    };
  },
  userUpdateLoading(bool) {
    return {
      type: userProfileActionTypes.updateUserDataLoading,
      isLoading: bool,
    };
  },
  userUpdateSuccess(data) {
    return {
      type: userProfileActionTypes.updateUserDataSuccess,
      payload: data,
    };
  },
  userUpdateFailure(error) {
    return {
      type: userProfileActionTypes.updateUserDataFailure,
      error,
    };
  },
  userProfileFeedbackLoading(bool) {
    return {
      type: userProfileActionTypes.userProfileFeedbackLoading,
      isLoading: bool,
    };
  },
  userProfileFeedbackSuccess(data) {
    return {
      type: userProfileActionTypes.userProfileFeedbackSuccess,
      payload: data,
    };
  },
  userProfileFeedbackFailure(error) {
    return {
      type: userProfileActionTypes.userProfileFeedbackFailure,
      error,
    };
  },
  moreCommentsLoading(bool) {
    return {
      type: userProfileActionTypes.commentsLoading,
      isLoading: bool,
    };
  },
  moreCommentsSuccess(data) {
    return {
      type: userProfileActionTypes.commentsSuccess,
      payload: data,
    };
  },
  moreCommentsFailure(err) {
    return {
      type: userProfileActionTypes.commentsFailure,
      error: err,
    };
  },
  oneWayLeftMatchesLoading(bool) {
    return {
      type: userProfileActionTypes.oneWayLeftMatchesLoading,
      isLoading: bool,
    };
  },
  oneWayLeftMatchesSuccess(data) {
    return {
      type: userProfileActionTypes.oneWayLeftMatchesSuccess,
      payload: data,
    };
  },
  oneWayLeftMatchesFailure(err) {
    return {
      type: userProfileActionTypes.onewayLeftMatchesFailure,
      error: err,
    };
  },
  additonalInfoLoading(bool) {
    return {
      type: userProfileActionTypes.additionalInfoLoading,
      isLoading: bool,
    };
  },
  additonalInfoSuccess(data) {
    return {
      type: userProfileActionTypes.additionalInfoSuccess,
      payload: data,
    };
  },
  additonalInfoFailure(err) {
    return {
      type: userProfileActionTypes.additionalInfoFailure,
      error: err,
    };
  },
  twoWayLeftMatchesLoading(bool) {
    return {
      type: userProfileActionTypes.twoWayLeftMatchesLoading,
      isLoading: bool,
    };
  },
  twoWayLeftMatchesSuccess(data) {
    return {
      type: userProfileActionTypes.twoWayLeftMatchesSuccess,
      payload: data,
    };
  },
  twoWayLeftMatchesFailure(err) {
    return {
      type: userProfileActionTypes.twoWayLeftMatchesFailure,
      error: err,
    };
  },
  updatePartnerPreferencesLoading(bool) {
    return {
      type: userProfileActionTypes.updatePartnerPreferencesLoading,
      isLoading: bool,
    };
  },
  updatePartnerPreferencesSuccess(data) {
    return {
      type: userProfileActionTypes.updatePartnerPreferencesSuccess,
      payload: data,
    };
  },
  updatePartnerPreferencesFailure(err) {
    return {
      type: userProfileActionTypes.updatePartnerPreferencesFailure,
      error: err,
    };
  },
  addUserImageLoading(bool) {
    return {
      type: userProfileActionTypes.addImageLoading,
      isLoading: bool,
    };
  },
  addUserImageSuccess(data) {
    return {
      type: userProfileActionTypes.addImageSuccess,
      payload: data,
    };
  },
  addUserImageFailure(err) {
    return {
      type: userProfileActionTypes.addImageFailure,
      error: err,
    };
  },
  deleteUserImageLoading(bool) {
    return {
      type: userProfileActionTypes.deleteImageLoading,
      isLoading: bool,
    };
  },
  deleteUserImageSuccess(data) {
    return {
      type: userProfileActionTypes.deleteImageSuccess,
      payload: data,
    };
  },
  deleteUserImageFailure(err) {
    return {
      type: userProfileActionTypes.deleteImageFailure,
      error: err,
    };
  },
  updateProfileImageLoading(bool) {
    return {
      type: userProfileActionTypes.updateProfileImageLoading,
      isLoading: bool,
    };
  },
  updateProfileImageSuccess(data) {
    return {
      type: userProfileActionTypes.updateProfileImageSuccess,
      payload: data,
    };
  },
  updateProfileImageFailure(err) {
    return {
      type: userProfileActionTypes.updateProfileImageFailure,
      error: err,
    };
  },
  updatePaidUserDetailsLoading(bool) {
    return {
      type: userProfileActionTypes.updatePaidUserDetailsLoading,
      isLoading: bool,
    };
  },
  updatePaidUserDetailsSuccess(data) {
    return {
      type: userProfileActionTypes.updatePaidUserDetailsSuccess,
      payload: data,
    };
  },
  updatePaidUserDetailsFailure(err) {
    return {
      type: userProfileActionTypes.updatePartnerPreferencesFailure,
      error: err,
    };
  },
  requestReceivedCountLoading(bool) {
    return {
      type: userProfileActionTypes.requestReceivedCountLoading,
      isLoading: bool,
    };
  },
  requestReceivedCountSuccess(data) {
    return {
      type: userProfileActionTypes.requestReceivedCountSuccess,
      payload: data,
    };
  },
  requestReceivedCountFailure(err) {
    return {
      type: userProfileActionTypes.requestReceivedCountFailure,
      error: err,
    };
  },
  deleteUserAccountLoading(bool) {
    return {
      type: userProfileActionTypes.deleteUserAccountLoading,
      isLoading: bool,
    };
  },
  deleteUserAccountSuccess() {
    return {
      type: userProfileActionTypes.deleteUserAccountSuccess,
    };
  },
  deleteUserAccountFailure(err) {
    return {
      type: userProfileActionTypes.deleteUserAccountFailure,
      error: err,
    };
  },
  forceSelfieVerificationLoading(bool) {
    return {
      type: userProfileActionTypes.forceSelfieVerificationLoading,
      bool,
    };
  },
  forceSelfieVerificationSuccess({ data }) {
    return {
      type: userProfileActionTypes.forceSelfieVerificationSuccess,
      payload: data,
    };
  },
  forceSelfieVerificationFailure(error) {
    return {
      type: userProfileActionTypes.forceSelfieVerificationFailure,
      error,
    };
  },
  shortListedUsersLoading(bool) {
    return {
      type: userProfileActionTypes.shortListedUsersLoading,
      bool,
    };
  },
  shortListedUsersSuccess({ data }) {
    return {
      type: userProfileActionTypes.shortListedUsersSuccess,
      payload: data,
    };
  },
  shortListedUsersFailure(error) {
    return {
      type: userProfileActionTypes.shortListedUsersFailure,
      error,
    };
  },
};

export const userProfileEffects = {
  showMatches({ id, url }) {
    return async (dispatch) => {
      dispatch(userProfileActions.showMatchesLoading(true));
      try {
        const urlToBeSent = !isEmpty(url)
          ? url.substring(url.indexOf('v2') + 2)
          : null;
        const req = isEmpty(url)
          ? `/users/${id}/recommendations/?limit=10`
          : urlToBeSent;
        let resp = await window.client.getUserMatches(req);
        if (resp.status >= 300 || resp.status < 200) throw resp;
        dispatch(userProfileActions.showMatchesSuccess({ data: resp.body }));
      } catch (e) {
        dispatch(userProfileActions.showMatchesFailure(e));
      }
    };
  },
  getUserProfileData({ id }) {
    return async (dispatch) => {
      dispatch(userProfileActions.userProfileDataLoading(true));
      try {
        let resp = await window.client.fetchUserProfile({ id });
        if (resp.status >= 300 || resp.status < 200) throw resp;
        const { body } = resp;
        const result = {};
        Object.keys(body).map((item) => {
          result[snakeToCamel(item)] = body[item];
          return null;
        });
        if (!isEmpty(result.partnerPreference)) {
          const {
            age_from,
            age_to,
            height_from,
            height_to,
            income_from,
            income_to,
            food_preference,
            marital_status,
            cities,
            languages,
            religions,
            modified_at,
          } = result.partnerPreference;
          result['agePreference'] = `${age_from} - ${age_to}`;
          result['heightPreference'] = `${heightConverter({
            inches: height_from,
          })} - ${heightConverter({ inches: height_to })}`;
          result['annualIncomePreference'] = `${incomeConverter({
            incomeFrom: income_from,
            incomeTo: income_to,
          })}`;
          result['foodPartnerPreference'] = food_preference;
          result['maritalStatusPreference'] = marital_status;
          result['citiesPreference'] = cities;
          result['languagesPreference'] = languages;
          result['religionsPreference'] = religions;
          result['preferenceLastUpdatedAt'] = modified_at;
        }
        if (!isEmpty(result['activePlan'])) {
          const { end_date, name, start_date, amount } = result['activePlan'];
          result['planEndDate'] = end_date;
          result['planName'] = name;
          result['planStartDate'] = start_date;
          result['amount'] = amount;
        }
        if (!isEmpty(result['sparklePlan'])) {
          const { name, consumable_quantities, amount } =
            result['sparklePlan'][0];
          result['sparklePlanName'] = name;
          result['sparkleQuantities'] = consumable_quantities;
          result['sparkleAmount'] = amount;
        }
        if (!isEmpty(result['hmPlan'])) {
          const { name, consumable_quantities, amount, start_date, end_date } =
            result.hmPlan;
          result['hmPlanName'] = name;
          result['hmQuantities'] = consumable_quantities;
          result['hmAmount'] = amount;
          result['hmStartDate'] = start_date;
          result['hmEndDate'] = end_date;
        }
        if (!isEmpty(result['bvPlan'])) {
          const { name, consumable_quantities, amount, start_date, end_date } =
            result.bvPlan;
          result['bvPlanName'] = name;
          result['bvQuantities'] = consumable_quantities;
          result['bvAmount'] = amount;
          result['bvStartDate'] = start_date;
          result['bvEndDate'] = end_date;
        }
        if (!isEmpty(result['kundaliPlan'])) {
          const { name, consumable_quantities, amount } =
            result['kundaliPlan'][0];
          result['kundaliPlanName'] = name;
          result['kundaliQuantities'] = consumable_quantities;
          result['kundaliAmount'] = amount;
        }
        if (!isEmpty(result['bvIntroPlan'])) {
          const { name, consumable_quantities, amount, start_date, end_date } =
            result.bvIntroPlan;
          result['bvIntroPlanName'] = name;
          result['bvIntroQuantities'] = consumable_quantities;
          result['bvIntroPlanAmount'] = amount;
          result['bvIntroPlanStartDate'] = start_date;
          result['bvIntroPlanEndDate'] = end_date;
        }
        if (!isEmpty(result['introPlan'])) {
          const { name, consumable_quantities, amount, start_date, end_date } =
            result.introPlan;
          result['introPlanName'] = name;
          result['introQuantities'] = consumable_quantities;
          result['introPlanAmount'] = amount;
          result['introPlanStartDate'] = start_date;
          result['introPlanEndDate'] = end_date;
        }
        result['convertedHeight'] = heightConverter({ inches: body['height'] });
        result['annualIncome'] = incomeConverter({
          incomeFrom: body['income_from'],
          incomeTo: body['income_to'],
        });

        if (!isEmpty(body.images) && body.images.length > 0)
          result['profileImage'] = body.images[0];

        dispatch(userProfileActions.userProfileDataSuccess(result));
      } catch (e) {
        dispatch(userProfileActions.userProfileDataFailure(e));
      }
    };
  },
  updateUserDetails({ id, data }) {
    return async (dispatch) => {
      dispatch(userProfileActions.userUpdateLoading(true));
      try {
        let resp = await window.client.updateUserProfile({ id, ...data });
        if (resp.status >= 300 || resp.status < 200) throw resp;
        dispatch(userProfileActions.userUpdateSuccess(resp.body));
        dispatch(userProfileEffects.getUserProfileData({ id }));
      } catch (e) {
        const {
          response: { data },
        } = e;
        dispatch(userProfileActions.userUpdateFailure(data));
      }
    };
  },
  getUserProfileFeedback({ id, qs }) {
    return async (dispatch) => {
      dispatch(userProfileActions.userProfileFeedbackLoading(true));
      try {
        let resp = await window.client.getUserFeedback({ id, qs });
        if (resp.status >= 300 || resp.status < 200) throw resp;
        dispatch(userProfileActions.userProfileFeedbackSuccess(resp.body));
      } catch (e) {
        dispatch(userProfileActions.userProfileFeedbackFailure(e));
      }
    };
  },
  retrieveExpiredCount({ id }) {
    return async (dispatch) => {
      dispatch(userProfileActions.userProfileFeedbackLoading(true));
      try {
        let resp = await window.client.retrieveExpiredCount({ id });
        if (resp.status >= 300 || resp.status < 200) throw resp;
        dispatch(userProfileActions.requestReceivedCountSuccess(resp.body));
      } catch (e) {
        dispatch(userProfileActions.userProfileFeedbackFailure(e));
      }
    };
  },
  getMoreComments({ url }) {
    return async (dispatch) => {
      dispatch(userProfileActions.moreCommentsLoading(true));
      const urlToBeSent = !isEmpty(url)
        ? url.substring(url.indexOf('v2') + 2)
        : '';
      try {
        let resp = await window.client.getMoreComments({ url: urlToBeSent });
        if (resp.status >= 300 || resp.status < 200) throw resp;
        dispatch(userProfileActions.moreCommentsSuccess(resp.body));
      } catch (e) {
        dispatch(userProfileActions.moreCommentsFailure(e));
      }
    };
  },
  getOnewayLeftMatches({ id }) {
    return async (dispatch) => {
      dispatch(userProfileActions.oneWayLeftMatchesLoading(true));
      try {
        let resp = await window.client.getOneWayLeftMatches({ id });
        dispatch(userProfileActions.oneWayLeftMatchesSuccess(resp.body));
      } catch (e) {
        dispatch(userProfileActions.oneWayLeftMatchesFailure(e));
      }
    };
  },
  getAdditonalInfo({ id }) {
    return async (dispatch) => {
      dispatch(userProfileActions.additonalInfoLoading(true));
      try {
        let resp = await window.client.getAdditionaInfo({ id });
        const result = {};
        Object.keys(resp.body).map((item) => {
          result[snakeToCamel(item)] = resp.body[item];
          return null;
        });
        dispatch(userProfileActions.additonalInfoSuccess(result));
      } catch (e) {
        dispatch(userProfileActions.additonalInfoFailure(e));
      }
    };
  },
  getTwowayLeftMatches({ id }) {
    return async (dispatch) => {
      dispatch(userProfileActions.twoWayLeftMatchesLoading(true));
      try {
        let resp = await window.client.getTwoWayLeftMatches({ id });
        dispatch(userProfileActions.twoWayLeftMatchesSuccess(resp.body));
      } catch (e) {
        dispatch(userProfileActions.twoWayLeftMatchesFailure(e));
      }
    };
  },
  clearUserData() {
    return async (dispatch) => {
      dispatch({
        type: userProfileActionTypes.clearUserData,
      });
    };
  },
  updatePartnerPreferences({ user_id, data, id }) {
    return async (dispatch) => {
      dispatch(userProfileActions.updatePartnerPreferencesLoading(true));
      try {
        let resp = await window.client.updatePartnerPreferences({
          user_id,
          id,
          ...data,
        });
        dispatch(userProfileActions.updatePartnerPreferencesSuccess(resp.body));
        dispatch(userProfileEffects.getUserProfileData({ id: user_id }));
        message.success('Preference updated successfully', 3);
      } catch (err) {
        message.error(
          `Something went wrong - Preferences are not updated ***${err}***`,
          5
        );
        dispatch(userProfileActions.updatePartnerPreferencesFailure(err));
      }
    };
  },
  addUserImage({ id, ...data }) {
    return async (dispatch) => {
      dispatch(userProfileActions.addUserImageLoading(true));
      try {
        let resp = await window.client.addUserImage({ id, ...data });
        dispatch(userProfileActions.addUserImageSuccess(resp.body));
        dispatch(userProfileEffects.getUserProfileData({ id }));
      } catch (e) {
        dispatch(userProfileActions.addUserImageFailure(e));
      }
    };
  },
  deleteUserImage({ id, image_id }) {
    return async (dispatch) => {
      dispatch(userProfileActions.deleteUserImageLoading(true));
      try {
        let resp = await window.client.deleteUserImage({ id, image_id });
        dispatch(userProfileActions.deleteUserImageSuccess(resp.body));
        dispatch(userProfileEffects.getUserProfileData({ id }));
      } catch (e) {
        dispatch(userProfileActions.deleteUserImageFailure(e));
      }
    };
  },
  updateUserProfileImage({ id, images }) {
    return async (dispatch) => {
      dispatch(userProfileActions.updateProfileImageLoading(true));
      try {
        let resp = await window.client.updateProfileImage({ id, images });
        dispatch(userProfileActions.updateProfileImageSuccess(resp.body));
        dispatch(userProfileEffects.getUserProfileData({ id }));
      } catch (e) {
        dispatch(userProfileActions.updateProfileImageFailure(e));
      }
    };
  },
  updatePaidUserDetails({ id, ...data }) {
    return async (dispatch) => {
      dispatch(userProfileActions.updatePaidUserDetailsLoading(true));
      try {
        let resp = await window.client.updatePaidUserPlanDetails({
          id,
          ...data,
        });
        dispatch(userProfileActions.updatePaidUserDetailsSuccess(resp.body));
        dispatch(userProfileEffects.getUserProfileData({ id }));
      } catch (e) {
        dispatch(userProfileActions.updatePaidUserDetailsFailure(e));
      }
    };
  },
  getRequestReceivedCount({ id }) {
    return async (dispatch) => {
      dispatch(userProfileActions.requestReceivedCountLoading(true));
      try {
        let resp = await window.client.requestReceivedCount({ id });
        dispatch(userProfileActions.requestReceivedCountSuccess(resp.body));
      } catch (e) {
        dispatch(userProfileActions.requestReceivedCountFailure(e));
      }
    };
  },
  deleteUserAccount({ id, data }) {
    return async (dispatch) => {
      dispatch(userProfileActions.deleteUserAccountLoading(true));
      try {
        let resp = await window.client.deleteUserProfile({ id, ...data });
        if (resp.status >= 300 || resp.status < 200) {
          dispatch(userProfileActions.deleteUserAccountFailure(resp.body));
          throw resp;
        }
        if (resp.status === 204) {
          dispatch(userProfileActions.deleteUserAccountSuccess());
        }
      } catch (e) {
        dispatch(userProfileActions.deleteUserAccountFailure(e));
      }
    };
  },
  forceSelfieVerification({ id }) {
    return async (dispatch) => {
      dispatch(userProfileActions.forceSelfieVerificationLoading(true));
      try {
        const body = {
          status: 'force_verification',
        };
        let resp = await window.client.forceSelfieVerification({ id, ...body });
        if (resp.status >= 300 || resp.status < 200) throw resp;
        dispatch(
          userProfileActions.forceSelfieVerificationSuccess({ data: resp.body })
        );
        dispatch(userProfileEffects.getUserProfileData({ id }));
      } catch (e) {
        dispatch(userProfileActions.forceSelfieVerificationFailure(e));
      }
    };
  },
  getShortlistedUsers({ id, qs }) {
    return async (dispatch) => {
      dispatch(userProfileActions.shortListedUsersLoading(true));
      try {
        let resp = await window.client.getShortlistedUsers({ id, qs });
        if (resp.status >= 300 || resp.status < 200) throw resp;

        dispatch(
          userProfileActions.shortListedUsersSuccess({ data: resp.body })
        );
      } catch (e) {
        dispatch(userProfileActions.shortListedUsersFailure(e));
      }
    };
  },
  getGovtIdRejectionReason({ id }) {
    return async (dispatch) => {
      dispatch(userProfileActions.shortListedUsersLoading(true));
      try {
        let resp = await window.client.getGovtIdRejectionReason({ id });
        if (resp.status >= 300 || resp.status < 200) throw resp;
        return resp;
      } catch (e) {
        throw e;
      }
    };
  },
};
