import React from 'react';
import { Panel } from './DetailCollapse';

const DetailPanel = ({ key, children, header, ...antdProps }) => (
  <Panel {...antdProps} header={header} key={key} className="detail-panel">
    <div>{children}</div>
  </Panel>
);

export default DetailPanel;
