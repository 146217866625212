/* eslint-disable no-useless-escape */
export const incomeConverter = ({ incomeFrom, incomeTo }) => {
  if (!incomeFrom && incomeTo) {
    return `0 - ${new Intl.NumberFormat('en-IN').format(incomeTo)} LPA`;
  }

  if (incomeFrom && incomeTo) {
    return `${new Intl.NumberFormat('en-IN').format(
      incomeFrom
    )} - ${new Intl.NumberFormat('en-IN').format(incomeTo)} LPA`;
  }

  if (incomeFrom && !incomeTo) {
    return `${new Intl.NumberFormat('en-IN').format(incomeFrom)} - + LPA`;
  }
  return '';
};

export const heightConverter = ({ inches }) => {
  const feet = Math.floor(inches / 12);
  const inch = inches % 12;
  return `${feet} ft ${inch} in`;
};

export const constructReasons = ({
  predefindReasons,
  reasonsCheck = [],
  extraText = '',
}) => {
  const extraReason = extraText.length
    ? `<li style="color: black;">${extraText}</li>`
    : null;

  const reasons =
    reasonsCheck.length > 0
      ? reasonsCheck
          .map((item, index) => {
            if (item) {
              return `<li style="color: black;">${predefindReasons[index].emailContent}</li>`;
            }
            return null;
          })
          .filter((item) => item)
      : null;

  return `<ul>${reasons || ``}${extraReason || ``}</ul>`;
};

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.addEventListener('load', () => resolve(image), false);
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

export const getCroppedImg = async (imageSrc, pixelCrop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const safeArea = Math.max(image.width, image.height) * 2;

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a file
  return new Promise((resolve) => {
    const file = canvas.toDataURL('image/jpg');
    resolve(file);
  });
};

export const isPhoneNumberValid = (phoneNumber) => {
  if (!phoneNumber) return true;
  const phoneno = /^\d{10}$/;
  if (phoneNumber.length > 0 && phoneNumber.match(phoneno)) return true;
  return false;
};

export const isEmailValid = (mail) => {
  if (!mail) return true;
  const emailVal =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (mail.length > 0 && mail.match(emailVal)) return true;
  return false;
};

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const getMonthIndex = (month) => {
  const index = months.indexOf(month);

  return index === -1 ? undefined : `0${index + 1}`.slice(-2);
};
export const getMonthString = (month) => months[month - 1];

export const formatNumber = (number) =>
  number?.toString().length === 1 ? `0${number}` : number;

/**
 * Validates that the input string is a valid date formatted as "yyyy/mm/dd"
 */
export function isValidDate(dateString) {
  // First check for the pattern
  const regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}$/;

  if (!regex_date.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = dateString.split('-');
  const day = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[0], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

/**
 * @description returns range of numbers from start to end (inclusive) as an array
 */
export const range = (start, end) =>
  Array(end - start + 1)
    .fill(null)
    .map((_, idx) => start + idx);
export const resizeArray = (arr, newSize, defaultValue) => [
  ...arr,
  ...Array(Math.max(newSize - arr.length, 0)).fill(defaultValue),
];

/**
 * @description inch -> feet'inches
 */
export const heightFormatter = (value) => {
  const feet = Math.floor(value / 12);
  const inches = value % 12;
  return `${feet} ft ${inches > 0 ? `${inches} in` : ''}`;
};
export const feetToCm = (feet) => {
  const cm = feet * 30.48;
  return cm;
};
export const convertToStartcase = (str) =>
  str
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');

export const cmToIn = (cm) => cm / 2.54;

export const InchesIntoCms = (inches) => {
  const cms = parseInt(inches * 2.54, 10);
  if (!inches) return '';
  return `${cms} cms`;
};

export const heightConverterIntoInches = (str) => {
  const ft = parseInt(str.substring(0, str.indexOf('ft') - 1), 10) * 12;
  const inches = parseInt(
    str.substring(str.indexOf('ft') + 2, str.indexOf('in') - 1),
    10
  );

  return ft + inches;
};

export const heightConverterIntoFtAndInches = ({ inches, format }) => {
  const feet = Math.floor(inches / 12);
  const inch = inches % 12;
  if (format === 'withoutFtAndIn') return `${feet}' ${inch}"`;
  return `${feet} ft ${inch} in`;
};

export const formatIncome = (str) => {
  const strings = str.split(' - ');
  return {
    incomeFrom: `${strings[0]}00000`,
    incomeTo: `${strings[1].slice(0, -4)}00000`,
  };
};
