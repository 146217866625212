export function sales({ get, post }) {
  return {
    getMatches(req) {
      return get(req);
    },
    addMatches({ id, ...body }) {
      return post(`/users/${id}/recommendations/`, body);
    },
  };
}
