import React from 'react';
import { Button as AntDButton } from 'antd';
import { CloseCircleTwoTone } from '@ant-design/icons';
import { uniqueIdGenerator } from '../../../../utilities/util';
import './styles.scss';

const SelectedItems = ({ selectedItems, onDelete }) =>
  selectedItems.length > 0 && (
    <div className="selected-items-container">
      <p>Selected</p>
      <div>
        {selectedItems.map((selectedItem) => (
          <AntDButton
            key={uniqueIdGenerator()}
            shape="round"
            className="delete-item-button"
            icon={<CloseCircleTwoTone twoToneColor="#ff78a5" />}
            onClick={() => onDelete(selectedItem)}
          >
            {selectedItem.displayText}
          </AntDButton>
        ))}
      </div>
    </div>
  );

export default SelectedItems;
