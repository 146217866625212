/* eslint-disable consistent-return */
/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-promise-reject-errors */
import httpClient from '../../../@betterhalf-private/api/client';
import store from '../../../store';
import { userProfileEffects } from '../../../@betterhalf-private/redux/src/userProfile';

const { dispatch } = store;

export const editUserDetails = async (userId, payload) => {
  const response = await httpClient({
    method: 'PATCH',
    url: `users/${userId}/`,
    data: payload,
  });

  await dispatch(userProfileEffects.getUserProfileData({ id: userId }));

  return response.body;
};
export const createUser = async (payload) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await httpClient({
      method: 'POST',
      url: `users/`,
      data: payload,
    });

    dispatch(userProfileEffects.getUserProfileData({ id: response.id }));

    return response.body;
  } catch (error) {
    // console.log(error.response);
    throw error;
  }
};

export const updatePartnerPreferences = async (
  userId,
  partnerPreferenceId,
  payload
) => {
  const response = await httpClient({
    method: 'PATCH',
    url: `users/${userId}/partner-preference/${partnerPreferenceId}/`,
    data: payload,
  });

  dispatch(userProfileEffects.getUserProfileData({ id: userId }));

  return response.body;
};

/* -------------------------------------------------------------------------- */
/*                                 SEARCH APIS                                */
/* -------------------------------------------------------------------------- */

export const searchHinduCommunities = async (searchTerm) => {
  const response = await fetch(
    `https://search.betterhalf.ai/search/community?charlist=${searchTerm}/`
  );
  const searchResults = await response.json();
  return searchResults;
};

export const searchCity = async (searchTerm) => {
  const response = await fetch(
    `https://search.betterhalf.ai/search/city?charlist=${searchTerm}/`
  );
  const searchResults = await response.json();
  return searchResults;
};

export const searchHighestQualification = async (searchTerm) => {
  const response = await fetch(
    `https://search.betterhalf.ai/search/degree?charlist=${searchTerm}/`
  );
  const searchResults = await response.json();
  return searchResults;
};

export const searchCollege = async (searchTerm) => {
  const response = await fetch(
    `https://search.betterhalf.ai/search/college?charlist=${searchTerm}/`
  );
  const searchResults = await response.json();
  return searchResults;
};

export const searchDesignation = async (searchTerm) => {
  const response = await fetch(
    `https://search.betterhalf.ai/search/designation?charlist=${searchTerm}/`
  );
  const searchResults = await response.json();
  return searchResults;
};

export const searchCompany = async (searchTerm) => {
  const response = await fetch(
    `https://search.betterhalf.ai/search/company?charlist=${searchTerm}/`
  );
  const searchResults = await response.json();
  return searchResults;
};

export const updateLocation = async (userId, payload, tag) => {
  const response = await httpClient({
    method: 'PUT',
    url: `users/${userId}/locations/${tag}/`,
    data: payload,
  });

  dispatch(userProfileEffects.getUserProfileData({ id: userId }));
  return response.body;
};

/* ---------------------------------------------------------------------------------------------------------------------- */
/*                                 NO LONGER USING ANY GOOGLE APIS BUT KEEPING IT FOR FUTURE                               */
/* ---------------------------------------------------------------------------------------------------------------------- */

// export const googleAutocomplete = async (searchTerm) =>
//   new Promise((resolve, reject) => {
//     try {
//       new window.google.maps.places.AutocompleteService().getPlacePredictions(
//         { input: searchTerm, types: ['(cities)'] },
//         resolve
//       );
//     } catch (e) {
//       reject(e);
//     }
//   });

// export const getLatLong = async (place_id) => {
//   const response = await fetch(
//     `https://maps.googleapis.com/maps/api/place/details/json?placeid=${place_id}&key=AIzaSyA5Y5DJf3IRjh6wLzWxu05fxdqtrBZPWDQ`
//   );
//   const searchResults = await response.json();
//   return searchResults;
// };

// export const getDetailsOfPlace = async (address) => {
//   const response = await fetch(
//     `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyA5Y5DJf3IRjh6wLzWxu05fxdqtrBZPWDQ`
//   );
//   const searchResults = await response.json();
//   return searchResults;
// };
