import React, { Component } from 'react';
import { Modal, Input, DatePicker, Slider, Select, message } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import Mixpanel from 'mixpanel-browser';

import DebounceSelect from '../../../components/DebounceSelect';
import { userProfileEffects } from '../../../@betterhalf-private/redux/src';
import {
  isEmpty,
  camelToSnake,
  removeUnderscore,
  addUnderscore,
  getLakhsFromCurrencyValue,
  capitalize,
  stringFormatter,
  letterToSnake,
} from '../../../utilities/util';
import { heightConverter } from '../../../utilities/helper';
import { fetchCityList } from '../../../services/searchAPI';
import {
  foodPartnerPreference,
  maritalStatusPreference,
  languagesPreference,
  religionsPreference,
  annualIncome,
  highestDegree,
  smokePreference,
  foodPreference,
  drinkPreference,
  planName,
  sparklePlanName,
  religion,
  motherTongue,
  settleDown,
  hmPlanName,
  kundaliPlanName,
} from '../../../components/DetailsCard/data.enum';
import './styles.scss';

const dateFormat = 'YYYY-MM-DD';

const { Option } = Select;

const { TextArea } = Input;

const styles = {
  modal: {},
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  input: {
    width: '60%',
  },
};

class EditProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editedDetails: [],
      cityValue: [],
      error: '',
      slider: {},
      dropdownList: {},
      numberRange: {},
      planDuration: '',
      sparkleQuantities: '',
      kundaliQuantities: '',
      consumableQty: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { updatePaidUserDetailsSuccess } = this.props;
    if (
      updatePaidUserDetailsSuccess &&
      !prevProps.updatePaidUserDetailsSuccess
    ) {
      message.success('User Plan Details Updated');
    }
  }

  onChangeInputText = (e, key) => {
    const { editedDetails } = this.state;

    this.setState({
      editedDetails: { ...editedDetails, [key]: e.target.value },
    });
  };

  onChangeDuration = (e) => {
    this.setState({ planDuration: e.target.value });
  };

  onChangeConsumableQty = (e) => {
    this.setState({ consumableQty: e.target.value });
  };

  onChangeQuantities = (e) => {
    this.setState({ sparkleQuantities: e.target.value });
  };

  onChangeKundaliQuantities = (e) => {
    this.setState({ kundaliQuantities: e.target.value });
  };

  onDateChange = (dates, dateStrings) => {
    const { editedDetails } = this.state;
    this.setState({
      editedDetails: { ...editedDetails, dateOfBirth: dateStrings },
    });
  };

  handleOkPress = () => {
    const {
      editedDetails,
      planDuration,
      sparkleQuantities,
      kundaliQuantities,
      consumableQty,
    } = this.state;
    const {
      updateUserDetails,
      userData,
      onModalClose,
      updatePartnerPreferences,
      selectedField,
      updatePaidUserDetails,
    } = this.props;
    const data = {};
    if (!isEmpty(editedDetails)) {
      Object.keys(editedDetails).map((item) => {
        data[camelToSnake(item)] = editedDetails[item];
        return null;
      });
      this.setState({ error: '' });
      if (!isEmpty(editedDetails.partner_preference)) {
        Mixpanel.track('UPDATE_PARTNER_PREFERENCE', {
          id: userData.id,
          data: {
            ...data.partner_preference,
            id: userData.partnerPreference.id,
          },
        });
        updatePartnerPreferences({
          user_id: userData.id,
          data: data.partner_preference,
          id: userData.partnerPreference.id,
        });
      } else if (selectedField === 6) {
        if (isEmpty(editedDetails.plan_name)) {
          this.setState({
            error: `Data cannot be accepted because plan name is empty`,
          });
        }
        if (!isEmpty(editedDetails.plan_name)) {
          Mixpanel.track('UPDATE_PLAN_DETAILS', {
            id: userData.id,
            data,
            ...{
              planDuration,
            },
          });

          let duration = '';
          if (
            editedDetails.plan_name.includes('Month') ||
            editedDetails.plan_name.includes('Year')
          ) {
            const splitStr = editedDetails.plan_name.split(' ');
            [duration] = splitStr;
          } else if (editedDetails.plan_name.includes('Lifetime')) {
            duration = '50';
          } else if (editedDetails.plan_name.includes('Trial')) {
            duration = planDuration;
          }

          // setting this to 5 as Premium Trial is only option
          const plan = 5;
          const formattedDuration = `${duration} 00:00:00`;

          updatePaidUserDetails({
            id: userData.id,
            duration: formattedDuration,
            plan,
          });
          this.setState({
            editedDetails: [],
            planDuration: '',
          });
          onModalClose();
        }
      } else if (selectedField === 7) {
        if (isEmpty(editedDetails.plan_name)) {
          this.setState({
            error: `Data cannot be accepted because plan name is empty`,
          });
        }
        if (!isEmpty(editedDetails.plan_name)) {
          Mixpanel.track('UPDATE_SPARKLE_DETAILS', {
            id: userData.id,
            data,
            ...{
              sparkleQuantities,
            },
          });

          if (editedDetails.plan_name.includes('Trial')) {
            // setting this to 5 as Sparkle Trial is only option
            const plan = 24;

            updatePaidUserDetails({
              id: userData.id,
              consumable_quantities: Number(sparkleQuantities),
              plan,
            });
            this.setState({
              editedDetails: [],
              sparkleQuantities: '',
            });
            onModalClose();
          }
        }
      } else if (selectedField === 12) {
        if (isEmpty(editedDetails.plan_name)) {
          this.setState({
            error: `Data cannot be accepted because plan name is empty`,
          });
        }
        if (!isEmpty(editedDetails.plan_name)) {
          Mixpanel.track('UPDATE_HM_DETAILS', {
            id: userData.id,
            data,
            ...{
              consumableQty,
              planDuration,
            },
          });

          if (editedDetails.plan_name.includes('HM Trial')) {
            // setting this to 55 as HM Trial
            const plan = 55;

            updatePaidUserDetails({
              id: userData.id,
              consumable_quantities: Number(consumableQty),
              plan,
              duration: `${planDuration} 00:00:00`,
            });
            this.setState({
              editedDetails: [],
              planDuration: '',
              consumableQty: '',
            });
            onModalClose();
          }
        }
      } else if (selectedField === 14) {
        if (isEmpty(editedDetails.plan_name)) {
          this.setState({
            error: `Data cannot be accepted because plan name is empty`,
          });
        }
        if (!isEmpty(editedDetails.plan_name)) {
          Mixpanel.track('UPDATE_KUNDALI_DETAILS', {
            id: userData.id,
            data,
            ...{
              kundaliQuantities,
            },
          });

          if (editedDetails.plan_name.includes('Kundali Trial')) {
            // setting this to 54 as Kundali Trial
            const plan = 54;

            updatePaidUserDetails({
              id: userData.id,
              consumable_quantities: Number(kundaliQuantities),
              plan,
            });
            this.setState({
              editedDetails: [],
              kundaliQuantities: '',
            });
            onModalClose();
          }
        }
      } else {
        Mixpanel.track('UPDATE_USER_PROFILE', {
          id: userData.id,
          data,
        });
        updateUserDetails({ id: userData.id, data });
      }
      if (selectedField !== 6) {
        this.setState({
          editedDetails: [],
        });
        onModalClose();
      }
    } else {
      this.setState({
        error:
          'Sorry! You need to change some fields to execute this operation.',
      });
    }
  };

  onSliderChange = (value, item) => {
    const { slider } = this.state;
    this.setState({ slider: { ...slider, [item.key]: value } });
  };

  onAfterSliderChange = ({ value, fieldsToBeSubmitted }) => {
    const { editedDetails } = this.state;
    const { selectedField } = this.props;
    if (selectedField === 5 && Array.isArray(fieldsToBeSubmitted)) {
      this.setState({
        editedDetails: {
          ...editedDetails,
          partner_preference: {
            ...editedDetails.partner_preference,
            [fieldsToBeSubmitted[0]]: value[0],
            [fieldsToBeSubmitted[1]]: value[1],
          },
        },
      });
    } else {
      this.setState({
        editedDetails: {
          ...editedDetails,
          [fieldsToBeSubmitted]: value,
        },
      });
    }
  };

  tipFormatter = (value, { key }) => {
    if (key === 'heightPreference' || key === 'height') {
      return heightConverter({ inches: value });
    }
    if (key === 'annualIncomePreference') {
      return `${new Intl.NumberFormat('en-IN').format(value)} LPA`;
    }
    return value;
  };

  getDropDownChildren = ({ key }) => {
    const children = [];
    if (key === 'foodPartnerPreference') {
      foodPartnerPreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'maritalStatusPreference') {
      maritalStatusPreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'languagesPreference') {
      languagesPreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'religionsPreference') {
      religionsPreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'annualIncome') {
      annualIncome.map((i) => {
        if (i === '50 - 100 LPA') {
          children.push(<Option key={i}>50+ LPA</Option>);
        } else {
          children.push(<Option key={i}>{i}</Option>);
        }
        return null;
      });
    } else if (key === 'highestDegree') {
      highestDegree.map((i) => {
        children.push(
          <Option key={i}>
            {stringFormatter({ text: i, type: 'string' })}
          </Option>
        );
        return null;
      });
    } else if (key === 'smokePreference') {
      smokePreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'drinkPreference') {
      drinkPreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'foodPreference') {
      foodPreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'settleDown') {
      settleDown.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'planName') {
      planName.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'sparklePlanName') {
      sparklePlanName.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'religion') {
      religion.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'motherTongue') {
      motherTongue.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'hmPlanName') {
      hmPlanName.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (key === 'kundaliPlanName') {
      kundaliPlanName.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    }
    return children;
  };

  handleDropDownChange = (
    value,
    { fieldsToBeSubmitted, type, submissionFormat }
  ) => {
    const { dropdownList, editedDetails } = this.state;
    if (type === 'list' || type === 'debouncedList') {
      if (value.includes(`Doesn't matter`)) {
        this.setState({
          dropdownList: {
            ...dropdownList,
            [fieldsToBeSubmitted]: [],
          },
          editedDetails: {
            ...editedDetails,
            partner_preference: {
              ...editedDetails.partner_preference,
              [fieldsToBeSubmitted]: [],
            },
          },
        });
        return null;
      }
      this.setState({
        dropdownList: {
          ...dropdownList,
          [fieldsToBeSubmitted]: Array.isArray(value) ? value : [value],
        },
        editedDetails: {
          ...editedDetails,
          partner_preference: {
            ...editedDetails.partner_preference,
            [fieldsToBeSubmitted]: Array.isArray(value)
              ? value.map((item) => {
                  if (fieldsToBeSubmitted === 'food_preference') {
                    return addUnderscore(item.toLowerCase());
                  }

                  if (fieldsToBeSubmitted === 'cities') {
                    return item;
                  }
                  return addUnderscore(item.toLowerCase());
                })
              : [letterToSnake(value)],
          },
        },
      });
      return null;
    }
    if (type === 'selector') {
      this.setState({
        dropdownList: {
          ...dropdownList,
          [fieldsToBeSubmitted]:
            submissionFormat === 'LowerCase' ? value.toLowerCase() : value,
        },
        editedDetails: {
          ...editedDetails,
          [fieldsToBeSubmitted]:
            submissionFormat === 'LowerCase' ? value.toLowerCase() : value,
        },
      });
      return null;
    }
    if (value === '50+ LPA') {
      this.setState({
        editedDetails: {
          [fieldsToBeSubmitted[0]]: 5000000,
          [fieldsToBeSubmitted[1]]: 10000000,
        },
        numberRange: {
          [fieldsToBeSubmitted[0]]: new Intl.NumberFormat('en-IN').format(
            5000000
          ),
          [fieldsToBeSubmitted[1]]: new Intl.NumberFormat('en-IN').format(
            10000000
          ),
        },
      });
    } else {
      const val1 = Number(`${value.substring(0, value.indexOf('-') - 1)}00000`);
      const val2 = Number(
        `${value.substring(
          value.indexOf('-') + 2,
          value.indexOf('L') - 1
        )}00000`
      );

      this.setState({
        editedDetails: {
          [fieldsToBeSubmitted[0]]: val1,
          [fieldsToBeSubmitted[1]]: val2,
        },
        numberRange: {
          [fieldsToBeSubmitted[0]]: new Intl.NumberFormat('en-IN').format(val1),
          [fieldsToBeSubmitted[1]]: new Intl.NumberFormat('en-IN').format(val2),
        },
      });
    }

    return null;
  };

  getDefaultValues = (list) => list.map((i) => removeUnderscore(i));

  getDropDownList = (item) => {
    const { userData } = this.props;
    const { dropdownList, editedDetails, numberRange } = this.state;
    if (item.type === 'list' || item.type === 'debouncedList') {
      if (!isEmpty(dropdownList[item.fieldsToBeSubmitted])) {
        return [...dropdownList[item.fieldsToBeSubmitted]];
      }
      if (!isEmpty(editedDetails.partner_preference)) {
        if (item.fieldsToBeSubmitted in editedDetails.partner_preference) {
          return editedDetails.partner_preference[item.fieldsToBeSubmitted];
        }
        return this.getDefaultValues(userData[item.key]);
      }
      return this.getDefaultValues(userData[item.key]);
    }
    if (item.type === 'numberRange') {
      if (!isEmpty(numberRange)) {
        if (
          numberRange.income_from === '50,00,000' &&
          numberRange.income_to === '1,00,00,000'
        ) {
          return `50+ LPA`;
        }
        return `${getLakhsFromCurrencyValue(
          numberRange.income_from
        )} - ${getLakhsFromCurrencyValue(numberRange.income_to)} LPA`;
      }
      const valCheck1 = userData[item.key].substring(
        0,
        userData[item.key].indexOf('-') - 1
      );
      const valCheck2 = userData[item.key].substring(
        userData[item.key].indexOf('-') + 2,
        userData[item.key].indexOf('L') - 1
      );
      if (valCheck1 === '50,00,000' && valCheck2 === '1,00,00,000') {
        return `50+ LPA`;
      }
      return `${getLakhsFromCurrencyValue(
        valCheck1
      )} - ${getLakhsFromCurrencyValue(valCheck2)} LPA`;
    }
    if (item.type === 'selector') {
      if (!isEmpty(dropdownList[item.fieldsToBeSubmitted])) {
        return capitalize(dropdownList[item.fieldsToBeSubmitted]);
      }
      return capitalize(userData[item.key]);
    }
    return null;
  };

  getDropDownMode = ({ key }) => {
    if (
      key === 'annualIncome' ||
      key === 'highestDegree' ||
      key === 'smokePreference' ||
      key === 'drinkPreference' ||
      key === 'foodPreference' ||
      key === 'settleDown' ||
      key === 'planName' ||
      key === 'religion' ||
      key === 'motherTongue' ||
      key === 'sparklePlanName' ||
      key === 'hmPlanName' ||
      key === 'kundaliPlanName'
    )
      return 'default';
    return 'multiple';
  };

  validateOk = () => {
    const {
      editedDetails,
      planDuration,
      sparkleQuantities,
      consumableQty,
      kundaliQuantities,
    } = this.state;
    if (editedDetails.about) {
      return { disabled: false };
    }

    if (!isEmpty(editedDetails.plan_name)) {
      if (
        editedDetails.plan_name.includes('Premium Trial') &&
        !isEmpty(planDuration)
      )
        return { disabled: false };

      if (
        editedDetails.plan_name.includes('Sparkle Trial') &&
        !isEmpty(sparkleQuantities)
      )
        return { disabled: false };

      if (
        editedDetails.plan_name.includes('HM Trial') &&
        !isEmpty(consumableQty) &&
        !isEmpty(planDuration)
      )
        return { disabled: false };
      if (
        editedDetails.plan_name.includes('Kundali Trial') &&
        !isEmpty(kundaliQuantities)
      )
        return { disabled: false };
    }

    return { disabled: true };
  };

  getTitle = (selectedField) => {
    if (selectedField === 6) {
      return 'Credit Premium Trial';
    }

    if (selectedField === 5) {
      return 'Edit Partner Preference';
    }
    if (selectedField === 7) {
      return 'Credit Sparkle Trial';
    }
    if (selectedField === 12) {
      return 'Credit HM Trial';
    }
    if (selectedField === 14) {
      return 'Credit Kundali Trial';
    }
    return 'Edit';
  };

  render() {
    const { fields, onModalClose, userData, visible, selectedField } =
      this.props;
    const {
      editedDetails,
      slider,
      error,
      planDuration,
      sparkleQuantities,
      kundaliQuantities,
      cityValue,
      consumableQty,
    } = this.state;
    const { partnerPreference } = userData;

    const title = this.getTitle(selectedField);

    const Fields = fields.map((item) => {
      if (item.belongsTo === 'paymentDetails') {
        return (
          <div key={`${item.key}_${item.text}`}>
            <div style={styles.card}>
              <h4>{item.text}</h4>
              <Select
                defaultActiveFirstOption={false}
                mode={this.getDropDownMode({ key: item.key })}
                placeholder="Please Select..."
                style={{ width: '60%', height: '25%' }}
                onChange={(value) => this.handleDropDownChange(value, item)}
              >
                {this.getDropDownChildren(item)}
              </Select>
            </div>
            {editedDetails.plan_name &&
              editedDetails.plan_name.includes('Trial') && (
                <div style={styles.card}>
                  <h4>Plan Duration</h4>
                  <Input
                    allowClear
                    value={planDuration}
                    style={styles.input}
                    disabled={!item.editable}
                    onChange={this.onChangeDuration}
                    placeholder="Enter a plan duration in days"
                  />
                </div>
              )}
          </div>
        );
      }
      if (item.belongsTo === 'sparkleDetails') {
        return (
          <div key={`${item.key}_${item.text}`}>
            <div style={styles.card}>
              <h4>{item.text}</h4>
              <Select
                defaultActiveFirstOption={false}
                mode={this.getDropDownMode({ key: item.key })}
                placeholder="Please Select..."
                style={{ width: '60%', height: '25%' }}
                onChange={(value) => this.handleDropDownChange(value, item)}
              >
                {this.getDropDownChildren(item)}
              </Select>
            </div>
            {editedDetails.plan_name &&
              editedDetails.plan_name.includes('Trial') && (
                <div style={styles.card}>
                  <h4>Sparkle Quantities</h4>
                  <Input
                    allowClear
                    value={sparkleQuantities}
                    style={styles.input}
                    disabled={!item.editable}
                    onChange={this.onChangeQuantities}
                    placeholder="Enter a quantities in numbers"
                  />
                </div>
              )}
          </div>
        );
      }
      if (item.belongsTo === 'hmDetails') {
        return (
          <div key={`${item.key}_${item.text}`}>
            <div style={styles.card}>
              <h4>{item.text}</h4>
              <Select
                defaultActiveFirstOption={false}
                mode={this.getDropDownMode({ key: item.key })}
                placeholder="Please Select..."
                style={{ width: '60%', height: '25%' }}
                onChange={(value) => this.handleDropDownChange(value, item)}
              >
                {this.getDropDownChildren(item)}
              </Select>
            </div>
            {editedDetails.plan_name &&
              editedDetails.plan_name.includes('HM Trial') && (
                <>
                  <div style={styles.card}>
                    <h4>Plan Duration</h4>
                    <Input
                      allowClear
                      value={planDuration}
                      style={styles.input}
                      disabled={!item.editable}
                      onChange={this.onChangeDuration}
                      placeholder="Enter a plan duration in days"
                    />
                  </div>
                  <div style={styles.card}>
                    <h4>Consumable Quantity</h4>
                    <Input
                      allowClear
                      value={consumableQty}
                      style={styles.input}
                      disabled={!item.editable}
                      onChange={this.onChangeConsumableQty}
                      placeholder="Enter quantities in numbers"
                    />
                  </div>
                </>
              )}
          </div>
        );
      }
      if (item.belongsTo === 'kundaliDetails') {
        return (
          <div key={`${item.key}_${item.text}`}>
            <div style={styles.card}>
              <h4>{item.text}</h4>
              <Select
                defaultActiveFirstOption={false}
                mode={this.getDropDownMode({ key: item.key })}
                placeholder="Please Select..."
                style={{ width: '60%', height: '25%' }}
                onChange={(value) => this.handleDropDownChange(value, item)}
              >
                {this.getDropDownChildren(item)}
              </Select>
            </div>
            {editedDetails.plan_name &&
              editedDetails.plan_name.includes('Kundali Trial') && (
                <div style={styles.card}>
                  <h4>Kundali Quantities</h4>
                  <Input
                    allowClear
                    value={kundaliQuantities}
                    style={styles.input}
                    disabled={!item.editable}
                    onChange={this.onChangeKundaliQuantities}
                    placeholder="Enter a quantities in numbers"
                  />
                </div>
              )}
          </div>
        );
      }
      if (item.editable) {
        return (
          <div key={`${item.key}_${item.text}`} style={styles.card}>
            <h4>{item.text}</h4>
            {item.editComponent === 'input' && (
              <Input
                allowClear
                style={styles.input}
                disabled={!item.editable}
                onChange={(e) => this.onChangeInputText(e, item.key)}
                placeholder={userData[item.key]}
              />
            )}
            {item.editComponent === 'calender' && (
              <DatePicker
                style={{ width: '60%' }}
                defaultValue={moment(userData[item.key], dateFormat)}
                format={dateFormat}
                onChange={this.onDateChange}
              />
            )}
            {item.editComponent.includes('slider') && (
              <div style={{ width: '60%' }}>
                <Slider
                  range={item.editComponent === 'dualslider'}
                  step={item.steps}
                  min={item.min}
                  max={item.max}
                  tipFormatter={(value) => this.tipFormatter(value, item)}
                  value={
                    isEmpty(slider[item.key]) && item.type === 'numberRange'
                      ? [
                          partnerPreference[item.fieldsToBeSubmitted[0]],
                          partnerPreference[item.fieldsToBeSubmitted[1]],
                        ]
                      : isEmpty(slider[item.key]) && item.type === 'slider'
                      ? userData[item.key]
                      : slider[item.key]
                  }
                  onChange={(value) => this.onSliderChange(value, item)}
                  onAfterChange={(value) =>
                    this.onAfterSliderChange({
                      value,
                      fieldsToBeSubmitted: item.fieldsToBeSubmitted,
                    })
                  }
                />
              </div>
            )}
            {item.editComponent === 'dropdown' && (
              <Select
                mode={this.getDropDownMode({ key: item.key })}
                placeholder="Please Select..."
                style={{ width: '60%', height: '25%' }}
                value={
                  selectedField === 6
                    ? isEmpty(editedDetails.plan_name)
                      ? undefined
                      : this.getDropDownList(item)
                    : this.getDropDownList(item)
                }
                onChange={(value) => this.handleDropDownChange(value, item)}
              >
                {this.getDropDownChildren(item)}
              </Select>
            )}
            {item.editComponent === 'search' && (
              <DebounceSelect
                mode="multiple"
                value={cityValue}
                allowClear
                size="large"
                style={{ width: '60%' }}
                placeholder="Please select..."
                fetchOptions={fetchCityList}
                onChange={(newValue) => {
                  this.setState({
                    cityValue: newValue,
                  });
                  this.handleDropDownChange(newValue, item);
                }}
              />
            )}
            {item.editComponent === 'LongInput' && (
              <TextArea
                defaultValue={userData[item.key]}
                disabled={!item.editable}
                onChange={(e) => this.onChangeInputText(e, item.key)}
                style={{ marginTop: 20, marginLeft: 20 }}
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            )}
          </div>
        );
      }
      return null;
    });
    return (
      <Modal
        visible={visible}
        destroyOnClose
        title={title}
        onCancel={() => {
          this.setState({ editedDetails: [] });
          onModalClose();
        }}
        onOk={() => this.handleOkPress()}
        style={styles.modal}
        okButtonProps={this.validateOk()}
      >
        {Fields}
        <h5 style={{ color: '#ff5b91' }}>{isEmpty(error) ? null : error}</h5>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  updatePaidUserDetailsSuccess:
    state.updateUserData.paidUserDetailsUpdateSuccess,
});

export default connect(mapStateToProps, {
  updateUserDetails: userProfileEffects.updateUserDetails,
  updatePartnerPreferences: userProfileEffects.updatePartnerPreferences,
  updatePaidUserDetails: userProfileEffects.updatePaidUserDetails,
})(EditProfileModal);
