import React, { useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import OptionPill from '../../../../components/OptionPill';
import Button from '../../../../components/Button';
import SearchList from '../../SearchList';
import { LOCATION_LIST } from '../../config';
import { searchCity } from '../../api';
import SelectedItems from '../SelectedItems';
import './styles.scss';

const LocationSearch = ({ selectedLocationsList, setSelectedLocation }) => {
  const [filteredLocations, setFilteredLocations] = useState(LOCATION_LIST);
  const [showSearchList, setShowSearchList] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  /* This is the to store the most current searchInput which will be used inside useMemo */
  const searchInputRef = useRef('');

  /* -------------------------------------------------------------------------- */
  /*                               SEARCH LIST HANDLERS                         */
  /* -------------------------------------------------------------------------- */

  const debouncedSearch = useMemo(
    () =>
      debounce(async () => {
        if (searchInputRef.current !== '') {
          const searchResults = await searchCity(searchInputRef.current);
          const transformedSearchResults =
            searchResults?.result?.map((searchResult) => ({
              ...searchResult,
              value: searchResult.city,
              displayText: searchResult.city,
            })) || [];
          setFilteredLocations(transformedSearchResults);
        }
      }, 250),
    []
  );

  const handleSearch = (searchTerm) => {
    const trimmedSearchTerm = searchTerm.trim();
    setSearchInput(searchTerm);
    searchInputRef.current = trimmedSearchTerm;

    if (trimmedSearchTerm === '') {
      setFilteredLocations(LOCATION_LIST);
    } else {
      debouncedSearch();
    }
  };

  const handleSelectLocationFromSearchList = (location) => {
    setSelectedLocation(location);
  };

  const handleOnBack = () => {
    setShowSearchList(false);
  };

  return showSearchList ? (
    <SearchList
      inputPlaceholder="Search your location"
      searchInput={searchInput}
      onChange={handleSearch}
      data={filteredLocations}
      onSelect={handleSelectLocationFromSearchList}
      selectedItems={selectedLocationsList}
      onBack={handleOnBack}
      onDelete={setSelectedLocation}
      showBtns={{ add: false, ctas: false }}
    />
  ) : (
    <>
      <section className="community-container">
        {filteredLocations.map((location) => (
          <OptionPill
            key={location.id}
            onClick={() => setSelectedLocation(location)}
            className="community-pill"
            selected={selectedLocationsList.some(
              (locationItem) => locationItem.value === location.value
            )}
          >
            {location.displayText}
          </OptionPill>
        ))}

        <Button
          isLink
          className="community-pill"
          onClick={() => setShowSearchList(true)}
        >
          See more
        </Button>
      </section>
      {selectedLocationsList && (
        <SelectedItems
          selectedItems={selectedLocationsList}
          onDelete={setSelectedLocation}
        />
      )}
    </>
  );
};

export default LocationSearch;
