import React, { useMemo, useState } from 'react';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { FileImageOutlined } from '@ant-design/icons';
import Collapse from '../../Details/DetailCollapse';
import DetailPanel from '../../Details/DetailPanel';
import DetailHeader from '../../Details/DetailHeader';
import FoodPreference from '../FoodPreference';
import HeightSlider from '../MustHave/HeightSlider';
import LocationSearch from '../LocationSearch';
import MotherTongue from '../../PrePersonalDetails/MotherTongue';
import {
  FOOD_PREFERENCE_LIST,
  MOTHER_TONGUE_LIST,
  LOCATION_LIST,
} from '../../config';
import { updatePartnerPreferences, editUserDetails } from '../../api';
import { uniqueIdGenerator } from '../../../../utilities/util';
import { handleSelectWithAny, heightFormatter } from '../helper';
import { useUserData } from '../../../UserProfile/CompleteProfile/UserDataContext';
import { useCollapse } from '../../hooks';
import Button from '../../../../components/Button';

const [ANY_FOOD_PREFERENCE] = FOOD_PREFERENCE_LIST.slice(-1);
const [ANY_LANGUAGE] = MOTHER_TONGUE_LIST.slice(-1);
const ACCORDION_KEYS = [
  'food-preference',
  'annual-income',
  'language',
  'location',
];

const NiceToHave = ({
  closeMainPanel,
  navigateToAiFromPreferences,
  closeCompleteProfile,
}) => {
  const { id: userId, partnerPreference, status, images = [] } = useUserData();
  const adminData = useSelector((state) => state.adminData.data);

  const { activeKey, closePanel, jumpToStep } = useCollapse(
    ACCORDION_KEYS,
    ACCORDION_KEYS[0],
    status === 'on_boarding_completed'
  );

  const {
    id: partnerPreferenceId = null,
    food_preference = [],
    languages: languagesFromPreferences = [],
    cities = [],
    marital_status = [],
    height_from = '',
    height_to = '',
  } = partnerPreference || {};

  const [foodPreferences, setFoodPreferences] = useState(() => {
    /* If the existing food preferences from preferences is empty, it means
    'Any' food preference must be pre-selected */
    if (food_preference.length === 0) {
      return [ANY_FOOD_PREFERENCE];
    }
    return FOOD_PREFERENCE_LIST.filter((foodPreferenceItem) =>
      food_preference.includes(foodPreferenceItem.value)
    );
  });
  const [languages, setLanguages] = useState(() => {
    /* If the existing languages from preferences is empty, it means
    'Any' language must be pre-selected */
    if (languagesFromPreferences.length === 0) {
      return [ANY_LANGUAGE];
    }
    return MOTHER_TONGUE_LIST.filter((languageItem) =>
      languagesFromPreferences.includes(languageItem.value)
    );
  });

  const [locations, setLocations] = useState(() =>
    cities.map((city) => {
      const homeTownFound = LOCATION_LIST.find(
        (homeTown) => homeTown.value === city
      );
      if (homeTownFound) {
        return homeTownFound;
      }
      return { id: uniqueIdGenerator(), value: city, displayText: city };
    })
  );

  const isDisabled = [
    foodPreferences,
    languages,
    locations,
    marital_status,
  ].some((preferenceItem) => preferenceItem.length === 0);

  const handleSelectFoodPreferences = async (foodPreference) => {
    const updatedFoodPreferences = handleSelectWithAny({
      selectedPreference: foodPreference,
      preferenceState: foodPreferences,
      ANY_ITEM: ANY_FOOD_PREFERENCE,
    });
    setFoodPreferences(updatedFoodPreferences);

    let payload;

    /* If "Any" is the selected food preference, send an empty array in the payload.
      The below logic works because, if "Any" is the selected food preference, then
      the array will always contain ONLY "Any"
     */
    if (updatedFoodPreferences[0].value === 'any') {
      payload = {
        food_preference: [],
      };
    } else {
      payload = {
        food_preference: updatedFoodPreferences.map(
          (foodPreferenceItem) => foodPreferenceItem.value
        ),
      };
    }

    await updatePartnerPreferences(userId, partnerPreferenceId, payload);
  };

  const handleSelectLanguages = async (language) => {
    const updatedLanguages = handleSelectWithAny({
      selectedPreference: language,
      preferenceState: languages,
      ANY_ITEM: ANY_LANGUAGE,
    });
    setLanguages(updatedLanguages);

    let payload;

    /* If "Any" is the selected language, send an empty array in the payload.
      The below logic works because, if "Any" is the selected language, then
      the array will always contain ONLY "Any"
     */
    if (updatedLanguages[0].value === 'any') {
      payload = {
        languages: [],
      };
    } else {
      payload = {
        languages: updatedLanguages.map((languageItem) => languageItem.value),
      };
    }

    await updatePartnerPreferences(userId, partnerPreferenceId, payload);
  };

  const handleSelectedLocation = async (location) => {
    let updatedLocations = [];

    if (
      !locations.some((locationItem) => locationItem.value === location.value)
    ) {
      /* If the location doesn't already exist in state, append it */
      updatedLocations = [...locations, location];
    } else {
      /* If the location already exists in state, remove it */
      updatedLocations = locations.filter(
        (locationItem) => locationItem.value !== location.value
      );
    }

    setLocations(updatedLocations);

    const payload = {
      cities: updatedLocations.map((locationItem) => locationItem.value),
    };
    await updatePartnerPreferences(userId, partnerPreferenceId, payload);
  };

  const handleComplete = async () => {
    if (status === 'personal_details' || status === 'partner_preferences') {
      if (images.length === 0) {
        message.error({
          content: 'User has no images uploaded. Click here to upload.',
          onClick: () => {
            navigateToAiFromPreferences();
            message.destroy('upload-image-toast');
          },
          style: { cursor: 'pointer' },
          duration: 7,
          icon: <FileImageOutlined />,
          key: 'upload-image-toast',
        });
      } else {
        const payload = {
          status: 'on_boarding_completed',
          on_boarded_by: adminData.id,
        };
        await editUserDetails(userId, payload);
        closePanel(); // Closes the expanded panel inside Nice to have
        closeMainPanel(); // Closes the Partner Preference panel
        closeCompleteProfile();
      }
    } else {
      closePanel(); // Closes the expanded panel inside Nice to have
      closeMainPanel(); // Closes the Partner Preference panel
      closeCompleteProfile();
    }
  };

  const handleOnChange = (key) => {
    jumpToStep(key);
  };

  const headerValueForFoodPreferences = useMemo(
    () =>
      foodPreferences
        .map((foodPreferenceItem) => foodPreferenceItem.displayText)
        .join(', '),
    [foodPreferences]
  );

  const headerValueForLanguages = useMemo(
    () => languages.map((languageItem) => languageItem.displayText).join(', '),
    [languages]
  );

  const headerValueForLocations = useMemo(
    () => locations.map((locationItem) => locationItem.displayText).join(', '),
    [locations]
  );

  const headerValueForHeight = useMemo(
    () => `${heightFormatter(height_from)} - ${heightFormatter(height_to)}`,
    [height_from, height_to]
  );

  return (
    <>
      <Collapse
        onChange={handleOnChange}
        activeKey={activeKey}
        destroyInactivePanel
      >
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Food preference"
              value={headerValueForFoodPreferences}
              placeholder="Eg: Vegetarian"
              isActive={activeKey === ACCORDION_KEYS[0]}
            />
          }
          key={ACCORDION_KEYS[0]}
        >
          <FoodPreference
            foodPreferences={foodPreferences}
            setFoodPreferences={handleSelectFoodPreferences}
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Height"
              value={headerValueForHeight}
              placeholder="Eg: 5 ft - 5 ft 5 inches"
              isActive={activeKey === ACCORDION_KEYS[3]}
            />
          }
          key={ACCORDION_KEYS[3]}
        >
          <HeightSlider onSave={closePanel} />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Language"
              value={headerValueForLanguages}
              placeholder="Eg: Hindi"
              isActive={activeKey === ACCORDION_KEYS[2]}
            />
          }
          key={ACCORDION_KEYS[2]}
        >
          <MotherTongue
            selectedMotherTongueList={languages}
            setSelectedMotherTongueList={handleSelectLanguages}
            section="partner-preference"
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Location"
              value={headerValueForLocations}
              placeholder="Eg: Mumbai"
              isActive={activeKey === ACCORDION_KEYS[3]}
            />
          }
          key={ACCORDION_KEYS[3]}
        >
          <LocationSearch
            selectedLocationsList={locations}
            setSelectedLocation={handleSelectedLocation}
          />
        </DetailPanel>
      </Collapse>
      <div className="partner-preference-button-container">
        <Button
          className="partner-preference-button"
          disabled={isDisabled}
          onClick={handleComplete}
        >
          Complete
        </Button>
      </div>
    </>
  );
};

export default NiceToHave;
