import React from 'react';

const HMStatusIcon = ({ size = 12, color = '#FF5B91' }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <svg width={size} height={size} viewBox="0 0 8 8" fill="none">
      <circle cx="4" cy="4" r="3.5" stroke={color} />
      <circle cx="4" cy="4" r="1" fill={color} />
    </svg>
  </div>
);

export default HMStatusIcon;
