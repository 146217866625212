import React from 'react';
import { Layout, Menu } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  SearchOutlined,
  UsergroupAddOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  createFromIconfontCN,
  UserAddOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import Cookies from 'js-cookie';
import Mixpanel from 'mixpanel-browser';

import {
  adminEffects,
  listOfSelfieVerificationEffects,
} from '../../@betterhalf-private/redux/src';
import { isEmpty } from '../../utilities/util';
import SearchUser from '../SearchUser';
import SelfieVerification from '../SelfieVerification';
import GovtIdVerification from '../GovtIdVerification';
import HmSearchUser from '../HmSearchUser';
import AdminHeader from '../../components/AdminHeader';
import Logo from '../../assets/logo.png';
import LogoSmall from '../../assets/logo_small.png';
// import Dashboard from "../containers/Dashboard";
import './Main.less';
import CreateProfile from '../CreateProfile';
import { listOfGovtIdVerificationEffects } from '../../@betterhalf-private/redux/src/govtIdVerification';

const { Sider, Content } = Layout;

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2161710_sqvituvwiuj.js',
});

const pages = {
  search: {
    index: '1',
    title: 'User Profile',
    name: 'search',
  },
  selfieVerification: {
    index: '2',
    title: 'Selfie Verification',
    name: 'selfieVerification',
  },
  hm: {
    index: '3',
    title: 'Human Matchmaker',
    name: 'humanMatchmaker',
  },
};

class Main extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { page },
      },
    } = props;

    this.state = {
      collapsed: true,
      selectedMenu: pages[page].name,
      title: pages[page].title,
      adminImage: null,
      defaultSelectedKeys: [pages[page].index],
    };
  }

  async componentDidMount() {
    const { getAdminProfileData } = this.props;
    const adminImage = Cookies.get('adminImage') || null;
    const userData = Cookies.get('userDate') || null;
    if (!adminImage) {
      try {
        const {
          id,
          images,
          first_name: firstName,
          last_name: lastName,
          email,
        } = await getAdminProfileData();
        Cookies.set('adminImage', images[0]);
        Cookies.set(
          'userData',
          JSON.stringify({ id, firstName, lastName, email, avatar: images[0] })
        );
        Mixpanel.identify(id);
        Mixpanel.people.set({
          $email: email,
          $first_name: firstName,
          $last_name: lastName,
          $avatar: images[0],
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }

    const parsedUserData = JSON.parse(userData);
    if (parsedUserData && adminImage) {
      const { id, firstName, lastName, email, avatar } = parsedUserData;
      Mixpanel.identify(id);
      Mixpanel.people.set({
        $email: email,
        $first_name: firstName,
        $last_name: lastName,
        $avatar: avatar,
      });
    }
    this.setState({
      adminImage,
    });
  }

  toggle = () => {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed,
    });
  };

  selectMenu = (e) => {
    switch (e.item.props.name) {
      case 'search':
        this.setState({ selectedMenu: 'search', title: 'User search' });
        break;
      case 'dashboard':
        this.setState({ selectedMenu: 'dashboard', title: 'Dashboard' });
        break;
      case 'selfieVerification':
        this.setState({
          selectedMenu: 'selfieVerification',
          title: 'Selfie verification',
        });

        break;
      case 'govtVerification':
        this.setState({
          selectedMenu: 'govtVerification',
          title: 'Government Id verification',
        });

        break;
      case 'humanMatchmaker':
        this.setState({
          selectedMenu: 'humanMatchmaker',
          title: 'Human matchmaker',
        });
        break;
      case 'createProfile':
        this.setState({
          selectedMenu: 'createProfile',
          title: 'Create Profile',
        });
        break;
      default:
        this.setState({ selectedMenu: 'search' });
        break;
    }
  };

  render() {
    const { collapsed, selectedMenu, title, adminImage, defaultSelectedKeys } =
      this.state;
    const { adminData } = this.props;
    return (
      <Layout className="Main">
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo-block">
            <img alt="" src={collapsed ? LogoSmall : Logo} className="logo" />
          </div>

          <Menu
            key="slider"
            theme="dark"
            mode="inline"
            defaultSelectedKeys={defaultSelectedKeys}
          >
            <Menu.Item onClick={this.selectMenu} key="1" name="search">
              <SearchOutlined />
              <span>Search</span>
            </Menu.Item>
            <Menu.Item
              onClick={this.selectMenu}
              key="2"
              name="selfieVerification"
            >
              <IconFont type="iconselfie" />
              <span> Selfie verification</span>
            </Menu.Item>
            <Menu.Item
              onClick={this.selectMenu}
              key="3"
              name="govtVerification"
            >
              <IdcardOutlined />
              <span> Government ID verification</span>
            </Menu.Item>
            <Menu.Item onClick={this.selectMenu} key="4" name="humanMatchmaker">
              <UsergroupAddOutlined />
              <span> Human Matchmaker</span>
            </Menu.Item>
            <Menu.Item onClick={this.selectMenu} key="5" name="createProfile">
              <UserAddOutlined />
              <span> Create Profile</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <AdminHeader
            title={title}
            backIcon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onBack={this.toggle}
            adminPic={
              adminImage !== null
                ? adminImage
                : !isEmpty(adminData)
                ? adminData.images[0]
                : null
            }
          />

          <Content className="Content" style={{ marginTop: '40px' }}>
            {selectedMenu === 'search' && <SearchUser />}
            {selectedMenu === 'selfieVerification' && <SelfieVerification />}
            {selectedMenu === 'govtVerification' && <GovtIdVerification />}
            {selectedMenu === 'humanMatchmaker' && <HmSearchUser />}
            {selectedMenu === 'createProfile' && <CreateProfile />}
            {/* {selectedMenu === "dashboard" && <Dashboard />} */}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  adminData: state.adminData.data,
  adminDataLoadingStatus: state.adminData.isLoading,
});

export default connect(mapStateToProps, {
  getAdminProfileData: adminEffects.getAdminProfileData,
  getListOfSelfieVerification:
    listOfSelfieVerificationEffects.getListOfSelfieVerification,
  getListOfGovtIdVerification:
    listOfGovtIdVerificationEffects.getListOfGovtIdVerification,
})(withRouter(Main));
