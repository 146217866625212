import React, { Component } from 'react';
import { Modal, Table, Pagination, Button } from 'antd';
import { connect } from 'react-redux';
import Mixpanel from 'mixpanel-browser';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { columns } from './columns';
import { isEmpty } from '../../../utilities/util';
import { userProfileEffects } from '../../../@betterhalf-private/redux/src';

class ShowMatchesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }

  handleOkPress = () => {
    const { onModalClose } = this.props;
    this.setState({
      currentPage: 1,
    });
    onModalClose();
  };

  onChangePage = (page) => {
    this.setState({
      currentPage: page,
    });
  };

  itemRender = (current, type, originalElement) => {
    const { availableMatches, id, fetchAvailableMatches } = this.props;
    const { isLoading } = availableMatches;
    const { next, previous } = availableMatches.data;
    if (type === 'prev') {
      return (
        <Button
          disabled={isEmpty(previous) || isLoading}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 10,
          }}
          onClick={
            isEmpty(previous)
              ? () => {}
              : () => {
                  Mixpanel.track('SHOW_RECOMMENDATIONS_PREVIOUS');
                  fetchAvailableMatches({ id, url: previous });
                }
          }
        >
          <LeftOutlined />
        </Button>
      );
    }

    if (type === 'next') {
      return (
        <Button
          disabled={isEmpty(next) || isLoading}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 10,
          }}
          onClick={
            isEmpty(next)
              ? () => {}
              : () => {
                  Mixpanel.track('SHOW_RECOMMENDATIONS_NEXT');
                  fetchAvailableMatches({ id, url: next });
                }
          }
        >
          <RightOutlined />
        </Button>
      );
    }

    return originalElement;
  };

  render() {
    const { title, onModalClose, visible, availableMatches } = this.props;
    const { isLoading, data } = availableMatches;
    const { currentPage } = this.state;

    return (
      <Modal
        visible={visible}
        title={title}
        destroyOnClose
        width="83%"
        onCancel={() => {
          this.setState({ currentPage: 1 });
          onModalClose();
        }}
        onOk={() => this.handleOkPress()}
      >
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={isEmpty(data) ? [] : data.results}
          rowKey={(row) => row.id}
          scroll={{ x: true }}
          pagination={false}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <Pagination
            hideOnSinglePage
            simple
            current={currentPage}
            total={data.count}
            onChange={this.onChangePage}
            itemRender={this.itemRender}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  availableMatches: state.showMatches,
});

export default connect(mapStateToProps, {
  fetchAvailableMatches: userProfileEffects.showMatches,
})(ShowMatchesModal);
