import {
  HINDU_COMMUNITIES,
  RELIGIONS,
} from '../containers/UserOnboarding/config';

export const fetchCityList = async (city) =>
  fetch(`https://search.staging.betterhalf.ai/search/city?charlist=${city}`)
    .then((response) => response.json())
    .then((body) =>
      body.result.map((item) => ({
        text: `${item.city}`,
        value: `${item.city}`,
        id: `${item.id}`,
      }))
    )
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e);
      return [];
    });

export const fetchCommunities = async (community) =>
  fetch(`https://search.betterhalf.ai/search/community?charlist=${community}`)
    .then((response) => response.json())
    .then((body) =>
      body.community_list.map((item) => ({
        text: `${item.community}`,
        value: `${item.community}`,
        id: `${item.id}`,
      }))
    )
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e);
      return [];
    });

export const fetchJobTitle = async (jobTitle) =>
  fetch(`https://search.betterhalf.ai/search/designation?charlist=${jobTitle}`)
    .then((response) => response.json())
    .then((body) =>
      body.designation_list.map((item) => ({
        text: `${item.designation}`,
        value: `${item.designation}`,
        id: `${item.id}`,
      }))
    )
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e);
      return [];
    });

export const fetchCollegeName = async (collegeName) =>
  fetch(`https://search.betterhalf.ai/search/college?charlist=${collegeName}`)
    .then((response) => response.json())
    .then((body) =>
      body.college_list.map((item) => ({
        text: `${item.College}`,
        value: `${item.College}`,
        id: `${item.id}`,
      }))
    )
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e);
      return [];
    });

export const fetchDegreeList = async (searchTerm) =>
  fetch(`https://search.betterhalf.ai/search/degree?charlist=${searchTerm}/`)
    .then((response) => response.json())
    .then((body) =>
      body.degree_list.map((item) => ({
        text: `${item.Name}`,
        value: `${item.Name.toLowerCase()}`,
        id: `${item.id}`,
      }))
    )
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e);
      return [];
    });

const getCombineReligions = () => {
  const temp = RELIGIONS.map((religion) => religion.caste);
  return temp
    .flat()
    .concat(HINDU_COMMUNITIES.slice(11))
    .filter(Boolean)
    .map((el) => el.displayText);
};

const COMBINE_RELIGIONS = getCombineReligions();
export const fetchCaste = async (searchTerm) => {
  const searchResults = COMBINE_RELIGIONS.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return searchResults.map((item) => ({
    text: `${item}`,
    value: `${item.toLowerCase()}`,
  }));
};
