import React, { PureComponent } from 'react';
import { Table, Empty } from 'antd';
import { isEmpty } from '../../../utilities/util';
import { dataColumns } from './data.enum';

class DeviceInfo extends PureComponent {
  render() {
    const { userData } = this.props;
    if (isEmpty(userData.devices)) {
      return <Empty />;
    }
    const { devices } = userData;
    return <Table size="small" dataSource={devices} columns={dataColumns} />;
  }
}

export default DeviceInfo;
