import React from 'react';
import { Button as AntDButton } from 'antd';
import './styles.scss';

const Button = ({ children, disabled, onClick, isLink, isSave, className }) => (
  <AntDButton
    className={`button ${isLink ? 'link' : ''} ${isSave ? 'save' : ''} ${
      className || ''
    }`}
    type={isLink ? 'link' : 'primary'}
    shape="round"
    size="large"
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </AntDButton>
);

export default Button;
