import React, { useState } from 'react';
import Collapse from '../Details/DetailCollapse';
import DetailPanel from '../Details/DetailPanel';
import DetailHeader from '../Details/DetailHeader';
import MaritalStatus from './MaritalStatus';
import Religion from './Religion';
import MotherTongue from './MotherTongue';
import Community from './Community';
import Button from '../../../components/Button';
import { useCollapse } from '../hooks';
import { editUserDetails } from '../api';
import { uniqueIdGenerator, capitalize } from '../../../utilities/util';
import { useUserData } from '../../UserProfile/CompleteProfile/UserDataContext';
import { MARITAL_STATUSES, RELIGIONS, MOTHER_TONGUE_LIST } from '../config';
import './styles.scss';

const ACCORDION_KEYS = [
  'marital-status',
  'religion',
  'mother-tongue',
  'community',
];

const PrePersonalDetails = ({ moveToNext: moveToNextSection }) => {
  const {
    maritalStatus,
    religion,
    motherTongue,
    caste,
    id: userId,
    status,
  } = useUserData();

  const { activeKey, moveToNext, jumpToStep, closePanel } = useCollapse(
    ACCORDION_KEYS,
    ACCORDION_KEYS[0],
    !!maritalStatus
  );

  const [maritalStatuses, setMaritalStatuses] = useState(() =>
    !maritalStatus
      ? []
      : [
          MARITAL_STATUSES.find(
            (statusItem) => statusItem.value === maritalStatus
          ),
        ]
  );

  const [religions, setReligions] = useState(() =>
    !religion
      ? []
      : [RELIGIONS.find((religionItem) => religionItem.value === religion)]
  );

  const [motherTongueList, setMotherTongueList] = useState(() =>
    !motherTongue
      ? []
      : [
          MOTHER_TONGUE_LIST.find(
            (motherTongueItem) => motherTongueItem.value === motherTongue
          ),
        ]
  );

  const [community, setCommunity] = useState(() => {
    if (!religion) return null;
    const castesArray = RELIGIONS.find(
      (religionItem) => religionItem.value === religion
    ).caste;
    const casteFound = castesArray.find(
      (casteItem) => casteItem.value === caste
    );
    if (casteFound) {
      return casteFound;
    }
    return {
      id: uniqueIdGenerator(),
      value: caste,
      displayText: capitalize(caste),
    };
  });

  const isDisabled =
    maritalStatuses.length === 0 ||
    religions.length === 0 ||
    motherTongueList.length === 0 ||
    !community;

  const handleOnChange = (key) => {
    /* Do not expand Community panel unless a religion has been selected  */
    if ((key === 'community' && religions[0]) || key !== 'community') {
      jumpToStep(key);
    }
  };

  const moveToNextPanel = () => {
    if (status === 'ai_details') {
      moveToNext();
    } else {
      closePanel();
    }
  };

  const handleSelectMaritalStatus = async (currentMaritalStatus) => {
    if (
      !maritalStatuses.some(
        (currentStatus) => currentStatus.value === currentMaritalStatus.value
      )
    ) {
      const payload = {
        marital_status: currentMaritalStatus.value,
      };
      await editUserDetails(userId, payload);
      setMaritalStatuses([currentMaritalStatus]);
    }
    moveToNextPanel();
  };

  const handleSelectReligion = async (currentSelectedReligion) => {
    if (
      !religions.some(
        (currentReligion) =>
          currentReligion.value === currentSelectedReligion.value
      )
    ) {
      const payload = {
        religion: currentSelectedReligion.value,
      };
      await editUserDetails(userId, payload);
      setReligions([currentSelectedReligion]);
      setCommunity(null);
    }
    moveToNextPanel();
  };

  const handleSelectMotherTongue = async (
    currentSelectedMotherTongue,
    options = { delete: false }
  ) => {
    /* This function will optionally receive an options object. If the delete property 
       inside it is true, we delete currentSelectedMotherTongue from the motherTongueList.  */
    if (options?.delete) {
      const updatedMotherTongueList = motherTongueList.filter(
        (motherTongueItem) =>
          motherTongueItem.value !== currentSelectedMotherTongue.value
      );
      setMotherTongueList(updatedMotherTongueList);
      return;
    }

    if (
      !motherTongueList.some(
        (motherTongueItem) =>
          motherTongueItem.value === currentSelectedMotherTongue.value
      )
    ) {
      const payload = {
        mother_tongue: currentSelectedMotherTongue.value,
      };
      await editUserDetails(userId, payload);
      setMotherTongueList([currentSelectedMotherTongue]);
    }
    moveToNextPanel();
  };

  const handleSelectCommunity = async (currentSelectedCommunity) => {
    /* Logic to delete the currently selected community */
    if (currentSelectedCommunity === null) {
      setCommunity(null);
      return;
    }

    if (currentSelectedCommunity?.value !== community?.value) {
      const payload = {
        caste: currentSelectedCommunity.value,
      };
      await editUserDetails(userId, payload);
      setCommunity(currentSelectedCommunity);
    }
    closePanel(); // This will close the Community panel that's currently open
  };

  return (
    <section className="pre-personal-details-container">
      <Collapse
        onChange={handleOnChange}
        destroyInactivePanel
        activeKey={activeKey}
      >
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Marital status"
              isActive={activeKey === ACCORDION_KEYS[0]}
              value={maritalStatuses[0]?.displayText}
              placeholder="Eg: Never married"
            />
          }
          key={ACCORDION_KEYS[0]}
        >
          <MaritalStatus
            maritalStatuses={maritalStatuses}
            setMaritalStatuses={handleSelectMaritalStatus}
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Religion"
              isActive={activeKey === ACCORDION_KEYS[1]}
              value={religions[0]?.displayText}
              placeholder="Eg: Hindu"
            />
          }
          key={ACCORDION_KEYS[1]}
        >
          <Religion religions={religions} setReligions={handleSelectReligion} />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Mother tongue"
              isActive={activeKey === ACCORDION_KEYS[2]}
              value={motherTongueList?.[0]?.displayText}
              placeholder="Eg: Hindi"
            />
          }
          key={ACCORDION_KEYS[2]}
        >
          <MotherTongue
            selectedMotherTongueList={motherTongueList}
            setSelectedMotherTongueList={handleSelectMotherTongue}
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Community"
              isActive={activeKey === ACCORDION_KEYS[3]}
              value={community?.displayText}
              placeholder="Eg: Brahmin"
            />
          }
          key={ACCORDION_KEYS[3]}
        >
          <Community
            selectedReligion={religions[0]}
            selectedCommunity={community}
            setSelectedCommunity={handleSelectCommunity}
          />
        </DetailPanel>
      </Collapse>
      {status === 'ai_details' && (
        <div className="pre-personal-next-button">
          <Button onClick={moveToNextSection} disabled={isDisabled}>
            Next
          </Button>
        </div>
      )}
    </section>
  );
};

export default PrePersonalDetails;
