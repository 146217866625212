import React, { useState, useRef, useMemo } from 'react';
import { debounce } from 'lodash';
import OptionPill from '../../../../components/OptionPill';
import Button from '../../../../components/Button';
import SearchList from '../../SearchList';

import './styles.scss';

const SelectWithSearch = ({
  onSelect,
  list,
  isTypeVertical = true,
  selectedData,
  inputPlaceholder = 'Search',
  searchCallback,
  getTransformedSearchResults,
  showBtns,
}) => {
  const filteredList = list.slice(0, 7);
  const [filteredData, setFilteredData] = useState(filteredList);
  const [showSearchList, setShowSearchList] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  /* This is the to store the most current searchInput which will be used inside useMemo */
  const searchInputRef = useRef('');

  const debouncedSearch = useMemo(
    () =>
      debounce(async () => {
        if (searchInputRef.current !== '') {
          const searchResults = await searchCallback(searchInputRef.current);

          const transformedSearchResults =
            getTransformedSearchResults(searchResults);

          setFilteredData(transformedSearchResults);
        }
      }, 250),
    []
  );

  const handleSearch = (searchTerm) => {
    const trimmedSearchTerm = searchTerm.trim();
    setSearchInput(searchTerm);
    searchInputRef.current = trimmedSearchTerm;

    if (trimmedSearchTerm === '') {
      setFilteredData(list);
    } else {
      debouncedSearch();
    }
  };

  const handleSelectDataFromSearchList = (element) => {
    setSearchInput('');
    onSelect(element);
  };

  const handleOnBack = () => {
    setShowSearchList(false);
  };

  return showSearchList ? (
    <SearchList
      inputPlaceholder={inputPlaceholder}
      searchInput={searchInput}
      onChange={handleSearch}
      data={filteredData}
      onSelect={handleSelectDataFromSearchList}
      // selectedItems={selectedDataList}
      onBack={handleOnBack}
      showSelectedItems={false}
      showBtns={showBtns}
    />
  ) : isTypeVertical ? (
    <section className="section__container-vertical">
      {filteredList.map((data) => (
        <button
          type="button"
          key={data.id}
          onClick={() => onSelect(data)}
          className="section__pill-vertical"
          selected={selectedData === data.displayText}
        >
          {data.displayText}
        </button>
      ))}
      <Button
        isLink
        className="section__pill-vertical see-more-button"
        onClick={() => setShowSearchList(true)}
      >
        See more
      </Button>
    </section>
  ) : (
    <section className="section__container-horizontal">
      {filteredList.map((data) => (
        <OptionPill
          key={data.id}
          onClick={() => onSelect(data)}
          className="section__pill-horizontal"
          selected={selectedData === data.displayText}
        >
          {data.displayText}
        </OptionPill>
      ))}
      <Button
        isLink
        className="section__pill-horizontal"
        onClick={() => setShowSearchList(true)}
      >
        See more
      </Button>
    </section>
  );
};

export default SelectWithSearch;
