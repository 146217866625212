import httpClient from '../../../../@betterhalf-private/api/client';

export const uploadUserPhoto = async (userId, formData) => {
  try {
    const response = await httpClient({
      method: 'POST',
      url: `users/${userId}/images/`,
      data: formData,
    });

    return response.body;
  } catch (error) {
    throw new Error(error);
  }
};


export const uploadVerificationDoc = async (userId, formData) => {
  try {
    const response = await httpClient({
      method: 'POST',
      url: `users/${userId}/verification/govt-id/`,
      data: formData,
    });

    return response.body;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteUserPhoto = async (userId, indexToDelete) => {
  try {
    await httpClient({
      method: 'DELETE',
      url: `users/${userId}/images/${indexToDelete}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};
