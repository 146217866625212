import { stringFormatter } from '../../../utilities/util';

export const columns = [
  {
    title: 'Plan name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Start date',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text) => stringFormatter({ text, type: 'date' }),
  },
  {
    title: 'End date',
    dataIndex: 'end_date',
    key: 'end_date',
    render: (text) => stringFormatter({ text, type: 'date' }),
  },
  {
    title: 'Consumable quantities',
    dataIndex: 'sparkle_numbers',
    key: 'sparkle_numbers',
  },
  {
    title: 'Coupon Name',
    dataIndex: 'coupon_code',
    key: 'coupon_code',
  },
  {
    title: 'Coupon Description',
    dataIndex: 'coupon_description',
    key: 'coupon_description',
  },
];
