import { isEmpty } from './utils';

export const PaymentPlansActions = {
  PaymentPlansLoading: 'PAYMENT_PLANS_LOADING',
  PaymentPlansSuccess: 'PAYMENT_PLANS_SUCCESS',
  PaymentPlansFailure: 'PAYMENT_PLANS_FAILURE',
  SparklePlansLoading: 'SPARKLE_PLANS_LOADING',
  SparklePlansSuccess: 'SPARKLE_PLANS_SUCCESS',
  SparklePlansFailure: 'SPARKLE_PLANS_FAILURE',
  HmPlansLoading: 'HM_PLANS_LOADING',
  HmPlansSuccess: 'HM_PLANS_SUCCESS',
  HmPlansFailure: 'HM_PLANS_FAILURE',
  BvPlansLoading: 'BV_PLANS_LOADING',
  BvPlansSuccess: 'BV_PLANS_SUCCESS',
  BvPlansFailure: 'BV_PLANS_FAILURE',
  KundaliPlansLoading: 'kundali_PLANS_LOADING',
  KundaliPlansSuccess: 'kundali_PLANS_SUCCESS',
  KundaliPlansFailure: 'kundali_PLANS_FAILURE',
};

export const PaymentLinksActions = {
  PaymentLinksLoading: 'PAYMENT_LINKS_LOADING',
  PaymentLinksSuccess: 'PAYMENT_LINKS_SUCCESS',
  PaymentLinksFailure: 'PAYMENT_LINKS_FAILURE',
};

export const PaymentsHistoryActionsTypes = {
  PaymentsHistoryLoading: 'PAYMENTS_HISTORY_LOADING',
  PaymentsHistorySuccess: 'PAYMENTS_HISTORY_SUCCESS',
  PaymentsHistoryFailure: 'PAYMENTS_HISTORY_FAILURE',
};

const initialState = {
  isLoading: false,
  data: {},
  error: {},
  success: false,
};

const sparkleInitialState = {
  isLoading: false,
  data: {},
  error: {},
  success: false,
};

const hmInitialState = {
  isLoading: false,
  data: {},
  error: {},
  success: false,
};

const bvInitialState = {
  isLoading: false,
  data: {},
  error: {},
  success: false,
};

const kundaliInitialState = {
  isLoading: false,
  data: {},
  error: {},
  success: false,
};

const paymentsHistory = {
  isLoading: false,
  data: {
    results: [],
    next: null,
    count: 0,
  },
  error: {},
  success: false,
};

export function paymentPlansReducer(state = initialState, action) {
  switch (action.type) {
    case PaymentPlansActions.PaymentPlansLoading:
      return {
        ...state,
        isLoading: true,
        success: false,
      };

    case PaymentPlansActions.PaymentPlansSuccess:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };

    case PaymentPlansActions.PaymentPlansFailure:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false,
      };

    default:
      return state;
  }
}

export function sparklePlansReducer(state = sparkleInitialState, action) {
  switch (action.type) {
    case PaymentPlansActions.SparklePlansLoading:
      return {
        ...state,
        isLoading: true,
        success: false,
      };

    case PaymentPlansActions.SparklePlansSuccess:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };

    case PaymentPlansActions.SparklePlansFailure:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false,
      };

    default:
      return state;
  }
}

export function hmPlansReducer(state = hmInitialState, action) {
  switch (action.type) {
    case PaymentPlansActions.HmPlansLoading:
      return {
        ...state,
        isLoading: true,
        success: false,
      };

    case PaymentPlansActions.HmPlansSuccess:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };

    case PaymentPlansActions.HmPlansFailure:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false,
      };

    default:
      return state;
  }
}

export function bvPlansReducer(state = bvInitialState, action) {
  switch (action.type) {
    case PaymentPlansActions.BvPlansLoading:
      return {
        ...state,
        isLoading: true,
        success: false,
      };

    case PaymentPlansActions.BvPlansSuccess:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };

    case PaymentPlansActions.BvPlansFailure:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false,
      };

    default:
      return state;
  }
}

export function kundaliPlansReducer(state = kundaliInitialState, action) {
  switch (action.type) {
    case PaymentPlansActions.KundaliPlansLoading:
      return {
        ...state,
        isLoading: true,
        success: false,
      };

    case PaymentPlansActions.KundaliPlansSuccess:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };

    case PaymentPlansActions.KundaliPlansFailure:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false,
      };

    default:
      return state;
  }
}

export function paymentLinksReducer(state = initialState, action) {
  switch (action.type) {
    case PaymentLinksActions.PaymentLinksLoading:
      return {
        ...initialState,
        isLoading: true,
        success: false,
      };

    case PaymentLinksActions.PaymentLinksSuccess:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };

    case PaymentLinksActions.PaymentLinksFailure:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false,
      };

    default:
      return state;
  }
}

export function paymentsHistoryReducer(state = paymentsHistory, action) {
  switch (action.type) {
    case PaymentsHistoryActionsTypes.PaymentsHistoryLoading:
      return {
        ...paymentsHistory,
        isLoading: true,
        success: false,
      };

    case PaymentsHistoryActionsTypes.PaymentsHistorySuccess:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          next: action.payload.next,
          count: action.payload.count,
          previous: action.payload.previous,
          results: [...state.data.results, ...action.payload.results],
        },
        success: true,
      };

    case PaymentsHistoryActionsTypes.PaymentsHistoryFailure:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false,
      };

    default:
      return state;
  }
}

export const paymentPlansActions = {
  paymentPlansLoading(bool) {
    return {
      type: PaymentPlansActions.PaymentPlansLoading,
      bool,
    };
  },
  paymentPlansSuccess(data) {
    return {
      type: PaymentPlansActions.PaymentPlansSuccess,
      payload: data,
    };
  },
  paymentPlansFailure(error) {
    return {
      type: PaymentPlansActions.PaymentPlansFailure,
      error,
    };
  },
  sparklePlansLoading(bool) {
    return {
      type: PaymentPlansActions.SparklePlansLoading,
      bool,
    };
  },
  sparklePlansSuccess(data) {
    return {
      type: PaymentPlansActions.SparklePlansSuccess,
      payload: data,
    };
  },
  sparklePlansFailure(error) {
    return {
      type: PaymentPlansActions.SparklePlansFailure,
      error,
    };
  },
  hmPlansLoading() {
    return {
      type: PaymentPlansActions.HmPlansLoading,
    };
  },
  hmPlansSuccess(data) {
    return {
      type: PaymentPlansActions.HmPlansSuccess,
      payload: data,
    };
  },
  hmPlansFailure(error) {
    return {
      type: PaymentPlansActions.HmPlansFailure,
      error,
    };
  },
  bvPlansLoading() {
    return {
      type: PaymentPlansActions.BvPlansLoading,
    };
  },
  bvPlansSuccess(data) {
    return {
      type: PaymentPlansActions.BvPlansSuccess,
      payload: data,
    };
  },
  bvPlansFailure(error) {
    return {
      type: PaymentPlansActions.BvPlansFailure,
      error,
    };
  },
  kundaliPlansLoading() {
    return {
      type: PaymentPlansActions.KundaliPlansLoading,
    };
  },
  kundaliPlansSuccess(data) {
    return {
      type: PaymentPlansActions.KundaliPlansSuccess,
      payload: data,
    };
  },
  kundaliPlansFailure(error) {
    return {
      type: PaymentPlansActions.KundaliPlansFailure,
      error,
    };
  },
};

export const paymentLinksActions = {
  paymentLinksLoading(bool) {
    return {
      type: PaymentLinksActions.PaymentLinksLoading,
      bool,
    };
  },
  paymentLinksSuccess(data) {
    return {
      type: PaymentLinksActions.PaymentLinksSuccess,
      payload: data,
    };
  },
  paymentLinksFailure(error) {
    return {
      type: PaymentLinksActions.PaymentLinksFailure,
      error,
    };
  },
};

export const paymentsHistoryActions = {
  paymentsHistoryLoading(bool) {
    return {
      type: PaymentsHistoryActionsTypes.PaymentsHistoryLoading,
      bool,
    };
  },
  paymentsHistorySuccess(data) {
    return {
      type: PaymentsHistoryActionsTypes.PaymentsHistorySuccess,
      payload: data,
    };
  },
  paymentsHistoryFailure(error) {
    return {
      type: PaymentsHistoryActionsTypes.PaymentsHistoryFailure,
      error,
    };
  },
};

export const paymentPlansEffects = {
  fetchPaymentPlans({ id }) {
    return async (dispatch) => {
      dispatch(paymentPlansActions.paymentPlansLoading(true));
      try {
        const resp = await window.client.getAllPremiumPlans({ id });
        if (resp.status >= 300 || resp.status < 200) throw resp;
        const { gst_percentage, is_gst_supported } = resp.body;
        const plans = resp.body.results;
        const plansWithIds = {};
        plans.forEach((plan) => {
          plansWithIds[plan.plan_id] = {
            is_gst_supported: is_gst_supported || false,
            gst_percentage: gst_percentage || 0,
            ...plan,
          };
        });
        dispatch(paymentPlansActions.paymentPlansSuccess(plansWithIds));
      } catch (e) {
        dispatch(paymentPlansActions.paymentPlansFailure(e));
      }
    };
  },
  fetchSparklePlans({ id }) {
    return async (dispatch) => {
      dispatch(paymentPlansActions.sparklePlansLoading(true));
      try {
        const resp = await window.client.getSparklePlans({ id });
        if (resp.status >= 300 || resp.status < 200) throw resp;
        const { gst_percentage, is_gst_supported } = resp.body;
        const plans = resp.body.results;
        const plansWithIds = {};
        plans.forEach((plan) => {
          plansWithIds[plan.plan_id] = {
            is_gst_supported: is_gst_supported || false,
            gst_percentage: gst_percentage || 0,
            ...plan,
          };
        });
        dispatch(paymentPlansActions.sparklePlansSuccess(plansWithIds));
      } catch (e) {
        dispatch(paymentPlansActions.sparklePlansFailure(e));
      }
    };
  },
  fetchHmPlans({ id }) {
    return async (dispatch) => {
      dispatch(paymentPlansActions.hmPlansLoading(true));
      try {
        const resp = await window.client.getHmPlans({ id });
        if (resp.status >= 300 || resp.status < 200) throw resp;
        const plans = resp.body.results;
        const plansWithIds = {};
        plans.forEach((plan) => {
          plansWithIds[plan.plan_id] = plan;
        });
        dispatch(paymentPlansActions.hmPlansSuccess(plansWithIds));
      } catch (e) {
        dispatch(paymentPlansActions.hmPlansFailure(e));
      }
    };
  },
  fetchBvPlans({ id }) {
    return async (dispatch) => {
      dispatch(paymentPlansActions.bvPlansLoading(true));
      try {
        const resp = await window.client.getBvPlans({ id });
        if (resp.status >= 300 || resp.status < 200) throw resp;
        const plans = resp.body.results;
        const plansWithIds = {};
        plans.forEach((plan) => {
          plansWithIds[plan.plan_id] = plan;
        });
        dispatch(paymentPlansActions.bvPlansSuccess(plansWithIds));
      } catch (e) {
        dispatch(paymentPlansActions.bvPlansFailure(e));
      }
    };
  },
  fetchKundaliPlans({ id }) {
    return async (dispatch) => {
      dispatch(paymentPlansActions.kundaliPlansLoading(true));
      try {
        const resp = await window.client.getKundaliPlans({ id });
        if (resp.status >= 300 || resp.status < 200) throw resp;
        const { gst_percentage, is_gst_supported } = resp.body;
        const plans = resp.body.results;
        const plansWithIds = {};
        plans.forEach((plan) => {
          plansWithIds[plan.plan_id] = {
            is_gst_supported: is_gst_supported || false,
            gst_percentage: gst_percentage || 0,
            ...plan,
          };
        });
        dispatch(paymentPlansActions.kundaliPlansSuccess(plansWithIds));
      } catch (e) {
        dispatch(paymentPlansActions.kundaliPlansFailure(e));
      }
    };
  },
  paymentLinks({ ...body }) {
    return async (dispatch) => {
      dispatch(paymentLinksActions.paymentLinksLoading(true));
      try {
        const resp = await window.client.paymentLinks(body);
        if (resp.status >= 300 || resp.status < 200) throw resp;
        dispatch(paymentLinksActions.paymentLinksSuccess(resp.body));
      } catch (e) {
        if (e.response.data) {
          dispatch(paymentLinksActions.paymentLinksFailure(e.response.data));
        } else {
          dispatch(paymentLinksActions.paymentLinksFailure(e));
        }
      }
    };
  },
  fetchPaymentsHistory({ id, url }) {
    return async (dispatch) => {
      dispatch(paymentsHistoryActions.paymentsHistoryLoading(true));
      try {
        const urlToBeSent = !isEmpty(url)
          ? url.substring(url.indexOf('v2') + 2)
          : null;
        const req = isEmpty(urlToBeSent)
          ? `/users/${id}/payments/history/?limit=10`
          : urlToBeSent;

        const resp = await window.client.getPaymentsHistory(req);
        if (resp.status >= 300 || resp.status < 200) throw resp;
        dispatch(paymentsHistoryActions.paymentsHistorySuccess(resp.body));
      } catch (e) {
        dispatch(paymentsHistoryActions.paymentsHistoryFailure(e));
      }
    };
  },
};
