/* eslint-disable react/no-unstable-nested-components */
import React, { memo, useEffect, useState, useMemo } from 'react';
import { Collapse } from 'antd';
import {
  UpCircleFilled,
  DownCircleFilled,
  LockFilled,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import ProfilePanel from './ProfilePanel';
import MobileNumber from './MobileNumber';
import PrePersonalDetails from './PrePersonalDetails';
import PartnerPreference from './PartnerPreference';
import PersonalDetails from './PersonalDetails';
import './styles.scss';

import { useCollapse } from './hooks';
import { useUserData } from '../UserProfile/CompleteProfile/UserDataContext';
import PreAiDetails from './PreAIDetails';

const Header = ({ isActive }) => (
  <div className="header-container">
    <p>Partner Preference</p>
    {isActive && (
      <p className="header-subtitle">You can select multiple options</p>
    )}
  </div>
);

// --------------------------------------------------
const ACCORDION_KEYS = [
  'mobile_number',
  'pre-ai-details',
  'pre-personal-details',
  'personal-details',
  'partner-preference',
];

const MAP_USER_STATUS_TO_STEP = {
  on_boarding_started: 1,
  ai_details: 2,
  personal_details: 3,
  partner_preferences: 4,
  on_boarding_completed: 5,
};

const getActiveKey = (props, statusTransitionFromPreAIDetails) => {
  const { maritalStatus, religion, motherTongue, caste, status } = props;
  // const { status } = props;

  if (status === 'on_boarding_started') return 'pre-ai-details';

  if (status === 'ai_details') {
    if (
      maritalStatus &&
      religion &&
      motherTongue &&
      caste &&
      !statusTransitionFromPreAIDetails
    )
      return 'personal-details';

    return 'pre-personal-details';
  }
  if (status === 'personal_details' || status === 'partner-preference')
    return 'partner-preference';
  return undefined; // => if (status === 'on_boarding_completed') return undefined; => no collapse panel should be opened
};
// if the user status is any one of these, the user can go to the any step
const priorityStatus = [
  'personal_details',
  'partner-preference',
  'on_boarding_completed',
];
const UserOnboarding = ({ closeCompleteProfile }) => {
  const location = useLocation();
  const { status, maritalStatus, religion, motherTongue, caste } =
    useUserData();

  const propsForActiveKey = {
    maritalStatus,
    religion,
    motherTongue,
    caste,
    status,
  };

  const [
    statusTransitionFromPreAIDetails,
    setStatusTransitionFromPreAIDetails,
  ] = useState(false);

  const { activeKey, moveToNext, jumpToStep, closePanel } = useCollapse(
    ACCORDION_KEYS,
    () => getActiveKey(propsForActiveKey)
  );

  useEffect(() => {
    jumpToStep(() =>
      getActiveKey(propsForActiveKey, statusTransitionFromPreAIDetails)
    );
  }, [status]);

  const handleOnChange = (key) => {
    const indexToJump = ACCORDION_KEYS.indexOf(key);

    const indexOfUserStatus = MAP_USER_STATUS_TO_STEP[status];

    if (
      priorityStatus.includes(status) ||
      indexToJump <= indexOfUserStatus ||
      (indexToJump === 3 && indexOfUserStatus === 2)
    )
      jumpToStep(key);
  };

  const navigateToAiFromPreferences = () => jumpToStep('pre-ai-details');

  const ExpandIcon = useMemo(
    () =>
      ({ isActive, panelKey }) => {
        /* Conditions for locking :
  
          variable - shouldLockPreAndPersonal : Checks if the current panel is Pre-Personal or Personal Details.
          Both of these will be locked if the status is 'on_boarding_started'
      
          variable - shouldLockPartnerPreference : Checks if the current panel is Partner Preference.
          This will be locked if the status is either 'on_boarding_started' or 'ai_details'.
      
          Mobile Number and AI Details panels will never be locked.
        */

        const shouldLockPreAndPersonal =
          (panelKey === 'pre-personal-details' ||
            panelKey === 'personal-details') &&
          status === 'on_boarding_started';

        const shouldLockPartnerPreference =
          panelKey === 'partner-preference' &&
          ['on_boarding_started', 'ai_details'].includes(status);

        if (shouldLockPreAndPersonal || shouldLockPartnerPreference) {
          return <LockFilled className="expandIcon" />;
        }

        return isActive ? (
          <UpCircleFilled className="expandIcon" />
        ) : (
          <DownCircleFilled className="expandIcon" />
        );
      },
    [status]
  );

  return (
    <Collapse
      accordion
      bordered={false}
      defaultActiveKey={[
        location.state?.redirectedFromCreateProfile ? '2' : '1',
      ]}
      className="collapsible-container"
      expandIcon={ExpandIcon}
      expandIconPosition="right"
      activeKey={activeKey}
      onChange={handleOnChange}
    >
      <ProfilePanel header="Mobile number" key={ACCORDION_KEYS[0]}>
        <MobileNumber moveToNext={moveToNext} />
      </ProfilePanel>
      <ProfilePanel header="Pre-AI details" key={ACCORDION_KEYS[1]}>
        <PreAiDetails
          moveToNext={() => {
            setStatusTransitionFromPreAIDetails(true);
            moveToNext();
          }}
        />
      </ProfilePanel>
      <ProfilePanel header="Pre-personal details" key={ACCORDION_KEYS[2]}>
        <PrePersonalDetails moveToNext={moveToNext} />
      </ProfilePanel>
      <ProfilePanel header="Personal details" key={ACCORDION_KEYS[3]}>
        <PersonalDetails moveToNext={moveToNext} />
      </ProfilePanel>
      <ProfilePanel
        header={<Header isActive={activeKey === ACCORDION_KEYS[4]} />}
        key={ACCORDION_KEYS[4]}
      >
        <PartnerPreference
          closePanel={closePanel}
          navigateToAiFromPreferences={navigateToAiFromPreferences}
          closeCompleteProfile={closeCompleteProfile}
        />
      </ProfilePanel>
    </Collapse>
  );
};

export default memo(UserOnboarding);
