import React from 'react';
import OptionPill from '../../../../components/OptionPill';

import './styles.scss';

const Preference = ({ handlePreference, selectedPreference, list }) => (
  <section className="preference__container">
    {list.map((preference) => (
      <OptionPill
        key={preference.id}
        onClick={() => handlePreference(preference)}
        className="preference__pill"
        selected={
          selectedPreference === preference.value ||
          selectedPreference === preference
        }
      >
        {preference.displayText}
      </OptionPill>
    ))}
  </section>
);

export default Preference;
