import { Select } from 'antd';
import React from 'react';
import './styles.scss';

const { Option } = Select;
const SelectCustom = ({
  data,
  defaultValue,
  handleChange,
  formatDisplayText,
}) => (
  <Select
    defaultValue={
      formatDisplayText ? formatDisplayText(defaultValue) : defaultValue
    }
    className="select"
    onChange={handleChange}
  >
    {data.map((option, index) => (
      <Option value={option.value} key={option.id ?? index} className="option">
        {formatDisplayText
          ? formatDisplayText(option.value)
          : option.displayText}
      </Option>
    ))}
  </Select>
);

export default SelectCustom;
