import React, { useState } from 'react';
import { Slider } from 'antd';
import Button from '../../../../../components/Button';
import { updatePartnerPreferences } from '../../../api';
import { useUserData } from '../../../../UserProfile/CompleteProfile/UserDataContext';
import './styles.scss';

const Age = ({ onSave }) => {
  const {
    id: userId,
    partnerPreference: { age_from, age_to, id: partnerPreferenceId },
  } = useUserData();
  const [ageRange, setAgeRange] = useState([age_from, age_to]);
  const selectedAgeRange = ageRange
    ? `${ageRange[0]} - ${ageRange[1]} years`
    : '22 - 32 years';

  const handleAgeSave = async () => {
    const payload = {
      age_from: ageRange[0],
      age_to: ageRange[1],
    };
    await updatePartnerPreferences(userId, partnerPreferenceId, payload);
    onSave();
  };

  return (
    <section>
      <Slider
        range
        step={1}
        onChange={setAgeRange}
        min={21}
        max={55}
        value={[ageRange[0], ageRange[1]]}
      />
      <p>{selectedAgeRange}</p>
      <div className="age-save-button-container">
        <Button isLink isSave onClick={handleAgeSave}>
          SAVE
        </Button>
      </div>
    </section>
  );
};

export default Age;
