import { AlertOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { resizeArray } from '../../../../utilities/helper';
import { useUserData } from '../../../UserProfile/CompleteProfile/UserDataContext';
import { deleteUserPhoto, uploadUserPhoto } from '../api';
import ImageInput from './ImageInput';
import './styles.scss';

let globalImages;

const generateImagesArray = (uploadedImages, images) => {
  if (!uploadedImages) return [];
  globalImages = resizeArray(uploadedImages, 6, null).map(
    (imageUrl, index) => ({
      uploadedImageUrl: imageUrl,
      localImageUrl: images ? images[index]?.localImageUrl : null,
      isUploading: false,
    })
  );

  return globalImages;
};

const UploadImageSection = ({ setIsImageValidationPassed }) => {
  const { images: uploadedImagesUrl, id: userId } = useUserData();

  const [images, setImages] = useState([]);

  useEffect(() => {
    if (images.filter((image) => image.uploadedImageUrl).length >= 1) {
      setIsImageValidationPassed(true);
    } else {
      setIsImageValidationPassed(false);
    }
  }, [images]);

  useEffect(() => {
    setImages(() => generateImagesArray(uploadedImagesUrl, images));
  }, [uploadedImagesUrl]);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleImageOperation = async ({
    action,
    localImageUrl,
    imageFile,
    imageInputComponentIndex,
  }) => {
    if (action === 'add') {
      const formData = new FormData();
      formData.append('image', imageFile);

      const tempImages = [...images];

      //! if action is add , then replace the nearest null value in the array
      let flag = 0;
      let indexToUpdate;
      images.forEach((image, i) => {
        if (!flag && image.uploadedImageUrl === null && !image.localImageUrl) {
          tempImages[i] = {
            ...images[i],
            localImageUrl,
            isUploading: true,
          };

          flag = 1;
          indexToUpdate = i;
        }
      });
      globalImages = tempImages;

      setImages(tempImages);
      try {
        const response = await uploadUserPhoto(userId, formData);

        const _tempImages = [...globalImages];
        _tempImages[indexToUpdate] = {
          uploadedImageUrl: response.url,
          isUploading: false,
          localImageUrl: null,
        };
        globalImages = _tempImages;

        const uploadedImageUrls = _tempImages.filter(
          (image) => image.uploadedImageUrl
        );
        const localImageUrls = _tempImages.filter(
          (image) => image.localImageUrl
        );
        const filteredImages = uploadedImageUrls.concat(localImageUrls);
        globalImages = resizeArray(filteredImages, 6, {
          uploadedImageUrl: null,
          localImageUrl: null,
        });
        setImages(globalImages);

        setErrorMessage(null);
      } catch (_error) {
        // When image upload failed

        if (_error instanceof TypeError) {
          setErrorMessage('Something went wrong! Check network speed!');
        } else {
          setErrorMessage(
            'Looks like some of your photos do not follow our guidelines.'
          );
        }

        const _tempImages = [...globalImages];
        _tempImages[indexToUpdate] = {
          ..._tempImages[indexToUpdate],
          isUploading: false,
          localImageUrl: null,
        };
        globalImages = _tempImages;

        //  sort _tempImages by uploadedImageUrl and localImageUrl
        const uploadedImageUrls = _tempImages.filter(
          (image) => image.uploadedImageUrl
        );
        const localImageUrls = _tempImages.filter(
          (image) => image.localImageUrl
        );
        const filteredImages = uploadedImageUrls.concat(localImageUrls);
        setImages(() =>
          resizeArray(filteredImages, 6, {
            uploadedImageUrl: null,
            localImageUrl: null,
          })
        );
      }
    } else {
      //! remove the image
      deleteUserPhoto(userId, imageInputComponentIndex);
      const filteredImages = images.filter(
        (_, i) => i !== imageInputComponentIndex
      );
      setImages(() =>
        resizeArray(filteredImages, 6, {
          uploadedImageUrl: null,
          localImageUrl: null,
        })
      );
    }
  };

  return (
    <div className="uploadImageSection">
      <h2>Images </h2>
      <div className="uploadImageSection__imagesWrapper">
        {images?.map((image, index) => (
          <ImageInput
            data={image}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            handleImageOperation={handleImageOperation}
            index={index}
            showDeleteButton={
              !(
                images.filter((img) => img.uploadedImageUrl).length === 1 &&
                index === 0
              )
            }
            disableDelete={images.filter((img) => img.isUploading).length >= 1} // disable the delete button if there is any image uploading | as we are handling optimistic UI here , there might be some unexpected behavior
          />
        ))}
      </div>
      {errorMessage && (
        <div className="errorMessage">
          <span>
            <AlertOutlined />
          </span>
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export default UploadImageSection;
