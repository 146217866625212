/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import Collapse from '../../Details/DetailCollapse';
import DetailPanel from '../../Details/DetailPanel';
import DetailHeader from '../../Details/DetailHeader';
import MaritalStatus from '../../PrePersonalDetails/MaritalStatus';
import Religion from '../../PrePersonalDetails/Religion';
import Age from './Age';
import AnnualIncome from '../AnnualIncome';

import { handleSelectWithAny } from '../helper';
import { useUserData } from '../../../UserProfile/CompleteProfile/UserDataContext';
import { MARITAL_STATUSES, RELIGIONS } from '../../config';
import { updatePartnerPreferences } from '../../api';
import Button from '../../../../components/Button';

/* Religion item with 'Any' value. We know it's the last item in the array. */
const [ANY_RELIGION] = RELIGIONS.slice(-1);

const ACCORDION_KEYS = ['marital-status', 'religion', 'age', 'height'];

const MustHave = ({ goToNiceToHave }) => {
  const { id: userId, partnerPreference, status: userStatus } = useUserData();
  const [activeKey, setActiveKey] = useState(
    userStatus === 'on_boarding_completed' ? null : ACCORDION_KEYS[0]
  );

  const {
    marital_status = [],
    religions: religionsFromPreferences = [],
    id: partnerPreferenceId = null,
    age_from = '',
    age_to = '',
    income_from = '',
    income_to = '',
  } = partnerPreference || {};

  const [maritalStatuses, setMaritalStatuses] = useState(() =>
    MARITAL_STATUSES.filter((maritalStatusItem) =>
      marital_status.includes(maritalStatusItem.value)
    )
  );

  const [religions, setReligions] = useState(() => {
    /* If the existing religions from preferences is empty, it means
    'Any' religion must be pre-selected */
    if (religionsFromPreferences.length === 0) {
      return [ANY_RELIGION];
    }
    return RELIGIONS.filter((religionItem) =>
      religionsFromPreferences.includes(religionItem.value)
    );
  });

  const handleSelectMaritalStatus = async (status) => {
    let updatedMaritalStatuses = [];
    if (
      !maritalStatuses.some(
        (maritalStatusItem) => maritalStatusItem.value === status.value
      )
    ) {
      updatedMaritalStatuses = [...maritalStatuses, status];
      setMaritalStatuses(updatedMaritalStatuses);
    } else {
      updatedMaritalStatuses = maritalStatuses.filter(
        (maritalStatus) => maritalStatus.value !== status.value
      );
      setMaritalStatuses(updatedMaritalStatuses);
    }

    const payload = {
      marital_status: updatedMaritalStatuses.map(
        (maritalStatusItem) => maritalStatusItem.value
      ),
    };
    await updatePartnerPreferences(userId, partnerPreferenceId, payload);
  };

  const handleSelectReligion = async (religion) => {
    const updatedReligions = handleSelectWithAny({
      selectedPreference: religion,
      preferenceState: religions,
      ANY_ITEM: ANY_RELIGION,
    });
    setReligions(updatedReligions);

    let payload;

    /* If "Any" is the selected religion, send an empty array in the payload.
      The below logic works because, if "Any" is the selected religion, then
      the array will always contain ONLY "Any"
     */
    if (updatedReligions[0].value === 'any') {
      payload = {
        religions: [],
      };
    } else {
      payload = {
        religions: updatedReligions.map((religionItem) => religionItem.value),
      };
    }

    await updatePartnerPreferences(userId, partnerPreferenceId, payload);
  };

  const closePanel = () => setActiveKey(null);

  const headerValueForMaritalStatuses = useMemo(
    () =>
      maritalStatuses
        .map((maritalStatusItem) => maritalStatusItem.displayText)
        .join(', '),
    [maritalStatuses]
  );

  const headerValueForReligions = useMemo(
    () => religions.map((religionItem) => religionItem.displayText).join(', '),
    [religions]
  );

  const headerValueForAnnualIncome = useMemo(
    () => `${income_from / 100000} - ${income_to / 100000} LPA`,
    [income_from, income_to]
  );

  return (
    <>
      <Collapse
        activeKey={activeKey}
        onChange={setActiveKey}
        destroyInactivePanel
      >
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Marital status"
              value={headerValueForMaritalStatuses}
              placeholder="Eg: Never married"
              isActive={activeKey === ACCORDION_KEYS[0]}
            />
          }
          key={ACCORDION_KEYS[0]}
        >
          <MaritalStatus
            maritalStatuses={maritalStatuses}
            setMaritalStatuses={handleSelectMaritalStatus}
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Religion"
              value={headerValueForReligions}
              placeholder="Eg: Hindu"
              isActive={activeKey === ACCORDION_KEYS[1]}
            />
          }
          key={ACCORDION_KEYS[1]}
        >
          <Religion
            religions={religions}
            setReligions={handleSelectReligion}
            section="partner-preference"
          />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Age"
              value={`${age_from} - ${age_to} years`}
              placeholder="Eg: 24 - 29 years"
              isActive={activeKey === ACCORDION_KEYS[2]}
            />
          }
          key={ACCORDION_KEYS[2]}
        >
          <Age onSave={closePanel} />
        </DetailPanel>
        <DetailPanel
          header={
            <DetailHeader
              headerTitle="Annual Income"
              value={headerValueForAnnualIncome}
              placeholder="Eg: 10 - 20 LPA"
              isActive={activeKey === ACCORDION_KEYS[1]}
            />
          }
          key={ACCORDION_KEYS[3]}
        >
          <AnnualIncome onSave={closePanel} />
        </DetailPanel>
      </Collapse>
      <div className="partner-preference-button-container">
        <Button className="partner-preference-button" onClick={goToNiceToHave}>
          Next
        </Button>
      </div>
    </>
  );
};

export default MustHave;
