import React, { useCallback, useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Layout, PageHeader, Menu, Avatar, Button, Dropdown, Tabs } from 'antd';
import { withRouter, useHistory } from 'react-router-dom';
import Mixpanel from 'mixpanel-browser';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import {otpActions} from '../../@betterhalf-private/redux/src/otp'
import { removeAuthHeaders } from '../../utilities/api';
import './styles.scss';


const { Header } = Layout;
const { TabPane } = Tabs;

const AdminHeader = ({
  title,
  subTitle,
  backIcon,
  onBack,
  adminPic,
  isUserProfile,
  isHumanMatchmaker,
  isSales,
  onProfilePress,
  onSalesPress,
  onHMPress,
  defaultActiveKey,
  userData,
  getUserProfileFeedback,
  userId,
  match: {
    params: { page },
  },
  ...props
}) => {
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (page === 'hm') {
      setTimeout(() => {
        getUserProfileFeedback({
          id: userId,
          qs: `type=human_matchmaker`,
        });
      }, 1000);
    }

    if (page === 'profile') {
      setTimeout(() => {
        getUserProfileFeedback({
          id: userId,
          qs: `type=positive,negative,in_app_review`,
        });
      }, 1000);
    }
  }, [userId, getUserProfileFeedback, page]);

  const handleMenuClick = useCallback(
    (e) => {
      if (e.key === 'header_item_1') {
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        Cookies.remove('tokenType');
        Cookies.remove('adminImage');
        Cookies.remove('userData');
        dispatch(otpActions.verificationFailure())
        removeAuthHeaders();
        history.replace('/');
        Mixpanel.track('LOGOUT_SUCCESSFUL');
      }
    },
    [history]
  );

  const renderMenu = useCallback(
    () => (
      <Menu key="header_menu" onClick={handleMenuClick}>
        <Menu.Item key="header_item_1">Logout</Menu.Item>
      </Menu>
    ),
    [handleMenuClick]
  );

  const renderContent = null;

  return (
    
    <Header style={{ background: '#fff', padding: 0 }}>
      <PageHeader
        title={title}
        subTitle={subTitle}
        backIcon={backIcon}
        onBack={onBack}
        extra={[
          <Dropdown key="dropdown_1" trigger={['click']} overlay={renderMenu}>
            <Button
              key="header_dropdown_1"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0px',
                border: 0,
                marginRight: '10px',
                marginTop: '5px',
              }}
            >
              <Avatar size={40} icon={<UserOutlined />} src={adminPic} />
            </Button>
          </Dropdown>,
        ]}
        footer={
          isUserProfile && (
            <Tabs
              defaultActiveKey={defaultActiveKey}
              onChange={(key) => {
                if (key === '1') {
                  onProfilePress();
                  setTimeout(() => {
                    getUserProfileFeedback({
                      id: userId,
                      qs: `type=positive,negative,in_app_review`,
                    });
                  }, 1000);

                  return;
                }

                if (key === '2') {
                  onSalesPress();
                  return;
                }

                if (key === '3') {
                  onHMPress();
                  setTimeout(() => {
                    getUserProfileFeedback({
                      id: userId,
                      qs: `type=human_matchmaker`,
                    });
                  }, 1000);
                }
              }}
            >
              <TabPane tab="Profile" key="1" />
              <TabPane tab="Sales" key="2" />
              <TabPane tab="Human Matchmaker" key="3" />
            </Tabs>
          )
        }
        {...props}
      >
        {renderContent}
      </PageHeader>
    </Header>
  );
};
export default React.memo(withRouter(AdminHeader));
