import React, { PureComponent } from 'react';
import { List, Card, Typography, Avatar, Button, Progress, Tag } from 'antd';
import Lightbox from 'react-image-lightbox';

import './styles.scss';

const { Meta } = Card;
const { Text } = Typography;

class UserCards extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      images: null,
      isOpenImages: false,
      photoIndex: 0,
    };
  }

  onOpenImages = ({ images, photoIndex }) => {
    this.setState({
      images,
      photoIndex,
      isOpenImages: true,
    });
  };

  getProgressColor = (score) => {
    let color = '#F15B5E';
    if (score >= 90) {
      color = '#87d068';
    } else if (score >= 70 && score < 90) {
      color = '#faad14';
    }

    return color;
  };

  renderItem = (item) => {
    const { onVerifyPress, onRejectPress } = this.props;

    const { user, extra, userProfileImages, images: selfieImages } = item;

    const similarityScore = extra?.similarity_score || 0;
    const isPreviouslyFroced = extra?.is_previously_forced || false;

    const progressColor = this.getProgressColor(similarityScore);
    return (
      <Card
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: isPreviouslyFroced ? '#ffc9db' : 'white',
        }}
        key={user}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            paddingBottom: '20px',
          }}
        >
          <Avatar
            onClick={() => {
              const otherWindow = window.open();
              otherWindow.opener = null;
              otherWindow.location = `/user-profile/${user}/profile`;
            }}
            size="large"
            style={{
              verticalAlign: 'middle',
              cursor: 'pointer',
            }}
            src={userProfileImages[0]}
          />
          <Text
            onClick={() => {
              const otherWindow = window.open();
              otherWindow.opener = null;
              otherWindow.location = `/user-profile/${user}/profile`;
            }}
            style={{
              marginLeft: 10,
              color: '#333333',
              verticalAlign: 'middle',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            User profile has
          </Text>

          <Progress
            style={{ marginLeft: 10, fontWeight: 'bold' }}
            strokeColor={progressColor}
            width={50}
            type="circle"
            percent={parseInt(similarityScore, 10)}
          />

          <Text
            style={{
              marginLeft: 10,
              color: '#333333',
              verticalAlign: 'middle',
              fontWeight: 'bold',
            }}
          >
            Similarity
          </Text>

          {isPreviouslyFroced && (
            <Tag
              style={{
                marginLeft: 10,
                fontWeight: 'bold',
              }}
              color="#f50"
            >
              FORCE VERIFICATION
            </Tag>
          )}
        </div>
        <div className="images-container">
          <List.Item>
            <Card
              hoverable
              onClick={() =>
                this.onOpenImages({
                  images: [userProfileImages[0], selfieImages[0]],
                  photoIndex: 0,
                })
              }
              cover={
                <img
                  className="UserImg"
                  alt="profile"
                  src={userProfileImages[0]}
                />
              }
            >
              <Meta title="Profile Image" />
            </Card>
          </List.Item>
          <List.Item>
            <Card
              hoverable
              onClick={() =>
                this.onOpenImages({
                  images: [userProfileImages[0], selfieImages[0]],
                  photoIndex: 1,
                })
              }
              cover={
                <img className="UserImg" alt="selfie" src={selfieImages[0]} />
              }
            >
              <Meta title="Selfie Image" />
            </Card>
          </List.Item>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button
            type="primary"
            shape="round"
            size="large"
            style={{
              backgroundColor: '#1AAC19',
              borderColor: '#1AAC19',
              width: '120px',
            }}
            onClick={() => onVerifyPress({ item })}
          >
            Verify
          </Button>
          <Button
            type="primary"
            shape="round"
            size="large"
            style={{
              marginLeft: '20px',
              backgroundColor: '#F15B5E',
              borderColor: '#F15B5E',
              width: '120px',
            }}
            onClick={() => onRejectPress({ item, isPreviouslyFroced })}
          >
            Reject
          </Button>
        </div>
      </Card>
    );
  };

  render() {
    const { listOfSelfieVerificationData } = this.props;
    const { isOpenImages, images, photoIndex } = this.state;

    return (
      <>
        <List
          grid={{ gutter: 24, column: 2 }}
          dataSource={listOfSelfieVerificationData}
          renderItem={this.renderItem}
        />
        {isOpenImages && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpenImages: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </>
    );
  }
}

export default UserCards;
