import React from 'react';
import { Button } from 'antd';
import './styles.scss';

const OptionPill = ({ children, className, selected, ...rest }) => (
  <Button
    className={`option-pill-button ${className} ${selected && 'selected'}`}
    shape="round"
    {...rest}
  >
    {children}
  </Button>
);

export default OptionPill;
