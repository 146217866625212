import { useState } from 'react';

export const useCollapse = (
  accordionKeys,
  currentKey,
  defaultClose = false
) => {
  const [activeKey, setActiveKey] = useState(
    defaultClose ? undefined : currentKey || accordionKeys[0]
  );

  const moveToNext = () => {
    let nextKey;
    const index = accordionKeys.indexOf(activeKey);
    if (index >= 0 && index < accordionKeys.length - 1)
      nextKey = accordionKeys[index + 1];

    setActiveKey(nextKey);
  };
  const moveToPrev = () => {
    let prevKey;
    const index = accordionKeys.indexOf(activeKey);
    if (index >= 0 && index < accordionKeys.length - 1)
      prevKey = accordionKeys[index - 1];
    setActiveKey(prevKey);
  };

  const jumpToStep = (key) => {
    setActiveKey(key);
  };

  const closePanel = () => setActiveKey(null);

  return {
    activeKey,
    moveToNext,
    moveToPrev,
    jumpToStep,
    closePanel,
  };
};
