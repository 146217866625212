export const getUserStatus = (status) => {
  switch (status) {
    case 'on_boarding_started':
      return 'Onbording Started';
    case 'marital_status':
      return 'Marital Status';
    case 'intangible_info':
      return 'Intagible Info';
    case 'personal_detail_info':
      return 'Personal Details';
    case 'education_career_info':
      return 'Education & Career';
    case 'about_myself_info':
      return 'About Myself';
    case 'user_images':
      return 'User Images';
    case 'otp_verification_info':
      return 'OTP Verification';
    case 'on_boarding_completed':
      return 'Onbording Completed';
    case 'ai_details':
      return 'AI Details';
    case 'personal_details':
      return 'Personal Details';
    case 'partner_preferences':
      return 'Partner Preferences';
    default:
      return '';
  }
};

export const getVerificationStatus = (status) => {
  switch (status) {
    case 'auto_unverified':
      return 'Auto Unverified';
    case 'auto_verified':
      return 'Auto Verified';
    case 'unverified_by_admin':
      return 'Unverified by Admin';
    case 'verified_by_admin':
      return 'Verified by Admin';
    case 'verification_on_hold':
      return 'Verification On Hold';
    default:
      return '';
  }
};

export const getOnBoardingMedium = (status) => {
  switch (status) {
    case 'facebook':
      return 'Facebook';
    case 'otp':
      return 'OTP';
    case 'apple':
      return 'Apple';
    case 'truecaller':
      return 'Truecaller';
    case 'biodata':
      return 'Biodata';
    case 'classified':
      return 'Classified';
    default:
      return '';
  }
};

export const getCurrentCity = ({ locations }) => {
  let currentLocation = null;
  // let homeLocation = null
  if (locations && locations.features.length) {
    locations.features.forEach((item) => {
      if (item.properties.tag === 'residential') {
        currentLocation = item.properties;
      }
      // if (item.properties.tag === 'hometown') {
      //   homeLocation = item.properties
      // }
    });
  }

  if (currentLocation) {
    return currentLocation.city;
  }

  return '';
};
