/* eslint-disable */
import { snakeToCamel } from './utils';

export const listOfGovtIdVerificationActionTypes = {
  listOfGovtIdVerificationLoading: 'LIST_OF_GOVT_ID_VERIFICATION_LOADING',
  listOfGovtIdVerificationSuccess: 'LIST_OF_GOVT_ID_VERIFICATION_SUCCESS',
  listOfGovtIdVerificationFailure: 'LIST_OF_GOVT_ID_VERIFICATION_FAILURE',
  removeGovtIdVerificationFromList: 'REMOVE_GOVT_ID_VERIFICATION_FROM_LIST',
  updateGovtIdVerificationStatusLoading:
    'UPDATE_GOVT_ID_VERIFICATION_STATUS_LOADING',
  updateGovtIdVerificationStatusSuccess:
    'UPDATE_GOVT_ID_VERIFICATION_STATUS_SUCCESS',
  updateGovtIdVerificationStatusFailure:
    'UPDATE_GOVT_ID_VERIFICATION_STATUS_FAILURE',
  updateCurrentDecisionCounter: 'UPDATE_CURRENT_DECISION_COUNTER',
  resetCurrentDecisionCounter: 'RESET_CURRENT_DECISION_COUNTER',
};

const initialState = {
  listOfGovtIdVerification: {
    isLoading: false,
    data: [],
    error: {},
    counter: 0,
  },
  updateGovtIdVerification: {
    isLoading: false,
    data: [],
    error: {},
  },
};

export function listOfGovtIdVerificationReducer(
  state = initialState.listOfGovtIdVerification,
  action
) {
  switch (action.type) {
    case listOfGovtIdVerificationActionTypes.listOfGovtIdVerificationSuccess: {
      return { ...state, data: action.payload, isLoading: false };
    }
    case listOfGovtIdVerificationActionTypes.listOfGovtIdVerificationFailure: {
      return { ...state, error: action.error, isLoading: false };
    }
    case listOfGovtIdVerificationActionTypes.listOfGovtIdVerificationLoading: {
      return { ...state, isLoading: true };
    }
    case listOfGovtIdVerificationActionTypes.removeGovtIdVerificationFromList: {
      const users = state.data?.users || [];
      const newUsers = users.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        data: {
          ...state.data,
          users: newUsers,
        },
      };
    }
    case listOfGovtIdVerificationActionTypes.updateCurrentDecisionCounter: {
      const currentCounter = state.counter || 0;
      const counter = currentCounter + 1;
      return {
        ...state,
        counter,
      };
    }
    case listOfGovtIdVerificationActionTypes.resetCurrentDecisionCounter: {
      return {
        ...state,
        counter: 0,
      };
    }
    default:
      return state;
  }
}

export function updateGovtIdVerificationStatusReducer(
  state = initialState.updateGovtIdVerification,
  action
) {
  switch (action.type) {
    case listOfGovtIdVerificationActionTypes.updateGovtIdVerificationStatusSuccess: {
      return { ...state, data: action.payload, isLoading: false };
    }
    case listOfGovtIdVerificationActionTypes.updateGovtIdVerificationStatusFailure: {
      return { ...state, error: action.error, isLoading: false };
    }
    case listOfGovtIdVerificationActionTypes.updateGovtIdVerificationStatusLoading: {
      return { ...state, isLoading: true };
    }
    default:
      return state;
  }
}

export const listOfGovtIdVerificationActions = {
  listOfGovtIdVerificationSuccess(usersData) {
    return {
      type: listOfGovtIdVerificationActionTypes.listOfGovtIdVerificationSuccess,
      payload: {
        users: usersData.results,
        count: usersData.count,
        next: usersData.next,
        previous: usersData.previous,
      },
    };
  },
  listOfGovtIdVerificationFailure(error) {
    return {
      type: listOfGovtIdVerificationActionTypes.listOfGovtIdVerificationFailure,
      error,
    };
  },
  listOfGovtIdVerificationLoading(bool) {
    return {
      type: listOfGovtIdVerificationActionTypes.listOfGovtIdVerificationLoading,
      isLoading: bool,
    };
  },
  removeGovtIdVerificationFromList(userData) {
    return {
      type: listOfGovtIdVerificationActionTypes.removeGovtIdVerificationFromList,
      payload: userData,
    };
  },
  updateGovtIdVerificationStatusLoading() {
    return {
      type: listOfGovtIdVerificationActionTypes.updateGovtIdVerificationStatusLoading,
    };
  },
  updateGovtIdVerificationStatusSuccess(payload) {
    return {
      type: listOfGovtIdVerificationActionTypes.updateGovtIdVerificationStatusSuccess,
      payload,
    };
  },
  updateGovtIdVerificationStatusFailure(error) {
    return {
      type: listOfGovtIdVerificationActionTypes.updateGovtIdVerificationStatusFailure,
      error,
    };
  },
  updateCurrentDecisionCounter(counter) {
    return {
      type: listOfGovtIdVerificationActionTypes.updateCurrentDecisionCounter,
      counter,
    };
  },
};

export const listOfGovtIdVerificationEffects = {
  getListOfGovtIdVerification({ limit = 1, url }) {
    return async (dispatch) => {
      dispatch(
        listOfGovtIdVerificationActions.listOfGovtIdVerificationLoading(true)
      );
      let qs = null;
      if (url) {
        qs = url;
      }

      try {
        let resp = await window.client.listGovtIdVerification({ limit, qs });
        const transformData = resp.body.results.map((row) => {
          const result = {};
          Object.keys(row).map((item) => {
            result[snakeToCamel(item)] = row[item];
            return null;
          });
          return result;
        });
        const dataToBeSent = { ...resp.body, ...{ results: transformData } };
        dispatch(
          listOfGovtIdVerificationActions.listOfGovtIdVerificationSuccess(
            dataToBeSent
          )
        );
        dispatch(listOfGovtIdVerificationActions.resetCurrentDecisionCounter());
      } catch (e) {
        dispatch(
          listOfGovtIdVerificationActions.listOfGovtIdVerificationFailure(e)
        );
      }
    };
  },
  removeUserFromList({ userData }) {
    return async (dispatch) => {
      dispatch(
        listOfGovtIdVerificationActions.removeGovtIdVerificationFromList(
          userData
        )
      );
    };
  },
  updateUserGovtIdVerificationStatus({ id, ...data }) {
    return async (dispatch) => {
      dispatch(
        listOfGovtIdVerificationActions.updateGovtIdVerificationStatusLoading()
      );

      try {
        let resp = await window.client.updateUserGovtIdVerificationStatus({
          id,
          ...data,
        });
        dispatch(
          listOfGovtIdVerificationActions.updateCurrentDecisionCounter()
        );
        dispatch(
          listOfGovtIdVerificationActions.updateGovtIdVerificationStatusSuccess(
            resp.body
          )
        );
        return { resp, error: null };
      } catch (e) {
        dispatch(
          listOfGovtIdVerificationActions.updateGovtIdVerificationStatusFailure(
            e
          )
        );
        return { resp: null, error: e };
      }
    };
  },
};
