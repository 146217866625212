import React from 'react';
import { Router } from 'react-router-dom';
import Cookies from 'js-cookie'; 
import history from './services/history';
import Routes from './routes';

import { createClient } from './@betterhalf-private/api/src';
import { API_PREFIX } from './utilities/apiUrls';

const accessToken = Cookies.get('accessToken');

window.client = createClient({
  baseURL: API_PREFIX, //! Sumit
});

if (accessToken) {
  window.client.setAuthToken(accessToken);
}

function App() {
  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
}

export default App;
