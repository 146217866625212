import { Col, Row, Alert } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { useUserData } from '../../UserProfile/CompleteProfile/UserDataContext';
import { editUserDetails, searchCity, updateLocation } from '../api';
import { SelectWithSearch } from '../Commons';
import { LOCATION_LIST } from '../config';

import DetailsCollapse from '../Details/DetailCollapse';
import DetailHeader from '../Details/DetailHeader';
import DetailPanel from '../Details/DetailPanel';
import {
  getFormattedLocationPayloadFromInternalAPI,
  extractCity,
  isValidEmail,
} from '../helpers';
import DateOfBirthSection from './DateOfBirthSection';
import GenderSection from './GenderSection';

import './styles.scss';
import UploadImageSection from './UploadImageSection';

const PreAiDetails = ({ moveToNext }) => {
  const {
    firstName: userFirstName,
    lastName: userLastName,
    email: userEmail,
    gender: userGender,
    dateOfBirth: userDateOfBirth,
    images: userImages,
    status: userStatus,
    locations: userSavedLocations,
    id: userId,
  } = useUserData();

  const [activeKey, setActiveKey] = useState(undefined);
  const [firstName, setFirstName] = useState(userFirstName);
  const [lastName, setLastName] = useState(userLastName);
  const [email, setEmail] = useState(userEmail);
  const [currentLocation, setCurrentLocation] = useState(() =>
    extractCity(userSavedLocations, 'residential')
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isImageValidationPassed, setIsImageValidationPassed] = useState(false);

  const [invalidEmail, setInvalidEmail] = useState(false);
  useEffect(() => {
    if (
      userFirstName?.length >= 2 &&
      userLastName?.length >= 1 &&
      userEmail &&
      userGender &&
      userDateOfBirth &&
      isImageValidationPassed &&
      currentLocation
    )
      setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  }, [
    userFirstName,
    userLastName,
    userEmail,
    userGender,
    userImages,
    userDateOfBirth,
    isImageValidationPassed,
    currentLocation,
  ]);
  // const debounceHandler = (callback) =>
  //   useMemo(() => debounce(callback, 500), []);
  const onClickHandler = async () => {
    if (userStatus === 'on_boarding_started') {
      const payload = {
        status: 'ai_details',
      };
      await editUserDetails(userId, payload);
    }

    moveToNext();
  };
  const handleCurrentLocation = async (city) => {
    let payload;
    setCurrentLocation(typeof city === 'string' ? city : city.displayText);

    // check if the selected city is from the list of static cities
    const filteredLocationList = LOCATION_LIST.filter(
      (_homeTown) => _homeTown.displayText === city.displayText
    );

    if (filteredLocationList.length === 0) {
      payload = getFormattedLocationPayloadFromInternalAPI(city);
    } else {
      // eslint-disable-next-line prefer-destructuring
      const temp_payload = filteredLocationList[0];
      payload = { ...temp_payload };
      delete payload.displayText;
      delete payload.id;
    }
    payload.properties.tag = 'residential';

    await updateLocation(userId, payload, 'residential');
    setActiveKey(undefined);
  };

  const updateFirstName = debounce(async () => {
    if (firstName?.length <= 2) return;
    // 👆 It's not getting the current value , if wrapped with useMemo which is expected. tried with useRef, but can't make it work
    const payload = {
      first_name: firstName,
    };
    await editUserDetails(userId, payload);
  }, 500);

  const updateLastName = debounce(async () => {
    if (lastName?.length <= 1) return;
    const payload = {
      last_name: lastName,
    };
    await editUserDetails(userId, payload);
  }, 500);

  const updateEmail = debounce(async () => {
    if (!email || email?.length === 0) {
      return;
    }
    if (!isValidEmail(email)) {
      setInvalidEmail(true);
      return;
    }
    setInvalidEmail(false);

    const payload = {
      email,
    };
    await editUserDetails(userId, payload);
  }, 500);

  const handleCollapseChange = (key) => {
    //  console.log(key);
    setActiveKey(key);
  };

  return (
    <div className="container">
      <Row gutter={[16, 24]} className="container__inputSection">
        <Col span={12}>
          <Input
            placeholder="Enter First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onBlur={updateFirstName}
            className="input"
          />
        </Col>
        <Col span={12}>
          <Input
            placeholder="Enter Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onBlur={updateLastName}
            className="input"
          />
        </Col>
        <Col span={12}>
          <Input
            placeholder="Enter Email Address"
            value={email}
            onChange={(e) => {
              setInvalidEmail(false);
              setEmail(e.target.value);
            }}
            onBlur={updateEmail}
            className="input"
          />
        </Col>
      </Row>
      {invalidEmail && (
        <Alert message="Email is not valid" showIcon type="error" />
      )}
      <GenderSection />
      <DateOfBirthSection />
      <UploadImageSection
        setIsImageValidationPassed={setIsImageValidationPassed}
      />
      <div className="collapse__container">
        <DetailsCollapse
          destroyInactivePanel
          activeKey={activeKey}
          onChange={handleCollapseChange}
        >
          <DetailPanel
            header={
              <DetailHeader
                headerTitle="Current Location"
                value={currentLocation}
                placeholder="Eg: Kolkata"
                isActive={activeKey === 'current_location'}
              />
            }
            key="current_location"
          >
            <SelectWithSearch
              selectedData={currentLocation}
              onSelect={handleCurrentLocation}
              list={LOCATION_LIST}
              isTypeVertical={false}
              searchCallback={searchCity}
              getTransformedSearchResults={(searchResults) =>
                searchResults?.result?.map((searchResult) => ({
                  ...searchResult,
                  value: searchResult.city,
                  displayText: searchResult.city,
                }))
              }
              showBtns={{ add: false, ctas: false }}
            />
          </DetailPanel>
        </DetailsCollapse>
      </div>

      <div className="container__nextButton">
        <Button onClick={onClickHandler} disabled={isButtonDisabled}>
          {userStatus === 'on_boarding_started' ? 'Next' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

export default PreAiDetails;
