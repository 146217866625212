import { isStagingEnv } from './util';

export const getApiPrefix = () => {
  // if (process.env.NODE_ENV === 'production')
  //   return process.env.REACT_APP_BASE_URL;
  if (isStagingEnv()) {
    return localStorage.getItem('bh__staging-data')
      ? JSON.parse(localStorage.getItem('bh__staging-data')).value
      : 'https://gcpstaging2.betterhalf.ai/v2';
  }
  return process.env.REACT_APP_BASE_URL;
};

export const API_PREFIX = getApiPrefix();

export const GetObject = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const fetchDashboardInfo = {
  url: `${API_PREFIX}/admin_panel/dashboard/`,
  method: 'GET',
};

export const fetchUserProfiles = {
  url: `${API_PREFIX}/users/`,
  method: 'GET',
};

export const updateUsersProfile = {
  url: `${API_PREFIX}/users/`,
  method: 'PATCH',
};

export const fetchAdminDetails = {
  url: `${API_PREFIX}/me/`,
  method: 'GET',
};

export const sendUserFeedbackEndpoint = {
  url: `/feedback/`,
  method: 'GET',
};

export const onewayLeftMatchesCountEndpoint = {
  url: '/remaining-recommendations/count/?type=one_way',
  method: 'GET',
};

export const twowayLeftMatchesCountEndpoint = {
  url: '/remaining-recommendations/count/?type=two_way',
  method: 'GET',
};
