import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg';
import './styles.scss';

const ImageInput = ({
  data,
  handleImageOperation,
  index,
  disableDelete,
  showDeleteButton,
}) => {
  const [placeholderImageUrl, setPlaceholderImageUrl] = useState(
    data.localImageUrl || data.uploadedImageUrl || null
  );

  const onDrop = useCallback(
    //! dropzone returns an array of files even if the multiple attribute is set to false
    (acceptedFiles) => {
      handleImageOperation({
        action: 'add',
        localImageUrl: URL.createObjectURL(acceptedFiles[0]),
        imageFile: acceptedFiles[0],
        imageInputComponentIndex: index,
      });
    },
    [handleImageOperation]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  useEffect(() => {
    setPlaceholderImageUrl(data.uploadedImageUrl || data.localImageUrl);
  }, [data.localImageUrl, data.uploadedImageUrl]);

  const removeFile = () => {
    if (disableDelete) return;
    handleImageOperation({ action: 'remove', imageInputComponentIndex: index });
  };

  return (
    <div className=" imageInput__wrapper">
      <div className={classNames(' imageInput__inner ')}>
        <div {...getRootProps()} className="imageInput__dropzone">
          {data.isUploading ? (
            <LoadingOutlined
              style={{
                color: 'rgba(255, 91, 145, 100)',
                zIndex: '99',
                fontSize: '22px',
              }}
            />
          ) : (
            <PlusIcon />
          )}

          <input {...getInputProps()} disabled={data.isUploading} />
        </div>
        {placeholderImageUrl && (
          <div className="imageInput__image">
            <img src={placeholderImageUrl} alt="Uploaded img" />
          </div>
        )}
      </div>
      {/* //👇 button to delete file */}
      {data.uploadedImageUrl && showDeleteButton && (
        <button
          type="button"
          className="imageInput__delete "
          onClick={removeFile}
        >
          <CloseOutlined
            style={{
              color: disableDelete ? '#ccc' : ' rgba(255, 91, 145, 100)',
            }}
          />
        </button>
      )}
    </div>
  );
};

export default ImageInput;
