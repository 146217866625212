export const Status = [
  {
    title: 'Pending',
    type: 'pending',
  },
  {
    title: 'Interested',
    type: 'interested',
  },
  {
    title: 'Not interested',
    type: 'not_interested',
  },
  {
    title: 'Call scheduled',
    type: 'vc_scheduled',
  },
  {
    title: 'Call done',
    type: 'vc_done',
  },
];
