export const RightSections = [
  {
    index: 0,
    title: 'Accounts Details',
    editable: false,
  },
  {
    index: 1,
    title: 'Personal Details',
    editable: false,
  },
  {
    index: 2,
    title: 'Education and Career',
    editable: false,
  },
  {
    index: 3,
    title: 'About',
    editable: true,
  },
  {
    index: 4,
    title: 'Lifestyle',
    editable: false,
  },
  {
    index: 5,
    title: 'Partner Preferences',
    editable: false,
  },
  {
    index: 9,
    title: 'Additional Information',
    editable: true,
  },
  {
    index: 6,
    title: 'Payment Details',
    editable: true,
  },
  {
    index: 7,
    title: 'Sparkle Details',
    editable: true,
  },
  {
    index: 12,
    title: 'Human Matchmaker Details',
    editable: true,
  },
  {
    index: 13,
    title: 'Background Verification Details',
    editable: true,
  },
  {
    index: 14,
    title: 'Kundali Details',
    editable: true,
  },
  {
    index: 8,
    title: 'User Images',
    editable: true,
  },
  {
    index: 10,
    title: 'Feedback',
    editable: true,
  },
  {
    index: 11,
    title: 'Devices',
    editable: false,
  },
];

export const FemaleRightSections = [
  {
    index: 0,
    title: 'Accounts Details',
    editable: true,
  },
  {
    index: 1,
    title: 'Personal Details',
    editable: true,
  },
  {
    index: 2,
    title: 'Education and Career',
    editable: true,
  },
  {
    index: 3,
    title: 'About',
    editable: true,
  },
  {
    index: 4,
    title: 'Lifestyle',
    editable: false,
  },
  {
    index: 5,
    title: 'Partner Preferences',
    editable: true,
  },
  {
    index: 9,
    title: 'Additional Information',
    editable: true,
  },
  {
    index: 6,
    title: 'Payment Details',
    editable: true,
  },
  {
    index: 7,
    title: 'Sparkle Details',
    editable: true,
  },
  {
    index: 12,
    title: 'Human Matchmaker Details',
    editable: true,
  },
  {
    index: 13,
    title: 'Background Verification Details',
    editable: true,
  },
  {
    index: 14,
    title: 'Kundali Details',
    editable: true,
  },
  {
    index: 8,
    title: 'User Images',
    editable: true,
  },
  {
    index: 10,
    title: 'Feedback',
    editable: true,
  },
  {
    index: 11,
    title: 'Devices',
    editable: false,
  },
];
