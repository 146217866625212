import React from 'react';
import { Input as AntdInput } from 'antd';
import './styles.scss';

const Input = ({ label, id, className, ...restProps }) => (
  <>
    {!!label && (
      <label htmlFor={id} className="label">
        {label}
      </label>
    )}
    <AntdInput className={`input ${className}`} id={id} {...restProps} />
  </>
);

export default Input;
