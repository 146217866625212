export function users({ get, post, patch, delete: del, put }) {
  return {
    listUserProfiles({ qs }) {
      return get(`/users/${qs}`);
    },
    fetchUserProfile({ id }) {
      return get(`/users/${id}/`);
    },
    updateUserProfile({ id, ...body }) {
      return patch(`/users/${id}/`, body);
    },
    deleteUserProfile({ id, ...body }) {
      return del(`/users/${id}/`, { data: body });
    },
    updatePartnerPreferences({ user_id, id, ...body }) {
      return patch(`/users/${user_id}/partner-preference/${id}/`, body);
    },
    updatePaidUserPlanDetails({ id, ...body }) {
      return post(`users/${id}/plans/`, body);
    },
    getUserFeedback({ id, qs }) {
      return get(`/users/${id}/feedback/?limit=10&${qs}`);
    },
    retrieveExpiredCount({ id }) {
      return patch(`/users/${id}/connections/revert-expired/`);
    },
    sendUserFeedback({ id, ...body }) {
      return post(`/users/${id}/feedback/`, body);
    },
    getMoreComments({ url }) {
      return get(url);
    },
    getOneWayLeftMatches({ id }) {
      return get(`/users/${id}/remaining-recommendations/count/?type=one_way`);
    },
    getTwoWayLeftMatches({ id }) {
      return get(`/users/${id}/remaining-recommendations/count/?type=two_way`);
    },
    getAdditionaInfo({ id }) {
      return get(`/users/${id}/additional-info/`);
    },
    addUserImage({ id, payload }) {
      return post(`/users/${id}/images/`, payload, {
        'Content-Type': 'multipart/form-data',
      });
    },
    deleteUserImage({ id, image_id }) {
      return del(`/users/${id}/images/${image_id}/`);
    },
    updateProfileImage({ id, ...body }) {
      return patch(`/users/${id}/images/rearrange/`, body);
    },
    getUserMatches(req) {
      return get(req);
    },
    requestReceivedCount({ id }) {
      return get(`/users/${id}/connections/received/count/`);
    },
    forceSelfieVerification({ id, ...body }) {
      return put(`/users/${id}/selfie-verification/status/`, body);
    },
    getShortlistedUsers({ id, qs }) {
      if (qs) {
        return get(`/users/${id}/human-matchmaker/shortlisted/?limit=30&${qs}`);
      }
      return get(`/users/${id}/human-matchmaker/shortlisted/?limit=30`);
    },
    getGovtIdRejectionReason({ id }) {
      return get(`/users/${id}/govt-verification/reason/`);
    },
  };
}
