/* eslint-disable */
import { snakeToCamel } from './utils';

export const listOfSelfieVerificationActionTypes = {
  listOfSelfieVerificationLoading: 'LIST_OF_SELFIE_VERIFICATION_LOADING',
  listOfSelfieVerificationSuccess: 'LIST_OF_SELFIE_VERIFICATION_SUCCESS',
  listOfSelfieVerificationFailure: 'LIST_OF_SELFIE_VERIFICATION_FAILURE',
  removeSelfieVerificationFromList: 'REMOVE_SELFIE_VERIFICATION_FROM_LIST',
  updateSelfieVerificationStatusLoading:
    'UPDATE_SELFIE_VERIFICATION_STATUS_LOADING',
  updateSelfieVerificationStatusSuccess:
    'UPDATE_SELFIE_VERIFICATION_STATUS_SUCCESS',
  updateSelfieVerificationStatusFailure:
    'UPDATE_SELFIE_VERIFICATION_STATUS_FAILURE',
  updateCurrentDecisionCounter: 'UPDATE_CURRENT_DECISION_COUNTER',
  resetCurrentDecisionCounter: 'RESET_CURRENT_DECISION_COUNTER',
};

const initialState = {
  listOfSelfieVerification: {
    isLoading: false,
    data: [],
    error: {},
    counter: 0,
  },
  updateSelfieVerification: {
    isLoading: false,
    data: [],
    error: {},
  },
};

export function listOfSelfieVerificationReducer(
  state = initialState.listOfSelfieVerification,
  action
) {
  switch (action.type) {
    case listOfSelfieVerificationActionTypes.listOfSelfieVerificationSuccess: {
      return { ...state, data: action.payload, isLoading: false };
    }
    case listOfSelfieVerificationActionTypes.listOfSelfieVerificationFailure: {
      return { ...state, error: action.error, isLoading: false };
    }
    case listOfSelfieVerificationActionTypes.listOfSelfieVerificationLoading: {
      return { ...state, isLoading: true };
    }
    case listOfSelfieVerificationActionTypes.removeSelfieVerificationFromList: {
      const users = state.data?.users || [];
      const newUsers = users.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        data: {
          ...state.data,
          users: newUsers,
        },
      };
    }
    case listOfSelfieVerificationActionTypes.updateCurrentDecisionCounter: {
      const currentCounter = state.counter || 0;
      const counter = currentCounter + 1;
      return {
        ...state,
        counter,
      };
    }
    case listOfSelfieVerificationActionTypes.resetCurrentDecisionCounter: {
      return {
        ...state,
        counter: 0,
      };
    }
    default:
      return state;
  }
}

export function updateSelfieVerificationStatusReducer(
  state = initialState.updateSelfieVerification,
  action
) {
  switch (action.type) {
    case listOfSelfieVerificationActionTypes.updateSelfieVerificationStatusSuccess: {
      return { ...state, data: action.payload, isLoading: false };
    }
    case listOfSelfieVerificationActionTypes.updateSelfieVerificationStatusFailure: {
      return { ...state, error: action.error, isLoading: false };
    }
    case listOfSelfieVerificationActionTypes.updateSelfieVerificationStatusLoading: {
      return { ...state, isLoading: true };
    }
    default:
      return state;
  }
}

export const listOfSelfieVerificationActions = {
  listOfSelfieVerificationSuccess(usersData) {
    return {
      type: listOfSelfieVerificationActionTypes.listOfSelfieVerificationSuccess,
      payload: {
        users: usersData.results,
        count: usersData.count,
        next: usersData.next,
        previous: usersData.previous,
      },
    };
  },
  listOfSelfieVerificationFailure(error) {
    return {
      type: listOfSelfieVerificationActionTypes.listOfSelfieVerificationFailure,
      error,
    };
  },
  listOfSelfieVerificationLoading(bool) {
    return {
      type: listOfSelfieVerificationActionTypes.listOfSelfieVerificationLoading,
      isLoading: bool,
    };
  },
  removeSelfieVerificationFromList(userData) {
    return {
      type: listOfSelfieVerificationActionTypes.removeSelfieVerificationFromList,
      payload: userData,
    };
  },
  updateSelfieVerificationStatusLoading() {
    return {
      type: listOfSelfieVerificationActionTypes.updateSelfieVerificationStatusLoading,
    };
  },
  updateSelfieVerificationStatusSuccess(payload) {
    return {
      type: listOfSelfieVerificationActionTypes.updateSelfieVerificationStatusSuccess,
      payload,
    };
  },
  updateSelfieVerificationStatusFailure(error) {
    return {
      type: listOfSelfieVerificationActionTypes.updateSelfieVerificationStatusFailure,
      error,
    };
  },
  updateCurrentDecisionCounter(counter) {
    return {
      type: listOfSelfieVerificationActionTypes.updateCurrentDecisionCounter,
      counter,
    };
  },
};

export const listOfSelfieVerificationEffects = {
  getListOfSelfieVerification({ limit = 1, url }) {
    return async (dispatch) => {
      dispatch(
        listOfSelfieVerificationActions.listOfSelfieVerificationLoading(true)
      );
      let qs = null;
      if (url) {
        qs = url;
      }

      try {
        let resp = await window.client.listSelfieVerification({ limit, qs });
        const transformData = resp.body.results.map((row) => {
          const result = {};
          Object.keys(row).map((item) => {
            result[snakeToCamel(item)] = row[item];
            return null;
          });
          return result;
        });
        const dataToBeSent = { ...resp.body, ...{ results: transformData } };
        dispatch(
          listOfSelfieVerificationActions.listOfSelfieVerificationSuccess(
            dataToBeSent
          )
        );
        dispatch(listOfSelfieVerificationActions.resetCurrentDecisionCounter());
      } catch (e) {
        dispatch(
          listOfSelfieVerificationActions.listOfSelfieVerificationFailure(e)
        );
      }
    };
  },
  removeUserFromList({ userData }) {
    return async (dispatch) => {
      dispatch(
        listOfSelfieVerificationActions.removeSelfieVerificationFromList(
          userData
        )
      );
    };
  },
  updateUserSelfieVerificationStatus({ id, ...data }) {
    return async (dispatch) => {
      dispatch(
        listOfSelfieVerificationActions.updateSelfieVerificationStatusLoading()
      );

      try {
        let resp = await window.client.updateUserSelfieVerificationStatus({
          id,
          ...data,
        });
        dispatch(
          listOfSelfieVerificationActions.updateCurrentDecisionCounter()
        );
        dispatch(
          listOfSelfieVerificationActions.updateSelfieVerificationStatusSuccess(
            resp.body
          )
        );
        return { resp, error: null };
      } catch (e) {
        dispatch(
          listOfSelfieVerificationActions.updateSelfieVerificationStatusFailure(
            e
          )
        );
        return { resp: null, error: e };
      }
    };
  },
};
