export function admin({ get, post, patch }) {
  return {
    getAdminDetails() {
      return get('/me/');
    },
    sendOTP({ ...body }) {
      return post('/auth/otp/send/', body);
    },
    verifyOTP({ ...body }) {
      return post('/auth/otp/verify/', body);
    },
    updateUserHmStatus({ id, connectionId, payload }) {
      return patch(
        `/users/${id}/human-matchmaker/shortlisted/${connectionId}/`,
        payload
      );
    },
    revokePlan({ id, payload }) {
      return patch(`/users/${id}/revoke-plan/`, payload);
    },
    getRefreshToken(payload){
      return post('/auth/token/refresh/', payload);
    }
  };
}
