/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

//! This file is not refactored
import React, { Component } from 'react';

import {
  Input,
  Button,
  Table,
  DatePicker,
  Pagination,
  Select,
  Slider,
} from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';

import queryString from 'query-string';
import moment from 'moment';
import { connect } from 'react-redux';
import Mixpanel from 'mixpanel-browser';
import { withRouter } from 'react-router-dom';
// import Community from './Community';
import { fetchUserProfiles } from '../../utilities/apiUrls';
import {
  isEmpty,
  camelToSnake,
  stringFormatter,
  capitalize,
} from '../../utilities/util';
import { columns } from './columnData';

// TODO : set the default values for the filters from the url and make the api call

import {
  filtersAndSorterEffects,
  listOfUsersinDashboardEffects,
} from '../../@betterhalf-private/redux/src';

import {
  sliderDetails,
  religionsPreference,
  verificationStatuses,
  govtIdVerificationStatuses,
  gender,
  incomeSearch,
  motherTongue,
  rating,
  onBoardingStatues,
  onBoardingMediums,
} from '../../components/DetailsCard/data.enum';
import './styles.less';
import DebounceSelect from '../../components/DebounceSelect';
import { fetchCommunities, fetchCityList } from '../../services/searchAPI';

const { RangePicker } = DatePicker;
const { Option } = Select;

const CALENDAR_FORMAT = 'YYYY-MM-DD HH:mm';

// function convert income number to strings =>
const convertIncomeNumberToString = (income) => {
  const [incomeFrom, incomeTo] = income.split(' - ');
  const formattedIncomeFrom = incomeFrom.substring(0, incomeFrom.length - 5);
  const formattedIncomeTo = incomeTo.substring(0, incomeTo.length - 5);
  return `${formattedIncomeFrom} - ${formattedIncomeTo} LPA`;
};

const generateSearchInput = ({
  searchEmail,
  searchIdText,
  searchFnameText,
  searchLnameText,
  searchMbNo,
  gender,
  religion,
  motherTongue,
  rating,
  verificationStatus,
  incomeToLte,
  incomeFmGte,
  ageFmGte,
  ageToLte,
  dateStrings,
  onBoardingCompletedFilter,
  cityValue,
  casteValue,
  govtIdVerificationStatus,
  onboardingStatus,
  onboardingMedium,
}) => {
  let searchInput = '';
  if (searchEmail) {
    searchInput += `&email=${searchEmail}`;
  }

  if (searchIdText) {
    searchInput += `&id=${searchIdText}`;
  }

  if (searchFnameText) {
    searchInput += `&first_name=${searchFnameText}`;
  }

  if (searchLnameText) {
    searchInput += `&last_name=${searchLnameText}`;
  }

  if (searchMbNo) {
    searchInput += `&mobile=${searchMbNo}&`;
  }

  if (gender) {
    searchInput += `&gender=${gender.toLowerCase()}`;
  }

  if (religion) {
    if (religion.includes('matter')) {
      searchInput += `&religion=`;
    } else {
      searchInput += `&religion=${religion.toLowerCase()}`;
    }
  }

  if (motherTongue) {
    searchInput += `&mother_tongue=${motherTongue.toLowerCase()}`;
  }

  if (rating) {
    searchInput += `&rating=${rating}`;
  }

  if (verificationStatus) {
    searchInput += `&${camelToSnake(
      'verificationStatus'
    )}=${verificationStatus}`;
  }

  if (incomeToLte && incomeFmGte) {
    searchInput += `&income_from__gte=${incomeFmGte}&income_to__lte=${incomeToLte}`;
  }
  if (ageToLte) {
    searchInput += `&age_from=${ageFmGte}&age_to=${ageToLte}`;
  }

  if (dateStrings[0] && dateStrings[1]) {
    searchInput += `&created_at__gte=${dateStrings[0]}&created_at__lte=${dateStrings[1]}`;
  }
  if (onBoardingCompletedFilter[0] && onBoardingCompletedFilter[1]) {
    searchInput += `&onboarding_completed__gte=${onBoardingCompletedFilter[0]}&onboarding_completed__lte=${onBoardingCompletedFilter[1]}`;
  }

  if (Array.isArray(cityValue) && !isEmpty(cityValue)) {
    let cities = '';
    cityValue.forEach((c, index) => {
      const text = index === 0 ? `${c.toLowerCase()}` : `,${c.toLowerCase()}`;
      cities += text;
    });

    searchInput += `&city=${cities}`;
  } else if (!isEmpty(cityValue)) {
    searchInput += `&city=${cityValue}`;
  }

  if (Array.isArray(casteValue) && !isEmpty(casteValue)) {
    let castes = '';
    casteValue.forEach((c, index) => {
      const text = index === 0 ? `${c.toLowerCase()}` : `,${c.toLowerCase()}`;
      castes += text;
    });

    searchInput += `&caste=${castes}`;
  } else if (!isEmpty(casteValue)) {
    searchInput += `&caste=${casteValue}`;
  }

  if (!isEmpty(govtIdVerificationStatus)) {
    searchInput += `&govt_verification_status=${govtIdVerificationStatus}`;
  }
  if (!isEmpty(onboardingStatus)) {
    searchInput += `&status=${onboardingStatus}`;
  }
  if (!isEmpty(onboardingMedium)) {
    searchInput += `&on_boarding_medium=${onboardingMedium}`;
  }
  return searchInput;
};

class SearchUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownClicked: false,
      searchEmail: '',
      searchIdText: '',
      searchFnameText: '',
      searchLnameText: '',
      searchMbNo: '',
      dateStrings: [],
      govtIdVerificationStatus: undefined,
      onboardingStatus: undefined,
      onboardingMedium: undefined,
      onBoardingCompletedFilter: [],
      gender: undefined,
      religion: undefined,
      motherTongue: undefined,
      rating: undefined,
      verificationStatus: undefined,
      incomeFmGte: undefined,
      incomeToLte: undefined,
      ageFmGte: null,
      ageToLte: null,
      disableSlider: false,
      isHoveredOnCrossButton: false,
      cityValue: [],
      casteValue: [],
      pageNo: 1,
      pageSize: 10,
    };
    this.onChange = this.onChange.bind(this);
    this.onOnboardingCompleteFilterChange =
      this.onOnboardingCompleteFilterChange.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
  }

  componentDidMount() {
    // read the strings from url , if exists => make a request to get the data
    const {
      location: { search },
      totalNoOfUsers,
      getListOfUsersData,
    } = this.props;

    const {
      email = '',
      id = '',
      first_name = '',
      last_name = '',
      mobile = '',
      gender = '',
      religion = null,
      mother_tongue = null,
      rating = null,
      verification_status = null,
      city = [],
      caste = [],
      created_at__lte = '',
      created_at__gte = '',
      onboarding_completed__lte = '',
      onboarding_completed__gte = '',
      income_from__gte = '',
      income_to__lte = '',
      age_from = '',
      age_to = '',
      page = 1,
      limit = 10,
      govt_verification_status = '',
      status = '',
      on_boarding_medium = '',
    } = queryString.parse(search);

    // return if the object is empty
    if (Object.keys(queryString.parse(search)).length === 0) return;

    const valueFromUrl = {
      searchEmail: email,
      searchIdText: id,
      searchFnameText: first_name,
      searchLnameText: last_name,
      searchMbNo: mobile,
      gender: gender ? capitalize(gender) : undefined,
      religion: religion || undefined,
      rating: rating || undefined,
      verificationStatus: verification_status || undefined,
      motherTongue: mother_tongue || undefined,
      incomeFmGte: income_from__gte || undefined,
      incomeToLte: income_to__lte || undefined,
      ageFmGte: age_from || null,
      ageToLte: age_to || null,
      cityValue: city,
      casteValue: caste,
      onBoardingCompletedFilter:
        onboarding_completed__lte && onboarding_completed__gte
          ? [onboarding_completed__gte, onboarding_completed__lte]
          : [],
      dateStrings:
        created_at__lte && created_at__gte
          ? [created_at__gte, created_at__lte]
          : [],
      govtIdVerificationStatus: govt_verification_status || undefined,
      onboardingStatus: status || undefined,
      onboardingMedium: on_boarding_medium || undefined,
      // eslint-disable-next-line radix
      pageNo: parseInt(page),
    };
    this.setState(valueFromUrl);
    this.setState({
      disableSlider: valueFromUrl.ageFmGte && valueFromUrl.ageToLte,
    });

    const searchInput = generateSearchInput(valueFromUrl);
    this.setState({
      searchedInput: searchInput,
    });

    const UserProfilesUrl = `${fetchUserProfiles.url}?page=${page}&limit=${limit}${searchInput}`;
    getListOfUsersData(UserProfilesUrl);
  }

  onChange(dates, dateStrings) {
    this.setState({
      dateStrings,
    });
  }

  onOnboardingCompleteFilterChange(dates, dateStrings) {
    this.setState({
      onBoardingCompletedFilter: dateStrings,
    });
    // console.log({ onBoardingCompletedFilter: dateStrings });
  }

  handleEmailChange = (e) => {
    this.setState({ searchEmail: e.target.value });
  };

  handleIdChange = (e) => {
    this.setState({ searchIdText: e.target.value });
  };

  handleFnameChange = (e) => {
    this.setState({ searchFnameText: e.target.value });
  };

  handleLnameChange = (e) => {
    this.setState({ searchLnameText: e.target.value });
  };

  handleMbNoChange = (e) => {
    this.setState({ searchMbNo: e.target.value });
  };

  getUserProfiles = () => {
    const {
      searchEmail,
      searchIdText,
      searchFnameText,
      searchLnameText,
      searchMbNo,
      dateStrings,
      onBoardingCompletedFilter,
      gender,
      religion,
      verificationStatus,
      incomeFmGte,
      incomeToLte,
      ageFmGte,
      ageToLte,
      cityValue,
      casteValue,
      motherTongue,
      rating,
      pageSize,
      pageNo,
      govtIdVerificationStatus,
      onboardingStatus,
      onboardingMedium,
    } = this.state;

    const { setPaginationData, getListOfUsersData } = this.props;

    const { history } = this.props;
    const searchInput = generateSearchInput({
      searchEmail,
      searchIdText,
      searchFnameText,
      searchLnameText,
      searchMbNo,
      dateStrings,
      onBoardingCompletedFilter,
      gender,
      religion,
      verificationStatus,
      incomeFmGte,
      incomeToLte,
      ageFmGte,
      ageToLte,
      cityValue,
      casteValue,
      motherTongue,
      rating,
      govtIdVerificationStatus,
      onboardingStatus,
      onboardingMedium,
    });
    if (!isEmpty(searchInput)) {
      setPaginationData({ pageNo: 1 });
    }
    history.push(`?page=${pageNo}&limit=${pageSize}${searchInput}`);
    Mixpanel.track('SEARCH_USER', { passedParams: searchInput });
    this.setState({
      pageNo: 1,
      searchedInput: searchInput,
    });
    const UserProfilesUrl = `${fetchUserProfiles.url}?page=${pageNo}&limit=${pageSize}${searchInput}`;
    getListOfUsersData(UserProfilesUrl);
  };

  onChangePage = (page, updatedPageSize) => {
    const { setPaginationData, getListOfUsersData, totalNoOfUsers, history } =
      this.props;

    const { searchedInput } = this.state;

    history.push(`?page=${page}&limit=${updatedPageSize}${searchedInput}`);
    setPaginationData({ pageNo: page });

    this.setState({
      pageSize: updatedPageSize,
      pageNo: page,
    });

    const UserProfilesUrl = `${fetchUserProfiles.url}?page=${page}&limit=${updatedPageSize}${searchedInput}`;
    getListOfUsersData(UserProfilesUrl);
  };

  itemRender = (current, type, originalElement) => {
    const { previousUrl, loadingStatus, nextUrl, getListOfUsersData } =
      this.props;
    //  console.log({ previousUrl, loadingStatus, nextUrl });
    if (type === 'prev') {
      return (
        <Button
          disabled={isEmpty(previousUrl) || loadingStatus}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 10,
          }}
          onClick={
            isEmpty(previousUrl)
              ? () => {}
              : () => {
                  Mixpanel.track('LIST_OF_USER_PAGINATION_PREVIOUS');
                  getListOfUsersData(previousUrl);
                }
          }
        >
          <LeftOutlined />
        </Button>
      );
    }

    if (type === 'next') {
      return (
        <Button
          disabled={isEmpty(nextUrl) || loadingStatus}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 10,
          }}
          onClick={
            isEmpty(nextUrl)
              ? () => {}
              : () => {
                  Mixpanel.track('LIST_OF_USER_PAGINATION_NEXT');
                  getListOfUsersData(nextUrl);
                }
          }
        >
          <RightOutlined />
        </Button>
      );
    }

    return originalElement;
  };

  getDropDownChildren = ({ dropDownKey }) => {
    const children = [];
    if (dropDownKey === 'religion') {
      religionsPreference.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (dropDownKey === 'motherTongue') {
      motherTongue.map((i) => {
        children.push(<Option key={i}>{capitalize(i)}</Option>);
        return null;
      });
    } else if (dropDownKey === 'rating') {
      rating.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (dropDownKey === 'Verification Status') {
      verificationStatuses.map((i) => {
        children.push(
          <Option key={i}>
            {stringFormatter({ text: i, type: 'string' })}
          </Option>
        );
        return null;
      });
    } else if (dropDownKey === 'GovtID Verification Status') {
      govtIdVerificationStatuses.map((i) => {
        children.push(
          <Option key={i}>
            {stringFormatter({ text: i, type: 'string' })}
          </Option>
        );
        return null;
      });
    } else if (dropDownKey === 'Onboarding Status') {
      onBoardingStatues.map((i) => {
        children.push(
          <Option key={i}>
            {stringFormatter({ text: i, type: 'string' })}
          </Option>
        );
        return null;
      });
    } else if (dropDownKey === 'Onboarding Medium') {
      onBoardingMediums.map((i) => {
        children.push(
          <Option key={i}>
            {stringFormatter({ text: i, type: 'string' })}
          </Option>
        );
        return null;
      });
    } else if (dropDownKey === 'gender') {
      gender.map((i) => {
        children.push(<Option key={i}>{i}</Option>);
        return null;
      });
    } else if (dropDownKey === 'income') {
      incomeSearch.map((i) => {
        if (i !== '50 - 100 LPA') {
          children.push(<Option key={i}>{i}</Option>);
        }
        return null;
      });
    }
    return children;
  };

  handleDropDownChange = (value, { dropDownKey }) => {
    if (dropDownKey === 'religion') {
      this.setState({
        religion: value,
      });
    } else if (dropDownKey === 'motherTongue') {
      this.setState({
        motherTongue: value,
      });
    } else if (dropDownKey === 'Verification Status') {
      this.setState({
        verificationStatus: value,
      });
    } else if (dropDownKey === 'GovtID Verification Status') {
      this.setState({
        govtIdVerificationStatus: value,
      });
    } else if (dropDownKey === 'Onboarding Status') {
      this.setState({
        onboardingStatus: value,
      });
    } else if (dropDownKey === 'Onboarding Medium') {
      this.setState({
        onboardingMedium: value,
      });
    } else if (dropDownKey === 'gender') {
      this.setState({
        gender: value,
      });
    } else if (dropDownKey === 'rating') {
      this.setState({
        rating: value,
      });
    } else if (dropDownKey === 'income') {
      if (value) {
        const val1 = Number(
          `${value.substring(0, value.indexOf('-') - 1)}00000`
        );
        const val2 = Number(
          `${value.substring(
            value.indexOf('-') + 2,
            value.indexOf('L') - 1
          )}00000`
        );

        this.setState({
          incomeFmGte: val1,
          incomeToLte: val2,
        });
        return;
      }

      this.setState({
        incomeFmGte: null,
        incomeToLte: null,
      });
    }
  };

  resetSearch() {
    const { history } = this.props;

    this.setState({
      dropDownClicked: false,
      searchEmail: '',
      searchIdText: '',
      searchFnameText: '',
      searchLnameText: '',
      searchMbNo: '',
      dateStrings: [],
      onBoardingCompletedFilter: [],
      gender: undefined,
      religion: undefined,
      motherTongue: undefined,
      rating: undefined,
      verificationStatus: undefined,
      incomeFmGte: undefined,
      incomeToLte: undefined,
      ageFmGte: null,
      ageToLte: null,
      disableSlider: false,
      cityValue: [],
      casteValue: [],
      pageNo: 1,
      pageSize: 10,
      onboardingStatus: undefined,
      onboardingMedium: undefined,
    });
    history.push('?page=1&limit=10');
  }

  render() {
    const {
      dropDownClicked,
      searchEmail,
      searchIdText,
      searchFnameText,
      searchLnameText,
      searchMbNo,
      cityValue,
      casteValue,
      pageSize,
      gender,
      dateStrings,
      onBoardingCompletedFilter,
      religion,
      motherTongue,
      rating,
      verificationStatus,
      govtIdVerificationStatus,
      incomeFmGte,
      incomeToLte,
      ageFmGte,
      ageToLte,
      disableSlider,
      isHoveredOnCrossButton,
      pageNo,
      onboardingStatus,
      onboardingMedium,
    } = this.state;

    const { listOfUsers, loadingStatus, filters, sorter, totalNoOfUsers } =
      this.props;

    return (
      <div className="SearchUser">
        <div style={{ width: 600, paddingBottom: 16 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: 10,
              paddingTop: 25,
            }}
          >
            {dropDownClicked ? (
              <CaretUpOutlined
                style={{ marginRight: 20 }}
                onClick={() => {
                  this.setState({ dropDownClicked: !dropDownClicked });
                }}
              />
            ) : (
              <CaretDownOutlined
                style={{ marginRight: 20 }}
                onClick={() => {
                  this.setState({ dropDownClicked: !dropDownClicked });
                }}
              />
            )}
            <Input
              allowClear
              size="large"
              value={searchEmail}
              placeholder="Email"
              onChange={this.handleEmailChange}
              disabled={loadingStatus}
              onPressEnter={this.getUserProfiles}
            />
            &nbsp;&nbsp;
            <Button
              shape="round"
              size="large"
              type="primary"
              onClick={this.getUserProfiles}
              loading={loadingStatus}
              disabled={loadingStatus}
            >
              Search
            </Button>
            &nbsp;&nbsp;
            <Button
              shape="round"
              size="large"
              type="primary"
              onClick={this.resetSearch}
              // loading={loadingStatus}
              // disabled={loadingStatus}
            >
              Reset
            </Button>
          </div>

          {dropDownClicked && (
            <div
              style={{ width: '73%', marginLeft: 34 }}
              className="searchUser__input-wrapper"
            >
              <div style={{ paddingBottom: 10 }}>
                <Input
                  size="large"
                  allowClear
                  value={searchIdText}
                  placeholder="id"
                  onChange={this.handleIdChange}
                  disabled={loadingStatus}
                  onPressEnter={this.getUserProfiles}
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Input
                  size="large"
                  allowClear
                  value={searchFnameText}
                  placeholder="First Name"
                  onChange={this.handleFnameChange}
                  disabled={loadingStatus}
                  onPressEnter={this.getUserProfiles}
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Input
                  size="large"
                  allowClear
                  value={searchLnameText}
                  placeholder="Last Name"
                  onChange={this.handleLnameChange}
                  disabled={loadingStatus}
                  onPressEnter={this.getUserProfiles}
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Input
                  size="large"
                  allowClear
                  value={searchMbNo}
                  placeholder="Mobile No."
                  onChange={this.handleMbNoChange}
                  disabled={loadingStatus}
                  onPressEnter={this.getUserProfiles}
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Select
                  defaultValue={gender}
                  showSearch
                  mode="default"
                  placeholder="Gender"
                  onChange={(value) =>
                    this.handleDropDownChange(value, {
                      dropDownKey: 'gender',
                    })
                  }
                  style={{ width: '100%' }}
                  allowClear
                  size="large"
                  disabled={loadingStatus}
                >
                  {this.getDropDownChildren({ dropDownKey: 'gender' })}
                </Select>
              </div>
              <div style={{ paddingBottom: 10 }}>
                <p
                  style={{
                    marginBottom: 4,
                    paddingLeft: 10,
                    fontSize: 16,
                    // fontWeight: 'bold',
                    color: '#484444',
                  }}
                >
                  Onboarding Started Range :
                </p>
                <RangePicker
                  // placeholder={['Created Start Date', 'Created End Date']}
                  size="large"
                  showTime
                  format={CALENDAR_FORMAT}
                  allowClear
                  disabled={loadingStatus}
                  defaultValue={
                    dateStrings[0] && dateStrings[1]
                      ? [
                          moment(dateStrings[0], CALENDAR_FORMAT),
                          moment(dateStrings[1], CALENDAR_FORMAT),
                        ]
                      : null
                  }
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                    'This Year': [
                      moment().startOf('year'),
                      moment().endOf('year'),
                    ],
                  }}
                  onChange={this.onChange}
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <p
                  style={{
                    marginBottom: 4,
                    paddingLeft: 10,
                    fontSize: 16,
                    // fontWeight: 'bold',
                    color: '#484444',
                  }}
                >
                  Onboarding Completed Range :
                </p>
                <RangePicker
                  size="large"
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  allowClear
                  disabled={loadingStatus}
                  defaultValue={
                    onBoardingCompletedFilter[0] && onBoardingCompletedFilter[1]
                      ? [
                          moment(onBoardingCompletedFilter[0], CALENDAR_FORMAT),
                          moment(onBoardingCompletedFilter[1], CALENDAR_FORMAT),
                        ]
                      : null
                  }
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                    'This Year': [
                      moment().startOf('year'),
                      moment().endOf('year'),
                    ],
                  }}
                  onChange={this.onOnboardingCompleteFilterChange}
                />
              </div>
              {/* Age Slider for filtering users */}
              <div className="div-age-slider">
                <span>Age: </span>
                <Slider
                  className="slider-css"
                  range
                  marks={sliderDetails.marksPointingToEnds}
                  defaultValue={
                    ageFmGte && ageToLte
                      ? [ageFmGte, ageToLte]
                      : [
                          sliderDetails.defaultBeginningValue,
                          sliderDetails.defaultEndingValue,
                        ]
                  }
                  step={sliderDetails.steps}
                  min={sliderDetails.min}
                  max={sliderDetails.max}
                  onChange={(values) => {
                    this.setState({
                      ageFmGte: values[0],
                      ageToLte: values[1],
                      disableSlider: true,
                    });
                  }}
                />

                {/* Cross button for disabling the effect of slider */}
                {disableSlider && (
                  <button
                    className="crossMarkAgeSlider"
                    type="button"
                    onMouseEnter={() => {
                      this.setState({
                        isHoveredOnCrossButton: true,
                      });
                    }}
                    onMouseLeave={() => {
                      this.setState({
                        isHoveredOnCrossButton: false,
                      });
                    }}
                    onClick={() => {
                      this.setState({
                        disableSlider: false,
                        ageFmGte: null,
                        ageToLte: null,
                      });
                    }}
                    style={{
                      background: isHoveredOnCrossButton
                        ? '#939393'
                        : '#A0A0A0',
                    }}
                  >
                    x{' '}
                  </button>
                )}
              </div>

              <div style={{ paddingBottom: 10 }}>
                <DebounceSelect
                  mode="multiple"
                  value={casteValue}
                  style={{ width: '100%' }}
                  allowClear
                  size="large"
                  placeholder="Select Castes"
                  fetchOptions={fetchCommunities}
                  onChange={(newValue) => {
                    this.setState({
                      casteValue: newValue,
                    });
                  }}
                />
              </div>

              {/* Community  */}

              {/* <Community /> */}
              <div style={{ paddingBottom: 10 }}>
                <Select
                  showSearch
                  mode="default"
                  placeholder="Religion"
                  defaultValue={religion}
                  onChange={(value) =>
                    this.handleDropDownChange(value, {
                      dropDownKey: 'religion',
                    })
                  }
                  style={{ width: '100%' }}
                  allowClear
                  size="large"
                  disabled={loadingStatus}
                >
                  {this.getDropDownChildren({ dropDownKey: 'religion' })}
                </Select>
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Select
                  showSearch
                  mode="default"
                  placeholder="Mother tounge"
                  onChange={(value) =>
                    this.handleDropDownChange(value, {
                      dropDownKey: 'motherTongue',
                    })
                  }
                  defaultValue={motherTongue}
                  style={{ width: '100%' }}
                  allowClear
                  size="large"
                  disabled={loadingStatus}
                >
                  {this.getDropDownChildren({ dropDownKey: 'motherTongue' })}
                </Select>
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Select
                  showSearch
                  mode="default"
                  placeholder="Verification Status"
                  onChange={(value) =>
                    this.handleDropDownChange(value, {
                      dropDownKey: 'Verification Status',
                    })
                  }
                  defaultValue={verificationStatus}
                  style={{ width: '100%' }}
                  allowClear
                  size="large"
                  disabled={loadingStatus}
                >
                  {this.getDropDownChildren({
                    dropDownKey: 'Verification Status',
                  })}
                </Select>
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Select
                  showSearch
                  mode="default"
                  placeholder="GovtID Verification Status"
                  onChange={(value) =>
                    this.handleDropDownChange(value, {
                      dropDownKey: 'GovtID Verification Status',
                    })
                  }
                  defaultValue={govtIdVerificationStatus}
                  style={{ width: '100%' }}
                  allowClear
                  size="large"
                  disabled={loadingStatus}
                >
                  {this.getDropDownChildren({
                    dropDownKey: 'GovtID Verification Status',
                  })}
                </Select>
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Select
                  showSearch
                  mode="default"
                  placeholder="Onboarding Status"
                  onChange={(value) =>
                    this.handleDropDownChange(value, {
                      dropDownKey: 'Onboarding Status',
                    })
                  }
                  defaultValue={onboardingStatus}
                  style={{ width: '100%' }}
                  allowClear
                  size="large"
                  disabled={loadingStatus}
                >
                  {this.getDropDownChildren({
                    dropDownKey: 'Onboarding Status',
                  })}
                </Select>
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Select
                  showSearch
                  mode="default"
                  placeholder="Onboarding Medium"
                  onChange={(value) =>
                    this.handleDropDownChange(value, {
                      dropDownKey: 'Onboarding Medium',
                    })
                  }
                  defaultValue={onboardingMedium}
                  style={{ width: '100%' }}
                  allowClear
                  size="large"
                  disabled={loadingStatus}
                >
                  {this.getDropDownChildren({
                    dropDownKey: 'Onboarding Medium',
                  })}
                </Select>
              </div>

              <div style={{ paddingBottom: 10 }}>
                <Select
                  showSearch
                  mode="default"
                  placeholder="Income"
                  defaultValue={
                    incomeFmGte &&
                    incomeToLte &&
                    convertIncomeNumberToString(
                      `${incomeFmGte} - ${incomeToLte}`
                    )
                  }
                  onChange={(value) =>
                    this.handleDropDownChange(value, {
                      dropDownKey: 'income',
                    })
                  }
                  style={{ width: '100%' }}
                  allowClear
                  size="large"
                  disabled={loadingStatus}
                >
                  {this.getDropDownChildren({
                    dropDownKey: 'income',
                  })}
                </Select>
              </div>
              <div style={{ paddingBottom: 10 }}>
                <DebounceSelect
                  mode="multiple"
                  value={cityValue}
                  style={{ width: '100%' }}
                  allowClear
                  size="large"
                  placeholder="Select Cities"
                  fetchOptions={fetchCityList}
                  onChange={(newValue) => {
                    this.setState({
                      cityValue: newValue,
                    });
                  }}
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Select
                  showSearch
                  mode="default"
                  placeholder="Rating"
                  onChange={(value) =>
                    this.handleDropDownChange(value, {
                      dropDownKey: 'rating',
                    })
                  }
                  defaultValue={rating}
                  style={{ width: '100%' }}
                  allowClear
                  size="large"
                  disabled={loadingStatus}
                >
                  {this.getDropDownChildren({ dropDownKey: 'rating' })}
                </Select>
              </div>
            </div>
          )}
        </div>

        <Table
          loading={loadingStatus}
          columns={columns({ filters, sorter })}
          dataSource={!isEmpty(listOfUsers) ? listOfUsers : []}
          rowKey={(row) => row.id}
          scroll={{ x: true }}
          pagination={false}
          onChange={(pagination, filter, sorter) => {
            const { setSorterData, setFiltersData } = this.props;
            if (filter) {
              setFiltersData({ filters: filter });
            }

            if (sorter) {
              setSorterData({ sorter });
            }
          }}
        />
        {true && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 10,
            }}
          >
            <Pagination
              showQuickJumper
              hideOnSinglePage
              current={pageNo}
              total={totalNoOfUsers}
              onChange={this.onChangePage}
              itemRender={this.itemRender}
              pageSizeOptions={['10', '25']}
              defaultPageSize={pageSize}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loadingStatus: state.listOfUsersData.isLoading,
  previousUrl: state.listOfUsersData.data.previous,
  totalNoOfUsers: state.listOfUsersData.data.count,
  nextUrl: state.listOfUsersData.data.next,
  filters: state.filtersAndSortData.filters,
  sorter: state.filtersAndSortData.sorter,
  pageNo: state.filtersAndSortData.pageDetails,
  listOfUsers: state.listOfUsersData.data.users,
});

export default connect(mapStateToProps, {
  getListOfUsersData: listOfUsersinDashboardEffects.getListOfUsersData,
  setFiltersData: filtersAndSorterEffects.setFiltersData,
  setSorterData: filtersAndSorterEffects.setSorterData,
  setPaginationData: filtersAndSorterEffects.setPaginationData,
})(withRouter(SearchUser));
