import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import Sparkle from '../../../containers/UserProfile/assets/sparkle.png';
import IntroMessage from '../../../containers/UserProfile/assets/intro.png';

import {
  isEmpty,
  _calculateAge,
  capitalize,
  getHMStatus,
} from '../../../utilities/util';
import {
  heightConverter,
  incomeConverter,
} from '../../../@betterhalf-private/redux/src/utils';
import HMStatusIcon from '../../../assets/HMStatusIcon';
import { Status } from './status';

const PLACEHOLDER_IMAGE =
  'https://testimagesv1.s3.ap-south-1.amazonaws.com/admin/placeholder%403x.png';

const ShortlistedItem = ({ item, id: userId, updateUserHmStatus }) => {
  const { title, color } = getHMStatus(item.state);
  const [status, setStatus] = useState(title);
  const [image, setImage] = useState(item.receiver.images[0]);
  const [statusColor, setStatusColor] = useState(color);

  const {
    id,
    receiver: {
      id: receiver_id,
      first_name,
      last_name,
      date_of_birth,
      height,
      designation,
      company_name,
      caste,
      religion,
      income_from,
      income_to,
      mobile,
      current_city,
      current_country,
      active_at,
    },
    seen_at,
    sparkle_message,
    intro_message,
    is_chat_connection,
  } = item;

  const HEIGHT = heightConverter({ inches: height }) || '';
  const AGE = _calculateAge(date_of_birth) || '';
  const DESIGNATION = capitalize(designation) || '';
  const COMPANY = capitalize(company_name) || '';
  const CASTE = capitalize(caste) || '';
  const RELIGION = capitalize(religion) || '';
  const LOCATION = `${capitalize(current_city)}, ${capitalize(
    current_country
  )}`;
  const INCOME = incomeConverter({
    incomeFrom: income_from,
    incomeTo: income_to,
  });
  const MOBILE = mobile ? `+91 ${mobile}` : '';

  const isSparkle = sparkle_message === '' || !isEmpty(sparkle_message);
  const isIntro = intro_message === '' || !isEmpty(intro_message);

  const onClickStatus = async (e) => {
    // eslint-disable-next-line no-shadow
    const { title, color } = getHMStatus(e.key);

    setStatus(title);
    setStatusColor(color);
    const payload = {
      hm_state: e.key,
    };
    updateUserHmStatus({ id: userId, connectionId: id, payload });
  };

  const Items = Status.map((i) => (
    <Menu.Item onClick={onClickStatus} key={i.type}>
      {i.title}
    </Menu.Item>
  ));

  const menu = <Menu>{Items}</Menu>;

  return (
    <div className="ShortlistedUser" key={`connection_${id}`}>
      <div className="ShortlistedUser__User">
        <Link
          to={{
            pathname: `/user-profile/${receiver_id}/profile`,
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="profile icon"
            src={image}
            onError={() => {
              setImage(PLACEHOLDER_IMAGE);
            }}
            className={`ShortlistedUser__UserImage ${
              isSparkle ? 'GoldBorder' : ''
            }${isIntro ? 'PinkBorder' : ''}`}
          />
        </Link>
        <div className="ShortlistedUser__Details">
          <Dropdown
            disabled={item.state === 'accepted' || item.state === 'declined'}
            overlay={menu}
            trigger={['click']}
          >
            <div className="WithIcon Cursor">
              <HMStatusIcon color={statusColor} />
              <p
                className="ShortlistedUser__Details_SubTitle Pinktext"
                style={{ color: statusColor }}
              >{`${status}`}</p>
            </div>
          </Dropdown>
          <div style={{ height: 20 }} />
          <div className="WithIcon">
            <p className="ShortlistedUser__Details_Title">
              {`${first_name} ${last_name}, ${AGE}     `}
            </p>
            <p className="ShortlistedUser__Details_SubTitle Text">
              {active_at}
            </p>
          </div>
          <p className="ShortlistedUser__Details_SubTitle">{LOCATION}</p>
          <p className="ShortlistedUser__Details_SubTitle">{`${DESIGNATION}, ${COMPANY}`}</p>
          <p className="ShortlistedUser__Details_SubTitle">{`${HEIGHT} | ${CASTE} / ${RELIGION} | ${INCOME}`}</p>
          <p className="ShortlistedUser__Details_SubTitle">{`${MOBILE}`}</p>
          {isSparkle && (
            <div
              style={{
                marginTop: 5,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img
                alt="sparkle icon"
                style={{ width: 24, height: 24, marginRight: 10 }}
                src={Sparkle}
              />
              {sparkle_message && (
                <p className="ShortlistedUser__Details_DarkSubTitle">
                  {`Sparkle Message: ${sparkle_message}`}
                </p>
              )}
            </div>
          )}
          {isIntro && (
            <div
              style={{
                marginTop: 5,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img
                alt="intro icon"
                style={{ width: 20, height: 20, marginRight: 20 }}
                src={IntroMessage}
              />
              {intro_message && (
                <p className="ShortlistedUser__Details_DarkSubTitle">
                  {`Intro Message: ${intro_message}`}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      <div>
        {seen_at ? (
          <div className="ShortlistedUser__Seen">
            <p className="ShortlistedUser__Seen_Text">{seen_at}</p>
          </div>
        ) : (
          !seen_at && (
            <div className="ShortlistedUser__Seen Purple">
              <p className="ShortlistedUser__Seen_Text PurpleText">Unseen</p>
            </div>
          )
        )}
        {is_chat_connection && (
          <div className="ShortlistedUser__Connected">
            <p className="ShortlistedUser__Connected_Text">Via Chat</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShortlistedItem;
