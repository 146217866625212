import { combineReducers } from 'redux';

import {
  otpReducer,
  sendOtpReducer,
} from '../@betterhalf-private/redux/src/otp';
import {
  showMatchesReducer,
  userDataReducer,
  updateUserReducer,
  userFeedbackReducer,
  additionalInfoReducer,
  partnerPreferencesReducer,
  userProfileDeleteReducer,
  shortlistedUsersReducer,
} from '../@betterhalf-private/redux/src/userProfile';
import {
  adminDataReducer,
  sendUserFeedbackReducer,
} from '../@betterhalf-private/redux/src/admin';
import { filtersAndSortReducer } from '../@betterhalf-private/redux/src/filtersAndSorter';
import { listOfUsersReducer } from '../@betterhalf-private/redux/src/listOfUsersInDashboard';
import {
  paymentPlansReducer,
  sparklePlansReducer,
  paymentLinksReducer,
  paymentsHistoryReducer,
  hmPlansReducer,
  bvPlansReducer,
  kundaliPlansReducer,
} from '../@betterhalf-private/redux/src/payments';
import {
  listOfSelfieVerificationReducer,
  updateSelfieVerificationStatusReducer,
} from '../@betterhalf-private/redux/src/selfieVerification';
import {
  listOfGovtIdVerificationReducer,
  updateGovtIdVerificationStatusReducer,
} from '../@betterhalf-private/redux/src/govtIdVerification';
import {
  matchesReducer,
  addMatchesReducer,
} from '../@betterhalf-private/redux/src/sales';

const rootReducer = combineReducers({
  listOfUsersData: listOfUsersReducer,
  userData: userDataReducer,
  adminData: adminDataReducer,
  updateUserData: updateUserReducer,
  verifyOtpData: otpReducer,
  sendOtpData: sendOtpReducer,
  filtersAndSortData: filtersAndSortReducer,
  userFeedbackData: userFeedbackReducer,
  sendUserFeedback: sendUserFeedbackReducer,
  additionalInfo: additionalInfoReducer,
  addMatches: addMatchesReducer,
  matches: matchesReducer,
  partnerPreferenceUpdateStatus: partnerPreferencesReducer,
  showMatches: showMatchesReducer,
  userProfileDelete: userProfileDeleteReducer,
  paymentPlans: paymentPlansReducer,
  sparklePlans: sparklePlansReducer,
  paymentLinks: paymentLinksReducer,
  paymentsHistory: paymentsHistoryReducer,
  listOfSelfieVerification: listOfSelfieVerificationReducer,
  updateSelfieVerificationStatus: updateSelfieVerificationStatusReducer,
  listOfGovtIdVerification: listOfGovtIdVerificationReducer,
  updateGovtIdVerificationStatus: updateGovtIdVerificationStatusReducer,
  hmPlans: hmPlansReducer,
  bvPlans: bvPlansReducer,
  kundaliPlans: kundaliPlansReducer,
  shortlistedUsers: shortlistedUsersReducer,
});

export default rootReducer;
