import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import Mixpanel from 'mixpanel-browser';
import { Button, Layout, List, Col, message, Modal, Row } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';

import SalesSection from '../SalesSection';
import DetailsCard from '../../components/DetailsCard';
import DeleteAccountModal from './DeleteAccountModal';
import EditProfileModal from './EditProfileModal';
import FeedbackModal from './FeedbackModal';
import ImageUploadModal from './ImageUploadModal';
import CompleteProfile from './CompleteProfile';

import {
  accountsDetails,
  personalDetails,
  educationDetails,
  lifestyleDetails,
  partnerPreferenceDetails,
  aboutDetails,
  paymentDetails,
  sparkleDetails,
  hmDetails,
  bvDetails,
  kundaliDetails,
} from '../../components/DetailsCard/data.enum';
import {
  userProfileEffects,
  adminEffects,
} from '../../@betterhalf-private/redux/src';
import './styles.scss';
import IcoReligion from './assets/religion.png';
import IcoHeight from './assets/height.png';
import IcoMotherTongue from './assets/motherTongue.png';
import IcoHometown from './assets/hometown.png';
import IcoCollege from './assets/college.png';
import IcoJob from './assets/job.png';
import IcoSalary from './assets/salary.png';
import IcoFood from './assets/food.png';
import IcoDrinks from './assets/drinks.png';
import IcoSmoke from './assets/smoke.png';
import IcoSettledown from './assets/settledown.png';
import ImgBackdrop from './assets/large-backdrop.png';
import ImgPin from './assets/pin.png';
import Premium from './assets/premium.png';
import Sparkle from './assets/sparkle.png';
import ImgBriefcase from './assets/briefcase.png';
import { _calculateAge, isEmpty, capitalize } from '../../utilities/util';
import { heightConverter, incomeConverter } from '../../utilities/helper';
import { RightSections, FemaleRightSections } from './sections';
import UnverifyModal from './UnverifyModal';
import ShowMatchesModal from './ShowMatchesModal';
import RestoreExpireModal from './RestoreExpireModal';
import AdminHeader from '../../components/AdminHeader';
import BuyPremiumModal from './BuyPremiumModal';
import BuySparkleModal from './BuySparkleModal';
import BuyHmModal from './BuyHmModal';
import BuyBvModal from './BuyBvModal';
import BuyKundaliModal from './BuyKundaliModal';
import PaymentLinkModal from './PaymentLinkModal';
import SparkleLinkModal from './SparkleLinkModal';
import PaymentsHistoryModal from './PaymentsHistoryModal';
import HmSection from '../HmSection';
import Rating from '../Rating/Rating';
import VerificationOnHoldModal from './VerificationOnHoldModal';

const { confirm } = Modal;

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2161710_sqvituvwiuj.js',
});

const grid = {
  gutter: 6,
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
  xl: 1,
  xxl: 1,
};

const ProfileDetailsIndex = {
  accountsDetails: 0,
  personalDetails: 1,
  educationDetails: 2,
  aboutDetails: 3,
  lifestyleDetails: 4,
  partnerPreferenceDetails: 5,
  paymentDetails: 6,
  sparkleDetails: 7,
  hmDetails: 12,
  bvDetails: 13,
  kundaliDetails: 14,
  userImageDetails: 8,
  additionalInfoDetails: 9,
  feedbackDetails: 10,
};

const pages = {
  profile: {
    index: '1',
    title: 'Profile',
  },
  sales: {
    index: '2',
    title: 'Sales',
  },
  hm: {
    index: '3',
    title: 'Human Matchmaker',
  },
};

class UserProfile extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { page },
      },
    } = props;

    this.state = {
      adminImage: null,
      modalStatus: false,
      verificationOnHoldModalStatus: false,
      editProfileModalStatus: false,
      editProfileFields: [],
      selectedField: null,
      feedbackModalStatus: false,
      showMatchesModalStatus: false,
      imageUploadModalStatus: false,
      showDeleteAccountModal: false,
      showRestoreExpireModal: false,
      showPremiumPlanModal: false,
      showSparklePlanModal: false,
      showCopyPaymentLinkModal: false,
      showCopySparkleLinkModal: false,
      showPaymentHistoryModal: false,
      showUserProfile: page === 'profile',
      showSales: page === 'sales',
      showHM: page === 'hm',
      defaultActiveKey: pages[page].index,
      showCompleteProfileSection: false,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { userId },
      },
      getUserProfileData,
      getAdditonalInfo,
      location: { state: { redirectedFromCreateProfile = false } = {} },
    } = this.props;
    getUserProfileData({ id: userId });
    getAdditonalInfo({ id: userId });
    const adminImage = Cookies.get('adminImage') || null;
    this.setState({
      adminImage,
      showCompleteProfileSection: redirectedFromCreateProfile,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      updateUserDataSuccess,
      updateUserDataFailure,
      getUserProfileData,
      userData,
    } = this.props;
    if (updateUserDataSuccess && !prevProps.updateUserDataSuccess) {
      getUserProfileData({ id: userData.id });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        modalStatus: false,
        verificationOnHoldModalStatus: false,
      });
    }

    if (
      JSON.stringify(updateUserDataFailure) !==
      JSON.stringify(prevProps.updateUserDataFailure)
    ) {
      const { detail = null } = updateUserDataFailure;

      if (detail && detail.includes('transition from `auto_unverified`')) {
        message.error(detail, 5);
        return;
      }

      if (updateUserDataFailure && updateUserDataFailure.non_field_errors) {
        if (
          updateUserDataFailure.non_field_errors[0].includes(
            ' mobile, isd_code must make a unique set.'
          )
        ) {
          message.error(
            `An account already exists with the mobile no you trying to update`,
            5
          );
        } else {
          message.error(updateUserDataFailure.non_field_errors[0], 5);
        }
      }
    }
  }

  componentWillUnmount() {
    const { clearUserData } = this.props;
    clearUserData();
  }

  handleVerifyClick = ({ id }) => {
    const { updateUserDetails } = this.props;
    const data = {
      verification_status: 'verified_by_admin',
    };
    Mixpanel.track('CHANGE_VERIFICATION_STATUS', {
      id,
      data,
    });
    updateUserDetails({ id, data });
  };

  handleClick = ({ id, verificationStatus }) => {
    const { updateUserDetails } = this.props;
    if (verificationStatus.includes('unverified')) {
      const data = {
        verification_status: 'verified_by_admin',
      };
      Mixpanel.track('CHANGE_VERIFICATION_STATUS', {
        id,
        data,
      });
      updateUserDetails({ id, data });
    } else {
      this.setState({
        modalStatus: true,
      });
    }
  };

  handleHoldVerifyClick = () => {
    this.setState({
      verificationOnHoldModalStatus: true,
    });
  };

  getFields = (index) => {
    switch (index) {
      case ProfileDetailsIndex.accountsDetails:
        return accountsDetails;
      case ProfileDetailsIndex.personalDetails:
        return personalDetails;
      case ProfileDetailsIndex.educationDetails:
        return educationDetails;
      case ProfileDetailsIndex.aboutDetails:
        return aboutDetails;
      case ProfileDetailsIndex.lifestyleDetails:
        return lifestyleDetails;
      case ProfileDetailsIndex.partnerPreferenceDetails:
        return partnerPreferenceDetails;
      case ProfileDetailsIndex.paymentDetails:
        return paymentDetails;
      case ProfileDetailsIndex.sparkleDetails:
        return sparkleDetails;
      case ProfileDetailsIndex.hmDetails:
        return hmDetails;
      case ProfileDetailsIndex.bvDetails:
        return bvDetails;
      case ProfileDetailsIndex.kundaliDetails:
        return kundaliDetails;
      default:
        return {};
    }
  };

  getPaymentHistory = () => {
    this.setState({
      showPaymentHistoryModal: true,
    });
  };

  introCallConfirmationModal = ({ type }) => {
    const {
      revokePlan,
      getUserProfileData,
      match: {
        params: { userId },
      },
    } = this.props;
    confirm({
      title: 'Are you sure?',
      content:
        'NOTE: PLEASE MAKE SURE YOU HAVE TAKEN THE CALL WITH USER. THIS ACTION CANNOT BE UNDONE',
      onOk: async () => {
        const payload = {
          type,
        };
        const { status } = await revokePlan({ id: userId, payload });

        if (status === 204) {
          getUserProfileData({ id: userId });
        }
      },
      onCancel: () => {},
      centered: true,
    });
  };

  getLeftButtonPress = (index) => {
    const { userData } = this.props;
    switch (index) {
      case 0:
        return () => this.deleteAccountClick(userData);
      case 6:
        return () => this.setState({ showPremiumPlanModal: true });
      case 7:
        return () => this.setState({ showSparklePlanModal: true });
      case 12:
        return () => this.setState({ showHmPlanModal: true });
      case 13:
        return () => this.setState({ showBvPlanModal: true });
      case 14:
        return () => this.setState({ showKundaliPlanModal: true });
      case 9:
        return () => this.showMatchesClick(userData);
      default:
        return () => {};
    }
  };

  onEditDetails = (item) => {
    if (item.index === ProfileDetailsIndex.feedbackDetails) {
      this.setState({
        feedbackModalStatus: true,
      });
    } else if (item.index === ProfileDetailsIndex.userImageDetails) {
      this.setState({
        imageUploadModalStatus: true,
      });
    } else {
      const data = this.getFields(item.index);
      this.setState({
        editProfileModalStatus: true,
        editProfileFields: data,
        selectedField: item.index,
      });
    }
  };

  showMatchesClick = (userData) => {
    const { fetchAvailableMatches } = this.props;
    fetchAvailableMatches({ id: userData.id });
    this.setState({
      showMatchesModalStatus: true,
    });
  };

  onClickCompleteProfile = () => {
    this.setState({ showCompleteProfileSection: true });
  };

  deleteAccountClick = () => {
    this.setState({
      showDeleteAccountModal: true,
    });
  };

  onRestoreExpireModal = () => {
    this.setState({
      showRestoreExpireModal: true,
    });
  };

  onUserRating = ({ rating }) => {
    const {
      updateUserDetails,
      adminData: { id: adminId },
      match: {
        params: { userId },
      },
    } = this.props;

    const data = {
      rating_count: rating,
      rated_by: adminId,
    };

    updateUserDetails({ id: userId, data });
  };

  renderRightItems = (item) => {
    const { userData, userFeedback, loadingStatus, adminData } = this.props;

    return (
      <DetailsCard
        key={`sections_${item.index}`}
        data={{
          ...userData,
          ...userFeedback,
        }}
        adminData={adminData}
        editable={item.editable}
        loading={loadingStatus}
        index={item.index}
        title={item.title}
        onEditPress={() => this.onEditDetails(item, userData)}
        leftButtonClick={this.getLeftButtonPress(item.index)}
        onClickHistory={this.getPaymentHistory}
        onRestoreExpireModal={this.onRestoreExpireModal}
        onClickMarkCompleted={this.introCallConfirmationModal}
        onClickCompleteProfile={this.onClickCompleteProfile}
      />
    );
  };

  render() {
    const {
      userData,
      userFeedback,
      additionalInfo,
      history,
      match: {
        params: { userId },
      },
      updateUserLoadingStatus,
      forceSelfieVerification,
      getUserProfileFeedback,
      adminData,
      loadingStatus,
    } = this.props;
    const {
      adminImage,
      modalStatus,
      verificationOnHoldModalStatus,
      editProfileModalStatus,
      editProfileFields,
      selectedField,
      feedbackModalStatus,
      showMatchesModalStatus,
      imageUploadModalStatus,
      showDeleteAccountModal,
      showRestoreExpireModal,
      showPremiumPlanModal,
      showSparklePlanModal,
      showHmPlanModal,
      showBvPlanModal,
      showKundaliPlanModal,
      showCopyPaymentLinkModal,
      showCopySparkleLinkModal,
      showPaymentHistoryModal,
      showUserProfile,
      showSales,
      defaultActiveKey,
      showHM,
      showCompleteProfileSection,
    } = this.state;

    const locations =
      userData && userData.locations && userData.locations.features
        ? userData.locations.features
        : null;
    let currentLocation = null;
    let homeLocation = null;
    if (locations && locations.length) {
      locations.forEach((item) => {
        if (item.properties.tag === 'residential') {
          currentLocation = item.properties;
        }
        if (item.properties.tag === 'hometown') {
          homeLocation = item.properties;
        }
      });
    }
    // styles overwritten only for this badge
    const selfieVerificationStatus = (
      <div className="card-box">
        <div className="content-box" style={{ display: 'flex' }}>
          <div className="content-data blue">
            <div
              className="card-text"
              style={{
                margin: '0 auto',
              }}
            >
              Selfi Verification Status :{' '}
              {userData.selfieVerification &&
                userData.selfieVerification.status}
            </div>
          </div>
        </div>
      </div>
    );

    // eslint-disable-next-line no-shadow
    const personalDetails = (
      <div className="card-box">
        <h2 className="title-heading">Personal details</h2>
        <div className="content-box">
          <div className="content-data purple">
            <img alt="" className="card-icon" src={IcoReligion} />
            <div className="card-text">{userData.religion}</div>
          </div>
          <div className="content-data blue">
            <div className="card-text">
              {heightConverter({ inches: userData.height })}
            </div>
            <img alt="" className="card-icon" src={IcoHeight} />
          </div>
          <div className="content-data red">
            <div className="card-text">{userData.motherTongue}</div>
            <img alt="" className="card-icon" src={IcoMotherTongue} />
          </div>
          <div className="content-data orange">
            <div className="card-text">
              {homeLocation && homeLocation.city},<br />
              {homeLocation && homeLocation.country}
            </div>
            <img alt="" className="card-icon" src={IcoHometown} />
          </div>
        </div>
      </div>
    );

    const educationCareer = (
      <div className="card-box">
        <h2 className="title-heading">Education & career</h2>
        <div className="content-box">
          <div className="content-data blue">
            <div className="card-text">{userData.collegeName}</div>
            <img alt="" className="card-icon" src={IcoCollege} />
          </div>
          <div className="content-data purple">
            <div className="card-text">
              {userData.designation}
              ,<br />
              {userData.companyName}
            </div>
            <img alt="" className="card-icon" src={IcoJob} />
          </div>
          <div className="content-data yellow">
            <div className="card-text">
              {`${incomeConverter({
                incomeFrom: userData.incomeFrom,
                incomeTo: userData.incomeTo,
              })} `}
            </div>
            <img alt="" className="card-icon" src={IcoSalary} />
          </div>
        </div>
      </div>
    );

    const lifeStyle = (
      <div className="card-box">
        <h2 className="title-heading">Lifestyle</h2>
        <div className="content-box">
          <div className="content-data red">
            <div className="card-text">{userData.foodPreference}</div>
            <img alt="" className="card-icon" src={IcoFood} />
          </div>
          <div className="content-data orange">
            <div className="card-text">{userData.drinkPreference}</div>
            <img alt="" className="card-icon" src={IcoDrinks} />
          </div>
          <div className="content-data blue">
            <div className="card-text">{userData.smokePreference}</div>
            <img alt="" className="card-icon" src={IcoSmoke} />
          </div>
        </div>
      </div>
    );

    const settleDown = (
      <div className="card-box">
        <div className="card-data">
          <img alt="" className="card-img" src={ImgBackdrop} />
          <div className="card-text">
            {`${userData.firstName} is single and planning to settle down.`}
          </div>
          <img alt="" className="card-icon" src={IcoSettledown} />
        </div>
      </div>
    );

    const otherImages = (index) => (
      <div className="img-box" style={{ borderRadius: 8 }}>
        <img alt="" className="otherImage" src={userData.images[index]} />
      </div>
    );

    const section = (image) => {
      if (
        isEmpty(userData) ||
        isEmpty(image) ||
        isEmpty(userData.partnerPreference)
      ) {
        return <h2>Sorry! Data Unavailable</h2>;
      }
      if (image.length === 0 || image.length === 1) {
        return (
          <>
            {selfieVerificationStatus}
            {personalDetails}
            {educationCareer}
            {lifeStyle}
            {settleDown}
          </>
        );
      }

      if (image.length === 2) {
        return (
          <>
            {selfieVerificationStatus}
            {personalDetails}
            {educationCareer}
            {otherImages(1)}
            {lifeStyle}
            {settleDown}
          </>
        );
      }

      if (image.length === 3) {
        return (
          <>
            {selfieVerificationStatus}
            {personalDetails}
            {otherImages(1)}
            {educationCareer}
            {lifeStyle}
            {otherImages(2)}
            {settleDown}
          </>
        );
      }

      if (image.length === 4) {
        return (
          <>
            {selfieVerificationStatus}
            {personalDetails}
            {otherImages(1)}
            {educationCareer}
            {otherImages(2)}
            {lifeStyle}
            {otherImages(3)}
            {settleDown}
          </>
        );
      }

      if (image.length === 5) {
        return (
          <>
            {selfieVerificationStatus}
            {personalDetails}
            {otherImages(1)}
            {educationCareer}
            {otherImages(2)}
            {lifeStyle}
            {otherImages(3)}
            {settleDown}
            {otherImages(4)}
          </>
        );
      }

      if (image.length === 6) {
        return (
          <>
            {selfieVerificationStatus}
            {personalDetails}
            {otherImages(1)}
            {educationCareer}
            <div className="img-box-2">
              <img alt="" className="img-fluid" src={userData.images[2].src} />
            </div>
            <div className="img-box-2">
              <img alt="" className="img-fluid" src={userData.images[3].src} />
            </div>
            {lifeStyle}
            {otherImages(4)}
            {settleDown}
            {otherImages(5)}
          </>
        );
      }

      if (image.length > 6) {
        return (
          <>
            {selfieVerificationStatus}
            {personalDetails}
            {otherImages(1)}
            {educationCareer}
            {otherImages(2)}
            {otherImages(3)}
            {lifeStyle}
            {otherImages(4)}
            {settleDown}
            {otherImages(5)}
            {otherImages(6)}
          </>
        );
      }

      return null;
    };

    const dataSection = showCompleteProfileSection ? (
      <CompleteProfile
        onClose={() => this.setState({ showCompleteProfileSection: false })}
        userData={userData}
      />
    ) : (
      <List
        grid={grid}
        dataSource={
          userData && userData.gender === 'male'
            ? RightSections
            : FemaleRightSections
        }
        renderItem={this.renderRightItems}
      />
    );

    const styles = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      forceBtn: {
        backgroundColor: '#ff4d4f',
        fontWeight: 'bold',
        color: '#fff',
      },
    };

    const profileSection = (
      <div style={styles.container}>
        <div className="MyMatches" style={{ position: 'relative' }}>
          {!(
            userData?.verificationStatus?.includes('verification_on_hold') ||
            userData?.verificationStatus?.includes('unverified_by_admin')
          ) && (
            <Rating
              userRating={userData.ratingCount}
              userRatedBy={userData.ratedBy}
              onUserRating={this.onUserRating}
            />
          )}

          <div className="carousel-item">
            <div className="InfoCard">
              <div className="ImageCard">
                <img
                  alt=""
                  className="img-fluid main-img"
                  src={userData.profileImage}
                />
                <div className="highlight">
                  <div className="heading">
                    {userData.firstName}&nbsp;&nbsp;
                    {_calculateAge(userData.dateOfBirth)}
                  </div>

                  <div className="content">
                    <img
                      alt="location"
                      size={10}
                      className="img-fluid img-box-2"
                      src={ImgPin}
                    />
                    <div>
                      {currentLocation && currentLocation.city}
                      {currentLocation && !isEmpty(currentLocation.country)
                        ? ','
                        : null}{' '}
                      {currentLocation && currentLocation.country}
                    </div>
                  </div>
                  <div className="content">
                    <img
                      alt="job"
                      size={10}
                      className="img-fluid img-box-2"
                      src={ImgBriefcase}
                    />
                    <div>
                      {userData.designation}
                      {!isEmpty(userData.companyName) ? ',' : null}{' '}
                      {userData.companyName}
                    </div>
                  </div>
                </div>
              </div>
              {section(userData.images)}
              <div className="btn-box">
                {!isEmpty(userData?.verificationStatus) &&
                  userData?.status === 'on_boarding_completed' && (
                    <div>
                      {userData?.verificationStatus.includes('auto_verified') &&
                        userData?.ratingCount === 0 && (
                          <div>
                            <Button
                              type="Primary"
                              shape="round"
                              size="large"
                              disabled={updateUserLoadingStatus}
                              loading={updateUserLoadingStatus}
                              className="btn block-btn"
                              onClick={() => this.handleHoldVerifyClick()}
                            >
                              Verification On Hold
                            </Button>
                          </div>
                        )}{' '}
                      {userData?.verificationStatus.includes(
                        'verification_on_hold'
                      ) && (
                        <div>
                          <Button
                            type="primary"
                            shape="round"
                            size="large"
                            disabled={updateUserLoadingStatus}
                            loading={updateUserLoadingStatus}
                            className="btn block-btn"
                            onClick={() =>
                              this.handleVerifyClick({
                                id: userId,
                              })
                            }
                          >
                            Verify
                          </Button>
                        </div>
                      )}
                      <Button
                        type="Primary"
                        shape="round"
                        size="large"
                        disabled={updateUserLoadingStatus}
                        loading={updateUserLoadingStatus}
                        className="btn block-btn"
                        onClick={() =>
                          this.handleClick({
                            id: userId,
                            verificationStatus: userData?.verificationStatus,
                          })
                        }
                      >
                        {!isEmpty(userData)
                          ? userData?.verificationStatus.includes('unverified')
                            ? 'Verify'
                            : 'Unverify'
                          : ''}
                      </Button>
                    </div>
                  )}
              </div>
              <div className="verification-bottom" />
            </div>
          </div>
        </div>

        {userData.status === 'on_boarding_completed' && (
          <div>
            <Button
              onClick={() => {
                const { id } = userData;
                forceSelfieVerification({ id });
              }}
              style={styles.forceBtn}
              type="default"
              shape="round"
            >
              <IconFont type="iconselfie" />
              Forced Selfie Verification
            </Button>
          </div>
        )}
      </div>
    );

    const sparkleTag =
      userData.sparkleQuantities > 0 ? (
        <img
          alt=""
          style={{
            width: 28,
            height: 28,
            marginTop: -5,
            marginLeft: 20,
          }}
          src={Sparkle}
        />
      ) : null;

    const getTags = () => {
      const data = [];
      if (
        !isEmpty(userData) &&
        !isEmpty(userData.planName) &&
        userData.planName.toLowerCase() !== 'free'
      ) {
        data.push(
          <img
            alt=""
            style={{
              width: 30,
              height: 30,
              marginTop: -5,
            }}
            src={Premium}
          />
        );
      }

      if (!isEmpty(userData) && !isEmpty(userData.sparkleQuantities)) {
        data.push(sparkleTag);
      }
      return data;
    };

    const tags = getTags();

    return (
      <Layout className="Main">
        <AdminHeader
          isUserProfile
          isHumanMatchmaker={showHM}
          isSales={showSales}
          title={
            !isEmpty(userData)
              ? `${userData.firstName || 'New User'} ${
                  userData.lastName || ''
                }${
                  userData.status === 'on_boarding_completed'
                    ? `, ${_calculateAge(userData.dateOfBirth)}`
                    : ''
                }`
              : ''
          }
          userId={userId}
          getUserProfileFeedback={getUserProfileFeedback}
          subTitle={
            (!isEmpty(userData) &&
              userData.status === 'on_boarding_completed' &&
              showHM) ||
            showSales
              ? `${userData.convertedHeight} | ${capitalize(
                  userData.caste
                )} / ${capitalize(userData.religion)} | ${capitalize(
                  userData.motherTongue
                )} | ${capitalize(userData.designation)} | ${
                  userData.annualIncome
                } ${
                  showHM || showSales
                    ? `| +${userData.isdCode}-${userData.mobile}`
                    : ''
                }`
              : ''
          }
          tags={tags}
          userData={userData}
          defaultActiveKey={defaultActiveKey}
          onBack={() => history.goBack()}
          onProfilePress={() => {
            this.setState({
              showUserProfile: true,
              showSales: false,
              showHM: false,
            });
          }}
          onSalesPress={() => {
            this.setState({
              showUserProfile: false,
              showSales: true,
              showHM: false,
            });
          }}
          onHMPress={() => {
            this.setState({
              showUserProfile: false,
              showSales: false,
              showHM: true,
            });
          }}
          adminPic={adminImage}
        />

        {showUserProfile && (
          <Row className="Content" style={{ marginTop: '65px' }}>
            <Col xs={{ span: 24 }} xl={{ span: 8 }}>
              {profileSection}
            </Col>
            <Col xs={{ span: 24 }} xl={{ span: 16 }}>
              {dataSection}
            </Col>
          </Row>
        )}
        {showSales && (
          <SalesSection userData={userData} additionalInfo={additionalInfo} />
        )}

        {showHM && (
          <HmSection
            adminData={adminData}
            userData={userData}
            userFeedback={userFeedback}
            loadingStatus={loadingStatus}
            additionalInfo={additionalInfo}
          />
        )}

        <UnverifyModal
          verificationStatus={userData?.verificationStatus}
          title="Specify the reason for unverification"
          visible={modalStatus}
          cancelButtonProps={{ disabled: true }}
          onModalClose={() => {
            this.setState({
              modalStatus: false,
            });
          }}
        />
        <VerificationOnHoldModal
          verificationStatus={userData?.verificationStatus}
          title="Specify the reason for verification on hold"
          visible={verificationOnHoldModalStatus}
          cancelButtonProps={{ disabled: true }}
          onModalClose={() => {
            this.setState({
              verificationOnHoldModalStatus: false,
            });
          }}
        />
        <EditProfileModal
          visible={editProfileModalStatus}
          fields={editProfileFields}
          onModalClose={() => {
            this.setState({
              editProfileModalStatus: false,
            });
          }}
          userData={userData}
          selectedField={selectedField}
        />

        <DeleteAccountModal
          visible={showDeleteAccountModal}
          title="Delete Account"
          userData={userData}
          onModalClose={() => {
            this.setState({
              showDeleteAccountModal: false,
            });
          }}
        />

        <RestoreExpireModal
          visible={showRestoreExpireModal}
          title="Restore Expiry Request Confirmation"
          message="Restore all the expired request?"
          onModalClose={() => {
            this.setState({
              showRestoreExpireModal: false,
            });
          }}
        />
        <FeedbackModal
          visible={feedbackModalStatus}
          title="Add Feedback about the User..."
          onModalClose={() => {
            this.setState({
              feedbackModalStatus: false,
            });
          }}
          userData={userData}
        />
        <ImageUploadModal
          visible={imageUploadModalStatus}
          title="Add new images for the User..."
          onModalClose={() => {
            this.setState({
              imageUploadModalStatus: false,
            });
          }}
          userData={userData}
        />
        <ShowMatchesModal
          visible={showMatchesModalStatus}
          title="Recommendations"
          userId={userData.id}
          onModalClose={() => {
            this.setState({ showMatchesModalStatus: false });
          }}
        />

        {showPremiumPlanModal && (
          <BuyPremiumModal
            visible={showPremiumPlanModal}
            title="Buy Premium"
            userData={userData}
            onModalClose={() => this.setState({ showPremiumPlanModal: false })}
            onOkayPress={() => {
              this.setState({
                showPremiumPlanModal: false,
                showCopyPaymentLinkModal: true,
              });
            }}
          />
        )}
        {showSparklePlanModal && (
          <BuySparkleModal
            visible={showSparklePlanModal}
            title="Buy Sparkle"
            userData={userData}
            onModalClose={() => this.setState({ showSparklePlanModal: false })}
            onOkayPress={() => {
              this.setState({
                showSparklePlanModal: false,
                showCopySparkleLinkModal: true,
              });
            }}
          />
        )}
        {showHmPlanModal && (
          <BuyHmModal
            visible={showHmPlanModal}
            title="Buy Human matchmaker"
            userData={userData}
            onModalClose={() => this.setState({ showHmPlanModal: false })}
            onOkayPress={() => {
              this.setState({
                showHmPlanModal: false,
                showCopySparkleLinkModal: true,
              });
            }}
          />
        )}
        {showBvPlanModal && (
          <BuyBvModal
            visible={showBvPlanModal}
            title="Buy Background Verification"
            userData={userData}
            onModalClose={() => this.setState({ showBvPlanModal: false })}
            onOkayPress={() => {
              this.setState({
                showBvPlanModal: false,
                showCopySparkleLinkModal: true,
              });
            }}
          />
        )}
        {showKundaliPlanModal && (
          <BuyKundaliModal
            visible={showKundaliPlanModal}
            title="Buy kundali"
            userData={userData}
            onModalClose={() => this.setState({ showKundaliPlanModal: false })}
            onOkayPress={() => {
              this.setState({
                showKundaliPlanModal: false,
                showCopySparkleLinkModal: true,
              });
            }}
          />
        )}
        {showCopyPaymentLinkModal && (
          <PaymentLinkModal
            visible={showCopyPaymentLinkModal}
            onModalClose={() => {
              this.setState({ showCopyPaymentLinkModal: false });
            }}
          />
        )}
        {showCopySparkleLinkModal && (
          <SparkleLinkModal
            visible={showCopySparkleLinkModal}
            onModalClose={() => {
              this.setState({ showCopySparkleLinkModal: false });
            }}
          />
        )}
        {showPaymentHistoryModal && (
          <PaymentsHistoryModal
            visible={showPaymentHistoryModal}
            title="Payments history"
            userId={userData.id}
            onModalClose={() => {
              this.setState({ showPaymentHistoryModal: false });
            }}
          />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  adminData: state.adminData.data,
  userData: state.userData.data,
  additionalInfo: state.additionalInfo.data,
  loadingStatus: state.userData.isLoading,
  updateUserLoadingStatus: state.updateUserData.isLoading,
  updateUserDataSuccess: state.updateUserData.success,
  updateUserDataFailure: state.updateUserData.error,
  userFeedback: isEmpty(state.userFeedbackData.data)
    ? {}
    : state.userFeedbackData.data,
});

export default connect(mapStateToProps, {
  getUserProfileData: userProfileEffects.getUserProfileData,
  getUserProfileFeedback: userProfileEffects.getUserProfileFeedback,
  getAdditonalInfo: userProfileEffects.getAdditonalInfo,
  updateUserDetails: userProfileEffects.updateUserDetails,
  clearUserData: userProfileEffects.clearUserData,
  addMatches: adminEffects.addMatches,
  forceSelfieVerification: userProfileEffects.forceSelfieVerification,
  fetchAvailableMatches: userProfileEffects.showMatches,
  revokePlan: adminEffects.revokePlan,
})(withRouter(UserProfile));
