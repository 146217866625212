import React, { useState } from 'react';
import { Slider } from 'antd';
import Button from '../../../../../components/Button';
import styles from './styles.module.scss';
import { heightFormatter } from '../../helper';
import { updatePartnerPreferences } from '../../../api';
import { useUserData } from '../../../../UserProfile/CompleteProfile/UserDataContext';

const TipFormatter = (value) => heightFormatter(value);

const HeightSlider = ({ onSave }) => {
  const {
    id: userId,
    partnerPreference: { height_from, height_to, id: partnerPreferenceId },
  } = useUserData();
  const [heightRange, setHeightRange] = useState([height_from, height_to]);
  const selectedAgeRange = heightRange
    ? `${heightFormatter(heightRange[0])} - ${heightFormatter(heightRange[1])}`
    : '5 ft - 5 ft 5 inches';

  const handleHeightSave = async () => {
    const payload = {
      height_from: heightRange[0],
      height_to: heightRange[1],
    };
    await updatePartnerPreferences(userId, partnerPreferenceId, payload);
    onSave();
  };
  return (
    <section>
      <Slider
        range
        step={1}
        onChange={setHeightRange}
        min={48}
        max={90}
        tipFormatter={TipFormatter}
        value={[heightRange[0], heightRange[1]]}
      />
      <p>{selectedAgeRange}</p>
      <div className={styles.button}>
        <Button isLink isSave onClick={handleHeightSave}>
          SAVE
        </Button>
      </div>
    </section>
  );
};

export default HeightSlider;
