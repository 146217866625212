import { sorterFunc } from '../../SearchUser/columnData';
import { stringFormatter } from '../../../utilities/util';
import { getCurrentCity } from '../../../utilities/namingConvension';

export const columns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text) => stringFormatter({ text, type: 'date' }),
    sorter: (a, b) => sorterFunc(a, b, 'created_at'),
  },
  {
    title: 'First Name',
    dataIndex: ['recommended_user', 'first_name'],
    key: 'recommended_user.first_name',
    sorter: (a, b) => sorterFunc(a, b, 'recommended_user.first_name'),
  },
  {
    title: 'Last Name',
    dataIndex: ['recommended_user', 'last_name'],
    key: 'recommended_user.last_name',
    sorter: (a, b) => sorterFunc(a, b, 'recommended_user.last_name'),
  },
  {
    title: 'Mother Tongue',
    dataIndex: ['recommended_user', 'mother_tongue'],
    key: 'recommended_user.mother_tongue',
    sorter: (a, b) => sorterFunc(a, b, 'recommended_user.mother_tongue'),
  },
  {
    title: 'City',
    dataIndex: ['recommended_user', 'locations'],
    key: 'recommended_user.locations',
    render: (locations) => getCurrentCity({ locations }),
  },
  {
    title: 'Id',
    dataIndex: ['recommended_user', 'id'],
    key: 'recommended_user.id',
  },
];
