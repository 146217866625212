export const accountsDetails = [
  {
    id: 0,
    key: 'id',
    text: 'Profile Id',
    type: 'id',
    editComponent: 'input',
    editable: false,
  },
  {
    id: 1,
    key: 'firstName',
    text: 'First Name',
    type: 'string',
    editComponent: 'input',
    editable: true,
  },
  {
    id: 2,
    key: 'lastName',
    text: 'Last Name',
    type: 'string',
    editComponent: 'input',
    editable: true,
  },
  {
    id: 3,
    key: 'maritalStatus',
    text: 'Marital Status',
    type: 'snakeCase',
    editComponent: 'dropdown',
    editable: false,
  },
  {
    id: 4,
    key: 'email',
    text: 'Email',
    type: 'email',
    editComponent: 'input',
    editable: true,
  },
  {
    id: 5,
    key: 'mobile',
    text: 'Mobile No',
    type: 'string',
    editComponent: 'input',
    editable: true,
  },
  {
    id: 6,
    key: 'createdAt',
    text: 'Created At',
    type: 'date',
    editComponent: 'calender',
    editable: false,
  },
  {
    id: 7,
    key: 'activeAt',
    text: 'Last Active at',
    type: 'date',
    editComponent: 'calender',
    editable: false,
  },
  {
    id: 8,
    key: 'settleDown',
    text: 'Settle Down',
    type: 'selector',
    submissionFormat: 'LowerCase',
    editComponent: 'dropdown',
    fieldsToBeSubmitted: 'settle_down',
    editable: true,
  },
  {
    id: 9,
    key: 'isdCode',
    text: 'ISD Code',
    type: 'string',
    editComponent: 'input',
    fieldsToBeSubmitted: 'isd_code',
    editable: true,
  },
  {
    id: 10,
    key: 'status',
    text: 'Activation Status',
    type: 'string',
    editable: false,
  },
  {
    id: 11,
    key: 'verificationStatus',
    text: 'Verification Status',
    type: 'string',
    editable: false,
  },
  {
    id: 12,
    key: 'selfieVerification',
    text: 'Selfie Verification Status',
    type: 'selector',
    editable: false,
  },
  {
    id: 13,
    key: 'onBoardingMedium',
    text: 'On Boarding Medium',
    type: 'string',
    editable: false,
  },
  {
    id: 14,
    key: 'onboardingCompletedAt',
    text: 'On Boarding Completed At',
    type: 'date',
    editable: false,
  },
  {
    id: 15,
    key: 'adminName',
    text: 'Rated By',
    type: 'string',
    editable: false,
  },
  {
    id: 16,
    key: 'govtVerificationStatus',
    text: 'Govt Id Verification Status',
    type: 'string',
    editable: false,
  },
  {
    id: 17,
    key: 'adminOnboardedName',
    text: 'Onboarded By',
    type: 'string',
    editable: false,
  },
];

export const personalDetails = [
  {
    id: 0,
    key: 'gender',
    text: 'Gender',
    type: 'selector',
    editComponent: 'dropdown',
    fieldsToBeSubmitted: 'gender',
    editable: false,
  },
  {
    id: 1,
    key: 'religion',
    text: 'Religion',
    type: 'selector',
    submissionFormat: 'LowerCase',
    editComponent: 'dropdown',
    fieldsToBeSubmitted: 'religion',
    editable: true,
  },
  {
    id: 2,
    key: 'dateOfBirth',
    text: 'Date of Birth',
    type: 'string',
    editComponent: 'calender',
    editable: true,
  },
  {
    id: 3,
    key: 'height',
    alt: 'convertedHeight',
    text: 'Height',
    type: 'slider',
    editComponent: 'slider',
    min: 48,
    max: 88,
    steps: 1,
    fieldsToBeSubmitted: 'height',
    editable: true,
  },
  {
    id: 4,
    key: 'motherTongue',
    text: 'Mother Tongue',
    type: 'selector',
    editComponent: 'dropdown',
    fieldsToBeSubmitted: 'motherTongue',
    editable: true,
  },
  {
    id: 5,
    key: 'locations',
    text: 'Country',
    type: 'string',
    itemToBeShown: true,
    editComponent: 'input',
    editable: false,
  },
  {
    id: 6,
    key: 'locations',
    text: 'City',
    type: 'string',
    itemToBeShown: true,
    editComponent: 'input',
    editable: false,
  },
];

export const educationDetails = [
  {
    id: 0,
    key: 'highestDegree',
    text: 'Highest Qualification',
    editComponent: 'input',
    fieldsToBeSubmitted: 'highest_degree',
    type: 'selector',
    editable: true,
  },
  {
    id: 1,
    key: 'collegeName',
    text: 'College Name',
    editComponent: 'input',
    type: 'string',
    editable: true,
  },
  {
    id: 2,
    key: 'designation',
    text: 'Job Title',
    type: 'string',
    editComponent: 'input',
    editable: true,
  },
  {
    id: 3,
    key: 'companyName',
    text: 'Company Name',
    editComponent: 'input',
    type: 'string',
    editable: true,
  },
  {
    id: 4,
    key: 'annualIncome',
    text: 'Annual Income',
    type: 'numberRange',
    editComponent: 'dropdown',
    editable: true,
    fieldsToBeSubmitted: ['income_from', 'income_to'],
  },
  {
    id: 5,
    key: 'currency',
    text: 'Currency',
    type: 'string',
    editComponent: 'input',
    editable: false,
    fieldsToBeSubmitted: 'currency',
  },
];

export const aboutDetails = [
  {
    id: 0,
    key: 'about',
    text: 'About',
    type: 'string',
    editComponent: 'LongInput',
    editable: true,
  },
];

export const lifestyleDetails = [
  {
    id: 0,
    key: 'smokePreference',
    text: 'Smoking',
    type: 'selector',
    editComponent: 'dropdown',
    fieldsToBeSubmitted: 'smoke_preference',
    editable: true,
  },
  {
    id: 1,
    key: 'drinkPreference',
    text: 'Drinking',
    type: 'selector',
    editComponent: 'dropdown',
    fieldsToBeSubmitted: 'drink_preference',
    editable: true,
  },
  {
    id: 2,
    key: 'foodPreference',
    text: 'Food Preference',
    editComponent: 'dropdown',
    type: 'selector',
    fieldsToBeSubmitted: 'food_preference',
    editable: true,
  },
];

export const paymentDetails = [
  {
    id: 0,
    key: 'planName',
    text: 'Plan Name',
    type: 'selector',
    editComponent: 'dropdown',
    fieldsToBeSubmitted: 'plan_name',
    belongsTo: 'paymentDetails',
    editable: true,
    isRequired: true,
  },
  {
    id: 1,
    key: 'planStartDate',
    text: 'Plan Start Date',
    type: 'date',
    editComponent: 'calender',
    fieldsToBeSubmitted: 'plan_start_date',
    editable: false,
  },
  {
    id: 2,
    key: 'planEndDate',
    text: 'Plan End Date',
    type: 'date',
    editComponent: 'calender',
    fieldsToBeSubmitted: 'plan_end_date',
    editable: false,
  },
  {
    id: 3,
    key: 'duration',
    text: 'Duration',
    type: 'string',
    editComponent: 'input',
    fieldsToBeSubmitted: 'duration',
    editable: false,
    isRequired: true,
    toBeShown: false,
  },
  {
    id: 4,
    key: 'amount',
    text: 'Amount',
    type: 'string',
    editComponent: 'input',
    fieldsToBeSubmitted: 'amount',
    editable: false,
    isRequired: true,
    toBeShown: true,
  },
];

export const sparkleDetails = [
  {
    id: 0,
    key: 'sparklePlanName',
    text: 'Sparkle Plan Name',
    type: 'selector',
    editComponent: 'dropdown',
    fieldsToBeSubmitted: 'plan_name',
    belongsTo: 'sparkleDetails',
    editable: true,
    isRequired: true,
  },
  {
    id: 1,
    key: 'sparkleQuantities',
    text: 'Sparkle Quantities',
    type: 'string',
    editComponent: 'input',
    fieldsToBeSubmitted: 'consumable_quantities',
    editable: false,
    isRequired: true,
  },
  {
    id: 2,
    key: 'sparkleAmount',
    text: 'Amount',
    type: 'string',
    editable: false,
  },
];

export const hmDetails = [
  {
    id: 0,
    key: 'hmPlanName',
    text: 'HM Plan Name',
    type: 'selector',
    editComponent: 'dropdown',
    fieldsToBeSubmitted: 'plan_name',
    belongsTo: 'hmDetails',
    editable: true,
    isRequired: true,
  },
  {
    id: 1,
    key: 'hmStartDate',
    text: 'Plan Start Date',
    type: 'date',
    editComponent: 'calender',
    fieldsToBeSubmitted: 'plan_start_date',
    editable: false,
  },
  {
    id: 2,
    key: 'hmEndDate',
    text: 'Plan End Date',
    type: 'date',
    editComponent: 'calender',
    fieldsToBeSubmitted: 'plan_end_date',
    editable: false,
  },
  {
    id: 3,
    key: 'hmQuantities',
    text: 'Shortlisted Count Left',
    type: 'string',
    editComponent: 'input',
    fieldsToBeSubmitted: 'consumable_quantities',
    editable: false,
    isRequired: true,
  },
  {
    id: 4,
    key: 'hmAmount',
    text: 'Amount',
    type: 'string',
    editable: false,
  },
];

export const bvDetails = [
  {
    id: 0,
    key: 'bvPlanName',
    text: 'BV Plan Name',
    type: 'selector',
    editComponent: 'dropdown',
    fieldsToBeSubmitted: 'plan_name',
    belongsTo: 'bvDetails',
    editable: false,
    isRequired: true,
  },
  {
    id: 2,
    key: 'bvAmount',
    text: 'Amount',
    type: 'string',
    editable: false,
  },
];

export const kundaliDetails = [
  {
    id: 0,
    key: 'kundaliPlanName',
    text: 'Kundali Plan Name',
    type: 'selector',
    editComponent: 'dropdown',
    fieldsToBeSubmitted: 'plan_name',
    belongsTo: 'kundaliDetails',
    editable: true,
    isRequired: true,
  },
  {
    id: 1,
    key: 'kundaliQuantities',
    text: 'Kundali Quantities',
    type: 'string',
    editComponent: 'input',
    fieldsToBeSubmitted: 'consumable_quantities',
    editable: false,
    isRequired: true,
  },
  {
    id: 2,
    key: 'kundaliAmount',
    text: 'Amount',
    type: 'string',
    editable: false,
  },
];

// Parameters for the age slider on the Search user Page
export const sliderDetails = {
  min: 21,
  max: 50,
  steps: 1,
  marksPointingToEnds: {
    21: '21',
    50: '50',
  },
  defaultBeginningValue: 25,
  defaultEndingValue: 36,
};

export const partnerPreferenceDetails = [
  {
    id: 0,
    key: 'agePreference',
    text: 'Age',
    editComponent: 'dualslider',
    type: 'numberRange',
    min: 21,
    max: 55,
    steps: 1,
    fieldsToBeSubmitted: ['age_from', 'age_to'],
    editable: true,
  },
  {
    id: 1,
    key: 'heightPreference',
    text: 'Height',
    editComponent: 'dualslider',
    type: 'numberRange',
    min: 48,
    max: 88,
    steps: 1,
    fieldsToBeSubmitted: ['height_from', 'height_to'],
    editable: true,
  },
  {
    id: 2,
    key: 'annualIncomePreference',
    text: 'Annual Income',
    editComponent: 'dualslider',
    type: 'numberRange',
    editable: true,
    min: 100000,
    max: 10000000,
    steps: 100000,
    fieldsToBeSubmitted: ['income_from', 'income_to'],
  },
  {
    id: 3,
    key: 'foodPartnerPreference',
    text: 'Food Preference',
    editComponent: 'dropdown',
    type: 'list',
    fieldsToBeSubmitted: 'food_preference',
    editable: true,
  },
  {
    id: 4,
    key: 'maritalStatusPreference',
    text: 'Marital status',
    editComponent: 'dropdown',
    type: 'list',
    fieldsToBeSubmitted: 'marital_status',
    editable: true,
  },
  {
    id: 5,
    key: 'citiesPreference',
    text: 'Cities',
    editComponent: 'search',
    type: 'debouncedList',
    fieldsToBeSubmitted: 'cities',
    editable: true,
  },
  {
    id: 6,
    key: 'languagesPreference',
    text: 'Languages',
    editComponent: 'dropdown',
    type: 'list',
    fieldsToBeSubmitted: 'languages',
    editable: true,
  },
  {
    id: 7,
    key: 'religionsPreference',
    text: 'Religions',
    editComponent: 'dropdown',
    type: 'list',
    fieldsToBeSubmitted: 'religions',
    editable: true,
  },
  {
    id: 8,
    key: 'preferenceLastUpdatedAt',
    text: 'Last updated at',
    type: 'date',
  },
  {
    id: 9,
    key: 'degreePreference',
    text: 'Degrees',
    editComponent: 'searchDegree',
    type: 'debouncedList',
    fieldsToBeSubmitted: 'degrees',
    editable: true,
  },
  {
    id: 10,
    key: 'castePreference',
    text: 'Castes',
    editComponent: 'searchCaste',
    type: 'debouncedList',
    fieldsToBeSubmitted: 'castes',
    editable: true,
  },
  {
    id: 11,
    key: 'smokeRecommendationPreference',
    text: 'Smoke Preference',
    editComponent: 'dropdown',
    type: 'list',
    fieldsToBeSubmitted: 'smoke_preference',
    editable: true,
  },
  {
    id: 12,
    key: 'drinkRecommendationPreference',
    text: 'Drink Preference',
    editComponent: 'dropdown',
    type: 'list',
    fieldsToBeSubmitted: 'drink_preference',
    editable: true,
  },
  {
    id: 13,
    key: 'jobTitlePreference',
    text: 'Job Title',
    editComponent: 'searchJobTitle',
    type: 'debouncedList',
    fieldsToBeSubmitted: 'jobTitle',
    editable: true,
  },
  {
    id: 14,
    key: 'collegeNamePreference',
    text: 'College Name',
    editComponent: 'searchCollegeName',
    type: 'debouncedList',
    fieldsToBeSubmitted: 'collegeName',
    editable: true,
  },
];

export const additionalInfoDetails = [
  {
    id: 0,
    key: 'connectionCount',
    text: 'Connections Made',
    type: 'string',
  },
  {
    id: 1,
    key: 'activeConnectionCount',
    text: 'Active Connections Count',
    type: 'string',
  },
  {
    id: 2,
    key: 'hmAcceptedCount',
    text: 'Hm Accepted Count',
    type: 'string',
  },
  {
    id: 3,
    key: 'hmRecommendationSent',
    text: 'Hm Recommendation Sent Count',
    type: 'string',
  },
  {
    id: 4,
    key: 'interestReceivedCount',
    text: 'Interest Received',
    type: 'string',
  },
  {
    id: 5,
    key: 'interestSentCount',
    text: 'Interest Sent',
    type: 'string',
  },
  {
    id: 6,
    key: 'unSeenCount',
    text: 'Interest received unseen count',
    type: 'string',
    isButtonRequired: true,
  },
  {
    id: 7,
    key: 'seenCount',
    text: 'Interest received seen count',
    type: 'string',
    isButtonRequired: false,
  },
  {
    id: 8,
    key: 'expiredCount',
    text: 'Expired request count',
    type: 'string',
    isButtonRequired: false,
  },
  {
    id: 9,
    key: 'inactiveOrUnverifiedCount',
    text: 'Inactive or Unverified count',
    type: 'string',
    isButtonRequired: false,
  },
  {
    id: 10,
    key: 'queuedConnectionCount',
    text: 'Queued Connection Count',
    type: 'string',
  },

  {
    id: 11,
    key: 'recommendationCount',
    text: 'Recommendations',
    type: 'string',
  },
  {
    id: 12,
    key: 'interestAcceptedCount',
    text: 'Interest Accepted Count',
    type: 'string',
  },
  {
    id: 13,
    key: 'interestDeclinedCount',
    text: 'Interest Declined Count',
    type: 'string',
  },
];

export const planName = [
  'Premium Trial',
  // Removing as adding the Buy premium feature
  // '1 Month',
  // '6 Months',
  // '1 Year',
  // 'Lifetime',
];

export const sparklePlanName = [
  'Sparkle Trial',
  // Removing as adding the Buy premium feature
  // '1 Month',
  // '6 Months',
  // '1 Year',
  // 'Lifetime',
];
export const hmPlanName = ['HM Trial'];

export const kundaliPlanName = ['Kundali Trial'];

export const settleDown = ['6 months', 'In a year', '1-2 years', '2+ years'];

export const smokePreference = ['Never', 'Occasionally', 'Regularly'];

export const smokeRecommendationPreference = [
  'Never',
  'Socially',
  'Regularly',
  'Planning to quit',
];

export const drinkPreference = ['Never', 'Socially', 'Regularly'];

export const drinkRecommendationPreference = [
  'Never',
  'Socially',
  'Regularly',
  'Planning to quit',
];

export const foodPreference = ['Vegetarian', 'Non-Vegetarian'];

export const highestDegree = [
  'bachelors',
  'masters',
  'phd',
  'post doc',
  'other',
];

export const foodPartnerPreference = [
  'Vegetarian',
  'Non vegetarian',
  'Eggetarian',
  'Vegan',
  "Doesn't matter",
];

export const maritalStatusPreference = [
  'Never married',
  'Divorced',
  'Widowed',
  'Awaiting divorce',
];

export const languagesPreference = [
  'Hindi',
  'Bengali',
  'Telugu',
  'Marathi',
  'Tamil',
  'Malayalam',
  'Gujarati',
  'Kannada',
  'Punjabi',
  'Odia',
  'Urdu',
  'Assamese',
  'Maithili',
  'Santali',
  'Kashmiri',
  'Nepali',
  'Gondi',
  'Sindhi',
  'Konkani',
  'English',
  "Doesn't matter",
];

export const religionsPreference = [
  'Hindu',
  'Christian',
  'Muslim',
  'Sikh',
  'Jain',
  'Buddhist',
  'Others',
  "Doesn't matter",
];

export const religion = [
  'Hindu',
  'Christian',
  'Muslim',
  'Buddhist',
  'Jain',
  'Sikh',
  'Parsis',
  'Others',
];

export const gender = ['Male', 'Female'];

export const motherTongue = [
  'hindi',
  'bengali',
  'telugu',
  'marathi',
  'marwari',
  'tamil',
  'malayalam',
  'odia',
  'gujarati',
  'kannada',
  'punjabi',
  'urdu',
  'assamese',
  'maithili',
  'bhilli',
  'santali',
  'kashmiri',
  'nepali',
  'gondi',
  'sindhi',
  'konkani',
  'english',
  'tulu',
  'other',
];

export const annualIncome = [
  '0 - 1 LPA',
  '2 - 4 LPA',
  '4 - 7 LPA',
  '7 - 10 LPA',
  '10 - 15 LPA',
  '15 - 20 LPA',
  '20 - 30 LPA',
  '30 - 50 LPA',
  '50 - 100 LPA',
];

export const verificationStatuses = [
  'auto_verified',
  'verified_by_admin',
  'auto_unverified',
  'unverified_by_admin',
  'verification_on_hold',
];
export const onBoardingMediums = [
  'facebook',
  'otp',
  'apple',
  'truecaller',
  'biodata',
  'classified',
];

export const govtIdVerificationStatuses = [
  'reject',
  'failed',
  'fv_failed',
  'completed',
];

export const onBoardingStatues = [
  'on_boarding_started',
  'ai_details',
  'personal_details',
  'partner_preferences',
  'on_boarding_completed',
];

export const nonEditableStatus = [
  'on_boarding_started',
  'otp_verification_info',
  'ai_details',
];

export const selfieVerificationStatus = [
  'pending',
  'force_verification',
  'in_progress',
];

export const incomeSearch = [
  '0 - 4 LPA',
  '4 - 7 LPA',
  '7 - 10 LPA',
  '10 - 20 LPA',
  '20 - 50 LPA',
];

export const rating = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
