import React, { useState } from 'react';
import { Slider } from 'antd';
import Button from '../../../../components/Button';
import { updatePartnerPreferences } from '../../api';
import { useUserData } from '../../../UserProfile/CompleteProfile/UserDataContext';
import './styles.scss';

const AnnualIncome = ({ onSave }) => {
  const {
    id: userId,
    partnerPreference: { id: partnerPreferenceId, income_from, income_to },
  } = useUserData();
  const [annualIncomeRange, setAnnualIncomeRange] = useState(() => [
    income_from / 100000,
    income_to / 100000,
  ]);
  const selectedAnnualIncomeRange = annualIncomeRange
    ? `${annualIncomeRange[0]} - ${
        annualIncomeRange[1] === 100 ? '100+' : annualIncomeRange[1]
      } LPA`
    : '10 - 15 LPA';

  const handleAnnualIncomeSave = async () => {
    /* Convert to lakhs and add to payload */
    const payload = {
      income_from: annualIncomeRange[0] * 100000,
      income_to: annualIncomeRange[1] * 100000,
    };

    await updatePartnerPreferences(userId, partnerPreferenceId, payload);
    onSave();
  };
  return (
    <section>
      <Slider
        range
        step={2}
        onChange={setAnnualIncomeRange}
        value={[annualIncomeRange[0], annualIncomeRange[1]]}
        min={0}
        max={100}
      />
      <p>{selectedAnnualIncomeRange}</p>
      <div className="income-save-button-container">
        <Button isLink isSave onClick={handleAnnualIncomeSave}>
          SAVE
        </Button>
      </div>
    </section>
  );
};

export default AnnualIncome;
