export function selfieVerification({ get, patch }) {
  return {
    listSelfieVerification({ limit, qs }) {
      if (qs) {
        return get(`/selfie-verification/?${qs}`);
      }
      return get(`/selfie-verification/?limit=${limit}`);
    },
    updateUserSelfieVerificationStatus({ id, ...body }) {
      return patch(`/selfie-verification/${id}/`, body);
    },
  };
}
