import React, { Component } from 'react';
import { Modal, Input, Radio, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Mixpanel from 'mixpanel-browser';

import {
  userProfileEffects,
  listOfUsersinDashboardEffects,
} from '../../../@betterhalf-private/redux/src';
import { isEmpty } from '../../../utilities/util';
import { preDefinedType, preDefinedReason } from './data.enum';

const { removeUserFromList } = listOfUsersinDashboardEffects;

const { TextArea } = Input;

const styles = {
  modal: {},
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  input: {
    width: '60%',
  },
  radioGroup: {
    marginBottom: 20,
  },
  radioStyle: {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  },
};

const initialState = {
  type: null,
  reason: null,
  customReason: '',
  typeValue: null,
  reasonValue: null,
};

class DeleteAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      history,
      removeUserFromListAPI,
      isLoading,
      error,
      success,
      userData,
    } = this.props;

    if (prevProps.isLoading && !isLoading && success) {
      message.destroy();
      message.success({
        content: 'User profile deleted successfully',
        duration: 0,
      });
      setTimeout(() => {
        message.destroy();
      }, 3500);

      removeUserFromListAPI(userData);
      history.goBack();
    }

    if (prevProps.isLoading && !isLoading && !isEmpty(error)) {
      message.destroy();
      message.error({
        content: 'Something went wrong. Please try again',
        duration: 0,
      });
      setTimeout(() => {
        message.destroy();
      }, 3500);
    }
  }

  onCustomReasonChange = ({ target: { value } }) => {
    this.setState({ customReason: value, reason: value });
  };

  onChangeType = ({ target: { value } }) => {
    const filterTypeObj = preDefinedType.find((item) => item.value === value);
    const type = filterTypeObj.text;
    this.setState({ typeValue: value, type });
  };

  onChangeReason = ({ target: { value } }) => {
    if (value === 3) {
      this.setState({ reasonValue: value, reason: null, customReason: '' });
    } else {
      const filterReasonObj = preDefinedReason.find(
        (item) => item.value === value
      );
      const reason = filterReasonObj.text;
      this.setState({ reasonValue: value, reason });
    }
  };

  handleOkPress = () => {
    const { type, reason } = this.state;
    const { onModalClose, userData, deleteUserAccount, isLoading } = this.props;
    onModalClose();
    setTimeout(() => {
      Modal.confirm({
        title: 'Are you sure to delete this account?',
        okText: 'Delete',
        cancelText: 'Cancel',
        okButtonProps: {
          style: {
            backgroundColor: '#ff4d4f',
            color: '#fff',
            fontWeight: 'bold',
          },
          loading: isLoading,
        },
        onCancel: () => {
          this.setState({
            ...initialState,
          });
        },
        onOk: () => {
          Mixpanel.track('DELETE_USER_PROFILE', {
            id: userData.id,
          });
          message.loading({ content: 'Loading...', duration: 0 });
          deleteUserAccount({
            id: userData.id,
            data: {
              type,
              reason,
            },
          });
          this.setState({
            ...initialState,
          });
        },
      });
    }, 500);
  };

  render() {
    const { visible, onModalClose, title } = this.props;
    const { type, reason, customReason, reasonValue, typeValue } = this.state;

    const Types = preDefinedType.map(({ key, text, value }) => (
      <Radio key={key} style={styles.radioStyle} value={value}>
        {text}
      </Radio>
    ));
    const Reasons = preDefinedReason.map(({ key, text, value }) => (
      <Radio key={key} style={styles.radioStyle} value={value}>
        {text}
      </Radio>
    ));
    return (
      <Modal
        visible={visible}
        destroyOnClose
        title={title}
        onCancel={() => {
          this.setState({ ...initialState });
          onModalClose();
        }}
        onOk={() => this.handleOkPress()}
        style={styles.modal}
        okText="Delete"
        okButtonProps={
          isEmpty(type) || isEmpty(reason)
            ? {
                disabled: true,
                type: 'danger',
              }
            : {
                style: {
                  backgroundColor: '#ff4d4f',
                  color: '#fff',
                  fontWeight: 'bold',
                },
              }
        }
      >
        <>
          <h3>Type</h3>
          <Radio.Group
            onChange={this.onChangeType}
            value={typeValue}
            style={styles.radioGroup}
          >
            {Types}
          </Radio.Group>
          <h3>Reason</h3>
          <Radio.Group
            onChange={this.onChangeReason}
            value={reasonValue}
            style={styles.radioGroup}
          >
            {Reasons}
          </Radio.Group>
          {reasonValue === 3 && (
            <TextArea
              placeholder="Please add the reason to delete account"
              value={customReason}
              onChange={this.onCustomReasonChange}
              autoSize={{ minRows: 1 }}
            />
          )}
        </>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  success: state.userProfileDelete.success,
  isLoading: state.userProfileDelete.isLoading,
  error: state.userProfileDelete.error,
});

export default connect(mapStateToProps, {
  deleteUserAccount: userProfileEffects.deleteUserAccount,
  removeUserFromListAPI: removeUserFromList,
})(withRouter(DeleteAccountModal));
