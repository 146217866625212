import { Modal, Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import CustomCalendar from '../../../../components/CustomCalendar';
import { getMonthIndex, isValidDate } from '../../../../utilities/helper';
import CustomButton from '../../../../components/Button';
import './styles.scss';
import { useUserData } from '../../../UserProfile/CompleteProfile/UserDataContext';
import { editUserDetails } from '../../api';

const formatDateOfBirth = (dateOfBirth) => {
  const [year, month, day] = dateOfBirth.split('-');
  return `${day}-${month}-${year}`;
};

const DateOfBirthSection = () => {
  const { dateOfBirth: userDateOfBirth, id: userId } = useUserData();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState('yyyy-mm-dd');
  const [error, setError] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    setDateOfBirth(userDateOfBirth || 'yyyy-mm-dd');
  }, [userDateOfBirth]);

  const handleCloseModal = () => {
    if (isValidDate(dateOfBirth)) setError(false);
    else setError(true);
    if (!isButtonEnabled) return;
    setIsModalVisible(false);
  };
  const handleUpdateDate = async () => {
    handleCloseModal();
    const payload = {
      date_of_birth: dateOfBirth,
    };
    await editUserDetails(userId, payload);
  };

  const formatDob = (obj) => {
    const { day, month, year } = obj;
    setDateOfBirth(
      `${year || ' yyyy '}-${getMonthIndex(month) || ' mm '}-${day || ' dd'}`
    );
  };

  return (
    <div className="dob__wrapper">
      <h2>Date of Birth</h2>
      <div className="dob__inner">
        <span>{formatDateOfBirth(dateOfBirth)}</span>

        <CustomButton
          isLink
          onClick={() => setIsModalVisible(true)}
          className="add-date"
          date_of_birth={dateOfBirth}
        >
          {userDateOfBirth ? 'Edit Date' : ' Add Date'}
        </CustomButton>
      </div>
      <Modal
        centered
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        wrapClassName="modalStyle2"
        footer={null}
        width={410}
        closable={false}
        closeIcon={null}
      >
        <CustomCalendar
          formatDob={formatDob}
          setError={setError}
          setIsButtonEnabled={setIsButtonEnabled}
          date_of_birth={userDateOfBirth}
        />
        {error && (
          <div className="dob__wrapper-errorMessage">
            <Alert message="Date is not valid" showIcon type="error" />
          </div>
        )}
        <div className="modal__button__container">
          <CustomButton onClick={handleUpdateDate}>Save</CustomButton>
        </div>
      </Modal>
    </div>
  );
};

export default DateOfBirthSection;
