export function isEmpty(obj) {
  if (obj !== null && obj !== undefined) {
    // for general objects
    if (typeof obj === 'string') {
      if (obj.trim() === '' || obj === 'null' || obj === null) {
        // for string
        return true;
      }

      return false;
    }
    if (obj.length <= 0) {
      // for array
      return true;
    }
    if (typeof obj === 'object') {
      const keys = Object.keys(obj);
      const len = keys.length;
      if (len <= 0) {
        return true;
      }
      return false;
    }
    return false;
  }

  return true;
}

export const snakeToCamel = (str) =>
  str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  );

export const incomeConverter = ({ incomeFrom, incomeTo }) => {
  if (!incomeFrom && incomeTo) {
    return `0 - ${new Intl.NumberFormat('en-IN').format(incomeTo)} LPA`;
  }

  if (incomeFrom && incomeTo) {
    return `${new Intl.NumberFormat('en-IN').format(
      incomeFrom
    )} - ${new Intl.NumberFormat('en-IN').format(incomeTo)} LPA`;
  }

  if (incomeFrom && !incomeTo) {
    return `${new Intl.NumberFormat('en-IN').format(incomeFrom)} - + LPA`;
  }

  return null;
};

export const heightConverter = ({ inches }) => {
  const feet = Math.floor(inches / 12);
  const inch = inches % 12;
  return `${feet} ft ${inch} in`;
};
