import React, { PureComponent } from 'react';
import { Modal, Tooltip, notification, message } from 'antd';
import { CopyOutlined, LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { isEmpty } from '../../../utilities/util';

const styles = {
  paymentLinkModalContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
  },
  text: {
    fontSize: '16px',
    margin: '10px 0px',
  },
};

class SparkleLinkModal extends PureComponent {
  static getDerivedStateFromProps(newProps) {
    const { sparkleLinkDetails, onModalClose } = newProps;
    if (sparkleLinkDetails) {
      const { error } = sparkleLinkDetails;
      if (!isEmpty(error)) {
        message.error(JSON.stringify(error));
        onModalClose();
      }
    }
  }

  openNotificationWithIcon = () => {
    notification.success({
      message: 'Copied',
      description: 'Link has been copied to the clipboard',
    });
  };

  copyText = async (textToCopy) => {
    await navigator.clipboard.writeText(textToCopy);
    this.openNotificationWithIcon();
  };

  render() {
    const { sparkleLinkDetails, visible, onModalClose } = this.props;
    const sms_sent = sparkleLinkDetails?.data?.sms_sent || false;
    const email_sent = sparkleLinkDetails?.data?.email_sent || false;
    const short_url = sparkleLinkDetails?.data?.short_url || '';
    const loading = sparkleLinkDetails?.isLoading || false;

    return (
      <Modal
        centered
        onCancel={onModalClose}
        destroyOnClose
        title="Payment Link"
        visible={visible}
        footer={null}
        maskClosable={false}
        bodyStyle={styles.paymentLinkModalContent}
      >
        {loading ? (
          <LoadingOutlined />
        ) : (
          <>
            <div>
              <div style={styles.text}>
                Payment Link :{' '}
                <a rel="noopener noreferrer" target="_blank" href={short_url}>
                  {short_url}
                </a>
              </div>
              <div style={styles.text}>
                Email sent : {email_sent ? 'Yes' : 'No'}
              </div>
              <div style={styles.text}>
                SMS sent : {sms_sent ? 'Yes' : 'No'}
              </div>
            </div>
            <Tooltip
              placement="bottomLeft"
              title="Click to copy"
              arrowPointAtCenter
            >
              <CopyOutlined
                style={{ fontSize: 32 }}
                onClick={() => this.copyText(short_url)}
              />
            </Tooltip>
          </>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  sparkleLinkDetails: state.paymentLinks,
});

export default connect(mapStateToProps, {})(SparkleLinkModal);
