/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Button } from 'antd';
import {
  SyncOutlined,
  FacebookFilled,
  PhoneOutlined,
  PlusCircleTwoTone,
  EditTwoTone,
} from '@ant-design/icons';

import { userProfileEffects } from '../../@betterhalf-private/redux/src';
import Comments from './Comments';
import GalleryComponent from './Gallery';
import {
  accountsDetails,
  personalDetails,
  educationDetails,
  aboutDetails,
  lifestyleDetails,
  paymentDetails,
  sparkleDetails,
  hmDetails,
  bvDetails,
  kundaliDetails,
  partnerPreferenceDetails,
  additionalInfoDetails,
  nonEditableStatus,
} from './data.enum';
// const partnerPreferenceDetails = partnerPreferenceDetails.slice(0, 9);

import { stringFormatter, isEmpty } from '../../utilities/util';
import './styles.scss';
import DeviceInfo from './DeviceInfo';
import Shortlisted from './Shortlisted';
import VerifyModal from '../VerifyModal';

const styles = {
  card: {
    marginTop: 20,
  },
  icon: {
    fontSize: 20,
    alignSelf: 'center',
  },
  disabledIcon: {
    fontSize: 20,
    color: '#bobobo',
  },
  rowDiv: {
    display: 'flex',
    flexDirection: 'row',
  },
  deleteButton: {
    marginRight: 30,
    backgroundColor: '#ff4d4f',
    fontWeight: 'bold',
    color: '#fff',
  },
  showReButton: {
    marginRight: 10,
    backgroundColor: '#ff78a5',
    fontWeight: 'bold',
    color: '#fff',
  },
  sendReButton: {
    backgroundColor: '#ff78a5',
    fontWeight: 'bold',
    color: '#fff',
    marginRight: 10,
  },
};

const ProfileDetailsIndex = {
  accountsDetails: 0,
  personalDetails: 1,
  educationDetails: 2,
  aboutDetails: 3,
  lifestyleDetails: 4,
  partnerPreferenceDetails: 5,
  paymentDetails: 6,
  sparkleDetails: 7,
  hmDetails: 12,
  bvDetails: 13,
  kundaliDetails: 14,
  userImageDetails: 8,
  additionalInfoDetails: 9,
  feedbackDetails: 10,
};

const getFields = (index) => {
  switch (index) {
    case ProfileDetailsIndex.accountsDetails:
      return accountsDetails;
    case ProfileDetailsIndex.personalDetails:
      return personalDetails;
    case ProfileDetailsIndex.educationDetails:
      return educationDetails;
    case ProfileDetailsIndex.aboutDetails:
      return aboutDetails;
    case ProfileDetailsIndex.lifestyleDetails:
      return lifestyleDetails;
    case ProfileDetailsIndex.partnerPreferenceDetails:
      return partnerPreferenceDetails.slice(0, 9);
    case ProfileDetailsIndex.paymentDetails:
      return paymentDetails;
    case ProfileDetailsIndex.sparkleDetails:
      return sparkleDetails;
    case ProfileDetailsIndex.hmDetails:
      return hmDetails;
    case ProfileDetailsIndex.bvDetails:
      return bvDetails;
    case ProfileDetailsIndex.kundaliDetails:
      return kundaliDetails;
    case ProfileDetailsIndex.additionalInfoDetails:
      return additionalInfoDetails;
    default:
      return {};
  }
};

class DetailsCard extends PureComponent {
  getText = (userData, item) => {
    const locations =
      userData && userData.locations && userData.locations.features
        ? userData.locations.features
        : null;
    let currentLocation = null;
    if (locations && locations.length) {
      // eslint-disable-next-line no-shadow
      locations.forEach((item) => {
        if (item.properties.tag === 'residential') {
          currentLocation = item.properties;
        }
      });
    }
    if (item.key === 'height') return userData[item.alt];
    if (item.text === 'Country') return currentLocation?.country || '';
    if (item.text === 'City') return currentLocation?.city || '';
    if (item.key === 'selfieVerification') {
      const selfieVerification =
        userData.selfieVerification?.status || 'Pending';
      return selfieVerification;
    }
    return userData[item.key];
  };

  // eslint-disable-next-line consistent-return
  getAPICalls = (index, userData) => {
    const { getRequestReceivedCount } = this.props;
    switch (index) {
      case 2:
        getRequestReceivedCount({ id: userData.id });
        break;
      default:
        return () => {};
    }
  };

  renderItems = ({ userData, adminData }) => {
    const { index } = this.props;
    if (index === 8) {
      if (userData && userData.images) {
        // eslint-disable-next-line no-shadow
        const newImages = userData.images.map((item, index) => ({
          index,
          src: item,
          thumbnail: item,
        }));
        return <GalleryComponent images={newImages} userData={userData} />;
      }
      return null;
    }
    if (index === 9) {
      const { additionalInfo, isLoadingMatch } = this.props;
      const data = getFields(index);

      const Items = data.map((item) => {
        const onClick = () => {
          this.getAPICalls(2, userData);
        };
        return (
          <Row
            className="additionalinfo-row"
            align="middle"
            type="flex"
            key={`${item.key}_${index}`}
          >
            <Col span={8}>{item.text}</Col>
            <Col span={8}>
              {additionalInfo[item.key]}{' '}
              {item.isButtonRequired &&
                userData.status === 'on_boarding_completed' && (
                  <Button
                    size="small"
                    onClick={onClick}
                    shape="circle"
                    style={{
                      marginLeft: !isEmpty(additionalInfo[item.key]) ? 20 : -5,
                    }}
                  >
                    <SyncOutlined
                      spin={isLoadingMatch}
                      style={{ fontSize: 12 }}
                    />
                  </Button>
                )}
            </Col>
          </Row>
        );
      });
      return Items;
    }

    if (index === 10) {
      if (userData) {
        return <Comments userData={userData} adminData={adminData} />;
      }
    }

    if (index === 'human_matchmaker') {
      return <Shortlisted adminData={adminData} userData={userData} />;
    }

    if (index === 11) {
      if (userData) {
        return <DeviceInfo userData={userData} />;
      }
    }
    const data = getFields(index);
    const Items = data.length
      ? data.map((item) => {
          if (item.toBeShown === false) {
            return null;
          }
          if (item.key === 'onBoardingMedium') {
            return (
              <Row key={`${item.key}_${index}`}>
                {item.text && <Col span={8}>{item.text}</Col>}
                <Col span={item.text ? 16 : 24}>
                  {userData[item.key] && userData[item.key] === 'facebook' ? (
                    <FacebookFilled
                      style={{
                        fontSize: '20px',
                        color: '#1778F2',
                      }}
                    />
                  ) : (
                    stringFormatter({
                      text: this.getText(userData, item),
                      type: item.type,
                    })
                  )}
                </Col>
              </Row>
            );
          }
          return (
            // appended random value as the key to bypass the kwy warning
            <Row
              key={`${item.key}_${index}_${Math.floor(Math.random() * 1000)}`}
            >
              {item.text && <Col span={8}>{item.text}</Col>}
              <Col span={item.text ? 16 : 24}>
                {item.key === 'selfieVerification' &&
                  !userData[item.key] &&
                  'Pending'}
                {item.key === 'adminName' && !userData[item.key] && ''}
                {userData[item.key] &&
                  stringFormatter({
                    text: this.getText(userData, item),
                    type: item.type,
                  })}
              </Col>
            </Row>
          );
        })
      : null;
    return Items || null;
  };

  render() {
    const {
      loading,
      title,
      editable,
      onEditPress,
      onClickHistory,
      onRestoreExpireModal,
      onClickMarkCompleted,
      data,
      index,
      adminData,
      isAdditionalInfoLoading,
      leftButtonClick,
      shortlistedUsers,
      onClickCompleteProfile,
      getGovtIdRejectionReason,
    } = this.props;
    return (
      <Card
        size="default"
        style={styles.card}
        title={
          index === 0 ? (
            <div className="accountDetailsHeader">
              <p>{title}</p>
              <a className="completeProfile" onClick={onClickCompleteProfile}>
                {data.status === 'on_boarding_completed'
                  ? 'Edit Profile'
                  : 'Complete Profile'}
              </a>
              <div className="verifyButtonWrapper">
                <VerifyModal
                  userId={data.id}
                  govtIdVerificationStatus={data.govtVerificationStatus}
                  getGovtIdRejectionReason={getGovtIdRejectionReason}
                />
              </div>
            </div>
          ) : (
            title
          )
        }
        loading={loading || isAdditionalInfoLoading}
        extra={
          editable &&
          (index === 0 ? (
            <div style={styles.rowDiv}>
              {!nonEditableStatus.includes(data.status) &&
                // <EditTwoTone
                //   style={styles.icon}
                //   twoToneColor="#ff78a5"
                //   onClick={onEditPress}
                // />
                null}
            </div>
          ) : index === 9 ? (
            <div style={styles.rowDiv}>
              <Button
                style={styles.showReButton}
                onClick={onRestoreExpireModal}
                type="default"
                shape="round"
              >
                Restore Expired Request
              </Button>
              <Button
                style={styles.showReButton}
                onClick={leftButtonClick}
                type="default"
                shape="round"
              >
                Show Recommendations
              </Button>
            </div>
          ) : index === 6 && !nonEditableStatus.includes(data.status) ? (
            <div style={styles.rowDiv}>
              <Button
                style={styles.showReButton}
                onClick={onClickHistory}
                type="default"
                shape="round"
              >
                History
              </Button>
              <Button
                style={styles.showReButton}
                onClick={onEditPress}
                type="default"
                shape="round"
              >
                Premium Trial
              </Button>
              <Button
                style={styles.sendReButton}
                onClick={leftButtonClick}
                type="default"
                shape="round"
              >
                Sell Premium
              </Button>
            </div>
          ) : index === 7 && !nonEditableStatus.includes(data.status) ? (
            <div style={styles.rowDiv}>
              <Button
                style={styles.showReButton}
                onClick={onEditPress}
                type="default"
                shape="round"
              >
                Sparkle Trial
              </Button>
              <Button
                style={styles.sendReButton}
                onClick={leftButtonClick}
                type="default"
                shape="round"
              >
                Sell Sparkle
              </Button>
            </div>
          ) : index === 12 && !nonEditableStatus.includes(data.status) ? (
            <div style={styles.rowDiv}>
              {!isEmpty(data.introQuantities) && data.introQuantities > 0 && (
                <Button
                  type="default"
                  shape="round"
                  onClick={() => onClickMarkCompleted({ type: 'intro_call' })}
                  style={{
                    ...styles.sendReButton,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 12,
                    backgroundColor: 'rgba(17, 174, 135, 1)',
                    borderColor: 'transparent',
                  }}
                >
                  <PhoneOutlined style={{ fontSize: 20, marginRight: 12 }} />
                  <div style={{ fontWeight: 'bold' }}>Intro Call</div>
                </Button>
              )}

              {/* <Button
                style={styles.sendReButton}
                onClick={onEditPress}
                type="default"
                shape="round"
              >
                Human Matchmaker Trial
              </Button> */}
              {/* <Button
                style={styles.sendReButton}
                onClick={leftButtonClick}
                type="default"
                shape="round"
              >
                Sell Human Matchmaker
              </Button> */}
            </div>
          ) : index === 13 && !nonEditableStatus.includes(data.status) ? (
            <div style={styles.rowDiv}>
              {!isEmpty(data.bvIntroQuantities) && data.bvIntroQuantities > 0 && (
                <Button
                  type="default"
                  shape="round"
                  onClick={() =>
                    onClickMarkCompleted({ type: 'intro_call_bv' })
                  }
                  style={{
                    ...styles.sendReButton,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 12,
                    backgroundColor: 'rgba(17, 174, 135, 1)',
                    borderColor: 'transparent',
                  }}
                >
                  <PhoneOutlined style={{ fontSize: 20, marginRight: 12 }} />
                  <div style={{ fontWeight: 'bold' }}>Bv Intro Call</div>
                </Button>
              )}
              {!isEmpty(data.bvQuantities) && data.bvQuantities > 0 && (
                <Button
                  type="default"
                  shape="round"
                  onClick={() =>
                    onClickMarkCompleted({ type: 'background_verification' })
                  }
                  style={{
                    ...styles.sendReButton,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 12,
                    backgroundColor: 'rgba(17, 174, 135, 1)',
                    borderColor: 'transparent',
                  }}
                >
                  <PhoneOutlined style={{ fontSize: 20, marginRight: 12 }} />
                  <div style={{ fontWeight: 'bold' }}>BV Report Done</div>
                </Button>
              )}
              {/* {isEmpty(data.bvQuantities) && (
                <Button
                  style={styles.sendReButton}
                  onClick={leftButtonClick}
                  type="default"
                  shape="round"
                >
                  Sell Background Verification
                </Button>
              )} */}
            </div>
          ) : index === 14 && !nonEditableStatus.includes(data.status) ? (
            <div style={styles.rowDiv}>
              {/* <Button
                style={styles.sendReButton}
                onClick={onEditPress}
                type="default"
                shape="round"
              >
                Kundali Trial
              </Button> */}
              <Button
                style={styles.sendReButton}
                onClick={leftButtonClick}
                type="default"
                shape="round"
              >
                Sell Kundali
              </Button>
            </div>
          ) : index === 8 && !nonEditableStatus.includes(data.status) ? (
            data &&
            data.images &&
            data.images.length <= 5 && (
              <PlusCircleTwoTone
                style={styles.icon}
                twoToneColor="#ff78a5"
                onClick={onEditPress}
              />
            )
          ) : index === 10 && !nonEditableStatus.includes(data.status) ? (
            <PlusCircleTwoTone
              style={styles.icon}
              twoToneColor="#ff78a5"
              onClick={onEditPress}
            />
          ) : index === 'human_matchmaker' ? (
            shortlistedUsers.data.count && (
              <div style={{ fontWeight: 'bold' }}>
                Total Shortlisted: {shortlistedUsers.data.count}
              </div>
            )
          ) : (
            // Show the Edit button only for About section
            !nonEditableStatus.includes(data.status) &&
            title === 'About' && (
              <EditTwoTone
                style={styles.icon}
                twoToneColor="#ff78a5"
                onClick={onEditPress}
              />
            )
          ))
        }
      >
        {this.renderItems({ userData: data, adminData })}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  additionalInfo: state.additionalInfo.data,
  isLoadingMatch: state.additionalInfo.isLoading,
  isAdditionalInfoLoading: state.additionalInfo.isAddionalDetialsLoading,
  shortlistedUsers: state.shortlistedUsers,
});

export default connect(mapStateToProps, {
  getRequestReceivedCount: userProfileEffects.getRequestReceivedCount,
  getGovtIdRejectionReason: userProfileEffects.getGovtIdRejectionReason,
})(DetailsCard);
