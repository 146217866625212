import { Button, message, Select, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  InboxOutlined,
  IdcardOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import './index.scss';
import Title from 'antd/lib/typography/Title';
import { uploadVerificationDoc } from '../../containers/UserOnboarding/PreAIDetails/api';

const { Option } = Select;

// 'Pan Card': 'pan_card',
// 'Driving License': 'driving_license',
// 'Voter ID': 'voter_id',
// 'Passport': 'passport'

// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
// }

const VerifyModal = ({
  userId,
  govtIdVerificationStatus,
  getGovtIdRejectionReason,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState('Pan Card');
  const [frontSideImage, setFrontSideImage] = useState('');
  const [backSideImage, setBackSideImage] = useState('');

  const [isUploading, setIsUploading] = useState(false);

  const [verificationStatus, setVerificationStatus] = useState(
    govtIdVerificationStatus
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setVerificationStatus(govtIdVerificationStatus);
  }, [govtIdVerificationStatus]);

  const getRejectionReason = async () => {
    const { status, body } = await getGovtIdRejectionReason({
      id: userId,
    });

    if (status === 200 && body?.reason) {
      Modal.error({
        title: 'Rejection Reason',
        content: body?.reason,
      });
      return;
    }

    Modal.error({
      title: 'Rejection Reason',
      content: 'No reason found',
    });
  };

  const handleChangeOption = (value) => {
    setSelectedDocumentType(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsUploading(true);

    const formData = new FormData();

    formData.append('front_image', frontSideImage);

    if (backSideImage) formData.append('back_image', backSideImage);

    formData.append('govt_id_type', selectedDocumentType);

    try {
      await uploadVerificationDoc(userId, formData);
      message.success('Document uploaded successfully');
      setIsModalVisible(false);
      setVerificationStatus('in_progress');
    } catch (error) {
      message.error('Something went wrong. Please try again later.');
    }
    setFrontSideImage(null);
    setBackSideImage(null);
    setIsUploading(false);
  };

  const frontSideImageProps = {
    name: 'file',
    multiple: false,
    beforeUpload: (file) => {
      setFrontSideImage(file);
      return false;
    },
    listType: 'picture',
    onRemove: () => {
      setFrontSideImage(null);
    },
    maxCount: 1,
    fileList: frontSideImage ? [frontSideImage] : [],
  };
  const backSideImageProps = {
    name: 'file',
    multiple: false,
    beforeUpload: (file) => {
      setBackSideImage(file);
      return false;
    },
    onRemove: () => {
      setBackSideImage(null);
    },
    listType: 'picture',

    maxCount: 1,
    fileList: backSideImage ? [backSideImage] : [],
  };

  const isButtonDisabled = () =>
    !(selectedDocumentType === 'Passport'
      ? frontSideImage && backSideImage
      : frontSideImage || backSideImage);

  return (
    <div className="verifyModal__container">
      <Button
        onClick={showModal}
        disabled={verificationStatus === 'completed'}
        type={verificationStatus === 'completed' ? 'primary' : 'danger'}
        shape="round"
        style={
          verificationStatus === 'completed'
            ? { backgroundColor: 'rgba(17, 174, 135, 1)', color: 'white' }
            : null
        }
      >
        {verificationStatus === 'completed' && (
          <>
            <IdcardOutlined /> Govt Id : Verified
          </>
        )}
        {verificationStatus === 'in_progress' && (
          <>
            <IdcardOutlined /> Govt Id : Verification Pending
          </>
        )}
        {verificationStatus === 'reject' && (
          <>
            <IdcardOutlined /> Govt Id : Rejected
          </>
        )}
        {verificationStatus === 'fv_failed' && (
          <>
            <IdcardOutlined /> Govt Id : Face Verification Failed
          </>
        )}
        {verificationStatus === 'failed' && (
          <>
            <IdcardOutlined /> Govt Id : Verification Failed
          </>
        )}
        {verificationStatus === null && (
          <>
            <IdcardOutlined /> Govt Id : Not Verified
          </>
        )}
      </Button>
      {verificationStatus === 'reject' && (
        <Button
          onClick={getRejectionReason}
          type={verificationStatus === 'completed' ? 'primary' : 'danger'}
          shape="round"
          style={{
            backgroundColor: 'gray',
            color: 'white',
            borderWidth: 0,
            marginLeft: 10,
          }}
        >
          <MessageOutlined />
        </Button>
      )}
      <Modal
        title="Document Verification Modal"
        okText="Upload"
        visible={isModalVisible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        maskClosable={false}
        okButtonProps={{
          disabled: isButtonDisabled(),
          loading: isUploading,
        }}
      >
        <div className="verifyModal__modal">
          <Select
            defaultValue="Pan Card"
            style={{ width: 120 }}
            onChange={handleChangeOption}
          >
            <Option value="Pan Card">Pan Card</Option>
            <Option value="Driving License">Driving License</Option>
            <Option value="Voter ID">Voter ID</Option>
            <Option value="Passport">Passport</Option>
          </Select>
          {/* FRONT SIDE */}
          <Dragger {...frontSideImageProps}>
            <>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <Title level={5}>Front-side of the document</Title>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </>
          </Dragger>
          {/* BACK SIDE */}
          <Dragger {...backSideImageProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <Title level={5}>Back-side of the document</Title>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>
        </div>
      </Modal>
    </div>
  );
};
export default VerifyModal;
